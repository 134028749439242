import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'validation-dialog',
    template: `
    <div class="validation">
    <div class="header color-primary-bg">
        <h3 class="title"> Validation </h3>
    </div>
    <div class="main">
        {{data}}
    </div>
    <div class="footer">
        <button mat-raised-button color="primary"  class="cancel rounded" (click)="close()">Ok</button>
    </div>
    </div>
 `,
    styleUrls: ['./message-component.scss']

})
export class ValidationDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<ValidationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data) { }

    close(): void {
        this.dialogRef.close();
    }

}
