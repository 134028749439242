import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';
import { DynButtonInterface } from '../shared/dyn-button.interface';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'dyn-button',
    template: `  <button mat-button  (click)="handlerFunction(field,$event)" >{{to.text}}</button>`,
    styleUrls: ['./dyn-button.component.scss']
})
export class DynButtonComponent extends FieldType {
    constructor(private dynButtonService: DynButtonInterface) {
        super();
    }
    handlerFunction(field, event) {
        this.dynButtonService.onClick(field,event,this.field.templateOptions.id);
    }
}


