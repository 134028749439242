import { Injectable } from '@angular/core';
import { IStorage } from './storage';
import { Observable, ReplaySubject } from 'rxjs';

const AUTH_STORAGE_KEY = 'access-token';
const USER_DATA_STORAGE_KEY = 'userData';
const API_STORAGE_KEY = 'storageKey';

@Injectable()
export class StorageService implements IStorage {
  public token: Observable<string>;

  private _tokenSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _storage: Storage;

  constructor() {
    this.token = this._tokenSubject.asObservable();
    this._storage = localStorage;
  }

  public async updateUserData(userData) {
    this._storage.setItem(AUTH_STORAGE_KEY, userData.token);
    this._tokenSubject.next(userData.token);
    return this.setLocalData(USER_DATA_STORAGE_KEY, userData);
  }

  public async getUserData() {
    return this.getLocalData(USER_DATA_STORAGE_KEY);
  }

  public async clearUserData() {
    this._storage.removeItem(AUTH_STORAGE_KEY);
    this._tokenSubject.next(undefined);
    return Promise.resolve();

  }

  public async getAuthToken(): Promise<string> {
    return Promise.resolve(this._storage.getItem(AUTH_STORAGE_KEY));
  }

  // Save data
  public setLocalData<T = any>(key: string, data: T): Promise<T> {
    console.log('Set local data for: ', `${API_STORAGE_KEY}-${key}`);
    this._storage.setItem(`${API_STORAGE_KEY}-${key}`, JSON.stringify(data));
    return Promise.resolve(data);
  }

  // Get cached data
  public getLocalData<T = any>(key: string): Promise<T> {
    console.log('Getting cached data for: ', `${API_STORAGE_KEY}-${key}`);
    const item = this._storage.getItem(`${API_STORAGE_KEY}-${key}`);
    return !!item ? Promise.resolve(JSON.parse(item)) : Promise.reject({message: 'No Cached data found'});
  }
}
