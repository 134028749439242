import { InjectionToken } from "@angular/core";

export const LoggerConfig = new InjectionToken<ILogger>("Logger_Config");

// serverLoggingUrl: '/api/es/apps/environment/vochap',
// level: NgxLoggerLevel.DEBUG,
// serverLogLevel: NgxLoggerLevel.ERROR,
// disableConsoleLogging : !environment.isDevelopment
export interface ILogger {
  serverLoggingUrl: string;
  disableConsoleLogging?: boolean;
  serverLogLevel?: number;
}

export enum LoggerLevel {
  Info = 1,
  Debug = 2,
  Warn = 3,
  Error = 4,
}
