import {
    Pipe,
    PipeTransform
} from '@angular/core';
import {
    IRequest, searchForText
} from '@discoverer/core/services';
import { FlattenedDirRequests } from '@discoverer/dynamic-reports/common/filtered-reports-view/filtered-reports-view.component';


@Pipe({
    name: 'filterReq',
    pure: true
})
export class FilterRequestsPipe implements PipeTransform {
    transform(items: FlattenedDirRequests[], filter: string): FlattenedDirRequests[] {
        if (!items || !filter) { return items; }

        return items.filter(item => searchForText(filter, `${item?.requestName} ${item?.directoryName} ${item?.tabName}`));
    }
}


