<div class="histogram-container">
    <div *ngIf="histogramData.length > 0  else LOADING">
        <disco-histogram (seriesClicked)="seriesClick($event)" [range]="formattedRange" [histogramData]="histogramData">
        </disco-histogram>
    </div>
    <ng-template #LOADING>
        <div class="spinner-container">
            <mat-spinner class="spinner"></mat-spinner>
        </div>
    </ng-template>
</div>
