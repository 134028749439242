import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IStateService } from './state-service';

export class CombinedStateService<T> implements IStateService<T> {
    public oState: Observable<T>;
    private state: { [key: string]: any };

    constructor(private observables: any) {
        this.combineObservables();
        this.oState.subscribe(s => {
            this.state = s;
        });
    }

    public getState(): any {
        return Object.assign({}, this.state);
    }

    public setValue(val: any, reset = false) {
        // TODO: Implement
    }

    public destroy() {
        // TODO: Implement
    }

    private combineObservables() {
        const oArray = Object.keys(this.observables).map(k => this.observables[k]);
        this.oState = combineLatest(oArray).pipe(map(arr => {
            const res = {} as T;
            Object.keys(this.observables).forEach((k, i) => {
                res[k] = arr[i];
            });
            return res;
        }));
    }
}
