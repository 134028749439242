import { Component, Inject, OnInit } from '@angular/core';
import { ActionService } from '../../../services/api/action.service';
import { BaseController } from '@discoverer/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatasetActionType } from '../../../models/enums';
export class TestActionModel {
    datasetId: string;
    configuration: any;
    testingFileId: string;
    type: DatasetActionType;
}
@Component({
    selector: 'app-test-action',
    templateUrl: './test-action-dialog.component.html',
    styleUrls: ['./test-action-dialog.component.scss']
})
export class TestActionDialogComponent extends BaseController implements OnInit {
    public testResult: string[];
    public loading = true;
    public errorMsg: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: TestActionModel,
        private dialogRef: MatDialogRef<TestActionDialogComponent>,
        private _actionsService: ActionService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.runTest()
    }

    close() {
        this.dialogRef.close();
    }
    async runTest() {
        try {
            this.errorMsg = null;
            this.loading = true;
            const { isSuccess, result } = (await this._actionsService.runTest(this.data.datasetId,
                {
                    testingFileId: this.data.testingFileId,
                    configuration: this.data.configuration,
                    type: this.data.type
                }).toPromise())
            if (isSuccess) {
                this.testResult = result;
            } else {
                this.errorMsg = 'Something went wrong!';
            }
        } catch (ex) {
            this.errorMsg = 'Something went wrong: ' + ex.error.title;
        } finally {
            this.loading = false;
        }
    }

}
