import { Pipe, PipeTransform } from '@angular/core';
import { IFacetDefinition } from '@discoverer/core/services';

@Pipe({
    name: 'filterPinnedFilters',
    pure: false
})
export class FilterPinnedFiltersPipe implements PipeTransform {
    transform(items: IFacetDefinition[], filters: any[] = []): any {
        if (!items) { return items; }
        return items.filter(pf => !filters.find(f => f.fields[0] === pf.name) && pf.isPinned)
    }
}
