<div class="wrapper">
    <div class="header">
        <button *ngIf="showIcon" class="header-icon" mat-icon-button (click)="toggleOuterSidenav()">
            <mat-icon>menu</mat-icon>
        </button>
        Profile
    </div>

    <div class="main">
        <div class="row-form">
            <div class="profile-initials">
                {{nameInitials}}
            </div>

            <div class="profile-details">
                <form (ngSubmit)="saveProfile()" [formGroup]="fg">
                    <div class="name">
                        <mat-label>Full name</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput placeholder="Full name" formControlName="fullname" />
                            <mat-error *ngIf="fg.get('fullname').invalid">Full name is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="email">
                        <mat-label>Email address</mat-label>
                        <mat-form-field appearance="outline">
                            <input matInput placeholder="Email address" formControlName="email" />
                            <mat-error *ngIf="fg.get('email').invalid">Email address is required</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-error *ngIf="fgInvalid">Invalid form</mat-error>
                </form>
            </div>
        </div>
        <div class="row-actions">
            <div class="actions">
                <button mat-stroked-button color="primary" type="submit" (click)="saveProfile()">
                    <mat-icon *ngIf="!isLoading">save</mat-icon>
                    <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
                    Save
                </button>
            </div>
        </div>


    </div>
</div>