import { Observable, Subject, ReplaySubject } from 'rxjs';

import { DRQuery } from '../classes';
import { IDRFilter } from '../classes/filter.interface';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { indexOf } from 'lodash';
export abstract class IFilterSortDataService<ROW> {
  public oQuery: Observable<DRQuery>;

  get filters(): IDRFilter[] {
    return this._filters;
  }

  get sorts(): { [key: string]: string } {
    return this._sorts;
  }

  get start(): number {
    return this._start;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  get fields(): Array<string> {
    return this._fields;
  }

  get numberOfRecords() {
    return this._numberOfRecords;
  }

  get randomSeed() {
    return this._randomSeed;
  }

  get groups(): GroupDescriptor[] {
    return this._groups;
  }

  get dataFlattenBy(): string {
    return this._dataFlattenBy;
  }

  protected _sQuery: Subject<DRQuery>;
  protected _filters: IDRFilter[] = [];
  protected _sorts: { [key: string]: "asc" | "desc" } = {};
  protected _fields: Array<string> = [];
  protected _groups: GroupDescriptor[] = [];
  protected _dataFlattenBy: string = '';
  // change flags
  protected _changeId = 0;
  protected _sortChanged = false;
  protected _groupChanged = false;
  protected _pageChanged = false;
  protected _filterChanged = false;
  protected _dataFlattenByChanged = false;
  // paging
  protected _pageNumber = 1;
  protected _pageSize = 0;
  protected _start = 1;
  protected _numberOfRecords = 0;
  protected _randomSeed = 0;

  protected static createMergedFilter(
    parentFilters: IDRFilter[],
    childFilters: IDRFilter[]
  ) {
    let combinedFilterArr = childFilters;
    if (parentFilters.length > 0) {
      combinedFilterArr = combinedFilterArr.concat(parentFilters);
    }
    return combinedFilterArr;
  }

  protected static createMergedSort(
    parentSorts: { [key: string]: string },
    childSorts: { [key: string]: string }
  ) {
    const combinedFilterObj = Object.assign({}, childSorts);
    if (!!parentSorts) {
      const combinedKeys = Object.keys(parentSorts).concat(
        Object.keys(childSorts)
      );
      combinedKeys.forEach((k) => {
        combinedFilterObj[k] = !!childSorts[k] ? childSorts[k] : parentSorts[k];
      });
    }
    return combinedFilterObj;
  }

  constructor() {
    this._sQuery = new ReplaySubject<DRQuery>(1);
    this.oQuery = this._sQuery.asObservable();
  }

  public setFields(val: Array<string>) {
    this._fields = val;
    this._changeId++;
  }

  public resetFields() {
    this._fields = [];
    this._changeId++;
  }

  public setFilter(filterName: string, filter: IDRFilter, addToEnd = true) {
    let filterIndex = -1;
    if (filterName == "Advanced") {
      filterIndex = this._filters.findIndex(
        (f) =>
          (f.type == "All" || f.type == "Any")
      );
    } else {
      filterIndex = this._filters.findIndex(
        (f) =>
          f.type == filter.type &&
          f.fields.includes(filterName)
      );
    }
    if (filterIndex >= 0) {
      this._filters.splice(filterIndex, 1);
      this._filters.splice(filterIndex, 0, filter);
    } else {
      if (addToEnd) {
        this._filters.push(filter);
      } else {
        this._filters.unshift(filter);
      }
    }
    this._filterChanged = true;
    this._changeId++;
  }


  public unSetFilter(filterName: string) {
    let filterIndex = -1;
    if (filterName.includes('Advanced_Facet')) {
      filterIndex = this._filters.findIndex(
        (f) => f.type == "All" && f.fields.includes(filterName)
      );
    }
    else if (filterName == "Advanced") {
      filterIndex = this._filters.findIndex(
        (f) =>
          (f.type == "All" || f.type == "Any")
      );
    } else if (filterName == "Search" || filterName == "search") {
      filterIndex = this._filters.findIndex((f) => f.type == "text");
    } else {
      filterIndex = this._filters.findIndex(
        (f) => f.type == "facet" && f.fields.includes(filterName)
      );
    }
    if (filterIndex > -1) {
      this._filters.splice(filterIndex, 1);
    }

    this._filterChanged = true;
    this._changeId++;
  }

  public unSetAdvFilter(filterName: string) {
    //let advFilter = this.filters['Advanced'];
    let advFilter = this.filters.filter(
      (f) => (f.type == "All" || f.type == "Any")
    )[0];
    if (advFilter.filters.length === 1) {
      let advFilterIndex = this._filters.indexOf(advFilter);
      this._filters.splice(advFilterIndex, 1);
      return;
    }

    let filterIndex = advFilter.filters.findIndex(
      (f) => f.fields[0] === filterName
    );
    advFilter.filters.splice(filterIndex, 1);
    this._filterChanged = true;
    this._changeId++;
  }

  public setOrderBy(column: string, dir?: "asc" | "desc") {
    if (!!dir) {
      this._sorts[column] = dir;
      this._sortChanged = true;
      this._changeId++;
    }
  }

  public unsetOrderBy(column: string) {
    delete this._sorts[column];
    this._sortChanged = true;
    this._changeId++;
  }

  public unsetGroupBy(group: string) {
    const index = this.groups.findIndex((x) => x.field === group);
    this._groups.slice(index, 1);
    this._groupChanged = true;
    this._changeId++;
  }
  private _generateGroupSorts():  {[key:string]: 'asc'|'desc'} {
    return (this.groups || []).reduce((sorts, { field, dir }) => {
        sorts[field] = dir;
        return sorts;
    }, {} as {[key:string]: 'asc'|'desc'});
}

public resetOrderBy() {
    this._sorts = this._generateGroupSorts();
    this._sortChanged = true;
    this._changeId++;
}

  public resetGroupBy() {
    this._groups = [];
    this._groupChanged = true;
    this._changeId++;
  }

  public resetDataFlattenBy() {
    this._dataFlattenBy = '';
    this._dataFlattenByChanged = true;
    this._changeId++;
  }

  public resetFilters() {
    this._filters = [];
    this._filterChanged = true;
    this._changeId++;
  }

  public setDataFlattenBy(dataFlattenBy: string) {
    this._dataFlattenBy = dataFlattenBy;
    this._dataFlattenByChanged = true;
    this._changeId++;
  }

  public setPageParams(pageNumber: number, pageSize: number) {
    this.setPageStart(pageSize * (pageNumber - 1) + 1, pageSize);
  }

  public setPageStart(start: number, pageSize: number) {
    this._start = start;
    this._pageSize = pageSize;
    this._pageChanged = true;
    this._changeId++;
  }

  public setNumberOfRecords(numberOfRecords: number) {
    this._numberOfRecords = numberOfRecords;
  }

  public setRandomSeed(seed: number) {
    this._randomSeed = seed;
  }

  public setGroups(groups: GroupDescriptor[]) {
    this._groups = groups;
    this._groupChanged = true;
    this._changeId++;
  }
  public setFilterMode(filterMode) {
    let filters = this._filters;
    let advFilter = filters.filter(f => f.type == 'Any' || f.type == 'All')[0];
    if (advFilter) {
      advFilter.type = filterMode;
    }
    filters.forEach(filter => {
      filter.filterMode = filterMode;
    });
    this._filterChanged = true;
    this._changeId++;

  }
  public hasAnyStateChanged() {
      return this._sortChanged || this._filterChanged || this._pageChanged || this._groupChanged || this._dataFlattenByChanged;
  }
  public abstract refresh(): void;
}
