
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BaseController, StateService, IColumnSetting, IFacetDefinition, IMainViewState, TabSettingsService } from '@discoverer/core/services';


@Component({
    selector: 'dynamic-filter',
    templateUrl: './dynamic-filter.component.html',
    styleUrls: ['./dynamic-filter.component.scss']
})
export class DynamicFilterComponent extends BaseController implements OnInit {

    @Input() public viewState: StateService<IMainViewState>;

    @Output()
    public isValidFacetsEvent?: EventEmitter<boolean>;

    public rightColumns: IColumnSetting[] = [];
    public defaultColumns: IColumnSetting[] = [];
    public allColumns: IColumnSetting[] = [];
    public filterAllColumns: IColumnSetting[] = [];
    public isDefaultFilter = true;
    public once = true;
    hasFacet: boolean;

    constructor(private tabSettings: TabSettingsService) {
        super();
        this.isValidFacetsEvent = new EventEmitter<boolean>();
    }
    public async ngOnInit() {
        let subs = null;
        subs = this.viewState.oState.subscribe(async state => {
            const defaultFacets = await this.tabSettings.getDefaultFacetColumns();
            this.hasFacet = true ; // (!!defaultFacets && defaultFacets.length > 0 && !!defaultFacets[0] && defaultFacets[0].length > 0);

            this.defaultColumns = Object.assign([], await defaultFacets);
            if (!!subs) {
                subs.unsubscribe();
            }
        });

        this.subscriptions.push(this.viewState.oState.subscribe(async state => {
            const facets = (state.isDefaultFacets) ? await this.tabSettings.getDefaultFacetColumns() : await this.tabSettings.translateToColumnList( state.facets.filter(f => f.group == "0").map(f => f.name));
            this.rightColumns = facets;
        }));
        this.allColumns = await this.tabSettings.getAllColumns();
        this.filterAllColumns = this.allColumns.filter(x => x.dataType !== 'text');
    }

    public updateCustomFilter(columnSettings: IColumnSetting[]) {
        this.isValidFacetsEvent.emit(!!columnSettings && columnSettings.length >= 0);
        this.viewState.setValue({ facets: columnSettings.map(col => col.fieldName) }, false);
    }

    public async updateDefaultFilter(isDefaultFacets: boolean) {
        const mvState = this.viewState.getState();
        mvState.facets = (await this.tabSettings.getDefaultFacetColumns()).map(x => { return { group: "0", name:x.fieldName } as IFacetDefinition});
        mvState.isDefaultFacets = true;
        this.isDefaultFilter = false;
        this.viewState.setValue(mvState);
    }

    public async useCustomFilters(isDefaultFacets: boolean) {
        this.isDefaultFilter = isDefaultFacets;
        this.rightColumns = await this.tabSettings.getDefaultFacetColumns();
        this.viewState.setValue({ facets: this.rightColumns.map( f=> f.fieldName) });
        this.viewState.setValue({ isDefaultFacets });
    }

}
