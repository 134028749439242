import { Component, Input, OnInit } from '@angular/core';
import {
    FacetValue, FacetFieldAccumulator, DRFacetOption, DiscovererQueryService, DRFilter, IDRFilter,
    NoDataRow, DiscovererDataService, DiscovererQueryInfoService,
} from '../services';
import { HttpClient } from '@angular/common/http';
import { EsQueryService } from '../services/core-data-services/discoverer-esquery-service';

@Component({
    selector: 'dropdown-list-facet',
    styleUrls: ['./dropdown-list-facet.component.scss'],
    templateUrl: 'dropdown-list-facet.component.html'
})
export class DropdownListFacetComponent implements OnInit {
    @Input()
    title: string;

    @Input()
    public queryService: DiscovererQueryService;

    @Input()
    facetKey: string;

    @Input()
    serviceUrl: string;

    @Input()
    isDynamic = false;

    colorLookup: any = {};

    facetValues: FacetValue[] = [];
    sortedFacetValues: FacetValue[] = [];
    selectedFacetValues: FacetValue[] = [];

    facetData: FacetFieldAccumulator;
    isOpen = false;
    orderByCount = false;
    private facetValueLimit = 10;

    private _dataService: DiscovererDataService<NoDataRow>;

    constructor(
        http: HttpClient,
        private queryInfoService: DiscovererQueryInfoService,
        private esQueryService:EsQueryService) {

        this._dataService = new DiscovererDataService(http,this.esQueryService);
        this.facetData = new FacetFieldAccumulator(this.title);
    }

    public facetValueChanged(facetValue: FacetValue) {
        const checked = facetValue.isChecked;
        facetValue.updateCheck(checked);
        this.selectedFacetValues = this.facetValues.filter(x => x.isChecked);
        this.refreshFilters(this.selectedFacetValues);
    }

    public selectFacetValue($event: any, facetValue: FacetValue) {
        if ($event.ctrlKey) {
            facetValue.isExclusion = true;
        }
        this.facetValues.forEach(fv => {
            fv.isChecked = false;
        });
        facetValue.isChecked = true;
    }

    ngOnInit() {
        this._dataService.init(this.serviceUrl + '?name=' + this.facetKey, this.queryService);
        this._dataService.setPageStart(1, 0);
        this._dataService.enabled = true;
        this._dataService.setDataIsRequested(this.facetKey, true);
        this._dataService.enabled = true;
        this._dataService.refresh();
        this.queryInfoService.oQueryInfo.subscribe(x => {
            this.facetValues = x[this.facetKey].facetOptions
                .map((value: DRFacetOption) => {
                    return new FacetValue(value);
                });
        });
        this._dataService.oFacetResults.subscribe(val => {
            val.forEach(result => {
                if (result.field === this.facetKey) {
                    this.facetData = result;
                    if (this.isDynamic) {
                        const keyMap = result.getKeyMap();
                        const facetValuesMap = {};
                        this.facetValues.forEach(f => {
                            facetValuesMap[f.facetKey] = f;
                        });
                        this.facetValues = Object.keys(keyMap).map(name => {
                            const key = keyMap[name];
                            return !!facetValuesMap[key] ? facetValuesMap[key] :
                                new FacetValue(new DRFacetOption(key, '"' + name + '"', name));
                        }
                        );
                    }
                    this.sortFacets();
                }
            });
        });
    }
    private sortFacets() {
        if (this.facetData != null && this.orderByCount) {
            this.sortedFacetValues =
                this.facetValues.sort((a, b) =>
                    this.facetData.getValue(b.facetKey) - this.facetData.getValue(a.facetKey)
                ).slice(0, this.facetValueLimit);
        } else {
            this.sortedFacetValues = this.facetValues;
        }
    }

    private refreshFilters(selectionList: FacetValue[]) {
        if (selectionList === undefined || selectionList.length === 0) {
            this.queryService.unSetFilter(this.facetKey);
        } else {
            this.queryService.setFilter(this.facetKey, new DRFilter('facet', [this.facetKey], selectionList));
        }
        this.queryService.refresh();
    }
}
