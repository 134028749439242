import {DRStat, DRFacetOption} from './discoverer.class';

export class DRFacetInfo {

    private _facetOptionDictionary: { [key: string]: DRFacetOption } = {};

    public getFacetOption( key: string ): DRFacetOption {
        return this._facetOptionDictionary[key];
    }

    public toString(): string {
        return this.facetOptions.map( x => x.key + '+' + x.query + '+' + x.display).join(',');
    }

    constructor( public name: string,
                 public type: string,
                 public field: string,
                 public display: string,
                 public shortDisplay: string,
                 public facetOptions: Array<DRFacetOption> ) {
        facetOptions.forEach( facetOption => {
            this._facetOptionDictionary[facetOption.key] = facetOption;
        });
    }

}

export class DRQueryInfo  {

    public getStats(): DRStat[] {
        return <DRStat[]>this.facetInfos.filter( x => x.type === 'stat' );
    }

    constructor( public facetInfos: Array< { name: string, type: string, display: string, field: string }> ) {
        facetInfos.forEach( facetInfo => {
            this[facetInfo.name] = facetInfo;
        });
    }

}
