import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { FormsDataServiceInterface, OptionDataServiceInterface } from '@discoverer/dynamic-forms';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';
import { DatasetActionType, FieldCategoryEnum } from '../../../models/enums';
import { HasuraOptionService } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { ActionModel } from '../../../models/action.model';
import { actionTypeImage, actionTypeName } from '../../../models/constants';
import { ActionFormService } from '../../../services/action-form.service';
import { ActionService } from '../../../services/api/action.service';
import { DatasetService } from '../../../services/api/dataset.service';
import { IgniteTabService } from '../../../services/ignite-tab.service';
import { SchemaService } from '../../../services/schema.service';
import { debounceTime, take } from 'rxjs/operators';
import { BaseController } from '@discoverer/core';
import { Field } from '../../../helpers/docpipeline-function';
import { ERROR_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';
import { TestActionHandlerService } from './test-action-handler.service';
import { TestActionDialogComponent, TestActionModel } from '../test-action-dialog/test-action-dialog.component';

@Component({
    selector: 'action-details-dialog',
    templateUrl: './action-details-dialog.component.html',
    styleUrls: ['./action-details-dialog.component.scss'],
    providers: [
        { provide: FormsDataServiceInterface, useClass: ActionFormService, deps: [HttpClient, ActionService] },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService }
    ]
})

export class ActionDetailsDialogComponent extends BaseController implements OnInit, OnDestroy {
    @ViewChild('dynForm') dynForm: DynFormComponent;
    @ViewChild('stepper') stepper: MatStepper;
    public promptMenuItems = [];
    public DatasetActionType = DatasetActionType;
    public isLoading = false;
    public isEditMode: boolean = false;
    public actionNameTitle: string = '';
    public testActionHandlerService: TestActionHandlerService = new TestActionHandlerService();
    public formData: ActionModel = {} as ActionModel;
    public promptValue: string = '';
    public actionList: ActionModel[] = [];
    public dialogData: ActionModel

    public availableActions = [
        {
            type: DatasetActionType.Webhook,
            description: 'Add a webhook to send the parsed data and events to your server or a 3rd-party platform and create any custom action.',
            imageUrl: 'assets/images/logo/webhook-logo.png',
            disabled: false
        },
        {
            type: DatasetActionType.AiGenerate,
            description: 'Add a Field to generate dynamic content from extracted data using custom prompts and include the output alongside the extracted results.',
            imageUrl: 'assets/images/logo/magic.png',
            disabled: false
        }
    ];

    constructor(public dialogRef: MatDialogRef<ActionDetailsDialogComponent>,
        private _snackBar: MatSnackBar,
        private _actionService: ActionService,
        private _igniteTabService: IgniteTabService,
        private _schemaService: SchemaService,
        private _datasetService: DatasetService,
        @Inject(MAT_DIALOG_DATA) public data: { data: ActionModel, actionList: ActionModel[] },
        protected dialog: MatDialog) {
        super();
        this.dialogData = this.data.data;
        this.actionList = this.data.actionList;
        this.actionNameTitle = !!this.dialogData?.id ? this.dialogData.name : null;
        this._actionService.updateActionNameTitle(this.actionNameTitle);
    }

    public ngOnDestroy(): void {
        this._actionService.updateActionNameTitle('');
    }
    ngOnInit() {
        this._init(this.dialogData.type);
    }

    public editorChange(event: { html: string, text: string }) {
        this.formData.configuration.prompt = event.text;
    }

    public runTest(): void {
        const testFileId = localStorage.getItem(this.formData.datasetId);
        if (!testFileId) {
            this._snackBar.open('Please upload a test file first', 'X', ERROR_SNACKBAR_OPTIONS);
            return;
        }
        var data: TestActionModel = {
            datasetId: this.formData.datasetId,
            configuration: this.formData.configuration,
            testingFileId: testFileId,
            type: this.formData.type
        }
        this.dialog.open(TestActionDialogComponent, { data, width: '50%', height: '500px' });
    }

    public onActionNameTitleChange(newName: string) {
        this.actionNameTitle = newName;
        this._actionService.updateActionNameTitle(newName);
    }

    public getActionDynFormKey(actionType: DatasetActionType): string {
        switch (actionType) {
            case DatasetActionType.Webhook:
                return 'new-webhooks'
            case DatasetActionType.AiActionField:
                return 'new-ai-action'
            case DatasetActionType.AiGenerate:
                return 'new-ai-generate'
        }
    }

    public getActionImage(type: DatasetActionType): string {
        return actionTypeImage.get(type)
    }

    public close(value = null) {
        this.dialogRef.close(value);
    }

    public async save() {
        this.isLoading = true;
        // if (this.actionNameTitle === 'Enter Action Name') {
        //     this._snackBar.open('Please enter action name', 'X', ERROR_SNACKBAR_OPTIONS);
        //     this.isLoading = false;
        //     return;
        // }

        switch (this.dialogData.type) {
            case DatasetActionType.Webhook:
                if (!this.dynForm.form.valid) {
                    this.isLoading = false;
                    return;
                }
                this.dynForm.submit();
                break;
            case DatasetActionType.AiGenerate:
                const actionNameTitle = this._actionService.getActionNameTitle();
                this.formData.name = actionNameTitle;
                if (this.formData.configuration.prompt === '' || this.formData.storeAs.display === '') {
                    this._snackBar.open('Please fill all the fields', 'X', ERROR_SNACKBAR_OPTIONS);
                    this.isLoading = false;
                    return;
                }
                let {isSuccess, result} = await ((!!this.formData.id)
                    ? this._actionService.update(this.formData)
                    : this._actionService.create(this.formData)).toPromise()
                if (isSuccess) {
                    this.isLoading = false;
                    this.onSubmit(result);
                } else {
                    this.isLoading = false;
                    console.error('Error during saving:', result);
                    this._snackBar.open('An error occurred while saving. Please try again. ', 'X', ERROR_SNACKBAR_OPTIONS);
                }
                break;
            default:
                throw `action type "${this.formData.type}" not implemented`;
        }
    }

    public isSaveDisabled(): boolean {
        switch (this.dialogData.type) {
            case DatasetActionType.Webhook:
                return !this.dynForm?.form?.valid;
            case DatasetActionType.AiGenerate:
                return !this.formData.configuration.prompt || !this.formData.storeAs.display;
            default:
                return true;
        }
    }

    public async onSubmit(event): Promise<void> {
        if (!!this.formData.storeAs?.display && this.formData.storeAs?.display !== '') {
            try {
                this.isLoading = true;
                await this._schemaService.updateDatasetSchema(this.formData.datasetId);
                const fields = await this._schemaService.oCurrentSchema.pipe(take(1)).toPromise()
                const files = await this._igniteTabService.initTab('All Records', fields, this.formData.datasetId);
                await this._datasetService.createOrUpdateAssets(this.formData.datasetId, files).toPromise();
                this.formData.datasetId = this.formData.datasetId;
                if (event) {
                    if (event.error) {
                        this._snackBar.open(event.error, 'X', ERROR_SNACKBAR_OPTIONS);
                    }
                    this.close(event);
                }
            } catch (error) {
                console.error('Error during submission:', error);
                this._snackBar.open('An error occurred while submitting. Please try again.', 'X', ERROR_SNACKBAR_OPTIONS);
            } finally {
                this.isLoading = false;
            }
        } else {
            this.close(event);
            this.isLoading = false;
        }

    }

    public getActionName(type: DatasetActionType): string {
        return actionTypeName.get(type)
    }

    public selectAction(type: DatasetActionType): void {
        this._init(type);
        setTimeout(() => {
            this.stepper.next();
        }, 500);
    }

    private objectToArray(obj: { [key: string]: Field; } = {}): any[] {
        if (!obj || typeof obj !== 'object')
            return [];

        return Object.entries(obj).filter(([key]) => obj[key].field_category === FieldCategoryEnum.Default).map(([key, value]) => ({
            ...value,
            key: key,
            fieldName: value.fieldName ?? key,
            fields: value.fields ? this.objectToArray(value.fields) : undefined,
        }));
    }

    private flattenData(data: any[], parentFieldName: string = '', parentDisplay: string = ''): any[] {
        const result: any[] = [];

        const processFields = (fields: any[], parentFieldName: string, parentDisplay: string) => {
            fields.forEach((field) => {
                const tag = parentFieldName ? `${parentFieldName}.${field.fieldName}` : field.fieldName;
                const display = parentDisplay ? `${parentDisplay} - ${field.display}` : field.display;

                if (field.type === 'object_array' || field.type === 'object') {
                    processFields(field.fields, field.fieldName, field.display);
                } else {
                    result.push({ ...field, tag: tag, display: display });
                }
            });
        };

        processFields(data, parentFieldName, parentDisplay);
        return result;
    }

    private _init(type: DatasetActionType): void {
        this.dialogData.type = type;
        switch (type) {
            case DatasetActionType.Webhook:
                if (this.dialogData.id) {
                    this._loadWebHookData();
                } else {
                    this._setWebHookDefaultValues();
                }
                break;
            case DatasetActionType.AiGenerate:
                this.subscriptions.push(this._schemaService.oCurrentSchema.pipe(debounceTime(100)).subscribe(fields => {
                    const fieldsObjectToArray = this.objectToArray(fields);
                    this.promptMenuItems = this.flattenData(fieldsObjectToArray);
                }));
                if (this.dialogData.id) {
                    this._loadAiGenerateData();
                } else {
                    this._setAiGenerateDefaultValues();
                }
                break;
        }
    }

    private _loadAiGenerateData() {
        this.isEditMode = !!this.dialogData.id;
        this.formData.apiToken = this.dialogData.apiToken;
        this.formData.configuration = this.dialogData.configuration;
        this.formData.name = this.dialogData.name;
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.id = this.dialogData.id;
        this.formData.type = this.dialogData.type;
        this.formData.storeAs = this.dialogData.storeAs;
        this.formData.imageUrl = this.dialogData.imageUrl;
        this.formData.isActive = this.dialogData.isActive;
        this.formData.order = this.dialogData.order;
        this.formData.configuration.prompt = this.dialogData.configuration.prompt;
        this.promptValue = this.dialogData.configuration.prompt;
        this.formData.alwaysRun = this.dialogData.alwaysRun;
        console.log('fafa formdata', this.formData);
        console.log('fafa this.dialogData', this.dialogData);
    }

    private _setAiGenerateDefaultValues() {
        this.formData.storeAs = { display: '' };
        this.formData.configuration = { prompt: '' };
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.type = this.dialogData.type;
        this.formData.order = this.actionList.length + 1;
        this.formData.alwaysRun = false;
    }

    private _loadWebHookData(): void {
        this.isEditMode = !!this.dialogData.id;
        this.formData.id = this.dialogData.id;
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.type = this.dialogData.type;
        this.formData.configuration = this.dialogData.configuration;
        if (!this.formData.configuration.headers.length) {
            this.formData.configuration.headers = [{ key: "", value: "" }];
        }
        this.formData.name = this.dialogData.name;
        this.formData.storeAs = this.dialogData.storeAs;
        this.formData.order = this.dialogData.order;
        this.formData.alwaysRun = this.dialogData.alwaysRun;
    }

    private _setWebHookDefaultValues() {
        this.formData.configuration = {
            headers: [{ key: "", value: "" }]
        };
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.type = this.dialogData.type;
        this.formData.order = this.actionList.length + 1;
        this.formData.alwaysRun = false;
    }
}
