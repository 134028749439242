<div class="boxes-container" *ngIf="dynOptions && dynOptions.length">
    <mat-selection-list [formControl]="$any(formControl)" #selection>
        <mat-list-option *ngFor="let option of dynOptions ; let i = index"
            [value]="useOption?option:option[valueMember]">
            {{getDisplayValue(option)}}
        </mat-list-option>
    </mat-selection-list>
    <p>
        Options selected: {{selection.selectedOptions.selected.length}}
    </p>
</div>