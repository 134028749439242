import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
  Output, EventEmitter
} from '@angular/core';
import {
  BaseController,
  DiscovererDataService, DiscovererFacetTranslationService, DiscovererQueryInfoService,
  DiscovererQueryService, DRFacetOption,
  DRQuery,
  FacetFieldAccumulator,
  FacetValue,
  NoDataRow,
  isEmpty,
  EsQueryService,
  IDisplayFilter,
  searchForText
} from '@discoverer/core/services'
import { combineLatest } from 'rxjs';
import { HttpClient } from '@angular/common/http';


const BLANK = '(Blank)';
const EmptyString = '(Empty String)'
@Component({
  selector: 'advanced-filter-facet',
  styleUrls: ['./advanced-filter-facet.component.scss'],
  templateUrl: 'advanced-filter-facet.component.html'
})
export class AdvancedFilterFacetComponent extends BaseController implements OnInit, OnChanges {
  @Input()
  title: string;

  @Input()
  colorArray: string[];

  @Input()
  singleMode = false;

  @Input()
  facetKey: string;

  @Input()
  serviceUrl: string;

  @Input()
  isDynamic = false;

  @Input()
  queryService: DiscovererQueryService; // 1
  _queryService: DiscovererQueryService;

  @Input()
  maxShow = 20;

  @Input()
  selectedValuesText = '';

  @Input()
  queryInfoServiceUrl: string;

  @Input()
  optionListId: number;

  @Input()
  filter: IDisplayFilter;

  @Output()
  selectedValueChange: EventEmitter<FacetValue[]> = new EventEmitter<FacetValue[]>();

  facetValues: FacetValue[] = [];
  sortedFacetValues: FacetValue[] = [];
  checkedFacetValues: FacetValue[] = [];

  facetData: FacetFieldAccumulator;
  isOpen = false;
  isSearchable = false;
  orderByCount = false;
  totalFiltered = 0;
  pageNumber = 0;
  pageFrom = 0;
  pageSize = 10;
  pageTo = 10;


  private _searchText = '';
  private _dataService: DiscovererDataService<NoDataRow>;
  isEmpty: (str: string) => boolean;
  blank: string;
  emptyString : string;
  public get searchText(): string {
    return this._searchText;
  }

  constructor(
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private queryInfoService: DiscovererQueryInfoService,
    private facetTranslationService: DiscovererFacetTranslationService,
    private esQueryService:EsQueryService) {
    super();
    this.facetData = new FacetFieldAccumulator(this.title);
    this.isEmpty = isEmpty
    this.blank = BLANK
    this.emptyString=EmptyString;
  }

  public facetValueChanged(facetValue: FacetValue) {
    const checked = facetValue.isChecked;
    if (this.singleMode) {
      this.facetValues.forEach(v => v.updateCheck(false));
    }
    facetValue.updateCheck(checked);

    this.checkedFacetValues = this.facetValues.filter(x => x.isChecked);

    this.selectedValueChange.emit(this.checkedFacetValues);
  }

  public selectFacetValue($event: any, facetValue: FacetValue) {
    $event.preventDefault();
    if ($event.ctrlKey) {
      facetValue.isExclusion = true;
    }
    facetValue.isChecked = !facetValue.isChecked;
    this.facetValueChanged(facetValue);
    this.searchTextUpdate('');
  }

  nextPage(event) {
    event.preventDefault();
    console.log(' pageTo >= totalFiltered', this.pageTo, this.totalFiltered);
    (this.pageTo >= this.totalFiltered) ? this.pageNumber = Math.floor(this.totalFiltered / this.pageSize) - 1 : this.pageNumber++;
    this.sortFacets();
  }

  prevPage(event) {
    (this.pageNumber <= 1) ? this.pageNumber = 0 : this.pageNumber--;
    this.sortFacets();
  }

  ngOnInit() {
    this.resetSubsciptions();
    this.subscriptions.push(this.queryInfoService.oQueryInfo.subscribe(x => {
      if (!!x[this.facetKey] && !!x[this.facetKey].facetOptions) {
        this.facetValues = x[this.facetKey].facetOptions
          .map((value: DRFacetOption) => {
            return new FacetValue(value);
          });
      }
    }));
    // if changes happen we need to rebuild dataService
    this.initDataService();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onQueryOrDataServiceChanges(changes);
  }

  private updatePaginationControls() {
    this.pageFrom = (this.pageNumber * this.pageSize);
    this.pageTo = this.maxShow + (this.pageNumber * this.pageSize);
    if (this.pageTo >= this.totalFiltered) {
      this.pageTo = this.totalFiltered;
    }
  }

  private onQueryOrDataServiceChanges(changes: SimpleChanges) {
    if (!!changes['queryService'] || !!changes['facetKey']) {
      this.resetSubsciptions();
      this.subscriptions.push(this.queryInfoService.oQueryInfo.subscribe(x => {
        if (!!x[this.facetKey] && !!x[this.facetKey].facetOptions) {
          this.facetValues = x[this.facetKey].facetOptions
            .map((value: DRFacetOption) => {
              return new FacetValue(value);
            });
        }
      }));
    }
  }

  private resetSubsciptions() {
    while (this.subscriptions.length > 0) {
      const subs = this.subscriptions.pop();
      subs.unsubscribe();
    }
  }

  facetTitleClicked() {
    this.isOpen = !this.isOpen;
    this._dataService.enabled = this.isOpen;
    this.searchTextUpdate('');
    if (this.isOpen) {
      this._dataService.refresh();
    }
    this.cd.markForCheck();
  }

  searchTextUpdate(val: string) {
    this._searchText = val;
    this.pageNumber = 0;
    this.updatePaginationControls();
    this.sortFacets();
  }

  private initDataService() {
    if (this._dataService != null) {
      this._dataService.destroy();
      this._dataService = null;
    }
    this._queryService = new DiscovererQueryService(this.title + '-subadv-main');
    if (this._queryService != null) {
      // queryService avail but no dataService create one
      this._dataService = new DiscovererDataService(this.http,this.esQueryService, { create: (a) => [a] });
      this._dataService.init(this.serviceUrl, this._queryService, this.facetKey);
      this._dataService.enabled = false;
      this._dataService.setFacet(this.facetKey, {
        name: this.facetKey,
        type: 'terms',
        field: this.facetKey,
        excludeTags: [this.facetKey],
        mincount: 1,
        limit: -1,
        display: this.title,
        shortDisplay: this.title
      });
      this._dataService.setPageStart(1, 0);
      const combined = combineLatest(this._dataService.oFacetResults, this._queryService.oQuery);
      this.dataServices.push(this._dataService);
      this.subscriptions.push(
        combined.subscribe(val => {
          const results = val[0];
          const query = val[1];
          this.processResultChanges(results);
          this.processQueryChanges(query);
          if (this.isOpen) {
            this.sortFacets();
            this.cd.markForCheck();
          }
        })
      );
    }
    this._queryService.refresh();
  }

  private processQueryChanges(query: DRQuery) {
    const filter = this.filter;
    if (!!filter) {
      filter?.expression?.forEach(selection => {
        const expressionWithoutExclusion: string = selection.startsWith('!') ? selection.substr(1) : selection;
        const facetValue = (this.facetValues.find(facet => facet.id === expressionWithoutExclusion));
        if (!!facetValue) {
          facetValue.fromExpression(selection);
        }
      });
    }
    this.checkedFacetValues = this.facetValues.filter(x => x.isChecked);
  }

  private processResultChanges(results: FacetFieldAccumulator[]) {
    results.forEach(async result => {
      if (result.field === this.facetKey) {
        this.facetData = result;
        if (this.isDynamic) {
          const keyMap = result.getKeyMap();
          const facetValuesMap = {};
          this.facetValues.forEach(f => {
            facetValuesMap[f.facetKey] = f;
          });
          this.facetValues = Object.keys(keyMap).map(name => {
            const key = keyMap[name];
            return !!facetValuesMap[key] ? facetValuesMap[key] :
              new FacetValue(new DRFacetOption(key, '"' + name + '"', name));
          });
        }
        else {
          await this.getFacetTranslationValuesFromUrl();
        }
        this.setFlagsBasedOnFacetSize();
        this.sortFacets();
      }
    });
  }

  private async getFacetTranslationValuesFromUrl() {
    await this.facetTranslationService.init(this.queryInfoServiceUrl,this.optionListId);
    const facetInfo = this.facetTranslationService.facetTranslationInfo;
    const facetValuesMap = {};
    this.facetValues.forEach(f => {
      facetValuesMap[f.facetKey] = f;
    });
    if (facetInfo != undefined) {
      facetInfo.forEach(info => {
        if (!facetValuesMap[info.Id]) {
          this.facetValues.push(new FacetValue(new DRFacetOption(info.Id, '"' + info.Id + '"', info.Display)));
        }
      });
    }
    if (!facetValuesMap[""]) {
      this.facetValues.push(new FacetValue(new DRFacetOption("", '""', '(Empty String)')));
    }
  }

  private sortFacets() {
    const allFilteredValues = this.facetValues.filter(facet => searchForText(this._searchText, facet.title) );
    this.totalFiltered = allFilteredValues.length;
    this.updatePaginationControls();
    console.log('pageFrom', this.pageFrom, 'pageTo', this.pageTo);

    if (this.facetData != null && this.orderByCount) {
      this.sortedFacetValues = allFilteredValues
        .sort((a, b) =>
          this.facetData.getValue(b.facetKey) - this.facetData.getValue(a.facetKey)
        )
        .sort((a, b) => (a.title < b.title) ? -1 : ((a.title > b.title) ? 1 : 0))
        .sort((a, b) => (b.isChecked ? 1 : 0) - (a.isChecked ? 1 : 0))
        .slice(this.pageFrom, this.pageTo);
    } else {
      this.sortedFacetValues = allFilteredValues;
    }

    this.cd.markForCheck();
  }


  private setFlagsBasedOnFacetSize() {
    if (this.facetValues && this.facetValues.length > this.maxShow) {
      this.orderByCount = true;
      this.isSearchable = true;
    } else {
      this.orderByCount = false;
      this.isSearchable = false;
    }
  }
}
