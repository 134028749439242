import { SafeResourceUrl } from "@angular/platform-browser"
import { FileViewerType } from "./enums"

export class FileInfoDto {
    fileId: string
    taskId?: string
}

export class FileLog {
    stepName: string
    jsonObject: any
    isCopied: boolean
}

export class FileDetailsDto {
    Id: string;
    DatasetKey: string;
    FileTaskId: string;
    Status: FileStatusEnum;
    OriginalFileName: string;
    FileExtension: string;
    CreatedAt: Date;
    IsTest: boolean;
    ParsedDate?: Date;
    UpdatedAt?: Date;
    NeedsReprocessing: boolean;
    ResultsCount?: number;
}
export interface OriginalFileViewerDetails {
    id: string;
    type: FileViewerType;
    extension?: string;
    url?: SafeResourceUrl;
}


export enum FileStatusEnum {
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED',
    PENDING = 'PENDING'
}

export class FilesByPrefixResultDto {
    total: number;
    fileNames: string[];
}

export class EmailDetailsDto {
    EmailHTMLBody: string
    EmailReceiver: string
    EmailSubject: string
    EmailSender: string
}

export class MultiFileDeletionDto {
    fileId: string
    resultIndex: number
}

export class FileLogsDto {
    stepsLog: Array<any>
    actionsLog: Array<any>
}