import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StripeService } from '../services/stripe.service';


@Injectable()
export class StripePlansResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _stripeService: StripeService,
        private _router: Router
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return new Observable<any>(observer => {
            this._stripeService.getPortalSession().subscribe(
                (response) => {
                    if (response.isSuccess) {
                        window.location.href = response.result
                    }
                    else {
                        this._router.navigate(['user-settings', 'profile']);
                    }
                },
                (err) => { }
            );
        })

    }
}
