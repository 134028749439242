import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import {
    MatSnackBar
} from '@angular/material/snack-bar';
import {
    Title
} from '@angular/platform-browser';
import {
    ActivatedRoute,
    Router
} from '@angular/router';
import {
    AuthService,
    GatewayIdentityUser
} from '@discoverer/app-core';
import {
    AppSettingsService,
    BaseController,
    DashboardFiltersService,
    DiscovererQueryService,
    IDashboardReportConfig,
    IDashboardViewState,
    IDirRequest,
    IMainViewState,
    IRequest,
    Permission,
    ReportPersistService,
    RequestFilingService,
    UserPermissionService,
    disLogger
} from '@discoverer/core/services';
import {
    KtdResizeEnd
} from '@katoid/angular-grid-layout';
import {
    Observable,
    combineLatest
} from 'rxjs';
import {
    first
} from 'rxjs/operators';
import {
    ViewTypes,
    resolveReportIcon
} from '../common';
import {
    DASHBOARDS
} from '../common/dashboards-list/dashboards-list.component';
import {
    DialogsManagerService
} from '../dialogs';
import {
    PopoverService
} from '../popover/popover.service';

export const DEFAULT = 'default';

@Component({
    selector: 'app-disco-dashboard',
    templateUrl: './disco-dashboard.component.html',
    styleUrls: ['./disco-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscoDashboardComponent extends BaseController implements OnInit, OnDestroy {
    @Input() showHeader = true;
    appId: string;
    tabs: { key: string, name: string, dirs: IDirRequest[] }[] = [];
    user: GatewayIdentityUser;
    states: Observable<[IDashboardViewState, IMainViewState]>
    selectedTab
    canEditReport = true;
    public canDeleteReport: boolean;
    dirID: number;
    editMode = false
    isNewDashboard = false;

    public dashboardFiltersService: DashboardFiltersService = new DashboardFiltersService();
    constructor(
        private requestFilingService: RequestFilingService,
        public reportPersist: ReportPersistService,
        private activatedRoute: ActivatedRoute,
        public queryService: DiscovererQueryService,
        private _cdRef: ChangeDetectorRef,
        public _appSettingService: AppSettingsService,
        private _router: Router,
        private dialogsManagerService: DialogsManagerService,
        private _authService: AuthService,
        private snackbar: MatSnackBar,
        private _userPermissionService: UserPermissionService,
        private _snackBar: MatSnackBar,
        private popoverService: PopoverService,
        private _title: Title
    ) { super(); }
    ngOnDestroy(): void {
    }
    get Math() { return Math; }

    async ngOnInit(): Promise<void> {
        const { appId } = this.activatedRoute.snapshot.data;
        const { editMode } = this.activatedRoute.snapshot.queryParams;
        // let newReq;
        this.appId = appId;
        this.isNewDashboard = editMode;
        this.editMode = editMode;
        await this._appSettingService.setActiveApp(appId);
        var tabs = (await this._appSettingService.getCurrentAppTabs());
        this.tabs = await Promise.all(tabs.map(async (tab, i) => {
            var dirs = await this.requestFilingService.getDirectories(appId, tab.key, false);
            return {
                ...tab,
                dirs: this._resolveDirRequestsIcons(dirs)
            };
        }));
        this.subscribeToQueryParam();


        this._cdRef.detectChanges();
    }
    private subscribeToQueryParam() {
        this.subscriptions.push(this.activatedRoute?.params?.subscribe(async paramMap => {
            await this.reportPersist.createState(this.queryService, ViewTypes.Dashboard);
            let reqId = paramMap[('reqId')];
            if (reqId === DEFAULT) {
                reqId = (await this._appSettingService.getCurrentApp())?.defaultDashboard;
            }
            await this.reportPersist.init(reqId);
            this.states = combineLatest([this.reportPersist.dashboardViewState.oState, this.reportPersist.mainViewState.oState]);
            const dirId = this.reportPersist.mainViewState.getState().dirId;
            this.changeRequest(dirId);
            this.user = await this._authService.gatewayIdentityUserObservable.pipe(first()).toPromise();
            const { newReqId, tab } = this.activatedRoute.snapshot.queryParams;
            if (!!newReqId && !!tab) {
                const dashboardReports = this.reportPersist.dashboardViewState.getState().reports;
                const newReportExists = dashboardReports.find(rep => rep.id === newReqId);
                if (!newReportExists) {
                    this.addNewReport({ name: this.tabs.find(t => t.key === tab).name, tab: tab, requestId: newReqId });
                } else {
                    this._openSnackBar(`Reprot (${newReportExists.name}) already selected`)
                }
                this._router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: {} });
            }
            this._router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: {} });

            this.dashboardFiltersService.init(this.reportPersist.dashboardViewState);
            this._cdRef.detectChanges();
        }))
    }
    titleChange(title: string) {
        this.reportPersist.mainViewState.setField('mainTitle', title);
    }
    editDashboardName(mainTitle: string) {
        this.dialogsManagerService.openEditTitleDialog(mainTitle).subscribe(async (newReqName: any) => {
            if (newReqName) {
                this.titleChange(newReqName);
                this._cdRef.detectChanges();
            }
        });
    }

    async shareDashboard() {
        const newRequestId = (await this.reportPersist.createRequest());
        const requestCopy = this.reportPersist.oLastRequestData.getValue();
        await this.reportPersist.updateRequest(newRequestId, requestCopy, false);
        this.dialogsManagerService.openShareDialog(this.appId, DASHBOARDS, newRequestId)
    }
    reset() {
        const requestId = this.reportPersist.loadReqId;
        this.reportPersist.loadRequest(requestId);
    }
    public changeRequest(dirId: number) {
        const currentReportOwnerId = this.reportPersist?.mainViewState?.getState()?.userId;
        this._title.setTitle(this.reportPersist?.mainViewState?.getState()?.mainTitle);
        this.setReportPermissions(currentReportOwnerId, dirId);
    }
    async saveDashboard() {
        try {
            const requestId = this.reportPersist.loadReqId;
            this.editMode = !this.editMode;
            const { mainTitle } = this.reportPersist.mainViewState.getState();
            if (!!mainTitle) {
                await this.reportPersist.persistRequestOwner(true, false, this.user.userId);
                await this.requestFilingService.setRequestDirectory(requestId, mainTitle, -1, this.appId, DASHBOARDS);
                this._router.navigateByUrl(`${this.appId}/dashboard/${requestId}`);
            } else {
                this._openSnackBar("Please Select a Valid Dashboard Name");
            }
            this._cdRef.markForCheck();
        } catch (e) {
            this._openSnackBar(e.error);
        }
    }

    async saveAsDashboard() {
        try {

            const { reportName, dirId } = await this.getSaveAsReportNameAndDir();
            await this.reportPersist.createRequest();
            const requestId = this.reportPersist.currentReqId;
            if (!!reportName) {
                await this.reportPersist.persistRequestOwner(false, false, this.user.userId);
                await this.requestFilingService.setRequestDirectory(requestId, reportName, dirId, this.appId, DASHBOARDS);
                await this.reportPersist.loadRequest(requestId);
                this._router.navigateByUrl(`${this.appId}/dashboard/${requestId}`);
                this.editMode = !this.editMode;
            }
            this._cdRef.markForCheck();
        } catch (e) {
            this._openSnackBar(e.error);

        }
    }
    private async getSaveAsReportNameAndDir() {
        let reportName, dirId;
        const result = await this.dialogsManagerService.openSaveReportDialog(DASHBOARDS).toPromise();
        if (!!result) {
            reportName = result.reportName;
            dirId = result.directoryId;
            this.reportPersist.mainViewState.setValue({ mainTitle: reportName, reportDir: result.reportDir });
        }
        return { reportName, dirId };
    }
    async deleteDashboard() {
        var confirm = await this.dialogsManagerService
            .openConfirmDialog('Delete Dashboard?', `Are you sure you want to delete this dashboard? This cannot be undone.`).toPromise();
        if (confirm) {
            const isDeleted: boolean = await this.reportPersist.deleteRequest(this.reportPersist.loadReqId);
            if (isDeleted) {
                const defaultRequestId = (await this._appSettingService.getCurrentApp())?.defaultDashboard;
                await this.reportPersist.loadRequest(defaultRequestId);
                this.requestFilingService.sReloadRequests.next(true);
                this._router.navigateByUrl(`${this.appId}/dashboard/default`);
                this._cdRef.markForCheck();
                this.openSnackBar('Dashboard has been deleted.');
            } else {
                this.openSnackBar(`Not authorized.`);
                disLogger('logged in userid does not match request owner, delete error');
            }
        }
    }

    private openSnackBar(msg: string) {
        this.snackbar.open(msg, '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }

    closePopover() {
        this.popoverService.setState(true);
    }
    addNewReport(event: { request?: IRequest, tab: string, name: string, requestId?: string }) {
        const reps = this.reportPersist.dashboardViewState.getState().reports;
        const reports = [...reps,
        {
            key: event.tab,
            name: event.name,
            id: event.requestId || event.request.id,
            h: 20, w: 6, x: 0, y: 50
        }];
        this._cdRef.detectChanges();
        this.save(reports);
    }
    onResize(e: KtdResizeEnd) {
        const reps = this.reportPersist.dashboardViewState.getState().reports;
        reps.map((rep, index) => {
            rep.h = e.layout[index].h;
            rep.w = e.layout[index].w;
            rep.x = e.layout[index].x;
            rep.y = e.layout[index].y;
            return rep;
        });
        this.save(reps);
    }

    onRemove(reportId: string) {
        const reps = this.reportPersist.dashboardViewState.getState().reports;
        const reports = reps.filter(rep => rep.id !== reportId);
        this.save(reports);
    }

    async save(newLayout: IDashboardReportConfig[]) {
        this.reportPersist.dashboardViewState.setField('reports', newLayout);
    }
    private async setReportPermissions(requestOwnerUserId: number, dirId: number) {
        this.canEditReport = await this.hasPermission(Permission.EditReport, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${this.reportPersist.loadReqId}`);
        this.canDeleteReport = await this.hasPermission(Permission.DeleteRequest, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${this.reportPersist.loadReqId}`);
    }
    private async hasPermission(permissionKey: string, resourceKey?: string): Promise<boolean> {
        return await this._userPermissionService.hasPermission(DASHBOARDS, permissionKey, resourceKey);
    }
    public async openNewReportDialog(tabId: string) {
        // const dirId = this.tabs.find(tab => tab.key === tabId).dirs.find(x => x.directoryName.toLowerCase() === (`${tabId}|dashboard`))?.id
        const resp = await this.dialogsManagerService.openNewReportDialog(tabId).toPromise();
        if (resp) {
            const { name, key } = this.tabs.find(tab => tab.key == resp.tab);
            this.addNewReport({ name, tab: key, requestId: resp.newReqId });
            this._openSnackBar(`New Report (${resp.reportMainTitle}) Added to Dashboard`);
        }
    }
    public async openFiltersSelector() {
        const newFilter = await this.dialogsManagerService.openFilterSelectorDialog().toPromise();
        if (newFilter) {
            const filters = this.reportPersist.dashboardViewState.getState().filters || [];
            const filterIndex = filters.findIndex(filter => filter.filter.fields[0] === newFilter.filter.fields[0])
            if (filterIndex >= 0) {
                filters[filterIndex] = newFilter;
            } else {
                filters.push(newFilter)
            }
            this.reportPersist.dashboardViewState.setField('filters', filters)
        }
    }
    removeFilter(key) {
        const filters = this.reportPersist.dashboardViewState.getState().filters || [];
        const newFilters = filters.filter(filter => filter.filter.fields[0] !== key)
        this.reportPersist.dashboardViewState.setField('filters', newFilters)
    }
    private _resolveDirRequestsIcons(dirs: IDirRequest[]) {
        return dirs?.map(dir => {
            dir.requests.map(req => {
                req.icon = resolveReportIcon(req.type, req.subType);
                return req;
            });
            return dir;
        }) || [];
    }
    private _openSnackBar(msg: string) {
        this._snackBar.open(msg, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }
    public async saveDashboardAction() {
        if (this.isNewDashboard) {
            this.isNewDashboard = false;
        }
        this.saveDashboard();
    }
}
