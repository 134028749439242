import { Injectable, OnDestroy } from '@angular/core';

import { ITableViewState, IMainViewState, IChartState, IDynamicReport, IKanbanViewState, IFacetDefinition, IDashboardViewState } from '../classes/report-state';
import { TabSettingsService } from './tab-settings.service';


import { disLogger } from '../functions';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { AppSettingsService } from './app-settings.service';
import { first } from 'rxjs/operators';
import { RequestPersistService } from './request-persist.service';
import { DiscovererQueryService } from '../core-data-services';
import { CombinedStateService, StateService } from '../state';
import { DRFacetOption, DRFilter, DRQuery, DSearchFilter, FacetValue, IColumnSetting, IPivotConfig } from '../classes';
import { RequestCacheService } from './request-cache.service';

export const DEFAULT_REPORT_TITLE = 'New Datasheet';
const moment = require('moment');

@Injectable()
export class ReportPersistService extends RequestPersistService<IDynamicReport> implements OnDestroy {

    private _mainQueryService: DiscovererQueryService;
    private _tableViewState: StateService<ITableViewState>;
    private _mainViewState: StateService<IMainViewState>;
    private _chartState: StateService<IChartState>;
    private _chartListState: StateService<IChartState[]>;
    private _pivotConfigState: StateService<IPivotConfig>;
    private _kanbanViewState: StateService<IKanbanViewState>;
    private _dashboardViewState: StateService<IDashboardViewState>;

    private _isMissingReport: boolean;
    public get isMissingReport(){
        return this._isMissingReport;
    }

    private _reportStateSubscription: Subscription;
    public constructor(
        http: HttpClient,
        appSettings: AppSettingsService,
        cache: RequestCacheService,
        public tabSettings: TabSettingsService) {
        super(http, appSettings, cache);
        console.log('*** New instance');
    }

    ngOnDestroy(): void {
        const arr = [this._tableViewState,
        this._mainViewState,
        this._chartState,
        this._pivotConfigState,
        this._chartListState,
        this._kanbanViewState,
        this._dashboardViewState
        ] as Array<{ destroy() }>;
        arr.filter(s => !!s).forEach(s => {
            s.destroy();
        });
    }
    async persistRequestOwner(isUpdate: boolean, cacheOnly = true, newRequestOwnerId?: number) {
        const state = await this.oLastRequestData.pipe(first()).toPromise();
        if (newRequestOwnerId) {
            state.mainView.userId = newRequestOwnerId;
        }
        await this.persistRequest(isUpdate, cacheOnly, state);
        // /await this.resetRequest();
    }
    public async createState(baseQueryService: DiscovererQueryService = null, mainStateType = 'table-view') {

        if (!!this._reportStateSubscription) {
            this._reportStateSubscription.unsubscribe();
            this._reportStateSubscription = null;
        }
        this._tableViewState = new StateService({
            columnSettings: [],
            isDefaultColumns: true,
            dataFlattenBy: '',
            groups: [],
            sorts: [],
            randomSeed: 0
        });
        this._mainViewState = new StateService({
            mainTitle: DEFAULT_REPORT_TITLE,
            subTitle: '',
            subType: null,
            type: mainStateType,
            reportDir: 'personal',
            facets: [],
            isDefaultColumns: true,
            isDefaultFacets: true,
            isDefaultSorts: false,
            userId: 0,
            dirId: 0,
            isVerticalFacets: false
        } as IMainViewState);
        this._chartState = new StateService<IChartState>({});
        this._chartListState = new StateService<IChartState[]>([]);
        this._pivotConfigState = new StateService<IPivotConfig>({});
        this._kanbanViewState = new StateService<IKanbanViewState>({
            columnSettings: [],
            groupByColumn: { field: '' },
            listsInfo: [],
            sorts: [],
        });
        this._dashboardViewState = new StateService<IDashboardViewState>({
            reports: []
        });
        this._mainQueryService = !!baseQueryService ? baseQueryService.createChildService('main') : new DiscovererQueryService('main');
        const combined = new CombinedStateService<IDynamicReport>({
            mainView: this._mainViewState.oState,
            tableView: this._tableViewState.oState,
            query: this.mainQueryService.oQuery,
            chart: this._chartState.oState,
            chartList: this._chartListState.oState,
            pivotChart: this._pivotConfigState.oState,
            kanbanView: this._kanbanViewState.oState,
            dashboard: this._dashboardViewState.oState
        });
        // this.oRequestData = combined.oState;
        // feed request data from combined.oState
        this._reportStateSubscription = combined.oState.subscribe(s => {
            delete s.query.changeId;
            this._sRequestData.next(s);
        });

        disLogger('createState');
    }

    protected async stateLoadCallback(persistState: IDynamicReport, missing: boolean) {
        this._isMissingReport = missing;
        if (!persistState) {
            return;
        }
        if (!!this.mainViewState) {
            if (persistState?.mainView) {
                if(persistState?.mainView?.facets?.length != 0) {
                    let facets = persistState.mainView.facets[0].group == null || undefined ? persistState.mainView.facets.map(f => { return { group: "0", name: f } }) : persistState.mainView.facets;
                    persistState.mainView.facets = (facets as IFacetDefinition[]);
                }
                (persistState?.mainView) && this.mainViewState.setValue(persistState.mainView, true);
            }
        }

        (persistState?.tableView) && this.tableViewState.setValue(persistState.tableView, true);
        (persistState?.chart) && this.chartState.setValue(persistState.chart, true);
        (persistState?.pivotChart) && this.pivotChartState.setValue(persistState.pivotChart, true);
        (persistState?.kanbanView) && this.kanbanViewState.setValue(persistState.kanbanView, true);
        (persistState?.dashboard) && this.dashboardViewState.setValue(persistState.dashboard, true);

        this.mainQueryService.resetFilters();
        this.mainQueryService.resetOrderBy();

        if (!!persistState.query) {
            if (!!persistState.query.filters) {
                persistState.query.filters
                    .forEach((filter: any) => {
                        // disLogger('', filter.type, filter.fields, filter.expression ? filter.expression : []);
                        if (filter.fields) {
                            this.mainQueryService.setFilter(filter.fields.join('-'), this.getFilterFromFilterState(filter));
                        }
                    });
            }
            if (!!persistState.query && persistState.query.size) {
                this.mainQueryService.setPageParams(persistState.query.start, persistState.query.size);
            }

            if (persistState.query.numberOfRecords) {
                this.mainQueryService.setNumberOfRecords(persistState.query.numberOfRecords);
            }
            this.mainQueryService.setGroups(persistState.query.groups);
            this.mainQueryService.setDataFlattenBy(persistState.query.dataFlattenBy);
            if (!!persistState.query.sorts && persistState.query.sorts.length > 0) {
                persistState.query.sorts.forEach(sort => this.mainQueryService.setOrderBy(sort.sortField, sort.dir));
            } else if (persistState.mainView.isDefaultSorts) {
                const currentTab = await this.tabSettings.getCurrentTab();
                if (!!currentTab.defaultSorts && currentTab.defaultSorts.length) {
                    currentTab.defaultSorts.forEach(sort => this.mainQueryService.setOrderBy(sort.sortField, sort.dir));
                }
            }
        }
        this.mainQueryService.refresh();
        disLogger('report loaded!');

    }

    public async getFieldNamesFromConfig(hideEditColumn: boolean): Promise<{ fields: string[], columnSettings: IColumnSetting[] }> {
        const tableViewState: ITableViewState = this.tableViewState.getState();
        const mainViewState: IMainViewState = this.mainViewState.getState();

        var columnSettings = await this.tabSettings.oDefaultColumns.pipe(first()).toPromise();
        var columnDictionary = Object.assign({}, ...columnSettings.map(x => ({ [x.fieldName]: x })));

        if (!mainViewState.isDefaultColumns) {
            if (!!tableViewState.columnSettings) {
                columnSettings = tableViewState.columnSettings.map(x => {
                    // copy column settings from app/tab column definition
                    var overrideColumn = Object.assign({}, columnDictionary[x.fieldName] ?? x) as IColumnSetting;
                    // use saved display order and width
                    overrideColumn.displayOrder = x.displayOrder;
                    overrideColumn.order = x.order;
                    overrideColumn.width = x.width;
                    return overrideColumn;
                });
            }
            const detailColIndex = columnSettings.findIndex(z => z.detailColumn);
            if (detailColIndex !== -1) {
                columnSettings[detailColIndex].order = -1;
            } else if (!hideEditColumn) {
                columnSettings.unshift({ fieldName: '', dataType: 'icon', type: 'icon', display: '', width: 50, detailColumn: true, order: -1, form: {} });
            }
        }

        const fieldNames = columnSettings.map(s => s.fieldName);


        fieldNames.push(...columnSettings.filter(f => !!f.referenceLink).map(m => m.referenceLink.sourceIdColumn));
        const currentTab = await this.tabSettings.getCurrentTab();
        const isBusinessKeyExits = fieldNames.find(f => f === currentTab.businessKey)
        if (!isBusinessKeyExits)
            fieldNames.unshift(currentTab.businessKey);

        return {
            fields: fieldNames.filter(x => !!x && x != ''),
            columnSettings
        };
    }

    private getFilterFromFilterState(filter: any) {
        return (filter.type !== 'text')
            ? new DRFilter(filter.type, filter.fields, (filter.facetValues ? filter.facetValues : [])
                .map(fv => {
                    let facetValue;
                    if (!!fv.isRelative && fv.isRelative) {
                        const dateRange = fv.id.split(' TO ');
                        const dateFrom = this.MapDateFromExpression(dateRange[0].replace('[', ''), false);
                        const dateTo = this.MapDateFromExpression(dateRange[1].replace(']', ''), true);
                        const fvKey = dateFrom.format('YYYY-MM-DD') + 'T00:00:00Z' + ' TO ' + dateTo.format('YYYY-MM-DD') + 'T00:00:00Z';
                        facetValue = new FacetValue(new DRFacetOption(fvKey, fv.id, fv.title));
                    } else {
                        facetValue = new FacetValue(new DRFacetOption(fv.facetKey, fv.id, fv.title));
                    }
                    facetValue.isChecked = true;
                    facetValue.isExclusion = filter.expression.some(x => `${x}`.includes('!'));
                    facetValue.isRelative = !!fv.isRelative ? fv.isRelative : false;
                    return facetValue;
                }), filter.filters)
            : new DSearchFilter(filter.type, filter.fields, (filter.expression ? filter.expression : []),
                (!!filter.originalValue ? filter.originalValue : ''));
    }

    private MapDateFromExpression(expression: string, toDate: boolean): typeof moment {

        let split;
        if (expression.includes('-')) {
            split = expression.split('-', 2);
        } else {
            split = expression.split('+', 2);
        }
        const left = split[0].trim();
        let date = moment();
        const today = new Date();
        switch (left) {
            case 'NOW/DAY':
                date = moment(new Date(today.getFullYear(), today.getMonth(), today.getDay()));
                break;
            case 'NOW/YEAR':
                date = moment(new Date(today.getFullYear(), 1, 1));
                break;
            case 'NOW/MONTH':
                date = moment(new Date(today.getFullYear(), today.getMonth(), 1));
                break;
            case 'NOW/WEEK':
                date = moment(new Date(today.getFullYear(), today.getMonth(), 1));
                break;
        }
        if (split.length > 1) {
            const right = split[1].trim();
            if (right.endsWith('MONTHS')) {
                const months = +right.replace('MONTHS', '');
                if (expression.includes('-')) {
                    date = date.add(-months, 'months');
                } else {
                    date = date.add(months, 'months');
                }
            } else if (right.endsWith('DAYS')) {
                const days = +right.replace('DAYS', '');
                if (expression.includes('-')) {
                    date = date.add(-days, 'days');
                } else {
                    date = date.add(days, 'days');
                }
            }
        }
        if (toDate && !expression.includes('DAYS')) {
            date = date.add(-1, 'days');
        }

        return date;
    }
    public get chartListState(): StateService<IChartState[]> {
        return this._chartListState;
    }

    public get chartState(): StateService<IChartState> {
        return this._chartState;
    }

    public get pivotChartState(): StateService<IPivotConfig> {
        return this._pivotConfigState;
    }

    public get mainQueryService(): DiscovererQueryService {
        return this._mainQueryService;
    }

    public get tableViewState(): StateService<ITableViewState> {
        return this._tableViewState;
    }


    public get mainViewState(): StateService<IMainViewState> {
        return this._mainViewState;
    }
    public get kanbanViewState(): StateService<IKanbanViewState> {
        return this._kanbanViewState;
    }
    public get dashboardViewState(): StateService<IDashboardViewState> {
        return this._dashboardViewState;
    }
    public resetRequestFromDB() {
        this.cache.removeItem(this.loadReqId);
        this.resetRequest();
    }
}
