
import { Injectable, Pipe, PipeTransform } from '@angular/core';
const FORMAT_SYMBOLS = [
    { value: 1E18, limit: -1E21, symbol: 'E' },
    { value: 1E15, limit: -1E18, symbol: 'P' },
    { value: 1E12, limit: -1E15, symbol: 'T' },
    { value: 1E9,  limit: -1E12, symbol: 'B' },
    { value: 1E6,  limit: -1E9,  symbol: 'm' },
    { value: 1E3,  limit: -1E6,  symbol: 'k' },
    { value: 1,    limit: -1E3,  symbol: '' },
    { value: 1,    limit: 1,     symbol: '' },
    { value: 1E3,  limit: 1E3,   symbol: 'k' },
    { value: 1E6,  limit: 1E6,   symbol: 'm' },
    { value: 1E9,  limit: 1E9,   symbol: 'B' },
    { value: 1E12, limit: 1E12,  symbol: 'T' },
    { value: 1E15, limit: 1E15,  symbol: 'P' },
    { value: 1E18, limit: 1E18,  symbol: 'E' }
];

@Pipe({
  name: 'formattedNumber'
})
@Injectable()
export class FormattedNumberPipe implements PipeTransform {


    transform(a: number, type: string = 'else'): string {
        let str: string;
        if (a != null && a != undefined) {
            if ((!!type && type === 'avg')) {
                str = a.toFixed(2);
            } else if (!!type && type === 'short') {
                str = this.nFormatter(a, 2);
            } else {
                str = (+a).toFixed(0);
            }
            return this.numberWithCommas(str);
        }
        return null;
    }
    private nFormatter(num: number, digits: number): string {
        let i;
        for (i = FORMAT_SYMBOLS.length - 1; i > 0; i--) {
            if (num >= FORMAT_SYMBOLS[i].limit) { break; }
        }
        return (num / FORMAT_SYMBOLS[i].value).toFixed(digits) + FORMAT_SYMBOLS[i].symbol;
    }
    private numberWithCommas(x: string) {
        let parts = x.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }
}
