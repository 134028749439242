import { Component, OnDestroy, OnInit } from "@angular/core";
import { FieldArrayType } from "@ngx-formly/core";
import { Subscription } from "rxjs";

@Component({
    selector: "formly-repeat-section",
    templateUrl: `./repeat-section.html`
})
export class RepeatTypeComponent extends FieldArrayType implements OnInit, OnDestroy {
    hideExpression
    hideAddBtn
    hideRemoveBtn
    autoRepeatSub: Subscription;

    ngOnInit(): void {
        const fieldOptions = this.field?.fieldArray?.templateOptions;

        let property = fieldOptions?.hideButtonsProperty;
        let hideButtons = fieldOptions?.hideButtons;

        this.hideAddBtn = fieldOptions?.hideAddButton === undefined ||
            fieldOptions?.hideAddButton === null ?
            true :
            !fieldOptions?.hideAddButton;

        this.hideRemoveBtn = fieldOptions?.hideRemoveButton === undefined ||
            fieldOptions?.hideRemoveButton === null ?
            true :
            !fieldOptions?.hideRemoveButton;

        this.hideExpression = hideButtons;
        if (property) {
            this.hideExpression = this.formState.model[property] || hideButtons;
        }
        if(fieldOptions.autoRepeat) {
            this.autoRepeatSub = this.formControl.valueChanges.subscribe((controls) => {
                if (Object.keys(controls[0]).some(field => !!this.formControl.controls[this.formControl.length - 1].value[field])) {
                    this.add();
                }
            });
        }
    }


    public leave(event) {
        event.preventDefault();
    }

    public getFieldGroupLength() {
        return this.field.fieldGroup.length;
    }


    ngOnDestroy(): void {
        if (this.autoRepeatSub) {
            this.autoRepeatSub.unsubscribe;
        }
    }

}
