import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';

@Component({
    selector: 'search-input-widget',
    styleUrls: ['./search-input.widget.scss'],
    templateUrl: './search-input.widget.html'
})
// tslint:disable-next-line:component-class-suffix
export class SearchInputWidget implements OnInit, OnChanges {

    public searchText: string;
    public searchIsCollapsed = true;
    public isInputFocused = false;
    @Input()
    public label: string;

    @Input()
    public searchValue?: string;

    @Input()
    public animate=false;

    @Output()
    public searchEvent: EventEmitter<string> = new EventEmitter<string>();

    public ngOnInit(): void {
        this.searchText = (!!this.searchValue) ? this.searchValue : null;
        this.searchIsCollapsed = (!this.searchValue || this.searchValue === '');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!!changes && changes['searchValue']) {
            const currentSV = changes['searchValue'];
            if (!!currentSV && !!currentSV.currentValue && currentSV.currentValue.trim() !== '') {
                this.searchText = currentSV.currentValue;
            } else {
                this.searchText = '';
            }
            this.searchIsCollapsed = (!this.searchText);
        }
    }


    public searchTextUpdate() {
        this.searchIsCollapsed = (!this.searchText.length);
        this.searchEvent.emit(this.searchText);
    }

    public closeSearch() {
        this.searchText = '';
        this.searchTextUpdate();
    }
}
