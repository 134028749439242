import { OnDestroy, Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';

import {
    IDisplayFilter,
    FILTERING_OPR_DICTIONARY,
    FIELDS_TYPES,
    DATE_OPERATION,
    DATE_OPR_DICTIONARY,
    FILTERING_OPERATION,
    FUTURE_FILTERING_OPR_DICTIONARY
} from '../classes/advanced-filters';

import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { Apollo } from 'apollo-angular';
import { IColumn, IColumnSetting, IDRFilter } from '../classes';
import { DiscovererFacetTranslationService } from '../core-data-services';
import { TabSettingsService } from './tab-settings.service';
import { fixTimeZone } from '../functions';
import { includes } from 'lodash';
import { debug } from 'console';
import { P } from '@angular/cdk/keycodes';
import { FILTERING_MODES } from '@discoverer/core/services';

function flat(input, depth = 1, stack = []) {
    for (let item of input) {
        if (item instanceof Array && depth > 0) {
            flat(item, depth - 1, stack);
        }
        else {
            stack.push(item);
        }
    }

    return stack;
}

@Injectable()
export class AdvancedFilterService implements OnDestroy {

    public oFilters: Observable<IDisplayFilter[]>;
    private _sFilters: ReplaySubject<IDisplayFilter[]>;

    private filters: IDisplayFilter[];

    protected subscriptions: Subscription[];

    private columnDictionary: { string: IColumn };

    private FILTERING_OPERATION_DICTIONARY = FILTERING_OPR_DICTIONARY;

    private advFilteringMode = 'all';
    private datePipe: DatePipe = new DatePipe('en-US');

    public createEmptyFacetFilter(): IDisplayFilter {
        return {
            id: AdvancedFilterService._makeid(),
            fields: [''],
            type: 'facet',
            expression: [''],
            filters: [],
            operationA: '',
            valueA: '',
            valueB: '',
            fromOperatorValue: FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN,
            toOperatorValue: FILTERING_OPR_DICTIONARY.LESS_THAN,
            isValid: true
        };
    }

    static unsubscribeAll(subscriptions: Subscription[]) {
        subscriptions.forEach(sub => {
            if (!!sub) {
                sub.unsubscribe();
            }
        });
    }

    static _makeid(): string {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }


    static expressionFromOperation(operationA: string, valueA: string, valueB?: string, fromOperatorValue?: string, toOperatorValue?: string, operationB?: string): string[] {
        if ((!!fromOperatorValue && fromOperatorValue !== '') && (!!toOperatorValue && toOperatorValue !== '') && (!!operationA && operationA == FILTERING_OPR_DICTIONARY.IS_BETWEEN)) {
            switch (true) {
                case fromOperatorValue == FILTERING_OPR_DICTIONARY.MORE_THAN && toOperatorValue == FILTERING_OPR_DICTIONARY.LESS_THAN:
                    return [`{${valueA} TO ${valueB}}`];
                case fromOperatorValue == FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN && toOperatorValue == FILTERING_OPR_DICTIONARY.LESS_THAN:
                    return [`[${valueA} TO ${valueB}}`];
                case fromOperatorValue == FILTERING_OPR_DICTIONARY.MORE_THAN && toOperatorValue == FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN:
                    return [`{${valueA} TO ${valueB}]`];
                case fromOperatorValue == FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN && toOperatorValue == FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN:
                    return [`[${valueA} TO ${valueB}]`];
                default:
                    return [`[${valueA} TO ${valueB}]`];
            }
        }
        if (!!operationB && operationB !== DATE_OPR_DICTIONARY.THE_DATE) {
            const dateOprInfo = DATE_OPERATION.filter(f => f.id === operationB)[0];
            if (valueA !== '') {
                valueA = `NOW${dateOprInfo.expression}${valueA}${dateOprInfo.filteringOn}`;
            } else {
                valueA = `[* TO *]`;
            }
        }
        if ((!valueA || valueA === '') && (operationA === FILTERING_OPR_DICTIONARY.NOT_EQUALS ||
            operationA === FILTERING_OPR_DICTIONARY.EQUALS)) {
            return (operationA === FILTERING_OPR_DICTIONARY.NOT_EQUALS) ? valueA === '' ? [`!""`] : [`!NULL`] : valueA === '' ? [`""`] : [`NULL`];
        } else if (!valueA || valueA === '' &&
            (operationA === FILTERING_OPR_DICTIONARY.DATE_BEFORE ||
                operationA === FILTERING_OPR_DICTIONARY.LESS_THAN ||
                operationA === FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE ||
                operationA === FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN ||
                operationA === FILTERING_OPR_DICTIONARY.DATE_AFTER ||
                operationA === FILTERING_OPR_DICTIONARY.MORE_THAN ||
                operationA === FILTERING_OPR_DICTIONARY.DATE_ON_AFTER ||
                operationA === FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN)
        ) {
            valueA = '*';
        }

        if (!!operationA && operationA !== '') {
            switch (operationA) {
                case FILTERING_OPR_DICTIONARY.EQUALS:
                case FILTERING_OPR_DICTIONARY.DATE_ON:
                    if (valueA.includes(', ')) {
                        return valueA.split(', ').map(s => {
                            return `"${s.trim()}"`;
                        });
                    } else {
                        if (valueA !== '*') {
                            {
                                return [`"${valueA}"`];
                            }
                        }
                    }
                    break;
                case FILTERING_OPR_DICTIONARY.NOT_EQUALS:
                    if (valueA.includes(', ')) {
                        return valueA.split(', ').map(s => {

                            return `!"${s.trim()}"`;
                        });
                    } else {
                        return [`!"${valueA}"`];
                    }
                case FILTERING_OPR_DICTIONARY.CONTAINS:
                    valueA = valueA.includes('-') ? valueA.split('-').join(' ') : valueA;
                    valueA = valueA.includes('+') ? valueA.split('+').join(' ') : valueA;
                    if (valueA.includes(' ')) {
                        return [`+${valueA.split(' ').join('* +')}*`];
                    } else {
                        return [`*${valueA}*`];
                    }
                case FILTERING_OPR_DICTIONARY.STARTS_WITH:
                    return [`${valueA}*`];
                case FILTERING_OPR_DICTIONARY.REGEX_MATCH:
                    return [`//${valueA}/`];
                case FILTERING_OPR_DICTIONARY.DATE_BEFORE:
                case FILTERING_OPR_DICTIONARY.LESS_THAN:
                    return [`[* TO ${valueB}}`];
                case FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE:
                case FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN:
                    return [`[* TO ${valueB}]`];
                case FILTERING_OPR_DICTIONARY.DATE_AFTER:
                case FILTERING_OPR_DICTIONARY.MORE_THAN:
                    return [`{${valueA} TO *]`];
                case FILTERING_OPR_DICTIONARY.DATE_ON_AFTER:
                    return [`[${valueA} TO *]`];
                case FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN:
                    return [`[${valueA} TO *]`];
                case FILTERING_OPR_DICTIONARY.IS_BETWEEN:
                    return [`[${valueA} TO ${valueB}]`];
                case FILTERING_OPR_DICTIONARY.NUMERIC_BLANK:
                case FILTERING_OPR_DICTIONARY.DATE_BLANK:
                    return [`![NULL TO NULL]`];
                case FILTERING_OPR_DICTIONARY.NUMERIC_NOT_BLANK:
                case FILTERING_OPR_DICTIONARY.DATE_NOT_BLANK:
                    return [`[NULL TO NULL]`];
                case FILTERING_OPR_DICTIONARY.DATE_HIGH_VALUE:
                    return [`[9999-12-31T00:00:00Z TO *]`];
                case FILTERING_OPR_DICTIONARY.DATE_NOT_HIGH_VALUE:
                    return [`[* TO 9999-12-31T00:00:00Z}`]
            }
        }
    }

    constructor(private facetTranslationService: DiscovererFacetTranslationService, private http: HttpClient, private apollo: Apollo, private tabSettings: TabSettingsService) {
        this.subscriptions = [];
        this.filters = [];
        this._sFilters = new ReplaySubject<IDisplayFilter[]>(1);
        this.oFilters = this._sFilters.asObservable();
    }

    public static isFilterReady(filter: IDisplayFilter): boolean {
        return (!!filter && !!filter.fields[0] && !!filter.expression && !!filter.expression[0]) || (filter.type === FILTERING_MODES.ALL || filter.type === FILTERING_MODES.ANY);
    }

    public validateFilter(filterIndex: number, isValid: boolean) {
        this.filters[filterIndex].isValid = isValid;
        this.updateFilterSubject();
    }

    public init(filterNode: IDRFilter, columnDictionary, parentFilter: IDisplayFilter = undefined) {
        if (!!parentFilter) {
            let parentFilterIDR = this.breakDownFilters([parentFilter]);
        }

        this.columnDictionary = columnDictionary;
        let filters = [];
        if (!!filterNode) {
            filters = this.breakDownFilters(filterNode.filters);
        }

        if (filters.length === 0 && this.filters.length === 0) {
            this.addFiler(0);
        } else {
            this.filters = filters.concat(this.filters.filter(f => !AdvancedFilterService.isFilterReady(f)));
            this.filters.forEach(f => f.type = AdvancedFilterService.typeFromExpression(f.expression[0], f.type));
            this._sFilters.next(this.filters);
        }
    }

    public addFiler(index) {
        const newFilter = this.createEmptyFacetFilter();
        this.filters.splice((index + 1), 0, newFilter);
        this.updateFilterSubject();
    }

    public addSubFilter(parentFilterIndex, index) {
        const newFilter = this.createEmptyFacetFilter();
        if (!this.filters[parentFilterIndex].filters) {
            this.filters[parentFilterIndex].filters = [];
        }
        this.filters[parentFilterIndex].filters.splice((index + 1), 0, newFilter);
        this.updateFilter(0, this.filters[parentFilterIndex]);
        //this.updateFilterSubject();
    }

    public updateFilteringMode(filteringMode) {
        this.advFilteringMode = filteringMode;
        this.updateFilterSubject();
    }

    public getFilterType(index) {
        return this.columnDictionary[this.filters[index].fields[0]];
    }

    public isNested(index) {
        return this.filters[index].fields[0] === 'Advanced';
    }

    public getSubAdvancedType(index) {
        return this.filters[index].type;
    }

    public removeFilter(index) {
        this.filters.splice(index, 1);
        if (this.filters.length === 0) {
            this.addFiler(0);
        }
        this.updateFilterSubject();
    }

    public updateFilter(index, filter: IDisplayFilter) {

        if (filter.fields[0] !== 'Advanced') {
            filter.expression = AdvancedFilterService.expressionFromOperation(filter.operationA, filter.valueA, filter.valueB, filter.fromOperatorValue, filter.toOperatorValue, filter.operationB);
        }

        this.updateElement(this.filters, filter);
        this.updateFilterSubject();
    }

    public updateElement = (obj, el) =>
        Array.isArray(obj)
            ? obj.map(o => this.updateElement(o, el)) : obj.id === el.id ? el : // else
                { ...obj, ...(obj.children ? { children: this.updateElement(obj.children, el) } : {}) };

    public updateFilterDate(index, filter: IDisplayFilter) {
        if (filter.operationB === DATE_OPR_DICTIONARY.THE_DATE) {

            if (!!FUTURE_FILTERING_OPR_DICTIONARY.find(dateOp => dateOp === filter.operationA)) {
                filter.valueA = this.datePipe.transform(filter.valueA, 'yyyy-MM-dd') + 'T00:00:00Z';
            } else {
                filter.valueB = this.datePipe.transform(filter.valueB, 'yyyy-MM-dd') + 'T00:00:00Z';

            }
        }
        this.updateFilter(index, filter);
    }

    public operationFromExpression(expression: string, fieldName: string): string {
        const fieldType = this.columnDictionary[fieldName].type;

        if (fieldType === FIELDS_TYPES.DATE && expression.includes('9999-12-31')) {
            return expression.includes('[*')
                ? this.FILTERING_OPERATION_DICTIONARY.DATE_NOT_HIGH_VALUE
                : this.FILTERING_OPERATION_DICTIONARY.DATE_HIGH_VALUE;
        }

        if (expression.includes('[*') && expression.includes('}')) {
            return fieldType === FIELDS_TYPES.DATE ?
                this.FILTERING_OPERATION_DICTIONARY.DATE_BEFORE : this.FILTERING_OPERATION_DICTIONARY.LESS_THAN;
        }
        if (expression.includes('[*') && expression.includes(']')) {
            return fieldType === FIELDS_TYPES.DATE ?
                this.FILTERING_OPERATION_DICTIONARY.DATE_ON_BEFORE : this.FILTERING_OPERATION_DICTIONARY.EQUALS_LESS_THAN;
        }
        if (expression.includes('{') && expression.includes('*]')) {
            return fieldType === FIELDS_TYPES.DATE ?
                this.FILTERING_OPERATION_DICTIONARY.DATE_AFTER : this.FILTERING_OPERATION_DICTIONARY.MORE_THAN;
        }
        if (expression.includes('[') && expression.includes('*]')) {
            return fieldType === FIELDS_TYPES.DATE ?
                this.FILTERING_OPERATION_DICTIONARY.DATE_ON_AFTER : this.FILTERING_OPERATION_DICTIONARY.EQUALS_MORE_THAN;
        }
        if (fieldType === FIELDS_TYPES.DATE && expression.includes('NULL TO')) {
            return expression.includes('!')
                ? this.FILTERING_OPERATION_DICTIONARY.DATE_BLANK
                : this.FILTERING_OPERATION_DICTIONARY.DATE_NOT_BLANK;
        }
        if (fieldType === FIELDS_TYPES.NUMERIC && expression.includes('NULL TO')) {
            return expression.includes('!')
                ? this.FILTERING_OPERATION_DICTIONARY.NUMERIC_BLANK
                : this.FILTERING_OPERATION_DICTIONARY.NUMERIC_NOT_BLANK;
        }

        if (expression.includes('!')) {
            return this.FILTERING_OPERATION_DICTIONARY.NOT_EQUALS;
        }


        if (fieldType === FIELDS_TYPES.TEXT && !expression.startsWith('*') && expression.endsWith('*'))
            return this.FILTERING_OPERATION_DICTIONARY.STARTS_WITH;
        if (fieldType === FIELDS_TYPES.TEXT && expression.startsWith('/') && expression.endsWith('/'))
            return this.FILTERING_OPERATION_DICTIONARY.REGEX_MATCH;
        // double check
        if (expression.includes('~1') || expression.includes('*')) {
            return this.FILTERING_OPERATION_DICTIONARY.CONTAINS;
        }

        if (!expression.includes('*') && !expression.includes("!") && !expression.includes("/") && expression.includes('[')) {
            return this.FILTERING_OPERATION_DICTIONARY.IS_BETWEEN
        }

        if ((expression[0].includes('[') || expression[0].includes(']') || expression[0].includes('{') || expression[0].includes('}')) && !expression[0].includes('*') && !expression[0].includes('!') && !expression.includes('"')) {
            return this.FILTERING_OPERATION_DICTIONARY.IS_BETWEEN
        }

        return fieldType === FIELDS_TYPES.DATE ?
            this.FILTERING_OPERATION_DICTIONARY.DATE_ON : this.FILTERING_OPERATION_DICTIONARY.EQUALS;
    }

    // tslint:disable-next-line:member-ordering
    public static typeFromExpression(expression: string, filterType = ''): string {
        if (!expression?.toLowerCase().includes(" to ") && expression.includes('~1')) {
            return 'text';
        } else {

            if (!expression?.toLowerCase().includes('to') && expression.includes('*')) {
                return 'text';
            }
            else {
                if (expression === '') {
                    return filterType;
                } else
                    return 'facet';
            }
        }
    }

    public secondaryOperationFromExpression(expression: string): string {
        const detectedOpr = DATE_OPERATION.filter(opr =>
            expression.includes(opr.expression) && expression.includes(opr.filteringOn));
        return !!detectedOpr[0] ? detectedOpr[0].id : DATE_OPR_DICTIONARY.THE_DATE;
    }

    public valueFromExpressions(filter: IDRFilter): string {
        const expression = filter.expression;
        const fieldName = filter.fields[0];
        const fieldType = this.columnDictionary[fieldName].type;
        const operation = this.operationFromExpression(expression.join(', '), fieldName);
        let returnValue = '';
        if (expression[0].includes('{') && expression[0].includes('}') && !expression.includes("*")) {
            returnValue = expression[0].split(' TO ')[0].replace('{', '') + " and " + expression[0].split(' TO ')[1].replace('}', '');
        }
        else if (expression[0].includes('{') && expression[0].includes(']') && !expression.includes("*")) {
            returnValue = expression[0].split(' TO ')[0].replace('{', '') + " and " + expression[0].split(' TO ')[1].replace(']', '');
        }
        else if (expression[0].includes('[') && expression[0].includes('}') && !expression.includes("*")) {
            returnValue = expression[0].split(' TO ')[0].replace('[', '') + " and " + expression[0].split(' TO ')[1].replace('}', '');
        }
        else if (expression[0].includes('[') && expression[0].includes(']') && !expression.includes("*")) {
            returnValue = expression[0].split(' TO ')[0].replace('[', '') + " and " + expression[0].split(' TO ')[1].replace(']', '');
        }
        else if (expression.includes('{*') || expression.includes('[*')) {
            // this.FILTERING_OPERATION_DICTIONARY.LESS_THAN || this.FILTERING_OPERATION_DICTIONARY.EQUALS_LESS_THAN
            returnValue = expression[0].split(' TO ')[1];
        } else if (expression.includes('*}') || expression.includes('*]')) {
            // this.FILTERING_OPERATION_DICTIONARY.MORE_THAN || FILTERING_OPERATION_DICTIONARY.EQUALS_MORE_THAN
            returnValue = expression[0].split(' TO ')[0];
        } else if (expression.includes('!')) {
            returnValue = this.cleanedUpExpression(expression.join(', '), fieldType, operation);
        } else if (expression.includes('*')) {
            // return this.FILTERING_OPERATION_DICTIONARY.CONTAINS;
            returnValue = this.cleanedUpExpression(expression.join(', ').replace('*', ' '), fieldType, operation);
        } else {
            returnValue = this.cleanedUpExpression(expression.join(', '), fieldType, operation);
        }

        if (fieldType === FIELDS_TYPES.DATE && !expression[0].includes('NOW')) {
            const dCheck = Date.parse(returnValue.replace(/["]/g, ''));
            if (!!returnValue && !!dCheck) {
                returnValue = this.datePipe.transform(fixTimeZone(returnValue.replace(/["]/g, '')), 'MM-dd-yy');
            }

            if (operation === this.FILTERING_OPERATION_DICTIONARY.DATE_ON) {
                returnValue = returnValue.replace(/["]/g, '');
            }
        }

        returnValue = !!returnValue ? returnValue.split(', ').join(' or ') : '';
        return returnValue === '' ? '""' : returnValue;
    }

    ngOnDestroy(): void {
        AdvancedFilterService.unsubscribeAll(this.subscriptions);
    }

    private updateFilterSubject() {
        this.filters = this.filters.map(f => {
            if (!!f.expression && f.expression.length > 0) {
                f.type = AdvancedFilterService.typeFromExpression(f.expression[0], f.type);
            }
            return f;
        });
        this._sFilters.next(this.filters);
    }



    private breakDownFilters(filters: Array<IDRFilter>): IDisplayFilter[] {
        return flat(filters.map(f => this.displayFiltersFromExpression(f.fields[0], f.type, f.expression, f.filters)));
    }

    private cleanedUpExpression(expression, fieldType, operation): string {
        const filterType = AdvancedFilterService.typeFromExpression(expression);
        const replaceExpr = /[\(\)\*\{\}\[\]!]/g;
        let returnValue = '';
        if (operation === FILTERING_OPR_DICTIONARY.REGEX_MATCH) {
            returnValue = expression.substring(2, expression.length - 1)
        } else if (fieldType !== FIELDS_TYPES.DATE) {
            if (operation === FILTERING_OPR_DICTIONARY.CONTAINS) {
                expression = Array.isArray(expression) ? expression.join(', ').replace('+', '').replace(/[\*\+]/g, '') :
                    expression.replace('+', '').replace(/[\*\+]/g, '');
            }
            if (Array.isArray(expression)) {
                returnValue = expression.join(', ').replace('NULL', '').replace(replaceExpr, '');
            } else {
                returnValue = expression.replace(' TO ', '').replace('NULL', '').replace(replaceExpr, '');
            }

            if (filterType === 'facet' || filterType === 'exclude') {
                returnValue = returnValue.replace(/["]/g, '');
            }
        } else {
            if (Array.isArray(expression)) {
                returnValue = expression.join(', ');
            } else {
                returnValue = expression;
            }

            if (expression.includes('MONTHS') || expression.includes('DAYS') || expression.includes('YEARS')) {
                returnValue = returnValue.replace('NOW', '')
                    .replace('MONTHS', '').replace('YEARS', '')
                    .replace('DAYS', '').replace(/[\+\-]/g, '');
            }
            returnValue = returnValue.replace(' TO ', '').replace('NULL', '')
                .replace(replaceExpr, '');
            returnValue = returnValue.replace('1DAY', '');

            if (operation === this.FILTERING_OPERATION_DICTIONARY.DATE_ON) {
                returnValue = returnValue.replace(/["]/g, '');
            }
        }

        return returnValue;
    }

    private getOperatorsFrom(expression) {
        if (expression[0].includes('[') && !expression.includes("*")) {
            return FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN
        }
        else if (expression[0].includes('{') && !expression.includes("*")) {
            return FILTERING_OPR_DICTIONARY.MORE_THAN
        }
    }
    private getOperatorsTo(expression) {
        if (expression[0].includes(']') && !expression.includes("*")) {
            return FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN
        }
        else if (expression[0].includes('}') && !expression.includes("*")) {
            return FILTERING_OPR_DICTIONARY.LESS_THAN
        }
    }

    private displayFiltersFromExpression(field: string, type: string, expressions: string[], subFilters): IDisplayFilter[] {
        const tmpFilters: IDisplayFilter[] = [];
        if (!!expressions && !!expressions[0]) {
            const operation = this.operationFromExpression(expressions[0], field);
            if (expressions[0].includes(' TO ')) {
                tmpFilters.push({
                    id: AdvancedFilterService._makeid(),
                    fields: [field],
                    type: AdvancedFilterService.typeFromExpression(expressions[0], type),
                    filters: subFilters,
                    expression: expressions,
                    operationA: operation,
                    operationB: this.secondaryOperationFromExpression(expressions[0]),
                    valueA: expressions[0].split(' TO ')[0].replace('{', '').replace('[', ''),
                    valueB: expressions[0].split(' TO ')[1].replace(']', '').replace('}', ''),
                    fromOperatorValue: this.getOperatorsFrom(expressions),
                    toOperatorValue: this.getOperatorsTo(expressions),
                    isValid: true,
                });
            } else {
                tmpFilters.push({
                    id: AdvancedFilterService._makeid(),
                    fields: [field],
                    type: AdvancedFilterService.typeFromExpression(expressions[0], type),
                    expression: expressions,
                    filters: subFilters,
                    operationA: operation,
                    operationB: this.secondaryOperationFromExpression(expressions[0]),
                    valueA: this.cleanedUpExpression(expressions.join(', '), this.columnDictionary[field].type, operation),
                    isValid: true
                });
            }
        } else {
            tmpFilters.push({
                id: AdvancedFilterService._makeid(),
                fields: [field],
                type: type,
                expression: [''],
                filters: subFilters,
                operationA: '',
                valueA: '',
                isValid: true
            });
        }
        return tmpFilters;
    }

    public async getDisplayFromExpression(expression: string[], column: IColumnSetting): Promise<string> {
        const queryInfoServiceUrl = (await this.tabSettings.getCurrentTab()).facetTranslationUrl;
        await this.facetTranslationService.init(`${queryInfoServiceUrl}/${column.fieldName}/${column.tableName}`, column.form.templateOptions.optionsListId);
        const facetInfo = this.facetTranslationService.facetTranslationInfo;
        const display = expression?.map(e => {
            const info = facetInfo?.find(fi => '\"\"' + fi.Id + '\"\"' === e);
            return info?.Display
        });
        if (display) { return display.join(', '); }
        else { return '' };
    }
}
