import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendResponse } from '../../models/common';
import { DatasetTypes } from '../../models/dataset.model';
import { EmailDetailsDto, FileDetailsDto, FileLogsDto, FilesByPrefixResultDto, MultiFileDeletionDto } from '../../models/datasetFile.models';
import { ExtractedFilesDto } from '../../models/dtos/extracted-files-dto';
import { BackendRouteEndpoint } from './constants/backend-route-constants';

@Injectable({
    providedIn: 'root'
})
export class DatasetFileService {

    constructor(
        private httpClient: HttpClient
    ) { }

    // Get Methods
    getAll(datasetId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}`);
    }
    getTotalNonTestAndNeedsReprocessingFileCount(datasetId: string, needsReprocess: boolean) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/need-reprocess/${needsReprocess}/total-count`);
    }
    downloadOriginalFile(datasetId: string, fileId: string) {
        return this.httpClient
            .get((`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/download`), {
                observe: 'response',
                responseType: 'blob'
            });
    }
    downloadFileFromPath(datasetId: string, filePath: string) {
        return this.httpClient
            .get(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/download-from-path?filePath=${filePath}`, {
                observe: 'response',
                responseType: 'blob'
            });
    }
    getResults(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/results`)
    }
    getLogs(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<FileLogsDto>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/logs`);
    }
    getActionsLog(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/actions-logs`);
    }
    getStepsLog(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/steps-logs`);
    }
    getFileDetails(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<FileDetailsDto>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/details`)
    }
    getExtractedFiles(datasetId: string, fileId: string): Observable<BackendResponse<ExtractedFilesDto>> {
        return this.httpClient.get<BackendResponse<ExtractedFilesDto>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/extracted-files`)
    }
    getEmailAttachments(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<FilesByPrefixResultDto>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/email-attachments`)
    }
    getEmailDetails(datasetId: string, fileId: string) {
        return this.httpClient.get<BackendResponse<EmailDetailsDto>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/email-details`)
    }

    // Post Methods
    uploadWithProgress(datasetId: string, file, datasetType: DatasetTypes) {
        const prefix = datasetType === DatasetTypes.DATASET ? 'upload' : 'workspace-pipeline';
        const req = new HttpRequest('POST', `${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${prefix}`, file, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.httpClient.request(req);
    }
    upload(datasetId: string, formData) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/upload`, formData);
    }
    reprocessAllFiles(datasetId: string, useNeedsReprocessing: boolean) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/re-process/use-need-reprocess/${useNeedsReprocessing}`, null);
    }
    reprocessMultipleFiles(datasetId: string, fileIds: string[]) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/re-process/multiple`, fileIds);
    }

    reprocessById(datasetId: string, fileId: string) {
        return this.httpClient.post<BackendResponse<string>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/re-process`, null);
    }
    addNewRecord(datasetId: string, data: any) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}`, data);
    }
 
    // Put Methods
    updateFileResults(datasetId: string, fileId: string, data, resultIndex: number = 0) {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}/results/${resultIndex}`, data)
    }

    // Delete Methods
    deleteById(datasetId: string, fileId: string) {
        return this.httpClient.delete<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/${fileId}`)
    }
    deleteRange(datasetId: string, filesToDelete: MultiFileDeletionDto[]) {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/deletion-range`, filesToDelete);
    }
    deleteByResultIndex(datasetId: string, fileId: string, resultIndex: number) {
        var filesToDelete: MultiFileDeletionDto = { fileId, resultIndex };
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_FILE_BASE_URL(datasetId)}/deletion-by-result-index`, filesToDelete);
    }
}
