import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {
    MatDialog
} from '@angular/material/dialog';
import {
    Router
} from '@angular/router';
import {
    IDirRequest,
    IRequest,
    AppSettingsService,
    BaseController,
    IDashboardReportConfig
} from '@discoverer/core/services';
import { FlattenedDirRequests } from '../filtered-reports-view/filtered-reports-view.component';

@Component({
    selector: 'dashboards',
    templateUrl: './dashboards.component.html',
    styleUrls: ['./dashboards.component.scss']
})
export class DashboardsComponent extends BaseController {
    @Input() public originalTabs: { key: string, name: string, dirs: IDirRequest[] }[] = [];
    @Input() public selectedRequests: IDashboardReportConfig[] = [];

    @Output() public personalDirectoryId: EventEmitter<number>;
    @Output() public hideReportsEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public mainTitleChanged: EventEmitter<string> = new EventEmitter<string>();
    @Output() public selectedRequest: EventEmitter<{ request: IRequest, tab: string, name: string }>
        = new EventEmitter<{ request: IRequest, tab: string, name: string }>();
    @Output() public onRemove: EventEmitter<string> = new EventEmitter<string>();


    public term = '';
    public selectedTab: { key: string, name: string, dirs: IDirRequest[] };
    public selectedDirectory: IDirRequest;

    constructor(
        public dialog: MatDialog,
        public router: Router,
        protected appSettings: AppSettingsService
    ) {
        super();
        this.personalDirectoryId = new EventEmitter<number>();
    }

    public async setReport(request: IRequest, tab: { key: string, name: string, dirs: IDirRequest[] }) {
        if (this.reportIsSelected(request)) {
            this.onRemove.emit(request.id);
        } else {
            this.selectedRequest.emit({ request, tab: tab.key, name: tab.name });
        }
    }
    public reportIsSelected(request: IRequest) {
        return this.selectedRequests.find(req => request.id === req.id);
    }

    public close() {
        this.hideReportsEvent.emit(true);
    }

    public setSelectedTab(tab) {
        this.selectedTab = tab;
        this.selectedDirectory = null;
    }
    public setSearchedReport(request: FlattenedDirRequests) {
        const tab = this.originalTabs.find(tab => tab.name === request.tabName);
        this.setReport(request, tab);
    }
}


