import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EsearchSettingsService } from '@discoverer/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { OptionDataServiceInterface } from '@discoverer/dynamic-forms/option-lists/shared-services/data-service';
import { HasuraOptionService } from '@discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { SchemaService } from '../../../services/schema.service';
import { FileDetailsHandlerService } from '../../shared-pages/file-details-handler.service';
import { FileFormsDataService } from './file-forms-data.service';
@Component({
    selector: 'app-file-result-editor',
    templateUrl: './file-result-editor.component.html',
    styleUrls: ['./file-result-editor.component.scss'],
    providers: [
        { provide: FormsDataServiceInterface, useClass: FileFormsDataService, deps: [HttpClient, FileDetailsHandlerService] },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
    ]
})

export class FileResultEditorComponent {
    @Input() datasetId!: string;
    @Input() fileId!: string;
    @Input() data!: any;
    @Input() isSaving: boolean;
    @Output() checkValidity = new EventEmitter<boolean>();

    constructor(
        protected eSearchSettingsService: EsearchSettingsService,
        public schemaService: SchemaService,
        public http: HttpClient) {
    }

    public onCheckValidity($event): void {
        this.checkValidity.emit($event);
    }
}
