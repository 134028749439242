<div class="list-container" [ngClass]="{'non-grouped-list-container': !showInGroups}">
  <div *ngIf="!selectedFormKey; else selectedForm" class="forms-list">
    <div style="display: flex;" *ngIf="!showInGroups; else groups">
      <div *ngFor="let form of (getDynForms.result | async)?.output">
        <button (click)="selectForm(form)" mat-raised-button class="form-button card card-1">
          <div class="form-title">{{form.Title}}</div>
          <div class="form-info">
            <button *ngIf="form.RelativeDirectory" mat-raised-button color="primary">{{form.RelativeDirectory}}</button>
            <div class="version">Version: {{form.Version}}</div>
          </div>
        </button>
      </div>
    </div>
    <ng-template #groups>
      <div class="groups-section">
        <div *ngIf="getDynForms.directories" class="groups-container">
          <span class="categories-title"> SERVICE CATEGORIES </span>
          <div class="group" [ngClass]="{'selected-dir': selectedDir==dir.group}" (click)="selectDir(dir)"
            *ngFor="let dir of filterDirectories(getDynForms.directories)">
            <span class="group-title">
              <mat-icon>folder_open</mat-icon>{{dir.group}}
            </span>
          </div>
        </div>
        <div class="forms">
          <div class="form" (click)="selectForm(form)" *ngFor="let form of selectedDirForms">
            <img class="request-icon" src="assets/request.png" />
            <div class="form-title">{{form.Title}}</div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-template #selectedForm>
    <mat-card class="selected-form">
      <app-dyn-form #dynForm [key]="selectedFormKey" [model]="model" [isEditMode]="true" [horizontalMode]="true"
       (isSubmitted)="onSubmit()">
      </app-dyn-form>
      <div class="control-buttons">
        <button mat-raised-button class="save-button" color="primary" mat-button
          (click)="dynForm.submit(); isDone = true;">SAVE &
          CLOSE</button>
        <button mat-raised-button class="save-button" color="primary" mat-button
          (click)="dynForm.submit(); isDone = false">SAVE &
          ADD NEW</button>
        <button mat-raised-button mat-button (click)="close()">CANCEL</button>
      </div>
    </mat-card>
  </ng-template>
</div>