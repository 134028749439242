<div class="wrapper">
    <div class="vertical-navigation-header">
        <div class="title-wrapper">
            <div class="flex items-center gap-2"
                *ngIf="(datasetService.oDatasetDetails | async)?.Type === datasetTypes.WORKSPACE else DATASET">

                <div class="dataset-title-wrapper items-center flex-1">
                    <div class="title workspace" [mat-menu-trigger-for]="datasetMenu">
                        <span class="app-name">{{ toolbarSettings?.title }}</span>
                        <mat-icon class="arrow-drop-icon">arrow_drop_down</mat-icon>
                    </div>
                    <mat-menu #datasetMenu="matMenu" class="dataset-menu">
                        <button mat-menu-item *ngFor="let x of datasetService.oWorkspaceDatasetList | async"
                            (click)="navigateToWorkspace(x)">{{x.Title}}</button>
                        <mat-divider></mat-divider>
                        <button mat-menu-item (click)="openCreateDialog()"><mat-icon>add</mat-icon> Add New</button>
                    </mat-menu>
                    <mat-icon class="edit-icon" *ngIf="toolbarSettings?.showBackButton"
                        (click)="editDataSetTitleDialog()">edit</mat-icon>
                </div>
            </div>
            <ng-template #DATASET>
                <div class="flex items-center gap-2">
                    <div class="back-item" (click)="back()">
                        <mat-icon>arrow_back</mat-icon>
                    </div>
                    <div class="dataset-title-wrapper items-center flex-1">
                        <div class="title">
                            <span class="app-name">{{ toolbarSettings?.title }}</span>
                        </div>
                        <mat-icon class="edit-icon" *ngIf="toolbarSettings?.showBackButton"
                            (click)="editDataSetTitleDialog()">edit</mat-icon>
                    </div>
                </div>
            </ng-template>

            <span *ngIf="toolbarSettings?.subTitle" class="text-subtitle">
                {{ toolbarSettings?.subTitle }}
                <mat-icon class="copy-icon" *ngIf="!isSubTitleCopied"
                    (click)="copyEmail(toolbarSettings?.subTitle, 'subTitle')" matTooltip="Copy">content_copy</mat-icon>
                <mat-icon class="check-icon" *ngIf="isSubTitleCopied">check</mat-icon>
            </span>
        </div>
    </div>
    <div class="vertical-navigation-content">
        <div *ngFor="let item of sidebarItems">
            <ng-container *ngTemplateOutlet="ai_group; context: {item: item}"></ng-container>
            <div *ngIf="item?.children">
                <ng-container *ngFor="let child of item?.children">
                    <ng-container *ngTemplateOutlet="ai_basic; context: {item: child}"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="vertical-navigation-footer">
        <div class="flex flex-col gap-2 items-center w-full px-2 py-8 border-t"
            *ngIf="toolbarSettings?.showBackButton">

            <button mat-flat-button color="primary" class="w-full" (click)="openImportDocument()">
                Import Documents
            </button>
            <app-reprocess-action [datasetId]="(datasetService.oDatasetDetails | async)?.Id"></app-reprocess-action>

        </div>
        <div class="flex items-center w-full px-2 py-4 border-t">
            <button mat-icon-button [matMenuTriggerFor]="userActions">
                <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            </button>
            <span class="flex flex-col leading-none">
                <span>{{user?.name}}</span>
                <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
            </span>
        </div>
        <mat-menu [xPosition]="'before'" class="user-actions" #userActions="matMenu">
            <button mat-menu-item>
                <span class="flex flex-col leading-none">
                    <span>Signed in as</span>
                    <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
                </span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <button mat-menu-item [routerLink]="['connections']">
                <mat-icon [svgIcon]="'link'"></mat-icon>
                <span>Connections</span>
            </button>
            <button mat-menu-item (click)="signOut()">
                <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
                <span>Sign out</span>
            </button>
        </mat-menu>

    </div>
</div>

<ng-template #ai_basic let-item="item">
    <div class="flex items-center justify-between">
        <a class="flex-1" *ngIf="item?.route" [routerLink]="[item?.route]" [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{exact: false}">
            <div class="flex pr-6 items-center justify-between item-wrapper item-child">
                <div class="flex items-center justify-start ">
                    <mat-icon class="mr-2" *ngIf="item?.icon" [svgIcon]="item?.icon"></mat-icon>
                    <p>{{item?.title}}</p>
                </div>
                <div class="flex gap-1">
                    <div class="flex gap-1 hideOnHover">
                        <div *ngIf="item?.leftBadge && !item.isLoading">
                            <span [ngClass]="{
                            'processing' : item?.leftBadge,
                            'pulse-animation': item?.leftBadge != 0
                            }" class="badge default-badge-color">{{item?.leftBadge}}</span>

                        </div>
                        <span *ngIf="item?.rightBadge != null && !item.isLoading" matTooltip="Processed Items"
                            class="badge default-badge-color">{{item?.rightBadge}}</span>
                        <span *ngIf="item?.isLoading">
                            <mat-spinner diameter="20"></mat-spinner>
                        </span>


                    </div>
                    <div class="flex showOnHover" *ngIf="item?.showDetails">
                        <div class="flex items-center justify-between">
                            <mat-icon (click)="$event.preventDefault()" [mat-menu-trigger-for]="selected_otions">
                                more_horiz </mat-icon>
                            <mat-menu #selected_otions="matMenu">
                                <button mat-menu-item (click)="goTo(item?.id, 'schema')">
                                    <mat-icon [svgIcon]="'heroicons_outline:view-columns'"></mat-icon> Fields
                                </button>
                                <button mat-menu-item (click)="goTo(item?.id, 'actions')">
                                    <mat-icon [svgIcon]="'schema'"></mat-icon> Actions
                                </button>
                                <button mat-menu-item (click)="goTo(item?.id, 'settings')">
                                    <mat-icon [svgIcon]="'settings'"></mat-icon> Settings
                                </button>
                                <button mat-menu-item (click)="goTo(item?.id, 'queue')">
                                    <mat-icon [svgIcon]="'heroicons_outline:inbox-arrow-down'"></mat-icon> Inbox
                                </button>
                                <button mat-menu-item (click)="goTo(item?.id, 'records')">
                                    <mat-icon [svgIcon]="'feather:database'"></mat-icon> Table
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                </div>
            </div>
        </a>


    </div>
</ng-template>



<ng-template #ai_group let-item="item">
    <div class="flex items-center justify-between item-wrapper item-group" *ngIf="item?.id !== 'ACTIONS' else ACTIONS">
        <div class="flex flex-1 items-center justify-between">
            <div class="flex items-center flex-1 justify-start">
                <mat-icon class="mr-2" *ngIf="item?.icon" [svgIcon]="item?.icon"></mat-icon>
                <p>{{item?.title}}</p>
            </div>
            <div class="flex mr-2" *ngIf="item?.id == 'datasets'">
                <button mat-icon-button (click)="openCreateDialog(datasetTypes.DATASET)"
                    matTooltip="Add Table">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <ng-template #ACTIONS>
        <div class="flex justify-self-center my-2">
            <button mat-flat-button color="primary" (click)="item.action()"><mat-icon [svgIcon]="item?.icon"></mat-icon>
                {{item.title}}</button>
        </div>
    </ng-template>
</ng-template>
