<div class="main">



    <ng-container *ngIf="!isEditMode && !isShowLogsEnabled">
        <!-- Large screen -->

        <div *ngIf="isActionInProgress">
            <mat-spinner diameter="50"></mat-spinner>
        </div>


        <ng-container *ngIf="result && headerWidth > 925 &&  !isActionInProgress">
            <button mat-flat-button color="basic" (click)="edit()" class="basic-button">
                <span>Edit</span>
            </button>
            <button mat-stroked-button color="basic" *ngIf="!isCopied" (click)="copy(result)" class="basic-button">
                <span>Copy</span>
            </button>
            <button mat-stroked-button color="basic" *ngIf="isCopied" class="basic-button">
                <mat-icon class="copy-icon">check</mat-icon>
            </button>
        </ng-container>
        <button class="more_vert" mat-icon-button color="basic" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <!-- small screen -->
            <ng-container *ngIf="headerWidth <= 925">
                <button *ngIf="showEditButton" (click)="edit()" mat-menu-item>
                    <span>Edit</span>
                </button>
                <button (click)="copy(result)" mat-menu-item>
                    <span *ngIf="!isCopied">Copy</span>
                    <mat-icon *ngIf="isCopied" class="copy-icon">check</mat-icon>
                </button>
            </ng-container>


            <button (click)="onToggleShowLogs()" mat-menu-item>
                <span>{{isShowLogsEnabled ? 'Hide ' : 'Show '}}Logs</span>
            </button>


            <button *ngIf="showReprocessFileButton" (click)="reprocess()" mat-menu-item>
                <span>Reprocess File</span>
            </button>
            <button mat-menu-item [disabled]="actionsList.length == 0" [matMenuTriggerFor]="actions">Rerun Action</button>


            <button class="warn" [disabled]="isActionInProgress" (click)="delete()" mat-menu-item>
                <span>Delete</span>
            </button>

        </mat-menu>

    <mat-menu #actions="matMenu">
        <button mat-menu-item *ngFor="let action of actionsList" (click)="rerunAction(action)">
            {{action.name}}
        </button>
    </mat-menu>
    </ng-container>

    <ng-container *ngIf="isEditMode">
        <button class="save-btn" [disabled]="isSaving" mat-flat-button color="basic" (click)="save()">
            <mat-spinner *ngIf="isSaving" diameter="20"></mat-spinner>
            {{isSaving ? 'Saving' : 'Save'}}
        </button>
        <button *ngIf="!isSaving" mat-stroked-button color="basic" (click)="cancel()">Cancel</button>
    </ng-container>



    <ng-container *ngIf="isShowLogsEnabled">
        <button mat-flat-button color="basic" (click)="onToggleShowLogs()">Hide Logs</button>
    </ng-container>
</div>
