<div (click)="fileInput.click()" id="drop-area" [matTooltip]="isUploading ? null : 'Upload Files'" matTooltipPosition="above"
    class="flex justify-center align-center file-input-wrapper cursor-pointer" (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave()" (drop)="onDrop($event)" [ngClass]="{ highlight: isDragOver }">
    <div class="hint gap-x-1">
        <div class="main-upload-spinner" *ngIf="isUploading">
            <mat-spinner color="primary" diameter="50"></mat-spinner>
            <p class="upload-hint">{{inputMsg}}</p>
        </div>
        <div *ngIf="!isUploading">
            <mat-icon class="icon">cloud_upload</mat-icon>
            <p class="upload-hint">Upload Document (PDF, RTF, DOC, DOCX)</p>
        </div>
    </div>
    <input [disabled]="isUploading" [multiple]="isMultipleUpload" type="file" [accept]="allowedExtensions" #fileInput hidden
        (change)="onFileSelected($event)" />
</div>