import { KtdGridLayoutItem } from '@katoid/angular-grid-layout';
import { GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import {IList} from './kanban-list.model';
import { IChartDataConfig, IChartFormatConfig, DRQuery, DRSortCriteria, Factory, IPivotConfig, IColumnSetting, IChartTable, IStat, IDRFilter } from '..';
import { IApp } from '../dynamic-reports-services/app-settings.service';

export enum ReportSubType {
    COLUMN_BAR = 'column-bar',
    COLUMN_STACK = 'column-stack',
    HORIZONTAL_STACK = 'horizontal-stack',
    HORIZONTAL_BAR = 'horizontal-bar',
    CIRCULAR_PIE = 'circular-pie',
    LINE = 'line',
    INDICATOR = 'indicator',
    VERTICAL_INDICATOR = 'vertical-indicator'
}

export interface IChartState {
    chartConfig?: IChartDataConfig;
    chartFormat?: IChartFormatConfig;
    isFull?: boolean;
    tableConfig?: IChartTable;
}

export interface IFacetDefinition {
    group: string;
    name: string;
    isPinned: boolean;
}

export type MainViewStateViewType = 'table-view' | 'chart-view' | 'summary-view' | 'pivot-view' | 'kanban-view' | 'dashboard-view';

export interface IMainViewState {
    mainTitle: string;
    reportDir: ('common' | 'personal' | 'session');
    type: MainViewStateViewType;
    description?: string;
    subType?: ReportSubType;
    facets: IFacetDefinition[];
    isDefaultColumns: boolean;
    isDefaultSorts: boolean;
    isDefaultFacets: boolean;
    stats?: IStat[];
    userId: number;
    dirId: number;
    isVerticalFacets: boolean;
    //facetsByGroup?: {[group:number]:string[]};
}

export interface IKanbanViewState {
    groupByColumn: GroupDescriptor;
    columnSettings: IColumnSetting[];
    listsInfo:IList[];
    sorts: {fieldName: string, dir: 'asc' | 'desc'}[]
}
export interface IDynamicReportObservables {
    mainView: Observable<IMainViewState>;
    query: Observable<DRQuery>;
    tableView?: Observable<ITableViewState>;
    chart?: Observable<IChartState>;
    chartList?: Observable<IChartState[]>;
    pivotChart?: Observable<IPivotConfig>;
    kanbanView?: Observable<IKanbanViewState>;
}
export interface IDashboardFilter {
    tabs: string[];
    filter: IDRFilter;
}
export interface IDashboardViewState {
    reports: IDashboardReportConfig[];
    filters?: IDashboardFilter[]
}
export interface IDashboardReportConfig extends KtdGridLayoutItem  {
    key: string;
    name: string
}
export interface IDynamicReport {
    mainView: IMainViewState;
    tableView: ITableViewState;
    query: DRQuery;
    chart: IChartState;
    chartList: IChartState[];
    pivotChart: IPivotConfig;
    kanbanView: IKanbanViewState;
    dashboard: IDashboardViewState;
}


export interface ITableViewState {
    columnSettings: IColumnSetting[];
    groups: GroupDescriptor[];
    dataFlattenBy:string,
    showGroupFooter?: boolean;
    sorts:SortDescriptor[];
    randomSeed?:number;
}

export interface ITableHeader {
    skip: number;
    pageSize: number;
    resultLength: number;
    lengthIsComplete: boolean;
}

export enum TabType {
    workspace = 'workspace'
}

export interface ITab2 {
    icon: string;
    name: string;
    key: string;
    serviceUrl: string;
    app: IApp;
    pageSize: number;
    claim: string;
    defaultRequestId?: string;
    requiredClaims?: string;
    defaultFacets?: string[];
    defaultSorts?: DRSortCriteria[];
    order?: number;
    businessKey: string,
    facetTranslationUrl: string,
    nestedPathsKeys: {[key: string]: string} []
    hideReportControlFeatures?: boolean;
    refreshTimeout?: number
}

export interface ITab {
    icon: string;
    name: string;
    isSelected?: boolean;
    defaultRequestId: string;
    viewType?: string;
    link?: string;
    key?: string;
    serviceUrl: string;
    defaultFacets?: string[];
    defaultSorts?: DRSortCriteria[];
    claim: string;
    requiredEntitlements?: string[];
    hidden?: boolean;
    order?: number;
    isReady?: boolean;
    type?: TabType;
    href?: string;
    parentKey?: string;
    pageSize?: number;
}

export interface IColumSetting {
    dataType: string;
    display: string;
    displayOrder: number;
    width: number;
    fieldName: string;
    option?: string[];
    showInFilter?: boolean;
}

export interface TableConfig {
    config: ITab2;
}

export class DefaultDataMapper implements Factory<any> {
    constructor(private isCleanBlank: boolean) {

    }
    create(row: any): any {
        const data = row['source'];
        const newRow = data;
        newRow['id'] = row['id'];
        return newRow;
    }
}
