import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConnectionService } from '../connection.service';
import { App } from '../connection.interface';
import { UserData } from '@discoverer/app-core';
import { ERROR_SNACKBAR_OPTIONS, WARN_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-connection-dialog',
  templateUrl: './new-connection-dialog.component.html',
  styleUrls: ['./new-connection-dialog.component.scss']
})
export class NewConnectionDialogComponent implements OnInit {
  apps: App[] = [];
  selectedApp: string = '';
  connectionName: string = 'test';
  user: UserData;
  checkAuthInterval: any;

  constructor(
    private dialogRef: MatDialogRef<NewConnectionDialogComponent>,
    private connectionService: ConnectionService,
    private _snackbar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { user: UserData }
  ) {
    this.user = data.user;
  }
  ngOnInit(): void {
    this.loadApps();
  }

  loadApps() {
    this.connectionService.getApps(this.user.token).subscribe(apps => {
      this.apps = apps;
      this.selectedApp = this.apps[0].app_name;
    }
    );
  }

  createConnection(selectedApp: string) {
    this.connectionService.authenticateConnection(selectedApp, this.user.token).subscribe({
      next: (data) => {
        if (data.status === 'needs_authentication') {
          const popup = window.open(
            data.auth_url,
            'OAuth Login',
            'width=600,height=800'
          );

          this.checkAuthInterval = setInterval(() => {
            if (popup?.closed) {
              clearInterval(this.checkAuthInterval);
              return;
            }

            this.connectionService.authenticateConnection(selectedApp, this.user.token).subscribe(statusData => {
              if (statusData.status === 'authenticated') {
                popup?.close();
                clearInterval(this.checkAuthInterval);
                this.dialogRef.close(true);
              }
            });
          }, 2000);
        } else if(data.status === 'authenticated') {
          this._showMsg('Connection already exists', WARN_SNACKBAR_OPTIONS);
        }
      },
      error: (error) => {
        console.error('Authentication failed:', error);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
    if (this.checkAuthInterval) {
      clearInterval(this.checkAuthInterval);
    }
  }

  private _showMsg(msg: string, config: MatSnackBarConfig = { duration: 3000, politeness: 'polite' }) {
    this._snackbar.open(msg, 'close', config);

  }
}
