import { disLogger, fixTimeZone } from '../functions';

export class GroupBy {
    public orginalGap?: any;
    public gap: string;
    public start: string;
    public end: string;
    public comparasionMode?: boolean;
    public compareType?: 'Values' | 'Percentage'
    public stepsNo?: number;
}
export class DateGroupBy extends GroupBy {
    public offset = 0;
    public compareOffset?: number;
    public numberOfGroups: number;
    public displayOffSet = 0;
    private _calculatedOffset = 0;
    public compareType?: 'Values' | 'Percentage'

    public setQueryType(which: 'regular' | 'compare') {
        this._calculatedOffset = which === 'regular' ? this.offset + this.displayOffSet : this.compareOffset + this.offset + this.displayOffSet;
        this.calculateStartEndGap(this._calculatedOffset);
    }

    private getMondayDiff(d) {
        d = new Date(d);
        const day = d.getDay();
        const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return diff;
    }

    constructor(
        gap: 'DAY' | 'WEEK' | 'MONTH' | 'QUARTER' | 'YEAR' | 'DAILY WEEK' | 'DAILY MONTH',
        numberOfGroups: number,
        offset = 0,
        compareOffset = 0,
        displayOffset = 0,
        comparasionMode: 'Values' | 'Percentage' = 'Percentage'
        ) {
        super();
        this.orginalGap = gap;
        this.numberOfGroups = numberOfGroups;
        this.offset = offset;
        this.compareOffset = compareOffset;
        this.displayOffSet = displayOffset;
        this.comparasionMode = compareOffset > 0;
        this.compareType = comparasionMode;
        this.calculateStartEndGap(this._calculatedOffset);
    }

    protected calculateStartEndGap(calculatedOffSet: number) {
        const lastMonday = -1 * this.getMondayDiff(new Date());
        switch (this.orginalGap) {
            case 'QUARTER':
                this.gap = '+3MONTHS';
                const now = fixTimeZone();
                const remainder = (now.getMonth()) % 3;
                // January is 0, February is 1, and so on.
                // make adjustment to months to ensure ranges are aligned
                // with quarters q1 = 1/1 - 31/3, q2 = 4/1 - 6/31 ...
                const backRemainder = this.numberOfGroups % 3;
                const numberOfMonthsBack = (remainder === 0) ? ((this.numberOfGroups + calculatedOffSet) * 3 - 3) :
                    (this.numberOfGroups + calculatedOffSet - 1) * 3 + remainder; // (((this.numberOfGroups + this._calculatedOffset) * 3) + remainder - backRemainder);
                this.start = 'NOW/MONTH' + '-' + (numberOfMonthsBack) + 'MONTHS';
                disLogger('', this.start);
                this.end = 'NOW/DAY' + (calculatedOffSet ? ('-' + (calculatedOffSet * 3) + 'MONTHS') : '');
                break;
            case 'YEAR':
                this.start = 'NOW/' + this.orginalGap + '-' + ((this.numberOfGroups + calculatedOffSet) * 12) + 'MONTHS';
                this.end = 'NOW/DAY' + (calculatedOffSet ? ('-' + (calculatedOffSet * 12) + 'MONTHS') : '');
                this.gap = '+1' + this.orginalGap;
                disLogger('', this.start, this.end, this.gap);
                break;
            case 'MONTH':
                this.start = 'NOW/' + this.orginalGap + '-' + (this.numberOfGroups + calculatedOffSet) + 'MONTHS';
                this.end = 'NOW/DAY' + (calculatedOffSet ? ('-' + (calculatedOffSet) + 'MONTHS') : '');
                this.gap = '+1' + this.orginalGap;
                disLogger(this.start, this.end, this.gap);
                break;
            case 'WEEK':
                this.start = 'NOW/WEEK' + '-' + (((this.numberOfGroups + calculatedOffSet) * 7) + lastMonday + 1) + 'DAYS';
                this.end = 'NOW/DAY' + (calculatedOffSet ? ('-' + ((calculatedOffSet * 7) + lastMonday + 1) + 'DAYS') : '');
                this.gap = '+1' + this.orginalGap;
                disLogger(this.start, this.end, this.gap);
                break;
            case 'DAILY WEEK':
                const startExpression = ((((this.numberOfGroups - 1) + calculatedOffSet) * 7) + lastMonday + 1);
                this.start = 'NOW/WEEK' + '-' + startExpression + 'DAYS';
                this.end = 'NOW/WEEK' + '-' + (startExpression - (7 * this.numberOfGroups)) + 'DAYS';
                this.gap = '+1DAY';
                disLogger(this.start, this.end, this.gap);
                break;
            case 'DAILY MONTH':
                let currentDate = new Date(); // Current date
                let endOfMonth: Date;
                let startOfMonth: Date
                
                if(calculatedOffSet === 0) {
                    startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - this.numberOfGroups);
                    endOfMonth = currentDate;
                } else if(calculatedOffSet > 0) {
                    startOfMonth = this.addMonths(currentDate, -(calculatedOffSet + this.numberOfGroups));
                    endOfMonth = this.addMonths(currentDate, -(calculatedOffSet));
                } else {
                    startOfMonth = this.addMonths(currentDate, (calculatedOffSet * -1) - 1);                    
                    endOfMonth = this.addMonths(currentDate, (calculatedOffSet * -1)  + this.numberOfGroups - 1);
                }

                startOfMonth = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 1)
                endOfMonth = new Date(endOfMonth.getFullYear(), endOfMonth.getMonth() + 1, 1)
                
                let startDays = this.calDaysDifference(new Date(startOfMonth.toISOString().split('T')[0]), new Date(currentDate.toISOString().split('T')[0]));
                let endDays = this.calDaysDifference(new Date(endOfMonth.toISOString().split('T')[0]), new Date(currentDate.toISOString().split('T')[0])) - 1;

                this.start = 'NOW/DAY' + '-' + (startDays - 1) + 'DAYS';
                this.end = 'NOW/DAY' + (endDays ? ('-' + endDays) + 'DAYS' : '');
                this.gap = '+1DAY';
                disLogger(this.start, this.end, this.gap, calculatedOffSet);
                break;
            case 'DAY':
                this.start = 'NOW/' + this.orginalGap + '-' + (this.numberOfGroups + calculatedOffSet) + 'DAYS';
                this.end = 'NOW/DAY' + (calculatedOffSet ? ('-' + calculatedOffSet + 'DAYS') : '');
                this.gap = '+1' + this.orginalGap;
                disLogger(this.start, this.end, this.gap, calculatedOffSet);
                break;
        }
    }

    protected calDaysDifference(startDate: Date, endDate: Date) {
        const timeDifference = endDate.getTime() - startDate.getTime();
        return Math.ceil(timeDifference / (1000 * 3600 * 24));
    }

    protected addMonths(date: Date, months: number): Date {
        const newDate = new Date(date);
        newDate.setMonth(date.getMonth() + months);

        if (newDate.getMonth() !== (date.getMonth() + months) % 12) {
           newDate.setDate(0); // Set to the last day of the previous month
        }
      
        return newDate;
    }
}



export class NumberGroupBy extends GroupBy {
    constructor(gap, start: number, end: number, stepsNo?: number) {
        super();
        this.orginalGap = gap;
        this.gap = Math.round(stepsNo ? (+end - +start) / stepsNo : gap).toString();
        this.start = start.toString();
        this.end = end.toString();
        this.stepsNo = stepsNo;
    }
}
