import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { IOption } from '../../option-lists/models/option';
import { OptionDataServiceInterface } from '../../option-lists/shared-services/data-service';
import { HasuraOptionService } from '../../option-lists/shared-services/hasura-option-service';
import { BaseCustomController } from '../shared/base-custom-controller';
@Component({
    selector: 'app-formly-field-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    providers: [{ provide: OptionDataServiceInterface, useClass: HasuraOptionService }],
})
export class StarRatingComponent extends BaseCustomController implements OnInit {
    public stars = [1, 2, 3, 4, 5];
    public dynOptions: IOption[] = [];
    public selectedValue;
    public hoveredValue;

    constructor(
        private optionsService: OptionDataServiceInterface,
        private ref: ChangeDetectorRef
    ) {
        super();
    }
    ngOnInit() {
        const observable = this.optionsService.getByOptionListId(this.field.templateOptions.optionsListId);
        if (observable) {
            observable.subscribe(response => {
                this.dynOptions = response;
                if (this.field.formControl.value) {
                    this.selectedValue = this.getSelectedValue(this.field.formControl.value);
                    this.ref.markForCheck();
                }
            });
        }
        this.field.formControl.valueChanges.subscribe(optionId => {
            if (this.dynOptions) {
                this.selectedValue = optionId ? this.getSelectedValue(optionId) : 0;
            }
        });

    }
    public fillStars(star) {
        this.selectedValue = star;
        this.updateValueAndValidity(this.field, this.getOptionId(this.selectedValue));
    }
    private getSelectedValue(id) {
        return id ? this.dynOptions.find(op => op.id === id).value : null;
    }
    private getOptionId(value) {
        return value && this.dynOptions ? this.dynOptions.find(op => op.value === value.toString()).id : null;
    }
}
