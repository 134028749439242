import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, Subject } from "rxjs";
import { DRQuery } from "../classes";
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EsQueryService {

  public oEsQuery: Observable<EsQuery>;
  protected _sEsQuery: Subject<EsQuery>;

  constructor(private _http: HttpClient) {

    this._sEsQuery = new ReplaySubject(1);

    this.oEsQuery = this._sEsQuery.asObservable();

  }

  public async updateQuery(drQuery: DRQuery, serviceUrl: string, name: string) {
    const url = serviceUrl.split('?')[0];
    const parts = url.split('/').reverse();
    const tabId = parts[0];
    const appId = parts[1];
    
    const esQuery: any = await this._http.post(`/api/es/BaseDiscoverer/GetESQuery/${appId}/${tabId}/esQuery`, drQuery).toPromise();
    this._setEsQuery({ name , esQuery: esQuery?.result })
  }

  private _setEsQuery(value: EsQuery) {
    this._sEsQuery.next(value);
  }

}

export class EsQuery {
  name : string ;
  esQuery: any;
}
