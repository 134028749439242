<disco-data (context)="contextChanged($event)" [appId]="appId" [tabId]="tab.key" [requestId]="'default'"
    [queryService]="reportPersist.mainQueryService">
    <ng-template let-context #myTemplate>
        <div class="actions-row">
            <export-report [resultSize]="(tableHeaderService.tableHeader | async)?.resultLength" [currentTab]="tab"
                [queryService]="reportPersist.mainQueryService">
            </export-report>
        </div>
        <search-header id="search-header" [queryService]="reportPersist.mainQueryService" [hideFilters]="true"
            [tableHeaderConfig]="tableHeaderService.tableHeader | async" [selectedView]="'table-view'">
        </search-header>
        <!-- <div class="scroll-wrapper"> -->
        <kendo-table-view [tableHeaderService]="tableHeaderService" [hideEditColumn]='true'
            (cellClickedEvent)="navigateToDetail($event)">
        </kendo-table-view>
        <!-- </div> -->
    </ng-template>
</disco-data>
