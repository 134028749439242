import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormControl } from '@angular/forms';
import { RequestFilingService, AppSettingsService } from '@discoverer/core/services';
import { SaveReportDialog } from '../save-report/save-report.dialog';

@Component({
    selector: 'app-request-directory',
    templateUrl: './request-directory.dialog.html',
    styleUrls: ['./request-directory.dialog.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RequestDirectoryDialog implements OnInit {

    public reportName: FormControl;
    public directory: FormControl;
    public showError = false;
    public directoryList;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<SaveReportDialog>,
        private requestFilingService: RequestFilingService,
        private appSettings: AppSettingsService
    ) {
        this.createForm();
    }
    async ngOnInit() {
        const appKey = (await this.appSettings.getCurrentApp()).key;
        this.directoryList = await this.requestFilingService.getDirectories(appKey, this.data.tab, false);
        this.directory.setValue(this.directoryList.find(x => x.directoryName.split('|')[1] === 'Personal').id);
    }

    public close() {
        this.dialogRef.close();
    }
    public save() {
        if (this.directory.value) {
            const reportdir = this.directoryList.find(x => x.id === this.directory.value);
            const name = reportdir.directoryName.split('|')[1].toLowerCase();
            this.dialogRef.close({ reportName: this.reportName.value, directoryId: this.directory.value, reportDir: name });
        }
    }

    private createForm() {
        this.reportName = new FormControl('', [
            Validators.required
        ]);
        this.directory = new FormControl('', [
            Validators.required
        ]);
    }

}
