import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { DRFacetOption, FacetFieldAccumulator, FacetValue } from '../classes';

import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

export class FacetTranslationInfo {
  Id: string;
  Display: string;
}
@Injectable()
export class DiscovererFacetTranslationService {

  public facetTranslationInfo: FacetTranslationInfo[] = [];

  private _serviceUrl: string;
  private _optionListId: number = 0;

  constructor(private http: HttpClient, private apollo: Apollo) {
  }

  public async init(serviceUrl: string, optionListId: number) {
    this._serviceUrl = serviceUrl;
    this._optionListId = optionListId??0;
    this.facetTranslationInfo = await this.loadInfo();
  }

  private loadInfo(): Promise<FacetTranslationInfo[]> {
    if (this._optionListId != 0) {
      return this.getInfoFromOptionList();
    }
    else {
      return this.getInfoFromServiceURL();

    }
  }

  private getInfoFromOptionList(): Promise<FacetTranslationInfo[]> {
    const queryObservable = this.apollo.query<{ Options: any[] }>({
      query: gql`query getOptions($optionListId: Int!) {
        Options: ignt_core_Options(where: {OptionListId: {_eq: $optionListId}}) {
          Id
          Description
          OptionListId
          Code
          Value
          Group
          Order
        }
      }
      `, variables: { optionListId: this._optionListId }
    });


    return queryObservable.pipe(map((options) =>
      (options.data ? options.data.Options : []).map(info => {
        return {
          Id: info.Code,
          Display: info.Description
        };
      }))).toPromise();
  }
  private getInfoFromServiceURL(): Promise<FacetTranslationInfo[]> {
    return this.http.get<any>(this._serviceUrl)
      .pipe(
        map((facetInfo: any) => {
          return facetInfo.map((info) => {
            return {
              Id: info.id,
              Display: info.label
            }
          });
        })
      ).toPromise();

  }

}





