import { DatasetActionType } from './enums';
export const actionTypeName = new Map<DatasetActionType, string>([
    [DatasetActionType.Webhook, 'Webhook'],
    [DatasetActionType.AiActionField, 'Ai Action'],
    [DatasetActionType.AiGenerate, 'Ai Generate']
])

export const actionTypeImage = new Map<DatasetActionType, string>([
    [DatasetActionType.Webhook, 'assets/images/logo/webhook-logo.png'],
    [DatasetActionType.AiActionField, 'assets/images/logo/ai-action.png'],
    [DatasetActionType.AiGenerate, 'assets/images/logo/ai-generate.png']
])

export const fileIconMap = new Map<string, string>([
    ['txt', 'description'],
    ['pdf', 'picture_as_pdf'],
    ['png', 'insert_photo'],
    ['jpg', 'insert_photo'],
    ['jpeg', 'insert_photo'],
    ['docx', 'description'],
    ['doc', 'description'],
    ['xlsx', 'description'],
    ['xls', 'description'],
    ['csv', 'description']
]);
