import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataSourceContext } from '@discoverer/core';


@Component({
    selector: 'app-document-header',
    templateUrl: './document-header.component.html',
    styleUrls: ['./document-header.component.scss']
})
export class DocumentHeaderComponent {
    public dataSourceContext: DataSourceContext;
    @Input() headerWidth: number;
    @Input() showEditButton: boolean = false;
    @Input() showReprocessFileButton: boolean = false;
    @Input() result: any;
    @Input() resultIndex: number;
    @Input() fileId: string;
    @Input() tabId: string;
    @Input() isCopied: boolean = false;
    @Input() isSaving: boolean;
    @Input() isShowLogsEnabled = false;
    @Output() reprocessFile = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();
    @Output() toggleIsSaving = new EventEmitter<boolean>();
    @Output() queryServiceChanged = new EventEmitter();
    @Output() toggleShowLogs = new EventEmitter<boolean>();
    @Output() limitChanged = new EventEmitter<number>();
    @Output() cancelEditMode = new EventEmitter<void>();
    @Output() toggleEditMode = new EventEmitter<boolean>();
    @Output() closeDialog = new EventEmitter<boolean>();
    @Input() isEditedValuesValid: boolean;
    @Input() isEditMode: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() fileInfo: any;
    @Input() fileExtension: string = "";
    @Input() fileName: any = "";
    @Input() dateReceived: any = "";
    @Input() titles: string[] = [];
    @Input() start: number = 0;
    @Input() total: number = 0;
    @Output() moveToPrevious = new EventEmitter<void>();
    @Output() moveToNext = new EventEmitter<boolean>();

    constructor(private _router: Router) { }

    onCloseDialog() {
        const urlTree = this._router.parseUrl(this._router.url);
        const segments = urlTree.root.children['primary']?.segments || [];
        segments.pop();
        this._router.navigate(segments.map(segment => segment.path), { queryParams: { refresh: true } });
    }
    onLimitChanged() {
        this.limitChanged.emit();
    }
    onCancelEditMode(isEditMode) {
        this.toggleEditMode.emit(isEditMode);
    }


    public onToggleIsSaving(saveInProgress) {
        this.toggleIsSaving.emit(saveInProgress);
    }
    public onToggleShowLogs(isShowLogsEnabled) {
        this.toggleShowLogs.emit(isShowLogsEnabled)
    }
    public onQueryServiceChanged() {
        this.queryServiceChanged.emit();
    }
    public onToggleEditMode(isEditMode) {
        this.toggleEditMode.emit(isEditMode);
    }
    onMoveToPrevious() {
        this.moveToPrevious.emit();
    }
    onMoveToNext() {
        this.moveToNext.emit();
    }

}
