import {
    Component,
    EventEmitter,
    HostListener,
    Input, Output,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';
import {
    Router
} from '@angular/router';
import {
    ChangeDetectionStrategy
} from '@angular/core';
import {
    DialogsManagerService
 } from '../../dialogs';
import {
    MatDialog
} from '@angular/material/dialog';
import {
    MatSnackBar
 } from '@angular/material/snack-bar';
import {
    AppSettingsService,
    BaseController,
    IDirRequest,
    IRequest,
    Permission,
    ReportPersistService,
    RequestFilingService,
    UserPermissionService
}
    from '@discoverer/core/services';
import {
    resolveReportIcon
 } from '../control-bar';
import { FlattenedDirRequests } from '../filtered-reports-view/filtered-reports-view.component';

export const DASHBOARDS = 'dashboards';

@Component({
    selector: 'dashboards-list',
    templateUrl: './dashboards-list.component.html',
    styleUrls: ['./dashboards-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardsListComponent extends BaseController implements OnInit {

    @Input() public currentAppKey: string;
    @Input() public editable = true;
    @Input() public routePath: string;
    @Input() public defaultRequestId: string;
    @Input() public reportType: string;
    @Input() public mobileView: boolean;
    @Input() public loadedRequestDirectory: string;
    @Input() public canEdit: boolean = false;

    @Output() public hideReportsEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public mainTitleChanged: EventEmitter<string> = new EventEmitter<string>();;
    @Output() public selectedDashboard: EventEmitter<string> = new EventEmitter<string>();;

    @Output() public saveReport: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public deleteReport: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() public loading: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public currentReqDirId: EventEmitter<number> = new EventEmitter<number>();


    public requestHover = false;
    public numberOfColumns = 5;
    public columnWidthPercentage = 10;
    public selectedIndex: number = undefined;

    public reportList: IDirRequest[];
    public requestsPermission = {};
    public selectedRequest: IRequest;
    public selectedDirectory: IDirRequest;
    public canCreatePublicDirectory: boolean;
    public canEditPublicDirectory: boolean;
    private originalDirs: IDirRequest[];
    private currentDirectory: string;
    term = ''
    constructor(
        public dialog: MatDialog,
        public router: Router,
        private reportPersist: ReportPersistService,
        private cd: ChangeDetectorRef,
        private requestFilingService: RequestFilingService,
        private dialogsManagerService: DialogsManagerService,
        private snackbar: MatSnackBar,
        protected appSettings: AppSettingsService,
        private _userPermissionService: UserPermissionService
    ) {
        super();
    }

    public async ngOnInit() {
        this.initUserReportSubscriptions();
        this.requestFilingService.sReloadRequests.next(true);
        this.cd.markForCheck();
    }

    public setSearchedReport(selectedReport: FlattenedDirRequests) {
        const dir = this.originalDirs.find(dir => dir.id === selectedReport.directoryId);
        this.setReport(selectedReport, dir);
    }
    public deleteRequest(request) {
        this.reportPersist.deleteRequest(request.id);
    }

    public async initUserReportSubscriptions() {
        this.subscriptions.push(this.requestFilingService.sReloadRequests.subscribe(async reload => {
            this.setUserPermissions();
            if (!!reload) {
                const appKey = this.currentAppKey;
                const dirs = await this.requestFilingService.getDirectories(appKey, DASHBOARDS, false);
                let sum = 0;
                this.requestsPermission = {}
                dirs.forEach(async dir => {
                    dir.requests = await Promise.all(dir.requests.map(async r => {
                        r.icon = resolveReportIcon(r.type, r.subType);
                        this.requestsPermission[r.id] = {
                            canEditReq: await this.canEditReq(r.directoryId, dir.userId, r.id),
                            canMove: await this.canMoveRequest(r.directoryId, dir.userId, r.id),
                            canDelete: await this.canDeleteRequest(r.directoryId, dir.userId, r.id)
                        }
                        return r;
                    }).filter(r => !!r));
                    dir.expanded = true;

                    dir.directoryName = dir.directoryName.split('|')[1];
                    dir.width = Math.ceil(dir.requests.length / 6) * 450;
                    sum += Math.floor(dir.requests.length / 6) + 1;
                });
                this.numberOfColumns = (sum < 5) ? 5 : sum;
                this.fixColmunWidth();
                this.reportList = dirs;
                const reqId = this.reportPersist.loadReqId;
                const selectedDir = dirs.find(dir => dir.requests.find(req => req.id === reqId));
                this.selectedDirectory = selectedDir;
                this.selectedRequest = selectedDir?.requests?.find(req => req.id === reqId);
                this.currentReqDirId.emit(selectedDir?.id);

                this.originalDirs = dirs;
                this.reportList.map(x => {
                    x.requests
                        .sort((a, b) => {
                            if (!!b && !!b.requestName && !!a && !!a.requestName) {
                                return -1 * b.requestName.localeCompare(a.requestName);
                            } else {
                                return -1;
                            }
                        })
                        .sort((a, b) => a.requestOrder - b.requestOrder);
                });
                this.reportList.sort((a, b) => a.directoryName.localeCompare(b.directoryName)).sort((b, a) => +b.userId - +a.userId);
            } else {
                this.numberOfColumns = 5;
                this.fixColmunWidth();
                this.reportList = [];
                this.originalDirs = [];
            }
            this.reportList.forEach(async report => {
                report.requests = report.requests.filter(req => !!req.id);
            });
            this.cd.markForCheck();
        }));
    }

    async canEditReq(dirId: number, requestOwnerUserId: number, reqId: string) {
        return await this._userPermissionService.hasPermission(DASHBOARDS, Permission.EditReport, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${reqId}`);
    }
    async canMoveRequest(dirId: number, requestOwnerUserId: number, reqId: string) {
        return await this._userPermissionService.hasPermission(DASHBOARDS, Permission.MoveRequest, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${reqId}`);
    }
    async canDeleteRequest(dirId: number, requestOwnerUserId: number, reqId: string) {
        return await this._userPermissionService.hasPermission(DASHBOARDS, Permission.DeleteRequest, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${reqId}`);
    }

    openEditDirectoryDialog(dir) {
        this.dialogsManagerService.openEditTitleDialog(dir.directoryName).subscribe(async (directoryName: any) => {
            if (directoryName) {
                this.requestFilingService.editDirectoryName(DASHBOARDS.toUpperCase(), dir.id, directoryName);
            }
        });
    }

    async moveReportFolder(request) {
        const result = await this.dialogsManagerService.openRequestDirectoryDialog(DASHBOARDS).toPromise();
        if (!!result) {
            await this.requestFilingService.setRequestDirectory(request.id, request.requestName, result.directoryId, this.currentAppKey, DASHBOARDS);
        }
    }

    removeDashboard(request) {
        this.dialogsManagerService
            .openConfirmDialog('Delete Dashboard?', `Are you sure you want to delete this dashboard (${request.requestName})? This cannot be undone.`)
            .subscribe(async (confirm: boolean) => {
                if (confirm) {
                    await this.reportPersist.deleteRequest(request.id);
                    this.requestFilingService.sReloadRequests.next(true);
                    this.cd.markForCheck();
                }
            });
    }

    openEditDashboardName(request) {
        this.dialogsManagerService.openEditTitleDialog(request.requestName).subscribe(async (newReqName: any) => {
            if (newReqName) {
                var req = await this.reportPersist.getRequest(request.id);
                req.mainView.mainTitle = newReqName;
                await this.reportPersist.updateRequest(request.id, req, false);
                await this.requestFilingService.setRequestDirectory(request.id, newReqName, this.selectedDirectory.id, this.currentAppKey, DASHBOARDS);
                if ((this.reportPersist.loadReqId) === request.id) {
                    this.mainTitleChanged.emit(newReqName);
                    this.cd.detectChanges();
                }
            }
        });
    }

    public async createPublicDirectory(dir: IDirRequest) {
        if (this.canCreatePublicDirectory) {
            this.dialogsManagerService
                .openConfirmDialog('Make Directory Public?', `Are you sure you want to make this directory (${dir.directoryName}) and associated reports public?`)
                .subscribe(async (confirm: boolean) => {
                    if (confirm) {
                        this.requestFilingService.createPublicDirectory(dir.id);
                    }
                });
        }
    }

    openAddDirectoryDialog() {
        this.dialogsManagerService.opeAddNewDirectoryDialog().subscribe(async (directoryName: any) => {
            if (directoryName) {
                this.requestFilingService.addDirectroyForTab(DASHBOARDS.toUpperCase(), directoryName);
            }
        });
    }

    public async removeDirectory(dir) {
        this.dialogsManagerService
            .openConfirmDialog('Delete Directory?', `Are you sure you want to delete this directory (${dir.directoryName}) and associated reports? This cannot be undone.`)
            .subscribe(async (confirm: boolean) => {
                if (confirm) {
                    const isDeleted = await this.requestFilingService.deleteDirectory(dir.id);
                    if (isDeleted) {
                        if (this.currentDirectory == null || (this.currentDirectory == this.loadedRequestDirectory)) {
                            const defaultRequestId = (await this.appSettings.getCurrentApp()).defaultDashboard;
                            await this.reportPersist.loadRequest(defaultRequestId);
                            this.requestFilingService.sReloadRequests.next(true);
                            this.cd.markForCheck();
                        }
                        this.openSnackBar('Directory has been deleted.');
                    } else {
                        this.openSnackBar('Not authorized.');
                    }
                }
            });
    }

    public async setReport(request: IRequest, dir: IDirRequest) {
        this.selectedRequest = request;
        if(this.editable) {
            await this.reportPersist.loadRequest(request.id);
            this.currentReqDirId.emit(dir.id);
            this.router.navigateByUrl(`${this.currentAppKey}/dashboard/${request.id}`);
        } else {
            this.selectedDashboard.emit(request.id)
        }
        this.hideReportsEvent.emit(true);
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        const windowWidth = event.target.innerWidth;
        this.fixColmunWidth(windowWidth);
    }

    public close() {
        this.hideReportsEvent.emit(true);
    }

    private fixColmunWidth(windowWidth = 0) {
        if (windowWidth === 0) {
            windowWidth = window.innerWidth;
        }
        const numOfColsPerRow = Math.floor(windowWidth / 200);
        this.columnWidthPercentage = 100 / numOfColsPerRow;
    }

    private openSnackBar(msg: string) {
        this.snackbar.open(msg, '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }
    private async setUserPermissions() {
        this.canCreatePublicDirectory = await this._userPermissionService.hasPermission(DASHBOARDS, Permission.CreatePublicDirectory);
        this.canEditPublicDirectory = await this._userPermissionService.hasPermission(DASHBOARDS, Permission.EditPublicDirectory);
    }
}


