import {
    Component,
    Input,
    OnInit,
    Injector,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
} from "@angular/core";
import {
    FacetValue,
    FacetFieldAccumulator,
    DRFacetOption,
    DiscovererQueryService,
    DRFilter,
    NoDataRow,
    DiscovererDataService,
    DiscovererQueryInfoService,
    DRQuery,
    BaseController,
    ReportPersistService,
    IFacetDefinition,
    DSearchFilter,
    IDisplayFilter,
    AdvancedFilterService,
    TabSettingsService,
    IColumnSetting,
    FILTERING_MODES,
    IDRFilter,
    FILTERING_OPERATION,
} from "../services";
import { HttpClient } from "@angular/common/http";
import { combineLatest } from "rxjs";
import { LoadingState } from "../services/classes/loading-state";
import { DiscovererFacetTranslationService } from "../services/core-data-services/discoverer-facet-translation-service.service";
import { EsQueryService } from "../services/core-data-services/discoverer-esquery-service";
import { Apollo } from "apollo-angular";
import { debounceTime, first, map, take } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { TableHeaderService } from "../services/dynamic-reports-services/table-header.service";
import { AdvancedFiltersDisplay } from "@discoverer/dynamic-reports/common/applied-filters-list/applied-filters.directive";

enum QueryType {
    exclude = "exclude",
    include = "include",
    and = "and",
    contains = "contains",
    starts_with = "starts_with",
    regex_match = "regex_match"
}

const SortSelection = {
    SELECTED: true,
    NOT_SELECTED: false,
    ALL: undefined,
};

@Component({
    selector: "facet1",
    styleUrls: ["./facet1.component.scss"],
    templateUrl: "facet1.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Facet1Component
    extends BaseController
    implements OnInit, OnChanges {
    @Input()
    clearButtonKey: boolean

    @Input()
    title: string;

    @Input()
    colorArray: string[];

    @Input()
    isStringArrayFacetType?: boolean = false;

    isAnded: boolean = false;

    queryType: QueryType = QueryType.include;

    @Input()
    singleMode = false;

    @Input()
    facetKey: string;

    @Input()
    serviceUrl: string;

    @Input()
    isDynamic: boolean;

    @Input()
    queryService: DiscovererQueryService;

    @Input()
    maxShow = 20;

    @Input()
    facetName: string;

    @Input()
    isOpen = false;

    @Input()
    facetType: string;

    @Input()
    includeBlank: boolean;

    @Input()
    queryInfoServiceUrl: string;

    @Input()
    filterMode: string;

    @Input()
    optionListId: number;

    @Input() isCollapsible: boolean = true;
    @Input() independent: boolean = false;
    @Input() showCount: boolean = true;
    @Input() isPinned = false;
    @Input() currentTab;
    @Input() showPin: boolean = false;
    @Input() showDoneButton: boolean = false;

    @Output() pinFilter: EventEmitter<IFacetDefinition> = new EventEmitter<IFacetDefinition>();
    @Output() doneButtonClicked: EventEmitter<any> = new EventEmitter<any>();

    // public advFilter: IDisplayFilter = this.advFilterService.createEmptyFacetFilter();
    public availableColumns: IColumnSetting[];
    public advancedFilterMode = 'all';
    public isSubFilter: boolean;
    public parentFilter: IDisplayFilter;
    public filtersList: IDisplayFilter[] = [];
    public validQuery = true;
    public chooseAlphaSorting: boolean;
    public chooseSumSorting: boolean = SortSelection.NOT_SELECTED;
    colorLookup: any = {};
    public facetsValuesAfterSorting: FacetValue[] = [];
    facetValues: FacetValue[] = [];
    sortedFacetValues: FacetValue[] = [];
    checkedFacetValues: FacetValue[] = [];
    ignoreQueryChangeId: string = "";
    totalFiltered = 0;
    pageNumber = 0;
    pageFrom = 0;
    pageSize = 20;
    pageTo = 20;

    facetData: FacetFieldAccumulator;
    isSearchable = false;
    orderByCount = true;

    excludeSelected = false;
    showClearButtonWhenCardIsNotOpen: boolean;

    private _dataService: DiscovererDataService<NoDataRow>;
    loadingState: LoadingState;
    private translationDescription: string[] = [];
    public showTranslation: boolean = true;

    public searchFieldControl = new FormControl();
    private searchTerm: string = '';


    constructor(
        private http: HttpClient,
        private cd: ChangeDetectorRef,
        private queryInfoService: DiscovererQueryInfoService,
        private facetTranslationService: DiscovererFacetTranslationService,
        private esQueryService: EsQueryService,
        private apollo: Apollo,
        private _reportPersistService: ReportPersistService,
        public tableHeaderService: TableHeaderService,
        private advFilterService: AdvancedFilterService,
        private tabSettings: TabSettingsService,
    ) {
        super();
        this.facetData = new FacetFieldAccumulator(this.title);
    }

    async ngOnInit() {
        this.subscriptions.push(
            this.searchFieldControl.valueChanges
                .pipe(debounceTime(500))
                .subscribe(newValue => this.searchTextUpdate(newValue)));
        this.gitQueryType();
    }

    private async initAdvFacet() {
        this.advFilterService = new AdvancedFilterService(this.facetTranslationService, this.http, this.apollo, this.tabSettings);
        this.serviceUrl = (await this.tabSettings.getCurrentTab()).serviceUrl
        this.availableColumns = await this.tabSettings.getAllColumns();
        let subs = null;
        subs = this.queryService.oQuery.subscribe((q) => {
            if (!!q) {
                if (!!subs) {
                    subs.unsubscribe();
                } else {
                    let advFilters = q.filters.filter((f) => f.fields[0] === this.facetKey + '|Advanced_Facet');
                    this.advancedFilterMode = !!advFilters[0] ? advFilters[0].type : FILTERING_MODES.ALL;
                    if (this.isSubFilter) {
                        advFilters = [this.parentFilter];
                    }
                    this.prepareAdvancedService(advFilters);
                }
            }
        });
    }

    private async prepareAdvancedService(advFilters) {
        const columnDictionary = await this.tabSettings.getColumnDictionary();
        this.advFilterService.init(!!advFilters[0] ? advFilters[0] : null, columnDictionary);
        this.subscriptions.push(this.advFilterService.oFilters.subscribe((filters: IDisplayFilter[]) => {
            this.filtersList = this.mapFilters(filters, columnDictionary);
            const allValid = filters.map((f) => f.isValid);
            this.checkValidateAdvancedFilter(allValid);
            if (filters.filter((f) => AdvancedFilterService.isFilterReady(f)) && this.validQuery) {
                const subFilters = filters.filter((f) => AdvancedFilterService.isFilterReady(f)).map((f) => {
                    return this.getDRFilter(f);
                });
                if (filters[0]?.fields[0] === this.facetKey) {
                    if (filters[0].operationA.length) {
                        if (filters[0].operationA === 'CONTAINS') {
                            this.queryType = QueryType.contains;
                        } else if (filters[0].operationA === 'STARTS_WITH') {
                            this.queryType = QueryType.starts_with;
                        } else if (filters[0].operationA === 'REGEX_MATCH') {
                            this.queryType = QueryType.regex_match;
                        }
                    }
                }
                const d: DRFilter = new DRFilter("All", [this.facetKey + '|Advanced_Facet'], [], subFilters);
                if (!this.isSubFilter) {
                    if (d.filters.length === 0) {
                        this.queryService.unSetFilter(this.facetKey + '|Advanced_Facet');
                    } else {
                        this.queryService.setFilter(this.facetKey + '|Advanced_Facet', d);
                        this.queryService.refresh();
                    }
                }
                this.cd.detectChanges();
            }
        }));
    }

    public switchQueryType(value: string, clearFilter = true) {
        setTimeout(() => {
            if (clearFilter && (this.countFiltersApplied() > 0 || this.countAdvancedFiltersApplied() > 0)) {
                this.clearFilter();
            }
        }, 500);
        switch (value) {
            case QueryType.include:
                this.setQueryType(false, false);
                break;
            case QueryType.exclude:
                this.setQueryType(true, false);
                break;
            case QueryType.and:
                this.setQueryType(false, true);
                break;
            case QueryType.contains:
                this.filtersList[0].operationA = 'CONTAINS';
                this.filtersList[0].valueA = '';
                this.filtersList[0].fields = [this.facetKey];
                break;
            case QueryType.starts_with:
                this.filtersList[0].operationA = 'STARTS_WITH';
                this.filtersList[0].valueA = '';
                this.filtersList[0].fields = [this.facetKey];
                break;
            case QueryType.regex_match:
                this.filtersList[0].operationA = 'REGEX_MATCH';
                this.filtersList[0].valueA = '';
                this.filtersList[0].fields = [this.facetKey];
                break;
            default:
                break;
        }
        this.queryType = QueryType[value];
    }

    public updateFilterValue() {
        this.advFilterService.updateFilter(0, this.filtersList[0]);
    }

    public countFiltersApplied() {
        var filter = this.queryService?.getAppliedFacetFilter(this.facetKey);
        return filter?.length == 1 ? (filter[0].facetValues?.length ?? 0) : 0;
    }

    public countAdvancedFiltersApplied() {
        var filter = this.queryService?.getAppliedAdvancedFacetFilter(this.facetKey);
        return filter?.length == 1 ? (filter[0].filters[0].facetValues?.length ?? 0) : 0;
    }

    public getAdvancedQueryTypeName(operationA: string) {
        return FILTERING_OPERATION.filter((o) => o.id === operationA)[0].label
    }

    public clearFilter() {
        this.facetValues.forEach((facetValue) => facetValue.updateCheck(false));
        this.checkedFacetValues = [];
        !!this.filtersList[0] ? this.filtersList[0].valueA = "" : null;
        this.queryService.unSetFilter(this.facetKey);
        this.queryService.unSetFilter(this.facetKey + '|Advanced_Facet');
        this.queryService.refresh();
    }
    public setQueryType(_isExcluded: boolean, _isAnded: boolean) {
        this.isAnded = _isAnded;
        this.excludeSelected = _isExcluded;
        if (this.checkedFacetValues.length !== 0) {
            this.checkedFacetValues?.forEach((x) => {
                x.isExclusion = this.excludeSelected;
                x.isAnded = this.isAnded;
            });
            this.refreshFilters(this.checkedFacetValues);
        }
    }

    public facetValueChanged(facetValue: FacetValue) {
        const checked = facetValue.isChecked;
        if (this.singleMode) {
            this.facetValues.forEach((v) => v.updateCheck(false));
        }
        facetValue.updateCheck(checked);

        this.checkedFacetValues = this.facetValues.filter((x) => x.isChecked);
        this.checkedFacetValues.forEach((x) => {
            x.isExclusion = this.excludeSelected;
            x.isAnded = this.isAnded;
        });
        this.refreshFilters(this.checkedFacetValues || []);
    }

    public toggleDynamicTranslation() {
        if (this.showTranslation == null) {
            this.showTranslation = !this.isDynamic;
        } else {
            this.showTranslation = !this.showTranslation;
        }
        this.facetValues.forEach((x, i) => {
            if (this.showTranslation) {
                this.translationDescription.push(x.title);
                x.title = x.facetKey;
            } else {
                x.title = this.translationDescription[i];
            }
            return x;
        });
    }

    public excludeSelectedValues(exclude: boolean) {
        if (this.checkedFacetValues.length !== 0) {
            this.excludeSelected = exclude;
            this.checkedFacetValues.forEach((x) => {
                x.isExclusion = this.excludeSelected;
            });
            this.refreshFilters(this.checkedFacetValues);
        }
    }

    public selectFacetValue(checked: boolean, facetValue: FacetValue) {
        facetValue.isChecked = checked;
        this.checkIfSelectedAllChecked();
        const checkedValues = this.facetsValuesAfterSorting
            .filter((x) => x.isChecked).length;

        checkedValues === 0
            ? this.clearFilter()
            : this.facetValueChanged(facetValue);

        this.switchQueryType(this.queryType, false);
    }

    public selectAll: boolean = false;

    public selectAllFacetValues($event: any) {
        const startSelection = this.pageNumber * this.pageSize;
        const endSelection = this.pageSize * (this.pageNumber + 1);
        this.facetsValuesAfterSorting
            .slice(startSelection, endSelection)
            .forEach((facet) => this.selectFacetValue($event.checked, facet));
    }

    public checkIfTypeOfSortIsBoolean(sortType: any) {
        return typeof (sortType) === "boolean";
    }

    private getDRFilter(f: IDisplayFilter | IDRFilter) {
        if (!f) return [];
        let filter = new DRFilter(f.type, f.fields, (!!f.expression) ? f.expression.map(e => e.trim()).map((e) => {
            return new FacetValue({
                key: e,
                query: e,
                display: e
            });
        }) : [], !!f.filters ? f.filters.map((sf) => {
            return this.getDRFilter(sf);
        }) : []);
        return filter;
    }

    private mapFilters(filters: IDisplayFilter[], columnDictionary: any): IDisplayFilter[] {
        filters.map(async f => {
            if (f.fields && f.fields.length > 0) {
                const column = columnDictionary[f.fields[0]];
                if (column) {
                    f.isDynamicFacet = column.isDynamicFacet;
                    f.tableName = column.tableName;
                    f.optionListId = column?.form?.templateOptions?.optionsListId == null ? 0 : column.form.templateOptions.optionsListId;
                    if (f.isDynamicFacet == false)
                        f.displayA = await this.advFilterService.getDisplayFromExpression(f.expression, column)
                }
            }
            return f;
        });

        return filters;
    }

    public checkValidateAdvancedFilter(filtersValidityList: boolean[]) {
        let allV = true;
        filtersValidityList.forEach(x => {
            if (x === false) {
                allV = false;
                return;
            }
        });
        this.validQuery = allV;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.onQueryOrDataServiceChanges(changes);
    }

    private gitQueryType(): void {
        const expression = this.queryService?.filters?.find(fil => fil.fields[0] === this.facetKey && fil.type === 'facet')?.expression;
        if (expression?.every(ex => ex.includes('!'))) {
            this.queryType = QueryType.exclude
        } else if (expression?.every(ex => ex.includes('+'))) {
            this.queryType = QueryType.and
        } else {
            this.queryType = QueryType.include
        }
        this.switchQueryType(this.queryType, false);
    }

    private onQueryOrDataServiceChanges(changes: SimpleChanges) {
        if (
            !!changes["queryService"] &&
            changes["queryService"].previousValue !==
            changes["queryService"].currentValue
        ) {
            this.resetFacetComponent();
        }

        if (
            !!changes["facetKey"] &&
            changes["facetKey"].previousValue !== changes["facetKey"].currentValue
        ) {
            this.resetFacetComponent();
        }
    }

    private resetFacetComponent() {
        this.resetSubsciptions();
        // this.subscriptions.push(this.queryInfoService.oQueryInfo.subscribe(x => {
        //     if (!!x[this.facetKey] && !!x[this.facetKey].facetOptions) {
        //         this.facetValues = x[this.facetKey].facetOptions
        //             .map((value: DRFacetOption) => {
        //                 return new FacetValue(value);
        //             });
        //     }
        // }));
        // if changes happen we need to rebuild dataService
        this.initDataService();
        this.initAdvFacet();
    }

    private resetSubsciptions() {
        while (this.subscriptions.length > 0) {
            const subs = this.subscriptions.pop();
            subs.unsubscribe();
        }
        if (this._dataService != null) {
            this._dataService.destroy();
            this._dataService = null;
        }
    }

    facetTitleClicked() {
        this.isOpen = !this.isOpen;
        this._dataService.enabled = this.isOpen;
        if (this.isOpen) {
            this._dataService.refresh();
        }
        this.cd.markForCheck();
    }

    searchTextUpdate(searchTerm: string) {
        this.pageNumber = 0;
        this.searchTerm = searchTerm;
        if (!this.isDynamic) {
            this.pageNumber = 0;
            this.updatePaginationControls();
            this.sortFacets();
        }
        else {
            this._addSearchFilter(searchTerm);
        }

    }

    nextPage(event) {
        event.preventDefault();
        this.pageTo >= this.totalFiltered
            ? (this.pageNumber = Math.floor(this.totalFiltered / this.pageSize))
            : this.pageNumber++;
        this.sortFacets();
    }

    prevPage(event) {
        this.pageNumber <= 1 ? (this.pageNumber = 0) : this.pageNumber--;
        this.sortFacets();
    }

    private checkIfSelectedAllChecked() {

        const startSelection = this.pageNumber * this.pageSize;
        const endSelection = this.pageSize * (this.pageNumber + 1);
        const temp = this.facetsValuesAfterSorting
            .slice(startSelection, endSelection)
            .filter((v) => v.isChecked === false);
        this.selectAll = temp.length === 0;
    }

    private initDataService() {
        if (this.queryService != null) {
            // queryService avail but no dataService create one
            this._dataService = new DiscovererDataService(
                this.http,
                this.esQueryService,
                { create: (a) => [a] },
                this.independent
            );

            this.subscriptions.push(
                this._dataService.oLoadingStatusResult.subscribe((res) => {
                    this.loadingState = res;
                    this.cd.markForCheck();
                })
            );
            const name =
                !!this.facetName && this.facetName !== ""
                    ? this.facetName
                    : this.facetKey;
            this._dataService.enabled = false;
            this._dataService.init(this.serviceUrl, this.queryService, name);
            if (!!this.facetName && this.facetName !== "") {
                this._dataService.setFacet(this.facetName, {
                    name: this.facetName,
                    type: "named",
                });
            } else {
                this._dataService.setFacet(this.facetKey, {
                    name: this.facetKey,
                    type: "terms",
                    field: this.facetKey,
                    excludeTags: [this.facetKey],
                    mincount: 1,
                    limit: -1,
                    display: this.title,
                    shortDisplay: this.title
                });
            }
            this._dataService.setPageStart(1, 0);
            this.dataServices.push(this._dataService);

            const combined = combineLatest([
                this._dataService.oFacetResults,
                this.queryService.oQuery,
            ]);
            this.subscriptions.push(
                combined.subscribe((val) => {
                    const results = val[0];
                    const query = val[1];

                    // if (this.ignoreQueryChangeId == this.queryService.changeId) {
                    //     return;
                    // }
                    this.processResultChanges(results);
                    this.processQueryChanges(query);
                    if (this.isOpen) {
                        this.sortFacets();
                        this.updatePaginationControls();
                        this.cd.detectChanges();
                    }
                })
            );

            this._dataService.enabled = this.isOpen;
            this._dataService.refresh();
        }
    }

    private async _addSearchFilter(searchTerm) {
        const tableResult = await this.tableHeaderService.tableHeader.pipe(first()).toPromise();
        this._dataService.setFacet(this.facetKey, {
            name: this.facetKey,
            type: "terms",
            field: this.facetKey,
            excludeTags: [this.facetKey],
            mincount: 1,
            limit: searchTerm === null || searchTerm === "" ? -1 : tableResult.resultLength,
            display: this.title,
            shortDisplay: this.title
        });
        this._dataService.refresh();
        this._dataService.baseQueryService.unSetFilter(this.facetKey);
        this._dataService.baseQueryService.setFilter(
            this.facetKey,
            new DSearchFilter(
                'text',
                [this.facetKey],
                [`*${searchTerm}*`],
                searchTerm));
        this._dataService.baseQueryService.refresh();
    }

    private processQueryChanges(query: DRQuery) {
        const filter = query.filters.filter(
            (x) => x.fields[0] === this.facetKey
        )[0];
        this.facetValues.forEach((x) => x.updateCheck(false));
        if (!!filter) {
            filter.expression.forEach((selection, i) => {
                const exclusionExpression = selection.startsWith("!");
                const hasAndedExpression = selection.startsWith("+");
                const expressionWithoutExclusion: string =
                    selection.startsWith("-") || exclusionExpression || hasAndedExpression
                        ? selection.substr(1)
                        : selection;

                const facetValue = this.facetValues.find(
                    (facet) => facet.id === expressionWithoutExclusion
                );
                if (!!facetValue) {
                    facetValue.fromExpression(selection);
                    facetValue.isExclusion = exclusionExpression;
                    this.excludeSelected = exclusionExpression;
                    facetValue.isAnded = hasAndedExpression;
                    this.isAnded = hasAndedExpression;
                } else {
                    this.facetValues.unshift(filter.facetValues[i])
                }
            });
            if (!this.selectAll) {
                this._setPagination(filter.expression.length);
            }
        } else {
            this.excludeSelected = false;
        }
        this.checkedFacetValues = this.facetValues.filter((x) => x.isChecked);
    }

    private _setPagination(length: number) {
        if (this.searchFieldControl.value) {
            this._setPaginationBasedOnFacetSize(length);
        } else {
            this._resetPagination();
        }
    }

    private processResultChanges(results: FacetFieldAccumulator[]) {
        results.forEach(async (result) => {
            if (result.field === this.facetKey) {
                this.facetData = result;
                if (this.isDynamic) {
                    const keyMap = result.getKeyMap();
                    const facetValuesMap = {};
                    this.facetValues.forEach((f) => {
                        facetValuesMap[f.facetKey] = f;
                    });
                    this.facetValues = Object.keys(keyMap).map((name) => {
                        const key = keyMap[name];
                        return !!facetValuesMap[key]
                            ? facetValuesMap[key]
                            : new FacetValue(new DRFacetOption(key, '"' + name + '"', name));
                    });
                    let blankFacet = this.facetValues.find((f) => f.facetKey == "");
                    if (blankFacet && !this.isStringArrayFacetType) {
                        blankFacet.title = "(Empty String)";
                    }

                    if (this.includeBlank && !blankFacet && !this.isStringArrayFacetType) {
                        this.facetValues.push(
                            new FacetValue(new DRFacetOption("", '""', "(Empty String)"))
                        );
                    }
                } else {
                    await this.getFacetTranslationValuesFromUrl();
                }
                this.setFlagsBasedOnFacetSize();
                this.sortFacets();
            }
        });
    }

    private async getFacetTranslationValuesFromUrl() {
        this.facetTranslationService = new DiscovererFacetTranslationService(
            this.http,
            this.apollo
        );
        await this.facetTranslationService.init(
            this.queryInfoServiceUrl,
            this.optionListId
        );
        const facetInfo = this.facetTranslationService.facetTranslationInfo;
        const facetValuesMap = {};
        this.facetValues.forEach((f) => {
            facetValuesMap[f.facetKey] = f;
        });
        if (facetInfo != undefined) {
            facetInfo.forEach((info) => {
                if (!facetValuesMap[info.Id]) {
                    this.facetValues.push(
                        new FacetValue(
                            new DRFacetOption(info.Id, '"' + info.Id + '"', info.Display)
                        )
                    );
                }
            });
        }
        if (!facetValuesMap[""]) {
            this.facetValues.push(
                new FacetValue(new DRFacetOption("", '""', "(Empty String)"))
            );
        }
    }

    private sortFacets() {
        const searchTexts = this.searchTerm.toLowerCase().split(" ");
        const allFilteredValues = this.facetValues.filter((facet) => {
            return searchTexts.every((text) =>
                facet.title.toLowerCase().includes(text)
            );
        });
        this.totalFiltered = allFilteredValues.length;
        this.updatePaginationControls();

        if (this.facetData != null && this.orderByCount) {
            this.facetsValuesAfterSorting = allFilteredValues.sort((a, b) => {
                if (
                    this.chooseAlphaSorting === SortSelection.ALL &&
                    this.chooseSumSorting === SortSelection.ALL
                ) {
                    return (
                        this.facetData.getValue(b.facetKey) -
                        this.facetData.getValue(a.facetKey)
                    );
                } else if (
                    this.chooseSumSorting === SortSelection.NOT_SELECTED &&
                    this.chooseAlphaSorting == SortSelection.ALL
                ) {
                    return (
                        this.facetData.getValue(b.facetKey) -
                        this.facetData.getValue(a.facetKey)
                    );
                } else if (
                    this.chooseSumSorting === SortSelection.SELECTED &&
                    this.chooseAlphaSorting === SortSelection.ALL
                ) {
                    return (
                        this.facetData.getValue(a.facetKey) -
                        this.facetData.getValue(b.facetKey)
                    );
                } else if (
                    this.chooseAlphaSorting == SortSelection.SELECTED &&
                    this.chooseSumSorting == SortSelection.ALL
                ) {
                    if (a.facetKey < b.facetKey) {
                        return 1;
                    }

                    if (a.facetKey > b.facetKey) {
                        return -1;
                    }

                    return 0;
                } else if (
                    this.chooseAlphaSorting === SortSelection.NOT_SELECTED &&
                    this.chooseSumSorting == SortSelection.ALL
                ) {
                    if (a.facetKey > b.facetKey) {
                        return 1;
                    }

                    if (a.facetKey < b.facetKey) {
                        return -1;
                    }

                    return 0;
                }
            });
            this.facetsValuesAfterSorting.sort(
                (a, b) => (b.isChecked ? 1 : 0) - (a.isChecked ? 1 : 0)
            );
            const sorted = this.facetsValuesAfterSorting.slice(
                this.pageFrom,
                this.pageTo
            );
            this.sortedFacetValues = sorted.filter(
                (f) => this.facetData.getValue(f.facetKey) >= 0
            );
        } else {
            this.sortedFacetValues = allFilteredValues;
        }
        this.checkIfSelectedAllChecked();
        this.cd.markForCheck();
    }

    setAlphaSortingValue() {
        this.selectAll = false;
        this.chooseSumSorting = SortSelection.ALL;
        this.chooseAlphaSorting = !this.chooseAlphaSorting;
        this.sortFacets();
    }
    setSumSortingValue() {
        this.selectAll = false;
        this.chooseAlphaSorting = SortSelection.ALL;
        this.chooseSumSorting = !this.chooseSumSorting;
        this.sortFacets();
    }

    private updatePaginationControls() {
        this.pageFrom = this.pageNumber * this.pageSize;
        this.pageTo = this.maxShow + this.pageNumber * this.pageSize;
        if (this.pageTo >= this.totalFiltered) {
            this.pageTo = this.totalFiltered;
        }
    }

    isEmpty(str: string): boolean {
        return !str || str.trim().length === 0;
    }
    private _setPaginationBasedOnFacetSize(filtersNo: number) {

        this.pageNumber = Math.floor(filtersNo / 20);
        this.pageFrom = this.pageNumber * 20;
        this.pageSize = 20;
        this.pageTo = this.pageFrom + 20;
    }
    private _resetPagination() {
        this.pageNumber = 0;
        this.pageFrom = 0;
        this.pageSize = 20;
        this.pageTo = 20;
    }

    private refreshFilters(selectionList: FacetValue[]) {
        let filter = new DRFilter(
            "facet",
            [this.facetKey],
            selectionList,
            [],
            this.filterMode
        );
        this.queryService.setFilter(this.facetKey, filter);
        this.ignoreQueryChangeId = this.queryService.changeId;
        this.queryService.refresh();
    }

    private setFlagsBasedOnFacetSize() {
        if (this.facetValues && this.facetValues.length > this.maxShow) {
            this.orderByCount = true;
            this.isSearchable = true;
        } else {
            this.orderByCount = true;
        }
    }

    public addPinnedFilter() {
        this.pinFilter.emit({ group: "0", isPinned: !this.isPinned, name: this.facetKey });
    }

    pickOnly(selectedFacetKey: string) {
        const selectedFacet = this.facetValues.find(facet => facet.title === selectedFacetKey);
        selectedFacet.isExclusion = this.excludeSelected;
        selectedFacet.isAnded = this.isAnded;
        if (selectedFacet) {
            this.facetValues.forEach(facet => {
                facet.updateCheck(facet === selectedFacet);
            });

            this.refreshFilters([selectedFacet]);
        }
    }
}
