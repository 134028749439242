import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@discoverer/app-core';
import { BaseController, DRQuery, DiscovererQueryService, ITab2, ITableHeader, ReportPersistService, TabSettingsService, disLogger } from "@discoverer/core";
import { TableHeaderService } from '@discoverer/core/services/dynamic-reports-services/table-header.service';
import { ExportReportComponent, MAX_EXCEL_SIZE } from '@discoverer/dynamic-reports/common/export-to-excel/export.component';
import { first } from 'rxjs/operators';
import { Field } from '../../../helpers/docpipeline-function';
import { SchemaService } from '../../../services/schema.service';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';

@Component({
    selector: 'app-results-actions',
    templateUrl: './results-actions.component.html',
    styleUrls: ['./results-actions.component.scss']
})
export class ResultsActionsComponent extends BaseController implements OnInit {
    @ViewChild('exportReport', { static: false }) exportReport: ExportReportComponent;
    @Input() tableHeaderConfig: ITableHeader;

    dataFlattenBy: string = ''
    queryService: DiscovererQueryService;
    currentQuery: DRQuery
    MAX_EXCEL_SIZE = MAX_EXCEL_SIZE;
    currentTab: ITab2
    isLoading: boolean = false;
    columnsToFlattenData: string [] = ['File' ];
    flattenByDic = { File: 'File' };
    constructor(
        public reportPersist: ReportPersistService,
        public tableHeaderService: TableHeaderService,
        public dialog: MatDialog,
        private _tabSettingsSer: TabSettingsService,
        private http: HttpClient,
        private _schemaService: SchemaService,
        private _matSnackBar: MatSnackBar,
        public authService: AuthService,
        private _cdr: ChangeDetectorRef
    ) { super(); }

    async ngOnInit(): Promise<void> {
        this.queryService = this.reportPersist.mainQueryService.defaultService()
        this.queryService = this.reportPersist.mainQueryService;

        this.subscriptions.push(this._schemaService.oCurrentSchema.subscribe((schema) => this._updateColumnsToFlatten(schema as { [key: string]: Field })));
        this.dataFlattenBy = "File";
        this.subscribeToQueryChanges();
        this.currentTab = await this._tabSettingsSer.getCurrentTab();
        this.subscriptions.push(this.tableHeaderService?.tableHeader.subscribe((val) => this.tableHeaderConfig = val));
        this.subscriptions.push(this.reportPersist.tableViewState.oState.subscribe((state) => {
            this.dataFlattenBy = state.dataFlattenBy;
        }))

    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.queryService.resetDataFlattenBy();
    }
    async exportTableData(count: number) {
        await this.exportReport.exportToExcel(count);
    }

    async exportToJson() {
        const state = await (this.reportPersist.oLastRequestData.pipe(first()).toPromise());
        const body = Object.assign(this.currentQuery, { fields: state.tableView.columnSettings.map(x => x.fieldName) }, { size: this.tableHeaderConfig?.resultLength });
        this.http.post(`${this.currentTab.serviceUrl}`, body)
            .subscribe(
                (res) => {
                    const data = res['response']['docs']?.map(d => d?.source) || [];
                    const flatenData = this.flattenData(data, this.dataFlattenBy);
                    const modelJsonString = JSON.stringify(flatenData) // This heavily depends on the response from ignite search data strucutre
                    const blob = new Blob([modelJsonString], { type: 'application/json' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = this._getReportTitle()
                    link.click();
                    URL.revokeObjectURL(url);
                }
            );
    }
    private flattenData(data: any[], flattenBy: string): any[] {
        if (!flattenBy) return data;
        return data.reduce((acc, item) => {
          if (Array.isArray(item[flattenBy])) {
            return acc.concat(
              item[flattenBy].map((nestedItem: any) => {
                const flattenedItem: any = {};
                for (const key in item) {
                    if (key !== flattenBy)
                    flattenedItem[key] = item[key];
                }
                flattenedItem[flattenBy] = nestedItem
                return flattenedItem;
              })
            );
          }
          return acc.concat(item);
        }, []);
      }
    public async saveReport() {
        try {
            await this.reportPersist.persistRequest(true, false);
            this._matSnackBar.open('Report updated successfully', null, SUCCESS_SNACKBAR_OPTIONS);
        } catch (error) {
            this._matSnackBar.open(`Error updating report: (${error.message})`, null, ERROR_SNACKBAR_OPTIONS);
        }
    }

    public setFlattenDataBy(groupName: string) {
        this.queryService.resetDataFlattenBy();
        this.queryService.setDataFlattenBy(groupName);
        this.queryService.refresh();
        this.dataFlattenBy = groupName;
        this.reportPersist.tableViewState.setValue({ dataFlattenBy: groupName });
    }
    log() {
        console.log(this.dataFlattenBy)
    }

    private subscribeToQueryChanges() {
        this.subscriptions.push(this.queryService.oQuery.subscribe(query => {
            disLogger('I received a query1', query);
            this.currentQuery = query;
        }));
    }
    private _getReportTitle(): string {
        const date = new Date().toLocaleDateString().replace('/', '_').replace('/', '_');
        const time = `${new Date().getHours()}_${new Date().getMinutes()}`;
        const reportTitle = `${this.currentTab.name} Report - ${date} ${time}`;
        return reportTitle;
    }

    private async _updateColumnsToFlatten(schema: { [key: string]: Field }) {
        const objectArrayFields = Object.keys(schema || {}).filter(key => schema[key].type === 'object_array')
        if (objectArrayFields.length > 0) {
            this.columnsToFlattenData = ['File' ];
            const allColumns = (await this._tabSettingsSer.getAllColumns()).filter(col => objectArrayFields.includes(col.fieldName)).map(c => {
                this.flattenByDic[c.fieldName] = c.display
                return c.fieldName
            });
            this.columnsToFlattenData.push(...allColumns.map(key => key));
        }
    }
}
