<mat-card (mouseover)="showClearButtonWhenCardIsNotOpen = true" (mouseleave)="showClearButtonWhenCardIsNotOpen = false"
    color="primary" class="card" *ngIf="isCollapsible; else List">
    <mat-card-content>
        <div class="facet-header">
            <div class="progress-bar-area">
                <mat-progress-bar *ngIf="loadingState?.status == 'Busy'" mode="indeterminate"></mat-progress-bar>
            </div>
            <div (click)="dateTitleClicked()" class="title-container">
                <button mat-icon-button mat-button>
                    <mat-icon>{{
                        isOpen ? "keyboard_arrow_down" : "keyboard_arrow_right"
                        }}</mat-icon>
                </button>
                <div class="facet-title">{{ title }}</div>
            </div>
            <div class="sub-title" *ngIf="!isOpen && isFilterApplied() ">
                <span>{{getFilterDisplay()}}</span>
                <span *ngIf="showClearButtonWhenCardIsNotOpen" class="clear-button" (click)="clearFilter()">CLEAR</span>
            </div>
        </div>
        <div [ngClass]="isOpen ? '' : 'hidden'" class="date-facet-values">
            <ng-container *ngTemplateOutlet="facetList"></ng-container>
        </div>
    </mat-card-content>
</mat-card>

<ng-template #List>
    <div *ngIf="title" class="uncollapsible-title">
        <div>{{ title }}</div>
    </div>
    <ng-container *ngTemplateOutlet="facetList"></ng-container>
</ng-template>

<ng-template #facetList>

    <!-- <disco-date-histogram *ngIf="minMaxLoaded && isOpen" [fieldName]="facetKey" [serviceUrl]="serviceUrl"
        [max]="range.toDate" [min]="range.fromDate" (seriesClicked)="seriesClicked($event)"
        [queryService]="queryService">
    </disco-date-histogram>
    <hr class="hr"> -->

    <!--NEW DATE RANGE LOOK-->
    <div class="date-range-content">
        <mat-daterange-picker [isPinned]="isPinned" [showPin]="showPin" (pinClicked)="addPinnedFilter()">
        </mat-daterange-picker>
        <app-facet-actions *ngIf="isOpen"
            [showClearButton]="(isCollapsible && clearButtonKey && isOpen && !!isFilterApplied()) || (!clearButtonKey && !!isFilterApplied())"
            (clearButtonClicked)="clearFilter()" [showDoneButton]="showDoneButton"
            (doneButtonClicked)="doneButtonClicked.emit()">
        </app-facet-actions>
    </div>
    <!--NEW DATE RANGE LOOK END-->
</ng-template>