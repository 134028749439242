import { NgModule } from '@angular/core';
import { KanbanViewComponent } from './kanban-view.component';
import { KanbanCardComponent } from './card/card.component';
import { ListComponent } from './list/list.component';
import { MatCardModule} from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    DragDropModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    KanbanViewComponent,
    KanbanCardComponent,
    ListComponent,

  ],
  entryComponents: [],
  providers: [],
  exports: [
    KanbanViewComponent
  ]
})
export class KanbanViewModule {
  constructor() {
  }
}
