import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
    selector: 'dyn-number-input',
    template: `
    <ng-container *ngIf="!to.disabled">
        <mat-form-field class="w-full" appearance="fill">
            <mat-label>{{field?.templateOptions?.label}}</mat-label>
            <input
                matInput
                [id]="id"
                [type]="'number'"
                [readonly]="to.readonly"
                [required]="to.required"
                [errorStateMatcher]="errorStateMatcher"
                [formControl]="$any(formControl)"
                [formlyAttributes]="field"
                [tabIndex]="to.tabindex"
                [placeholder]="to.placeholder"
            />
        </mat-form-field>
    </ng-container>
`,
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicNumberInputComponent extends FieldType implements OnInit {

    constructor() {
        super();
    }

    ngOnInit(): void {
    }
    onClick(url: string): void {
        const validUrl = this.getValidHref(url);
        if (validUrl) {
            window.open(validUrl, '_blank');
        } else {
            console.warn('Invalid URL:', url);
        }
    }
    getValidHref(url: string): string | null {
        return this.isValidUrl(url) ? url : null;
    }
    private isValidUrl(url: string): boolean {
        return url && /^(https?:\/\/|whatsapp:\/\/)/.test(url);
    }

}
