<div *ngIf="!separateGroupsList; else separateLists " class="select-wrapper">
    <mat-select disableOptionCentering [formControl]="optionControl" [formlyAttributes]="field"
        (selectionChange)="changeSelection($event)" [tabIndex]="to?.tabindex"
        (closed)="optionSearchControl.setValue(null)" (opened)="focus()" [compareWith]="compareObjects"
        [matTooltip]="to.tooltip">
        <input autocomplete="off" matInput class="searchInput" placeholder="search" [formControl]="optionSearchControl"
            #searchText>
        <div *ngIf="showInGroups">
            <mat-optgroup *ngFor="let group of groupedDynOptions" [label]="group.label">
                <mat-option *ngFor="let option of group.options"
                    [value]="this.field.templateOptions.useOption ? option: (this.field.templateOptions.useCode ? option.code : option.id) "
                    [disabled]="to?.disabledOptionsIds?.includes(option.id)">
                    {{option.description}}
                </mat-option>
            </mat-optgroup>
        </div>
        <div *ngIf="!showInGroups">
            <mat-option *ngFor="let option of dynOptions"
                [value]="this.field.templateOptions.useOption ? option :(this.field.templateOptions.useCode ? option.code : option.id)"
                [disabled]="to?.disabledOptionsIds?.includes(option.id)">
                {{getDisplayValue(option)}}
            </mat-option>
        </div>
    </mat-select>
    <button mat-button *ngIf="field.formControl.value !=null && field.formControl.value !=undefined && !to.disabled"
        class="clear-button" matSuffix mat-icon-button aria-label="Clear" (click)="changeSelection(null);">
        <mat-icon>close</mat-icon>
    </button>
</div>

<ng-template #separateLists>
    <div class="select-wrapper row" style="height: 16px;">
        <mat-select disableOptionCentering [formControl]="groupControl" (closed)="groupSearchControl.setValue(null)"
            (selectionChange)="filterByGroup($event.value)">
            <input autocomplete="off" matInput class="searchInput" placeholder="search"
                [formControl]="groupSearchControl">
            <mat-option *ngFor="let group of groups" [value]="group">
                {{group}}<span *ngIf="to.disabled && selectedOption"> - {{selectedOption.description}}</span>
            </mat-option>
        </mat-select>
        <button style="margin-right: 8px;" mat-button
            *ngIf="groupControl.value != null && groupControl.value != undefined && !to.disabled"
            class="clear-button separate-lists" matSuffix mat-icon-button aria-label="Clear"
            (click)="filterByGroup(null);">
            <mat-icon>close</mat-icon>
        </button>

        <mat-select disableOptionCentering [formControl]="optionControl" [formlyAttributes]="field"
            (selectionChange)="changeSelection($event)" (closed)="separateOptionsSearch.setValue(null)"
            *ngIf="allFilteredOptions?.length && !to.disabled">
            <input autocomplete="off" matInput placeholder="search" [formControl]="separateOptionsSearch"
                class="searchInput">
            <mat-option *ngFor="let option of filteredOptions"
                [value]="(this.field.templateOptions.useCode ? option.code : option.id)"
                [disabled]="to?.disabledOptionsIds?.includes(option.id)">
                {{option.description}}
            </mat-option>
        </mat-select>
        <button mat-button *ngIf="optionControl.value != null && optionControl.value != undefined && !to.disabled"
            class="clear-button separate-lists" matSuffix mat-icon-button aria-label="Clear"
            (click)="changeSelection(null)">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</ng-template>