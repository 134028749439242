<div class="height-100" id="pivot-chart-details">
    <div class="main-wrapper height-100">
        <div *ngIf="visibleDetailsHeader" class="upper-menu">
            <div class="left-menu">
                <charts-control #CONTROL [state]="pivotState" [chartType]="chartType"
                    [chartControlService]="pivotChartControlService" (periodChanged)="changePeriod($event)"
                    (displayModeChanged)="displayMode = $event" [metricList]="metricList"></charts-control>
            </div>
            <div class="right-menu">
                <mat-icon color="accent" (click)="exportToExcel()">download</mat-icon>
            </div>
        </div>
        <pivot-chart #PIVOT_CHART *ngIf="!!pivotState && !!queryService" [isEditMode]="isEditMode"
            [pivotState]="pivotState" [queryService]="queryService" [pageSize]="60" (isValid)="checkPivotStatus($event)"
            [displayMode]="displayMode"></pivot-chart>
    </div>

</div>
