import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { EmptyLayoutModule } from './layouts/empty/empty.module';
import { CenteredLayoutModule } from './layouts/horizontal/centered/centered.module';
import { EnterpriseLayoutModule } from './layouts/horizontal/enterprise/enterprise.module';
import { MaterialLayoutModule } from './layouts/horizontal/material/material.module';
import { ModernLayoutModule } from './layouts/horizontal/modern/modern.module';
import { ClassicLayoutModule } from './layouts/vertical/classic/classic.module';
import { ClassyLayoutModule } from './layouts/vertical/classy/classy.module';
import { CompactLayoutModule } from './layouts/vertical/compact/compact.module';
import { DenseLayoutModule } from './layouts/vertical/dense/dense.module';
import { FuturisticLayoutModule } from './layouts/vertical/futuristic/futuristic.module';
import { ThinLayoutModule } from './layouts/vertical/thin/thin.module';
import { SettingsModule } from './common/settings/settings.module';
import { AUTH_API, AUTH_USE_PRODUCT_LOGIN,AUTH_MAIN_ACCOUNT_ID } from './layouts/layout.constant';



const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    CenteredLayoutModule,
    EnterpriseLayoutModule,
    MaterialLayoutModule,
    ModernLayoutModule,

    // Vertical navigation
    ClassicLayoutModule,
    ClassyLayoutModule,
    CompactLayoutModule,
    DenseLayoutModule,
    FuturisticLayoutModule,
    ThinLayoutModule
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        CommonModule,
        SettingsModule,
        ...layoutModules
    ],
    exports: [
        LayoutComponent,
        ...layoutModules
    ]
})
export class IgniteLayoutModule { 
static forRoot(authApi: string, authUseProductLogin?: boolean, authMainAccountId?: number): ModuleWithProviders<IgniteLayoutModule> {
        return {
            ngModule: IgniteLayoutModule,
            providers: [
                { provide: AUTH_API, useValue: authApi },
                { provide: AUTH_USE_PRODUCT_LOGIN, useValue: authUseProductLogin },
                { provide: AUTH_MAIN_ACCOUNT_ID, useValue: authMainAccountId },
            ]
        };
    }
}
