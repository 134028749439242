import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BaseController, DiscovererQueryService, disLogger, DRQuery, DSearchFilter, ReportPersistService, TabSettingsService } from '@discoverer/core/services';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'disco-search',
    templateUrl: './disco-search.component.html',
    styleUrls: ['./disco-search.component.scss']
})
export class DiscoSearchComponent extends BaseController implements OnInit {
    @Input() queryService: DiscovererQueryService
    public searchValue: string;
    private $searchUpdate: Subject<boolean> = new Subject<boolean>();
    constructor(
        private _reportPersistService: ReportPersistService
        ) { super(); }

    ngOnInit(): void {
        this.subscriptions.push(this.$searchUpdate.pipe(debounceTime(250)).subscribe(s => {
            this.queryService.refresh();
        }));
        this.subscriptions.push(this._reportPersistService.mainQueryService.oQuery.subscribe(_currentQuery => {
            const searchText = _currentQuery?.filters.find(filter => filter.type === 'text')?.expression[0];
            this.searchValue = searchText?.endsWith("*") ?  searchText.slice(0, -1) : searchText;
        }));

    }
    // TODO: move code to a separate BLOC
    public async searchTextUpdate(searchText: string) {
        const statement = searchText?.endsWith(" ") ? searchText : searchText + "*";
        const fields = await this.getSearchTextColumns();
        disLogger('unset search filter');
        this.queryService.unSetFilter('search');
        const keys = Object.keys(this.queryService.filters);
        keys.forEach(k => {
            if (!!this.queryService && !!this.queryService.filters && !!this.queryService.filters[k]
                && this.queryService.filters[k].type === 'text') {
                this.queryService.unSetFilter(k);
            }
        });
        if (!!searchText && searchText.trim() !== '') {
            this.queryService.setFilter('search', new DSearchFilter('text', fields, [statement], searchText));
        }
        this.$searchUpdate.next(true);

    }
    // TODO: move code to a separate BLOC
    private async getSearchTextColumns() {
        return (await this._reportPersistService.tableViewState.getState().columnSettings)
            .map(s => s.fieldName).filter(x => !!x && x !== '');
    }

}
