import {
    ChangeDetectorRef,
    Component,
    OnInit
} from '@angular/core';
import {
    DefaultPivotLimit,
    ICategories,
    IChartDataConfig,
    IChartFormatConfig,
    IChartState,
    IKanbanViewState,
    IMetric,
    IPivotConfig,
    ITableViewState,
    ReportPersistService,
    TabSettingsService
} from '@discoverer/core/services';
import {
    IViewType,
    ViewTypes
} from '../control-bar';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs/dialogs-manager.service';

@Component({
    selector: 'views-type-control',
    templateUrl: './views-type-control.component.html',
    styleUrls: ['./views-type-control.component.scss']
})
export class ViewsTypeControlComponent  implements OnInit {
    public viewTypes: IViewType[] = [
        { name: ViewTypes.Table, icon: 'table_chart', title: 'Table' },
        { name: ViewTypes.Chart, icon: 'bar_chart', title: 'Chart' },
        { name: ViewTypes.Pivot, icon: 'pivot_table_chart', title: 'Pivot' },
        { name: ViewTypes.Kanban, icon: 'view_column', title: 'Kanban' }
    ];
    constructor(
        public reportPersist: ReportPersistService,
        private _tabSettings: TabSettingsService,
        private dialogsManagerService: DialogsManagerService,
        private _cdRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {

    }
    public currentView(type) {
        return this.viewTypes.find(v => v.name == type)
    }
    public async changeReportType(toType: string) {
        this.reportPersist.mainViewState.setField('type', toType);
        switch (toType) {
            case 'table-view':
                this._setTableView()
                break;
            case 'chart-view':
                this._setChartView()
                break;
            case 'pivot-view':
                this._setPivotView()
                break;
            case 'kanban-view':
                this._setKanbanView();
                break;
        }

        this._cdRef.markForCheck();
    }

    private async _setChartView() {

        var chartState = this.reportPersist.chartState.getState();

        if (!chartState?.chartConfig?.categories) {
            chartState = (chartState ?? {} as IChartState);
            chartState.chartConfig = (chartState.chartConfig ?? {} as IChartDataConfig);
            const colName = await this._groupByCandidate('chart-view')
            chartState.chartConfig.categories = (chartState.chartConfig.categories ?? {
                colName,
                sortDir: 'asc',
                limit: DefaultPivotLimit,
            } as ICategories);
            chartState.chartFormat = {
                categories: chartState.chartConfig.categories.colName,
                chartId: 0,
                chartTitle: null,
                chartType: 'column',
                isStack: false,
                series: null,
                metrics: [],
                tooltipFormat: "",
                isLogarithmScale: false
            } as IChartFormatConfig;
            this.reportPersist.chartState.setValue(chartState);
        }
    }

    private async _setTableView() {
        var tableViewState = this.reportPersist.tableViewState.getState();
        var kanbanViewState = this.reportPersist.kanbanViewState.getState();
        var pivotChartState = this.reportPersist.pivotChartState.getState();
        var chartState = this.reportPersist.chartState.getState();

        var colsDictionary = await this._tabSettings.getColumnDictionary();

        if (!tableViewState?.columnSettings && !tableViewState?.columnSettings.length) {
            tableViewState = (tableViewState ?? {} as ITableViewState);
            if (!!chartState?.chartConfig?.categories) {
                tableViewState.columnSettings = [colsDictionary[chartState.chartConfig.categories.colName]]
            } else if (!!pivotChartState?.categories) {
                tableViewState.columnSettings = [colsDictionary[pivotChartState.categories.colName]]
            } else if (!!kanbanViewState?.groupByColumn) {
                tableViewState.columnSettings = [colsDictionary[kanbanViewState?.groupByColumn?.field]]
            }
            this.reportPersist.tableViewState.setValue(tableViewState);
        }
    }

    private async _setPivotView() {
        var pivotChartState = this.reportPersist.pivotChartState.getState();
        if (!pivotChartState?.categories) {
            pivotChartState = (pivotChartState ?? {} as IPivotConfig);
            pivotChartState.categories = (pivotChartState.categories ?? {
                colName: await this._groupByCandidate('pivot-view'),
                sortDir: 'asc',
                limit: DefaultPivotLimit,
            } as ICategories);
        }
        if (!pivotChartState?.metric) {
            pivotChartState.metric = (pivotChartState.metric ?? {
                colName: await this._groupByCandidate('pivot-view'),
                functionType: 'count',
            } as IMetric)
        }
        this.reportPersist.pivotChartState.setValue(pivotChartState);
    }

    private async _setKanbanView() {
       let kanbanViewState = this.reportPersist.kanbanViewState.getState();
        if (!kanbanViewState?.groupByColumn?.field){
            this.dialogsManagerService.openKanbanDialog(this._tabSettings,this.reportPersist.mainQueryService,this.reportPersist.kanbanViewState,this.reportPersist.mainViewState).subscribe(kanbanState => {
                if (kanbanState) {
                    this.reportPersist.kanbanViewState.setValue(kanbanState);
                }
            });
        }
        else {
            this.reportPersist.kanbanViewState.setValue(kanbanViewState);
        }
    }
    private async _groupByCandidate(reportType: string) {
        var mainViewState = this.reportPersist.mainViewState.getState();
        var tableViewState = this.reportPersist.tableViewState.getState();
        var kanbanViewState = this.reportPersist.kanbanViewState.getState();
        var pivotChartState = this.reportPersist.pivotChartState.getState();
        var chartState = this.reportPersist.chartState.getState();

        if (reportType === 'chart-view' && !!chartState?.chartConfig?.categories) {
            return chartState.chartConfig.categories.colName;
        }
        if (reportType === 'pivot-view' && !!pivotChartState?.categories) {
            return pivotChartState.categories.colName;
        }
        if (reportType === 'kanban-view' && !!kanbanViewState?.groupByColumn?.field) {
            return kanbanViewState.groupByColumn.field
        }
        var groupByColumn = null;
        if (!!this.reportPersist.mainQueryService?.groups?.length) {
            // get first group key
            var keyName = Object.keys(this.reportPersist.mainQueryService.groups)[0];
            groupByColumn = this.reportPersist.mainQueryService.groups[+keyName].field;
        }
        if (!!this.reportPersist.mainQueryService?.filters?.length) {
            // get first filter key
            var keyName = Object.keys(this.reportPersist.mainQueryService.filters)[0];
            groupByColumn = this.reportPersist.mainQueryService.filters[keyName].fields[0];
        }
        if (!!mainViewState?.facets?.length) {
            groupByColumn = mainViewState?.facets[0].name;
        }
        if (!!tableViewState.columnSettings?.length) {
            groupByColumn = tableViewState.columnSettings.filter(f => f.fieldName != '')[0].fieldName;
        }
        if (reportType === 'kanban-view' && (groupByColumn == 'timestamp' || groupByColumn == 'date' || groupByColumn == 'timestamptz')) {
            return tableViewState.columnSettings.filter(f => f.fieldName != '' && f.dataType != 'timestamp' && f.dataType != 'date' && f.dataType != 'timestamptz')[0].fieldName;
        }
        return groupByColumn;
    }
}
