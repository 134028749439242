import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { BaseCustomController } from '../shared/base-custom-controller';

@Component({
  selector: 'formly-app-example',
  templateUrl: './dyn-adv-datepicker.component.html',
  styleUrls: ['./dyn-adv-datepicker.component.scss'],
})
export class DynAdvDatepickerComponent extends BaseCustomController implements OnInit {
  form = new FormGroup({});
  model: any = {} as any;
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: this.field?.key,
      type: 'datepicker',
      templateOptions: {
        label: this.field?.templateOptions.label,
        placeholder: this.field?.templateOptions.placeholder,
        required: this.field?.templateOptions.required,
        description: this.field?.templateOptions.description,
      },
    },
  ];
  ngOnInit(): void {
    this.fields[0].key=this.field.key;
    this.fields[0].templateOptions.label=this.field.templateOptions.label;
    this.fields[0].templateOptions.placeholder=this.field.templateOptions.placeholder;
    this.fields[0].templateOptions.required=this.field.templateOptions.required;
    this.fields[0].templateOptions.description=this.field.templateOptions.description;
  }
}
