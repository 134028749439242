import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { BaseController, ChartDataService, DataFormattingService, DiscovererFacetTranslationService, DiscovererQueryService, EsQueryService, IChartDataConfig, IChartState, IColumnSetting, IMetric, Permission, ReportPersistService, StateService, TabSettingsService, UserPermissionService } from "@discoverer/core";

import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { StatDialog } from "../stat-dialog/stat.dialog";



@Component({
    selector: 'stat-action',
    templateUrl: './stat-action.component.html',
    styleUrls: ['./stat-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class StatActionComponent extends BaseController implements OnInit, OnDestroy {
    public queryService: DiscovererQueryService;
    public countSubRecords: boolean = false;
    public statDataService: ChartDataService;
    public availableColumns;
    public StatColmuns: IColumnSetting[];
    public stats: IMetric[] = [];
    public statsResult: any[] = [];
    private _unSubscribeFromAll: Subject<any> = new Subject<any>();
    public chartDataCongf: IChartState = {
        chartConfig: {
            categories: {
                colName: "DefaultColumn",
                sortDir: "asc",
                limit: 1
            },
            countSubRecords: true
        }
    };
    public canAddStat;
    public statStateService = new StateService<IChartState>(this.chartDataCongf);
    public values;
    constructor(public dialog: MatDialog,
        private tabSettings: TabSettingsService,
        private reportPersistService: ReportPersistService,
        private http: HttpClient,
        private _esQueryService: EsQueryService,
        private _dataFormattingService: DataFormattingService,
        private _tabSettingsService: TabSettingsService,
        private _facetTranslationService: DiscovererFacetTranslationService,
        public reportPersist: ReportPersistService,
        private _cdRef: ChangeDetectorRef,
        private _userPermissionService: UserPermissionService

    ) {
        super();
        this.queryService = this.reportPersistService.mainQueryService;
    }

    async ngOnInit() {
        this.availableColumns = await this.tabSettings.getAllColumns();
        await this.initStateService();
        this.subScribeToStatDataService();
        this.subScribeToMainViewState();
        this.StatColmuns = this.availableColumns.filter(x => x.type === 'numeric');
        const tabKey = (await this.tabSettings.getCurrentTab()).key;
        this.canAddStat = await this._userPermissionService.hasPermission(tabKey, Permission.AddStat);
    }
    async subScribeToMainViewState() {
        this.reportPersistService.mainViewState.oState.pipe(takeUntil(this._unSubscribeFromAll)).subscribe(state => {
            if (state.stats?.length) {
                this.chartDataCongf.chartConfig.metrics = state.stats.map(x =>x)
                this.statStateService.setValue(this.chartDataCongf);
            }
        })
    }

    ngOnDestroy() {
        this._unSubscribeFromAll.next(null);
        this._unSubscribeFromAll.complete();
    }

    subScribeToStatDataService() {
        this.statDataService.oData.pipe(takeUntil(this._unSubscribeFromAll)).subscribe(data => {
            let i = 0;
            this.values = data.values;

            this.statsResult = this.stats.map((st, i) => {
                const column = this.StatColmuns.find(x => x.fieldName == st.colName);
                return {
                    colName: column.display,
                    functionType: st.functionType,
                    label: st.label,
                    result: this._dataFormattingService.getValue(data.values[i+1].values[0], column.dataType)
                };
            }
            );
            this._cdRef.detectChanges();
        })
    }

    async initStateService() {
        const serviceUrl = (await this.tabSettings.getCurrentTab()).serviceUrl;
        const statQueryService = this.reportPersistService.mainQueryService.createChildService('report-stats');
        statQueryService.name = 'table-view';
        this.statDataService = new ChartDataService(
            this.http,
            serviceUrl,
            statQueryService,
            this.statStateService,
            this._dataFormattingService,
            this._tabSettingsService,
            this._facetTranslationService,
            this.availableColumns,
            this._esQueryService
        );
    }

    public removeStat(i: number) {
        this.stats.splice(i, 1);
        this.statsResult.splice(i, 1);
        this.reportPersistService.mainViewState.setValue({ stats: this.stats }, false);

    }

    public editStat(i: number) {
        const dialogRef = this._openDialog({
            availableColumns: this.availableColumns,
            colName: this.stats[i].colName,
            functionType: this.stats[i].functionType,
            label: this.stats[i].label
        });
        dialogRef.afterClosed()
            .pipe(filter(x => !!x?.colName && !!x?.functionType && !!x?.label))
            .subscribe(x => {
                this.stats.splice(i, 1, { colName: x.colName, functionType: x.functionType, label: x.label });
                this.reportPersistService.mainViewState.setValue({ stats: this.stats }, false);
            });
    }

    async openStateDialog() {
        const dialogRef = this._openDialog({ availableColumns: this.availableColumns });
        dialogRef.afterClosed()
            .pipe(filter(x => !!x?.colName && !!x?.functionType && !!x?.label))
            .subscribe(x => {
                this.stats.push({
                    colName: x.colName,
                    functionType: x.functionType,
                    label: x.label
                });
                const stats = this.stats.slice();
                this.reportPersistService.mainViewState.setValue({ stats }, false);
            });
    }
    private _openDialog(data) {
        return this.dialog.open(StatDialog, {
            data,
            width: '20vw',
            minWidth: '400px',
            autoFocus: false,
            panelClass: 'white-class-details-dialog',
            disableClose: false
        });
    }
}
