<div id="details-label-value" class="col-item" >
    <div class="flex-item">
        <div class="label-component" *ngIf="displayedLabel && !hideLabel" >
            {{displayedLabel}}
        </div>
        <div class="values-component" >
            {{displayedValue}}
        </div>
    </div>
</div>
