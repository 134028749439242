
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformBy'
})
@Injectable()
export class TransformByPipe implements PipeTransform {
 
  transform(item: any, by: (item) => any): any {
    return by(item);
  }
}
