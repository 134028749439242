<!-- <div class="available-search">
    <div class="col-title">Available</div>
    <search-input-widget class="search-input" [label]="'Search columns'" (searchEvent)="filterLeftColumns($event)">
    </search-input-widget>
</div> -->
<div id="left-right-columns-settings">
    <div layoutDir="column" class="column">
        <h4 class="col-title">Available Columns</h4>
        <search-input-widget class="search-input" [label]="'Search columns'" (searchEvent)="filterLeftColumns($event)">
        </search-input-widget>
        <div id="left" class="mtable" dragula="DRAGULA_FACTS" [(dragulaModel)]="filteredLeftColumns">
            <div class="items" *ngFor="let col of filteredLeftColumns">
                <ng-container *ngIf="(col | typeof) === 'string'; else colItem">
                    <div appEllipsifyText [ngClass]="col ? 'key' : ''" (click)="selectGroup(col)">
                        {{ col.charAt(0).toUpperCase() + col.slice(1) }}
                    </div>
                </ng-container>
                <ng-template #colItem>
                    <div appEllipsifyText [ngClass]="{value: !!col.display,selected: !!col.isSelected,items: !!col}"
                        (click)="selectElement(col)" [attr.dragula-col-id]="col?.fieldName">
                        {{col?.nestedPath ? col?.display.slice(col.display.indexOf("-") + 1, col.display.length).trim()
                        : col?.display}}
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
    <div layoutDir="column" class="middle-cloumn">
        <mat-icon (click)="moveFromLeftToRight(true, null)" class="middel-icon">east</mat-icon>
        <mat-icon (click)="moveFromRightToLeft(true, null)" class="middel-icon">west</mat-icon>
    </div>
    <div layoutDir="column" class="columns-area right-columns column">
        <h4 class="col-title">{{ label }}</h4>
        <search-input-widget class="search-input" [label]="'Search columns'" (searchEvent)="filterRightColumns($event)">
        </search-input-widget>
        <button mat-flat-button color="accent" class="button" (click)="setDefaultSettings()" *ngIf="defaultLabel">
            {{ defaultLabel }}
        </button>
        <div id="right" class="mtable" dragula="DRAGULA_FACTS" [(dragulaModel)]="rightColumns">
            <div class="items" (click)="selectElement(col)" *ngFor="let col of filteredRightColumns; let i = index"
                [attr.dragula-col-id]="col?.fieldName">
                <div [ngClass]="col.isSelected ? 'selected' : 'not-selected'">
                    <div appEllipsifyText [ngClass]="col.display ? 'value' : ''">
                        {{ col?.display }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div layoutDir="column">
        <mat-icon (click)="moveUp()" class="middel-icon">north</mat-icon>
        <mat-icon (click)="moveDown()" class="middel-icon">south</mat-icon>
    </div>
</div>