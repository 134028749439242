import { Component } from '@angular/core';

@Component({
  selector: 'app-data-flatten-select',
  templateUrl: './data-flatten-select.component.html',
  styleUrls: ['./data-flatten-select.component.scss']
})
export class DataFlattenSelectComponent {

}
