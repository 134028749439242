import { DRFacetOption } from './discoverer.class';


export class FacetValue {

    public id: string;
    public facetKey: string;
    public title: string;
    public isExclusion = false;
    public isChecked = false;
    public isRelative = false;
    public isAnded = false;

    constructor(option: DRFacetOption) {
        this.facetKey = option.key;
        this.id = option.query;
        this.title = option.display;
    }

    public updateCheck(check: boolean) {
        this.isChecked = check;
    }
    public fromExpression(expr: string) {
        this.isChecked = true;
    }
}
