import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TestActionHandlerService {
    private startTestSubject = new Subject<void>();
    startTest$: Observable<void>;

    startTest() {
        this.startTestSubject.next();
    }
    constructor() {
    this.startTest$= this.startTestSubject.asObservable();
    }
}
