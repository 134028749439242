<div>
    <!--Advanced Filter broken down-->
    <div class="section-label" *ngIf="!isSubFilter">Defines the results when the report is first displayed</div>
    <div class="filter-card" [ngClass]="{'color-warn-b':itemHover}" (mouseenter)="itemHover=true"
        (mouseleave)="itemHover=false">
        <div class="filter-card-label" *ngIf="!isSubFilter">
            <mat-form-field style="margin: 0px; height: 50px;margin-right: 10px;">
                <mat-select placeholder="Show where" [(value)]="advancedFilterMode"
                    (selectionChange)="updateFilteringMode(true)">
                    <mat-option [value]="FILTERING_MODES.ALL">{{FILTERING_MODES.ALL}}</mat-option>
                    <mat-option [value]="FILTERING_MODES.ANY">{{FILTERING_MODES.ANY}}</mat-option>
                </mat-select>
            </mat-form-field>
            of these conditions are true
        </div>
        <ng-container *ngIf="!!mqService">
            <ng-container *ngFor="let f of filtersList; let i = index; trackBy: trackAdvancedFilters ">
                <broken-down-filter style="width: 100%;" [filter]="f" [filterIndex]="i" [filterDepth]="depth"
                    [availableCols]="availableColumns" [advFilterMode]="advancedFilterMode"
                    [advFilterService]="advFilterService" [readonly]="false" [serviceUrl]="serviceUrl"
                    [queryService]="mqService" [changeLocation]="'Advanced'" [tabSettings]="tabSettings">
                </broken-down-filter>
                <br>
            </ng-container>
        </ng-container>
        <div class="control-style" (click)="addNewFilter()">
            <mat-icon class="add-icon">add</mat-icon>
            <span> Add filter </span>
        </div>
    </div>
    <!--Front-End Facets broken down-->
    <ng-container *ngIf="!!bdFilters && bdFilters.length > 0">
        <div class="section-label filter-label">and</div>
        <div class="filter-card">
            <div class="filter-card-label">
                <mat-form-field>
                    <mat-select [value]="FILTERING_MODES.ALL">
                        <mat-option [value]="FILTERING_MODES.ALL" disabled>{{FILTERING_MODES.ALL}}</mat-option>
                    </mat-select>
                </mat-form-field>
                of these conditions are true
            </div>
            <ng-container *ngIf="!!mqService">
                <ng-container *ngFor="let f of bdFilters; let i = index">
                    <broken-down-filter [filter]="f" [filterIndex]="i" [availableCols]="availableColumns"
                        [readonly]="true" [advFilterService]="advFilterService" [advFilterMode]="FILTERING_MODES.ALL"
                        [changeLocation]="'Facets'">
                    </broken-down-filter>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</div>
