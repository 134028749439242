import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    DiscovererQueryService,
    DRFacetOption,
    DRFilter,
    FacetValue,
    IColumnSetting,
    IFacetDefinition,
    ITab2,
    ReportPersistService,
    TabSettingsService
} from '@discoverer/core/services';


@Component({
    selector: 'pinned-filters-list',
    templateUrl: './pinned-filters-list.component.html',
    styleUrls: ['./pinned-filters-list.component.scss']
})
export class PinnedFiltersListComponent implements OnInit {
    @Input() queryService: DiscovererQueryService;
    public currentTab: ITab2;
    public currentColumns: IColumnSetting[];

    constructor(
        private _tabSettings: TabSettingsService,
        public _reportPersistService: ReportPersistService
    ) { }

    async ngOnInit() {
        this.currentTab = await this._tabSettings.getCurrentTab();
        this.currentColumns = await this._tabSettings.getAllColumns();
    }

    public async addEmptyFilter(filter) {
        const col = (await this._tabSettings.getAllColumns()).find(col => col.fieldName === filter.name);
        var facetValues = [];
        switch (col.type) {
            case 'date':
                facetValues = [new FacetValue(new DRFacetOption('NULL TO NULL', '[NULL TO NULL]', 'Is not Blank'))];
                break;
            case 'numeric':
                facetValues = [new FacetValue(new DRFacetOption('NULL TO NULL', '[NULL TO NULL]', 'Is not Blank'))];
                break;
        }
        const newFilter = new DRFilter('facet', [col.fieldName], facetValues, [], '0');
        newFilter.openFacet = true;
        this.queryService.setFilter(col.fieldName, newFilter, false);
        this.queryService.refresh();
    }
    public getFacet(pinnedFilter: IFacetDefinition) {
        return this.currentColumns?.find(cs => cs.fieldName === pinnedFilter.name);
    }
}


