import { Injectable } from '@angular/core';

import { Observable ,  ReplaySubject } from 'rxjs';
import { DRQueryInfo, DRFacetInfo, DRFacetOption, DRStat } from '../classes';


import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class DiscovererQueryInfoService {

  public oQueryInfo: Observable<DRQueryInfo>;

  private _sQueryInfo: ReplaySubject<DRQueryInfo>;
  private _serviceUrl: string;

  public get serviceUrl() {
    return this._serviceUrl;
  }

  constructor(private http: HttpClient) {
    this._sQueryInfo = new ReplaySubject<DRQueryInfo>(1);
    this.oQueryInfo = this._sQueryInfo.asObservable();
  }

  public init(serviceUrl: string) {
    this._serviceUrl = serviceUrl;
    this.load();
  }

  private load() {
    const q = this.http.get(this._serviceUrl)
      .pipe(
        map((res: any) => {
          return <any[]>res;
        })
      );
    q.subscribe(x => {
      const facetInfo: DRFacetInfo[] =
        x.filter(item => !!item.facetOptions).map((facetInfoJson) =>
          new DRFacetInfo(facetInfoJson.name,
            facetInfoJson.type,
            facetInfoJson.field,
            facetInfoJson.display,
            facetInfoJson.shortDisplay,
            facetInfoJson.facetOptions.map((optionJson: any) =>
              new DRFacetOption(optionJson.key,
                optionJson.query,
                optionJson.display))
          )
        );

      const statInfo: DRStat[] =
        x.filter(item => item.type === 'stat').map((item) => {
          return new DRStat(item.display, item.functionType, item.field, item.name);
        });

      this._sQueryInfo.next(new DRQueryInfo([].concat(facetInfo).concat(statInfo)));
    });

  }

}




