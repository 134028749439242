<div class="wrapper">
    <div class="flex" (click)="log()">
        <button mat-icon-button (click)="saveReport()" [disabled]="(authService.gatewayIdentityUserObservable | async)?.userId != (reportPersist.oLastRequestData | async)?.mainView?.userId">
            <mat-icon>save</mat-icon>
        </button>
        <div >
            <button mat-icon-button [matMenuTriggerFor]="exportMenuJson"
                *ngIf="!isLoading">
                <mat-icon>download</mat-icon>
            </button>
            <mat-menu #exportMenuJson="matMenu" class="custom-menu">
                <button mat-menu-item (click)="exportToJson()">
                    <mat-icon class="small-icon">description</mat-icon>
                    Export to Json
                </button>
                <button mat-menu-item (click)="exportTableData(MAX_EXCEL_SIZE)">
                    <mat-icon class="small-icon">insert_drive_file</mat-icon>
                    Export to Excel
                </button>
                <button mat-menu-item (click)="exportTableData(tableHeaderConfig?.resultLength)">
                    <mat-icon class="small-icon">insert_drive_file</mat-icon>
                    Export to CSV
                </button>
            </mat-menu>

           

            <export-report #exportReport *ngIf="true" [currentTab]="currentTab" [queryService]="queryService">
            </export-report>


        </div>

    </div>
    <div class="flatten-by-container">
        <mat-label>Show Per</mat-label>
        <mat-form-field *ngIf="columnsToFlattenData.length > 0 ">
            <mat-select #select placeholder="select field" [(ngModel)]="dataFlattenBy" (valueChange)="setFlattenDataBy($event)">
                <!-- <mat-option value="" (click)="setFlattenDataBy('')">Document</mat-option> -->
                <mat-option *ngFor="let col of columnsToFlattenData" [value]="col" matTooltip="{{flattenByDic[col]}}">
                    {{flattenByDic[col]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-icon *ngIf="dataFlattenBy" class="dataFlattenBy-clear-button" (click)="setFlattenDataBy('')">clear</mat-icon>
    </div>
</div>
