<div id="copy-dialog">
    <div class="header color-primary-bg">
        <div class="title">{{title}}</div>
        <mat-icon class="close-icon" (click)="close()">close</mat-icon>
    </div>
    <hr/>
    <div class="main">
        <mat-form-field class="input-form">
            <input matInput type="text" [(ngModel)]="url" [readonly]="true">
            <div matSuffix style="display: flex;">
                <button *ngIf="url"  mat-icon-button aria-label="Clear" (click)="copy()">
                  <mat-icon>content_copy</mat-icon>
                </button>
                <button *ngIf="url" mat-icon-button aria-label="Clear" (click)="open()">
                    <mat-icon>open_in_new</mat-icon>
                  </button>
            </div>
          </mat-form-field>
    </div>
    <hr/>
    <div class="footer">
        <button mat-stroked-button class="cancel rounded" (click)="close()">Cancel</button>
    </div>
</div>
