import { Component, OnInit, Input, ViewEncapsulation, ChangeDetectorRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    StateService, DateGroupBy, NumberGroupBy,
    DiscovererQueryService,
    DiscovererDataService,
    FacetFieldAccumulator,
    IColumnSetting,
    DefaultPivotLimit,
    roundUpToHighestDigit,
    getNearestValueToGap
} from '@discoverer/core/services';
import {
    IChartDataConfig,
    IChartFormatConfig
} from '@discoverer/core/services';
import {
    IMainViewState, IChartState, TabSettingsService, 
} from '@discoverer/core/services';
import { ChartViewErrorHandler } from '@discoverer/core/kendo-chart-item/models';
import { HttpClient } from '@angular/common/http';
import { IChartType, CHART_TYPES, ChartAxis } from '../../models';
import { BaseChartDetails } from '../base-chart-details';
import { EsQueryService } from '@discoverer/core/services';
import { KendoChartItemWidget } from '@discoverer/core/kendo-chart-item';
import { ViewTypes } from '@discoverer/dynamic-reports/common';
import { KendoChartControlService } from '@discoverer/dynamic-reports/common/charts-control/kendo-chart-control.service';



export const SeriesLimit = 15;

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'chart-details',
    templateUrl: './chart-details.component.html',
    styleUrls: ['./chart-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ { provide: KendoChartControlService } ]
})
export class ChartDetailsComponent extends BaseChartDetails implements OnInit {

    @Input() public mainViewState: StateService<IMainViewState>;
    @Input() public isNew: boolean;
    @Input() public queryService: DiscovererQueryService;
    @Input() public serviceUrl: string;
    @Input() public visibleDetailsHeader: boolean = true;

    @Input() public chartState: StateService<IChartState>;
    @ViewChild('KENDO_CHART') public KendoChart: KendoChartItemWidget;

    public chartType: ViewTypes.Chart  = ViewTypes.Chart;
    public availableColumns: IColumnSetting[];
    public metricList = ['sum', 'avg', 'min', 'max', 'count', 'stddev', 'sumsq'];

    constructor(
        private snackBar: MatSnackBar,
        private tabSettings: TabSettingsService,
        public kendoChartControlService: KendoChartControlService,
        public chartViewErrorHandler: ChartViewErrorHandler,
        private _cdr: ChangeDetectorRef
    ) {
        super();
    }

    public exportChart(type: string) {
        if (type === 'png' || type === 'jpeg') {
            this.KendoChart.exportChartAsImage(type);
        } else if (type === 'svg') {
            this.KendoChart.exportChartAsSvg();
        } else if (type === 'pdf') {
            this.KendoChart.exportChartAsPDF();
        }
    }
    public async ngOnInit() {
        this.availableColumns = await this.tabSettings.getAllColumns();
        this._cdr.detectChanges();
    }

    public toggleLogarithmScale() {
        // this.chartFormatState.setValue({ isLogarithmScale: !this.isLogarithmScale });
    }

    public onChartError(msg) {
        this.snackBar.open(msg, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
    }
}
