import { IOption } from './option';


export interface IOptionList {
    id: number;
    description: string;
    options: IOption[];
}
export interface IOptionListQuery {
    Id: number;
    Description: string;
    Options: IOption[];
}
export interface IOptionListResult {
    data: IOptionListQuery[];
}

export class OptionList {
    id: number;
    description: string;
    options: IOption[];

    constructor(obj: IOptionList) {
        this.id = obj.id;
        this.description = obj.description;
        this.options = obj.options;
    }

}
