import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscovererQueryService, DRQuery } from '@discoverer/core/services';
import { takeWhile } from 'rxjs/operators';
import { HistogramData, HistogramTag } from '../histogram/histogram.component';
import { ChartGroupBy, HistogramService } from '../service/histogram.service';


@Component({
  selector: 'disco-numeric-histogram',
  templateUrl: './numeric-histogram.component.html',
  styleUrls: ['./numeric-histogram.component.scss']
})
export class NumericHistogramComponent implements OnInit {

    @Input() min: number;
    @Input() max: number;
    @Input() fieldName: string;
    @Input() serviceUrl: string;
    @Input() queryService: DiscovererQueryService;
    @Output() seriesClicked: EventEmitter<HistogramTag> = new EventEmitter<HistogramTag>();
    public get formattedRange(): {min, max} {
        return {min: this.min, max: this.max};
    }
    public histogramData: HistogramData[] = [];
    private _step = 1;
    private _alive = true;

    constructor(
        private _histogramService: HistogramService,
        private _cdr: ChangeDetectorRef
    ) { }

    ngOnDestroy(): void {
        this._alive = false;
    }

    ngOnInit(): void {
        this.queryService.oQuery.pipe(takeWhile(() => this._alive)).subscribe(async (req : DRQuery) => {
            this._step = Math.ceil((this.max - this.min) / 15);
            this.histogramData = [];
            const groupBy: ChartGroupBy = this._step != 0 ?
                this._getGroupBy(this.max, this.min, this._step) : this._getGroupBy(this.max);

            this.histogramData = (await this._histogramService.getNumericHistogramData(req, groupBy, this.fieldName, this.serviceUrl));
            this._cdr.detectChanges();
        });
    }
    _getGroupBy(max: number, min = max - 1, _step = 1): ChartGroupBy {
        var groupBy = {
            start: min.toString(),
            end: max.toString(),
            gap: _step.toString()
        }
        return groupBy;
    }

    seriesClick(event: string) {
        const range = event.split('-');
        this.seriesClicked.emit({ min: range[0], max: range[1] });
    }
}
