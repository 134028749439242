<div id="save-report">
    <div class="header color-primary-bg">
        <div>{{title}}</div>
        <mat-icon (click)="close(false)">close</mat-icon>
    </div>
    <div class="main">
       <div>{{message}}</div>
    </div>

    <div class="footer">
        <button mat-flat-button class="submit rounded" color="accent" (click)="save()">OK</button>
        <button mat-stroked-button class="cancel rounded" (click)="close(false)">Cancel</button>
    </div>

</div>