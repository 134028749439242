import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { OptionDataServiceInterface } from '../../option-lists/shared-services/data-service';
import { IOption } from '../../option-lists/models/option';
import { HasuraOptionService } from '../../option-lists/shared-services/hasura-option-service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseCustomController } from '../shared/base-custom-controller';


@Component({
    selector: 'app-formly-field-dyn-chip-list',
    templateUrl: './dyn-chip-list.component.html',
    styleUrls: ['./dyn-chip-list.component.scss'],
    providers: [{ provide: OptionDataServiceInterface, useClass: HasuraOptionService }],
    encapsulation: ViewEncapsulation.None
})
export class DynChipListComponent extends BaseCustomController implements OnInit {
    public dynOptionsById: { [id: string]: IOption } = {};
    public dynOptionsByCode: { [id: string]: IOption } = {};
    public dynOptions: IOption[] = [];
    public selectedOptions = [];
    public filteredOptions;
    optionControl = new FormControl('');
    @ViewChild('optionInput') optionInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    private _usedProp = 'id';

    constructor(
        private optionsService: OptionDataServiceInterface,
        private cdRef: ChangeDetectorRef
    ) {
        super();
    }
    ngOnInit() {
        const formChanges = new BehaviorSubject(this.field.formControl.value);
        this.field.formControl.valueChanges.subscribe(formChanges);
        combineLatest([this.optionsService.getByOptionListId(this.field.templateOptions.optionsListId), formChanges])
            .pipe(filter(f => !!f[0]))
            .subscribe(data => {
                this.dynOptions = data[0];
                const selectedOptions = data[1] as string[] | number[];
                this.dynOptions.forEach(o =>{
                  this.dynOptionsById[o.id] = o;
                  this.dynOptionsByCode[o.code] = o;
                });
                this.selectedOptions = selectedOptions ? this.getSelectedOptions(selectedOptions) : [];
                this.optionControl.setValue(null);
                this.optionInput.nativeElement.value = '';
                this.cdRef.markForCheck();
            });

        this.optionControl.valueChanges.subscribe(text => {
            if (this.dynOptions) {
                this.filteredOptions = text ? this._filter(text) : this.dynOptions;
                if (this.selectedOptions && this.selectedOptions.length) {
                    this.filteredOptions = this.filteredOptions.filter(option => this.selectedOptions.findIndex(op => op?.id === option.id) === -1);
                }
            }
        });
    }

    private getSelectedOptions( ids: (number | string)[] ) {
        const dynOption = this.field.templateOptions.useCode ? this.dynOptionsByCode : this.dynOptionsById
        if(typeof ids === 'string') {
            ids = String(ids).split(',')
        }
        return ids.map(id => {
            if(typeof id === 'string') {
                return dynOption[id.trim()]
            }
            return dynOption[id]
        });
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        if ((value || '').trim()) {
            const newOption = { id: 0, description: value.trim(), code: value.trim(), optionListId: this.field.templateOptions.optionsListId };
            this.optionsService.add(newOption).subscribe((result: any) => {
                const savedOption = result.mutationResult;
                const addedOption = { id: savedOption.id, code: savedOption.code, description: savedOption.description, optionListId: savedOption.optionListId };
                this.dynOptions.push(addedOption);
                this.selectedOptions.push(addedOption);
                this.updateValueAndValidity(this.field, this.selectedOptions.map(op => op[this._usedProp]));
            });
        }
        if (input) {
            input.value = '';
        }
        this.optionControl.setValue(null);
    }
    remove(option: string): void {
        const index = this.selectedOptions.indexOf(option);
        if (index >= 0) {
            this.selectedOptions.splice(index, 1);
            this.updateValueAndValidity(this.field, this.selectedOptions.map(op => op[this._usedProp]));
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.optionControl.setValue(null);
        const value = event.option.value;
        this.selectedOptions.push({ description: value.description, id: value.id, code: value.code });
        this.optionInput.nativeElement.value = '';
        this.updateValueAndValidity(this.field, this.selectedOptions.map(op => op[this._usedProp]));
    }

    private _filter(value): any[] {
        const filterValue = value.description ? value.description.toLowerCase() : value.toLowerCase();
        return this.dynOptions.filter(option => option.description.toLowerCase().includes(filterValue));
    }
}

