<mat-card class="card" color="primary">
    <mat-card-content>
        <div (click)="facetTitleClicked()" style="cursor: pointer">
            <div layoutDir="row" class="title-container">
                <div flex="4" class="facet-title">
                    <div>{{title}}</div>

                </div>
                <button mat-icon-button mat-button>
                    <mat-icon>{{isOpen ? 'keyboard_arrow_down':'keyboard_arrow_right'}}</mat-icon>
                </button>
            </div>
            <div *ngIf="checkedFacetValues && checkedFacetValues.length > 0 && !isOpen" flex="1" layoutDir="row"
                style="padding-left:30px;margin-top:-3px;font-size:12px;color:grey;">
                <span>{{checkedFacetValues.length}} Selected</span>
            </div>
        </div>
        <mat-list [ngClass]="(isOpen)?'filterList':'hidden-list'">
            <mat-list-item class="top-row" *ngIf="isSearchable">
                <mat-form-field class="search-field" floatLabel="never">
                    <input matInput color="primary" [formControl]="searchText" placeholder="filter"
                        autocomplete="off" />
                </mat-form-field>
                <div class="facet-total" *ngIf="sortedFacetValues.length < totalFiltered && isOpen">
                    <div style="height:20px">{{pageFrom + 1}} - {{pageTo}} of {{totalFiltered}}
                    </div>
                    <div class="pagination-btn" (click)="prevPage($event)">
                        <mat-icon [ngClass]="{'disabled': pageNumber < 1}">
                            keyboard_arrow_left
                        </mat-icon>
                    </div>
                    <div class="pagination-btn" (click)="nextPage($event)">
                        <mat-icon [ngClass]="{'disabled': pageTo >= totalFiltered}">
                            keyboard_arrow_right
                        </mat-icon>
                    </div>
                </div>
            </mat-list-item>
            <facet-data-source [facetName]="facetName" [title]="title" [serviceUrl]="serviceUrl"
                [queryService]="queryService" [facetKey]="facetKey" [singleMode]="singleMode" [enableQuery]="isOpen"
                (context)="getFacets($event)">
                <ng-template let-facetContext>
                    <div *ngIf="facetContext && facetContext?.facetValues">
                        <div layoutDir="row" class="short" *ngFor="let facetVal of sortedFacetValues ">
                            <mat-checkbox class="checkbox"
                                [ngClass]="{'excluded': facetVal.isExclusion, 'empty': facetContext.facetData?.getValue(facetVal?.facetKey) === 0}"
                                [checked]="facetContext?.isChecked(facetVal)" value="{{facetVal.facetKey}}"
                                (change)="$event.checked?facetContext.selectFacet(facetVal):facetContext.unselectFacet(facetVal)">
                                <span> {{ (!(facetVal.title)) ? '(Blank)' : facetVal.title}} </span>
                            </mat-checkbox>
                            <div *ngIf="facetContext.facetData?.getValue(facetVal?.facetKey)"
                                class="facet-value color-warn">
                                {{facetContext.facetData?.getValue(facetVal?.facetKey) | formattedNumber}}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </facet-data-source>
        </mat-list>
    </mat-card-content>
</mat-card>
