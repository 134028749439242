import { Component, Input, OnInit, Output, EventEmitter, TemplateRef, ElementRef, ContentChild } from '@angular/core';
import { BaseController, IColumnSetting } from '@discoverer/core/services';

import { AppSettingsService, ITab2, TabSettingsService } from '@discoverer/core/services/dynamic-reports-services';
import { combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export class DiscoTabContext {
    constructor(
        public currentTab: ITab2,
        public columnSettings: IColumnSetting[],
        public columnDictionary: { [key: string]: IColumnSetting }
    ) {

    }
}

@Component({
    selector: 'disco-tab',
    templateUrl: './disco-tab.component.html',
    styleUrls: ['./disco-tab.component.scss'],
    providers: [
        TabSettingsService
    ]
})
export class DiscoTabComponent extends BaseController implements OnInit {
    @Input()
    set appId(val: string) {
        this._appSettings.setActiveApp(val);
    }

    @Input()
    set tabId(val: string) {
        this._tabSettings.setCurrentTab(val);
    }

    @Output()
    context = new EventEmitter<DiscoTabContext>(true);


    @ContentChild(TemplateRef) template: TemplateRef<ElementRef>;

    constructor(private _appSettings: AppSettingsService, private _tabSettings: TabSettingsService) {
        super();
    }

    public ngOnInit() {
        const contextSubscription =
            combineLatest([
                this._tabSettings.oCurrentTab,
                this._tabSettings.oColumnSettings,
                this._tabSettings.oColumnDictionary
            ]).pipe(debounceTime(10))
                .subscribe(s => {
                    this.context.emit(null);
                    this.context.emit(
                        new DiscoTabContext(
                            s[0],
                            s[1],
                            s[2]
                        )
                    )
                });

        this.subscriptions.push(contextSubscription);
    }


}
