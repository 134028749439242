<div id="edit-directory">
    <div class="header color-primary-bg">
        <div class="title">Edit Title</div>
        <mat-icon class="close-icon" (click)="close()">close</mat-icon>
    </div>
    <div class="main">
        <form>
            <input-widget [label]="'Title'" [inputControl]="title"></input-widget>
        </form>
    </div>
    <div class="footer">
        <button mat-stroked-button class="cancel rounded" (click)="close()">Cancel</button>
        <button mat-flat-button class="create rounded" color="primary" (click)="save()">Save</button>
    </div>
</div>
