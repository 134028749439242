<div *ngIf="selectedCategory" class="category-list">
  <div class="title-bar">
    <span>{{selectedCategory.description}}</span>
    <div class="top-controls">
      <mat-search-bar [formControl]="searchControl"></mat-search-bar>
    </div>
  </div>
  <div class="main-content">
    <br />
    <div class="options-list" *ngIf="groups">
      <div *ngFor="let group of filteredGroups" class="grouped-options">
        <div class="group">
          <span class="group-title">{{group.name}}</span>
          <button *ngIf="selectedCategory.id" mat-icon-button (click)="addOptionToGroup(selectedCategory.id,group.name)"
            color="primary">
            <mat-icon class="material-icons">
              add_circle_outline
            </mat-icon>
          </button>
        </div>
        <div class="controls" *ngIf="sortChanges && selectedGroup==group.name ">
          <button mat-button class="sorting-button" color="primary" (click)="saveSorting(group.options)">Done
            sorting</button>
        </div>
        <div *ngIf="selectedOption?.id ==0 && selectedGroup ==group.name" class="list-card pointer">
          <option-details class="option-details" [option]="selectedOption" [mode]="'add'"
            (listChanged)="listChanged($event);"></option-details>
        </div>
        <div cdkDropList (cdkDropListDropped)="drop($event, group.options,group.name)">
          <div *ngFor="let option of group.options" class="list-card pointer" (click)="selectedOption = option" cdkDrag>
            <option-details class="option-details" [option]="option"
              [mode]="(selectedOption === option)? 'edit': 'display'" (listChanged)="listChanged($event); ">
            </option-details>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
    <div *ngIf="selectedOption?.id ==0 && selectedGroup =='' " class="list-card pointer">
      <option-details class="option-details" [option]="selectedOption" [mode]="'add'"
        (listChanged)="listChanged($event);"></option-details>
    </div>
    <button *ngIf="selectedCategory.id" color="primary" (click)="addOptionToGroup(selectedCategory.id)" class="add-btn"
      mat-raised-button>
      ADD ITEM
    </button>
  </div>
  <div class="delete-row" *ngIf="selectedCategory && selectedCategory.id">
    <button mat-button class="delete-button"
      (click)="mutateCategoryBloc.deleteOptionList(this.selectedCategory)">DELETE</button>
  </div>
</div>