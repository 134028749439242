<ng-container *ngIf="(dateRangeFilterService.oState | async) as output">
    <div class="flex flex-row justify-between align-center">
        <mat-form-field class="date-filter-operation" floatLabel="never">
            <mat-select [value]="output.masterSelection"
                (selectionChange)="dateRangeFilterService.setMasterSelection($event.value)">
                <mat-option *ngFor="let filterIngOperation of DATE_FILTERING_OPERATION" [value]="filterIngOperation.id">
                    {{ filterIngOperation.label }}
                </mat-option>
                <mat-option [value]="FILTERING_OPERATION_BETWEEN.id">{{ FILTERING_OPERATION_BETWEEN.label }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="flex flex-row align-center">
            <div *ngIf="showPin" class="action-icon" (click)="pinClicked.emit($event)">
                <mat-icon [fontSet]="isPinned ? 'material-icons' : 'material-icons-outlined'">push_pin</mat-icon>
                <span class="pointer">{{isPinned ? 'Pinned' : 'Pin'}}</span>
            </div>
        </div>
    </div>
    <div class="divider">
        <mat-divider></mat-divider>
    </div>
    <ng-container *ngIf="
    output.masterSelection !== FILTERING_OPR_DICTIONARY.DATE_BLANK &&
    output.masterSelection !== FILTERING_OPR_DICTIONARY.DATE_NOT_BLANK &&
    output.masterSelection !== FILTERING_OPR_DICTIONARY.DATE_HIGH_VALUE &&
    output.masterSelection !== FILTERING_OPR_DICTIONARY.DATE_NOT_HIGH_VALUE">
        <div class="date-operation-wrapper">
            <ng-container
                *ngIf="output.masterSelection === FILTERING_OPERATION_BETWEEN.id || output.masterSelection.includes('AFTER')|| output.masterSelection === 'DATE_ON' ">
                <date-operation-field
                    [numericMinOrMaxOperations]="output.masterSelection === FILTERING_OPERATION_BETWEEN.id ? numericMinOperations : []"
                    (dateValueChange)="dateRangeFilterService.setValue1Date($event)"
                    [valueIsInclusive]="output.value1IsInclusive"
                    [dateOperationShortcut]="output.dateOperationShortcut1" [value]="output.value1"
                    (numberValueChange)="dateRangeFilterService.setValue1($event)"
                    (dateOperationShortcutChange)="dateRangeFilterService.setDateOperationShortcut1($event)"
                    (valueIsInclusiveChange)="dateRangeFilterService.setValue1IsInclusive($event)"
                    [valueDate]="output.value1Date">
                </date-operation-field>
            </ng-container>

            <ng-container
                *ngIf="(output.masterSelection === FILTERING_OPERATION_BETWEEN.id || output.masterSelection.includes('BEFORE'))">
                <date-operation-field
                    [numericMinOrMaxOperations]="output.masterSelection === FILTERING_OPERATION_BETWEEN.id ? numericMaxOperations : []"
                    [valueIsInclusive]="output.value2IsInclusive"
                    [dateOperationShortcut]="output.dateOperationShortcut2" [value]="output.value2"
                    [valueDate]="output.value2Date" (numberValueChange)="dateRangeFilterService.setValue2($event)"
                    (dateOperationShortcutChange)="dateRangeFilterService.setDateOperationShortcut2($event)"
                    (dateValueChange)="dateRangeFilterService.setValue2Date($event)"
                    (valueIsInclusiveChange)="dateRangeFilterService.setValue2IsInclusive($event)">
                </date-operation-field>
            </ng-container>
        </div>


    </ng-container>

</ng-container>