<div class="align-center gap-2" *ngIf="(reportPersist.oLastRequestData | async) as state">
    <button *ngIf="currentView(state.mainView.type) as selecetedView" mat-button [matMenuTriggerFor]="viewTypesMenu" color="accent" class="title px-0"
        #viewTypesTrigger="matMenuTrigger">
        <mat-icon  class="chart-icon gap-2 text-lg">{{selecetedView?.icon}} </mat-icon>
        <span> {{selecetedView?.title}}</span>

    </button>
    <mat-menu #viewTypesMenu="matMenu">
        <div class="chart-types">
            <button mat-menu-item class="chart-type" [ngClass]="{ selected: state.mainView.type === view.name }"
                mat-menu-item *ngFor="let view of viewTypes" (click)="changeReportType(view.name);">
                <mat-icon>{{ view.icon }} </mat-icon>
                <span>{{ view.title }}</span>
            </button>
        </div>
    </mat-menu>
    <div  class="vl"></div>
</div>
