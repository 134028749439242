import {
    AfterViewInit,
    Directive,
    Input
} from '@angular/core';


@Directive({
    selector: '[autoFieldFocus]'
})
export class AutoFocus implements AfterViewInit {
    @Input() autoFieldFocus!: HTMLInputElement;

    constructor() {
    }

    ngAfterViewInit() {
        setTimeout(() => this.autoFieldFocus.focus());
    }
}
