import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alpha-revers',
  templateUrl: './alpha-revers.component.html',
  styleUrls: ['./alpha-revers.component.scss']
})
export class AlphaReversComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
