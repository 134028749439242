<div class="main-select-area">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div cdkDrag cdkDragBoundary=".main-select-area" id="settings-item" class="settings-row" *ngFor="let item of columns ; let i=index"
            [ngClass]="{'color-warn-b':$any(item).requestHover}" (mouseenter)="$any(item).requestHover=true"
            (mouseleave)="$any(item).requestHover=false">
            <div *ngIf="selectedView !='kanban-view'" class="order">
                <div class="sorting-icons">
                    <span *ngIf="item.dir === 'asc'" class="material-icons"
                        [ngClass]="{'selected-order': item.dir === 'asc'}" (click)="orderChange(item,'desc')">
                        <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision"
                            text-rendering="geometricPrecision" width="20" height="20" fill="#9e9e9e"
                            image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
                            viewBox="0 0 478 512.29">
                            <path fill-rule="nonzero"
                                d="M161.37 115.54H129.6V505.7c0 3.34-3.25 6.59-6.58 6.59H52.94c-3.41 0-6.63-2.94-6.63-6.59V115.54H14.58c-3.37 0-6.82-1.15-9.56-3.57-6.1-5.27-6.7-14.51-1.46-20.58L78.84 4.96c5.99-6.62 16.46-6.7 22.25.28l71.07 85.87c2.38 2.62 3.81 6.11 3.81 9.84 0 8.13-6.51 14.59-14.6 14.59zm50.02 396.75c-3.33 0-6.59-3.25-6.59-6.59v-56.59c0-3.38 2.94-6.63 6.59-6.63h260.03c3.65 0 6.58 3.25 6.58 6.63v56.59c0 3.34-3.25 6.59-6.58 6.59H211.39zm0-142.11c-3.17 0-6.59-3.38-6.59-6.59v-56.6c0-3.25 2.98-6.62 6.59-6.62h192.84c3.61 0 6.58 3.25 6.58 6.62v56.6c0 3.33-3.33 6.59-6.58 6.59H211.39zm0-142.11c-3.17 0-6.59-3.34-6.59-6.59v-56.6c0-3.29 2.94-6.62 6.59-6.62h125.65c3.65 0 6.58 3.25 6.58 6.62v56.6c0 3.33-3.33 6.59-6.58 6.59H211.39z" />
                        </svg>
                    </span>
                    <span *ngIf="item.dir === 'desc'" class="material-icons"
                        [ngClass]="{'selected-order':  item.dir === 'desc'}" (click)="orderChange(item,'asc')">
                        <svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision"
                            text-rendering="geometricPrecision" width="20" height="20" fill="#9e9e9e"
                            image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
                            viewBox="0 0 478 512.29">
                            <path fill-rule="nonzero"
                                d="M161.37 396.74H129.6V6.58c0-3.33-3.25-6.58-6.58-6.58H52.94c-3.41 0-6.63 2.93-6.63 6.58v390.16H14.58c-3.37 0-6.82 1.15-9.56 3.57-6.1 5.28-6.7 14.52-1.46 20.59l75.28 86.42c5.99 6.63 16.46 6.71 22.25-.27l71.07-85.87c2.38-2.62 3.81-6.11 3.81-9.84 0-8.13-6.51-14.6-14.6-14.6zM211.39 0c-3.33 0-6.59 3.25-6.59 6.58v56.6c0 3.37 2.94 6.63 6.59 6.63h260.03c3.65 0 6.58-3.26 6.58-6.63V6.58c0-3.33-3.25-6.58-6.58-6.58H211.39zm0 142.11c-3.17 0-6.59 3.37-6.59 6.59v56.59c0 3.26 2.98 6.63 6.59 6.63h192.84c3.61 0 6.58-3.26 6.58-6.63V148.7c0-3.34-3.33-6.59-6.58-6.59H211.39zm0 142.11c-3.17 0-6.59 3.33-6.59 6.59v56.59c0 3.3 2.94 6.63 6.59 6.63h125.65c3.65 0 6.58-3.25 6.58-6.63v-56.59c0-3.34-3.33-6.59-6.58-6.59H211.39z" />
                        </svg>
                    </span>
                </div>
            </div>
            <div class="field-name" *ngIf="controlsArray[i]">
                <mat-form-field class="mat-form-field-column">
                    <input type="text" placeholder="Select Field" matInput [formControl]="controlsArray[i].formControl" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn"
                        (optionSelected)="onFacetChanged($event, i)">
                        <mat-option *ngFor="let col of controlsArray[i].filteredList | async" [value]="col">
                            {{col.display}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

            <div *ngIf="selectedView !='kanban-view'" class="controls">
                <mat-icon fontSet="material-icons-outlined" (click)="removeField(i)"
                    class="remove-icon">delete</mat-icon>
                <mat-icon cdkDragHandle class="drag-icon">drag_indicator</mat-icon>
            </div>
        </div>
    </div>

    <div (click)="addField()" class="add-field flex align-center gap-2" *ngIf="selectedView !='kanban-view'">
        <mat-icon class="add-icon">add</mat-icon>
        {{addFieldLabel}}
    </div>
</div>