import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { DatasetService } from '../../services/api/dataset.service';
import { SchemaService } from '../../services/schema.service';

export enum SchemaDocumentViewTypes {
    pdfView = 'PDF_VIEW',
    uploadView = 'UPLOAD_VIEW',
    resultView = 'RESULT_VIEW'
}

@Component({
    selector: 'app-schema-main',
    templateUrl: './schema-main.component.html',
    styleUrls: ['./schema-main.component.scss']
})
export class SchemaMainComponent implements OnInit {

    viewTypes = SchemaDocumentViewTypes;
    public activeComponent: string = SchemaDocumentViewTypes.pdfView;
    public hideFileViewer = false
    public isLoading: boolean = true;
    public datasetId: string;
    public fileId: string
    public dragging = false;
    public fileName: string;
    public fileExtension: string;

    schema: any
    constructor(
        private _datasetService: DatasetService,
        private _datasetFileService: DatasetFileService,
        private _schemaService: SchemaService,
    ) { }

    async ngOnInit(): Promise<void> {
        this.datasetId = (await this._datasetService.getCurrentBaseDatasetDetails()).Id;
        this.fileId = !!this.datasetId ? localStorage.getItem(this.datasetId) : null;
        this.activeComponent = this.fileId ? this.viewTypes.pdfView : this.viewTypes.uploadView;
        await this._getFields();
        await this._getDetails();
        this.isLoading = false
    }

    processUploadedFiles(fileId: string) {
        localStorage.setItem(this.datasetId, fileId);
    }

    setFileId() {
        this.fileId = !!this.datasetId ? localStorage.getItem(this.datasetId) : null;
    }
    async handleFileUploaded(fileId: string) {
        localStorage.setItem(this.datasetId, fileId);
        this.setFileId();
        await this._getDetails();
        this.activeComponent = this.viewTypes.pdfView;
    }

    getFileId() {
        this.setFileId();
        return this.fileId;
    }

    private async _getFields() {
        const { isSuccess, result } = await this._datasetService.getFields(this.datasetId).toPromise();
        if (isSuccess && !!result) {
            this.schema = result;
            this._schemaService.updateSchema(result);
            // this._schemaService.setFields()
        }
        this.isLoading = false;
    }

    private async _getDetails() {
        try {
            if (!!this.datasetId && !!this.fileId) {
                const { isSuccess, result: detailsResponse } = await this._datasetFileService.getFileDetails(this.datasetId, this.fileId).toPromise();
                if (isSuccess && detailsResponse) {
                    this.fileName = detailsResponse.OriginalFileName;
                    this.fileExtension = detailsResponse.FileExtension;
                }
            }
        } catch (error) {
            console.error('Error fetching file details:', error);
        }
    }
}
