import { Location } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseController, DataSourceContext, DiscovererQueryService, DRFacetOption, DRFilter, FacetValue } from '@discoverer/core';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { Field } from '../../../helpers/docpipeline-function';
import { DatasetTypes } from '../../../models/dataset.model';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { DatasetService } from '../../../services/api/dataset.service';
import { FileDetailsHandlerService } from '../file-details-handler.service';
import { ChildQueue } from './main-content/chooser-queue-children-viewer/chooser-queue-children-viewer.component';

const NESTED_TYPES = ['object', 'object_array'];
const getFirstTwoNonObjectKeys = (schema: { [key: string]: Field }) => {
    if (!schema) return [];
    return Object.keys(schema).filter(key => !NESTED_TYPES.includes(schema[key].type)).slice(0, 2);
}
@Component({
    selector: 'app-document-view-details',
    templateUrl: './document-view-details.component.html',
    styleUrls: ['./document-view-details.component.scss']
})
export class DocumentViewDetailsComponent extends BaseController implements OnInit, OnDestroy {
    public tabId: any;
    public columnList: any = [];
    public titles: string[] = [];
    public SplitFiles: ChildQueue[] = [];
    public start: number = 0;
    public result: any;
    private pageLimitNumber = 20;
    public limit: number = this.pageLimitNumber;
    public resultIndex: number = -1;
    public headerWidth: number;
    public source: 'records' | 'queue';
    public fileKey: 'File.file_id' | 'Id';
    public currentReqId = 'default';
    public filterFileId: string;
    public fileId: string;
    public datasetId: string;
    public fileExtension: string;
    public fileName: string;
    public dateReceived: string;
    public documentIsCollapsed = false;
    public isCopied = false;
    public isEditMode = false;
    public isShowLogsEnabled = false;
    public dragging = false;
    public isLoading = false;
    public fieldsLoading: boolean = true;
    public isSaving: boolean = false;
    public dataSourceContext: DataSourceContext;
    public queryService: DiscovererQueryService = new DiscovererQueryService()
    private _dialogData: any;
    public isEditedValuesValid: boolean;
    constructor(@Inject(MAT_DIALOG_DATA) data,
        public _datasetService: DatasetService,
        private _location: Location,
        private _fileDetailsHandlerService: FileDetailsHandlerService,
        private _datasetFileService: DatasetFileService) {
        super();
        this._dialogData = data;
        this._setDialogData(data);
    }
    private _setDialogData(dialogData: any) {
        const snapshotData = dialogData.routeSnapshot.data;
        this.tabId = snapshotData['subDatasetId'] || snapshotData['tabId'];
        this.columnList = snapshotData['columnList'];
        this.source = snapshotData['source'];
        this.fileKey = snapshotData['fileKey'];
    }

    public async ngOnInit() {
        await this._loadData();
        await this._subscribeToResultsChanged();
    }
    private async _loadData() {

        const { datasetId, fileId, subDatasetId } = this._dialogData.routeSnapshot.parent.params;
        const { currentReqId, start, fileId: filterFileId } = this._dialogData.routeSnapshot.parent.queryParams;
        this.datasetId = subDatasetId || datasetId;
        this.fileId = fileId;
        this.currentReqId = currentReqId;
        this.filterFileId = filterFileId;
        this.start = +start;

        if (filterFileId)
            this._setFilters(filterFileId);

        await this._setTitleInfo();
    }

    public async setContext($event: DataSourceContext) {
        this.isLoading = true
        this.dataSourceContext = $event;
        this.fileId = this.dataSourceContext.data[0][this.fileKey]
        this.resultIndex = +(this.dataSourceContext.data[0]['File.result_index'] || -1)
        this.SplitFiles = this.dataSourceContext.data[0].esDoc['SplitFiles'] || [];
        this._location.replaceState(this._getNewRedirectUrl(this.fileId));
        await this._setTitleInfo();
        this.isLoading = false
    }
    public checkValidity(event) {
        this.isEditedValuesValid = event;
    }
    public moveToPrevious() {
        if (this.start <= 0) return;
        this.start--;
        this.dataSourceContext.pageChange(this.start, 1);
    }
    public moveToNext() {
        if (this.start + 1 >= this.dataSourceContext?.total) return;
        this.start++;
        this.dataSourceContext.pageChange(this.start, 1);
    }
    public limitChanged() {
        this.limit = this.pageLimitNumber;
    };
    public queryServiceChanged() {
        this.queryService.refresh();
    }
    public dataFetched($event) {
        this.result = $event
    }
    public setHeaderWidth(width: number) {
        this.headerWidth = width;
    }
    public async cancelEditMode() {
        this.isEditMode = false;
    }
    public async toggleIsSaving(isSaving: boolean) {
        this.isSaving = isSaving;
    }
    public async toggleShowLogs(isShowLogsEnabled: boolean) {
        this.isShowLogsEnabled = isShowLogsEnabled;

    }
    public async toggleEditMode(isEditMode: boolean) {
        this.isEditMode = isEditMode;
    }
    private _getNewRedirectUrl(fileId: string) {
        const { currentReqId } = this._dialogData.routeSnapshot.parent.queryParams;
        var queryParams = `currentReqId=${currentReqId}&start=${this.start}` + (this.filterFileId ? `&fileId=${this.filterFileId}` : '');

        return `/datasets/${this.datasetId}/${this.source}/${fileId}?${queryParams}`;
    }
    private _setFilters(filterFileId: any) {
        this.queryService.setFilter(this.fileKey, new DRFilter('facet',
            [this.fileKey],
            [new FacetValue(new DRFacetOption(`${filterFileId}`, `${filterFileId}`, `${filterFileId}`))],
            [],
            'All',
            true));
    }
    private async _setTitleInfo(): Promise<void> {
        try {
            const fileLogRes = await this._datasetFileService.getLogs(this.datasetId, this.fileId).toPromise();

            if (fileLogRes?.isSuccess && fileLogRes?.result) {
                const contextData = this.dataSourceContext.data[0];
                const inputStep = fileLogRes?.result?.stepsLog?.find(step => step.name === 'input');
                const inputInfo = inputStep?.input_info;

                this.fileName = inputInfo?.['original_file_name'] || contextData['OriginalFileName'];
                this.fileExtension = inputInfo?.['file_extension'] || contextData['FileExtension'];
                this.dateReceived = inputInfo?.['uploaded_on'] || contextData['CreatedAt'];
            }
        } catch (error) {
            console.error('Error fetching file log:', error);
        }
    }
    private async _subscribeToResultsChanged() {
        this.fieldsLoading = true;


        return this._subscribeToFileResultsAndSchema();

    }
    private _processFields(fileResults: { [key: string]: any }[], currentSchema: any): void {
        this.titles = [];
        try {
            const firstTwoFields = getFirstTwoNonObjectKeys(currentSchema);
            this.titles = firstTwoFields.map(fld => fileResults[this.resultIndex >= 0 ? this.resultIndex : 0][fld]);
            this.fieldsLoading = false;
        } catch (error) {
            console.error('Error processing fields:', error);
        }
    }
    private async _isWorkspace(): Promise<boolean> {
        return (await this._datasetService.oDatasetDetails.pipe(take(1)).toPromise()).Type === DatasetTypes.WORKSPACE;
    }
    private async _subscribeToFileResultsAndSchema() {
        this.subscriptions.push(combineLatest([
            this._fileDetailsHandlerService.oFileResults,
            this._fileDetailsHandlerService.schemaService.oCurrentSchema
        ]).subscribe(
            ([fileResults, currentSchema]) => this._processFields(fileResults, currentSchema),
            () => this.fieldsLoading = false
        ));
    }
}
