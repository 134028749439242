import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export class WorkspaceNotification {
    msg: string; duration: number; color: string; backgroundColor: string
}

export abstract class WorkspaceNotificationServiceInterface {
    abstract oNotification: Observable<WorkspaceNotification>;
    abstract setNotification(notifictaion: WorkspaceNotification): Observable<WorkspaceNotification>;
    abstract getNotification(): Promise<WorkspaceNotification>;
}

@Injectable()
export class WorkspaceNotificationService implements WorkspaceNotificationServiceInterface {
    private _sNotification = new Subject<WorkspaceNotification>();
    public oNotification: Observable<WorkspaceNotification>;

    constructor() {
        this.oNotification = this._sNotification.asObservable();

    }
    setNotification(notification: WorkspaceNotification): Observable<WorkspaceNotification> {
        throw new Error('Method not implemented.');
    }
    async getNotification(): Promise<WorkspaceNotification> {
        throw new Error('Method not implemented.');
    }
}
