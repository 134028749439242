import { Injectable } from '@angular/core';
import {
    FILTERING_OPR_DICTIONARY,
    FILTERING_OPERATION,
    IDRFilter,
    FacetValue,
    DRFacetOption,
    DiscovererQueryService,
    DRFilter,
    StateService,
    ICategories
} from "@discoverer/core/services";

import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilKeyChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
    NumericRangeState
} from "./numeric-range.model";

import { format } from "@progress/kendo-angular-intl";

export class DiscovererFilterStateInputs {
    queryService: DiscovererQueryService;
    facetKey: string;
}

export class DiscovererFilterChangeService {

    private _filterState$ = new StateService<DiscovererFilterStateInputs>({
        queryService: null,
        facetKey: null
    })

    oQueryService: Observable<DiscovererFilterStateInputs> = this._filterState$.oState;

    oFilter = this._whenFilterExpressionChanges();

    setQueryService(queryService: DiscovererQueryService) {
        this._filterState$.setValue({ queryService });
    }

    setFacetKey(facetKey: string) {
        this._filterState$.setValue({ facetKey });
    }

    private _whenFilterExpressionChanges(): Observable<{ filter: IDRFilter, queryService: DiscovererQueryService, facetKey: string }> {
        return combineLatest([
            this._filterState$.oState.pipe(
                filter(x => !!x && !!x.queryService),
                switchMap(x => x.queryService.oQuery)),
            this._filterState$.oState
        ]).pipe(
            map(result => ({ query: result[0], state: result[1] })),
            filter(data => !!data.query && !!data.state && !!data.state.queryService && !!data.state.facetKey),
            map((data) => {
                // get the filter for this facet (matching this.facetKey)
                var filter = data.query.filters.find(
                    (x) => x.fields[0] === data.state.facetKey
                );

                return {
                    filter,
                    facetKey: data.state.facetKey,
                    queryService: data.state.queryService,
                    distinctKey: !!(filter?.expression) ? JSON.stringify(filter.expression) : ''
                };
            }),
            distinctUntilKeyChanged('distinctKey'),
            map(s => ({ filter: s.filter, queryService: s.queryService, facetKey: s.facetKey }))
        );
    }
}

export abstract class GenericFacetFilterService<T> {

    oState: Observable<T>;

    protected destroy$ = new Subject<boolean>();

    protected command$ = new BehaviorSubject<string>('init');
    protected formState$: StateService<T>;
    protected filterChange$: DiscovererFilterChangeService;

    private _initalState: T;

    constructor(initialState: T) {
        this._initalState = initialState;
        this.formState$ = new StateService<T>(initialState);
        this.oState = this.formState$.oState;

        this.filterChange$ = new DiscovererFilterChangeService();

        this._whenFormInputChangesSendQuery();
        this._whenFilterExpressionChangesLoadFromExpression();
    }

    protected abstract validateFormState(formState: T): boolean;

    protected abstract convertFormStateToFilter(formState: T, facetKey: string): IDRFilter;

    protected abstract convertFilterToFormState(filter: IDRFilter): T;

    setQueryService(queryService: DiscovererQueryService) {
        this.filterChange$.setQueryService(queryService);
    }

    setFacetKey(facetKey: string) {
        this.filterChange$.setFacetKey(facetKey);
    }

    updateState(key: string, value: any) {
        this.formState$.setField(key, value);
        this.command$.next('update');
    }

    resetState() {
        this.formState$.setValue(this._initalState);
        this.command$.next('reset');
    }

    destroy() {
        this.destroy$.next(true);
    }

    private _whenFormInputChangesSendQuery() {
        combineLatest([this.command$, this.filterChange$.oQueryService])
            .pipe(
                takeUntil(this.destroy$),
                debounceTime(600),
                map(s => ({ command: s[0], ...s[1] })), //combine the two
                filter(s => !!s.queryService && !!s.facetKey)
            )
            .subscribe((v) => {
                var formSnapshot = this.formState$.getState();
                if (v.command == 'update') {
                    const isInputValid = this.validateFormState(formSnapshot);
                    if (isInputValid) { //trigger query change.
                        var filter = this.convertFormStateToFilter(formSnapshot, v.facetKey);
                        v.queryService.setFilter(v.facetKey, filter);
                        v.queryService.refresh();
                    } else {
                        v.queryService.unSetFilter(v.facetKey);
                    }
                } else if (v.command == 'reset') {
                    v.queryService.unSetFilter(v.facetKey);
                    v.queryService.refresh();
                }
            });
    }

    private _whenFilterExpressionChangesLoadFromExpression() {
        this.filterChange$.oFilter.pipe(
            takeUntil(this.destroy$),
            filter(s => !!s && !!s.filter),
            map(s => this.convertFilterToFormState(s.filter))
        )
            .subscribe(s => {
                this.formState$.setValue({ ...s });
            });
    }


}

@Injectable()
export class NumericRangeFilterService extends GenericFacetFilterService<NumericRangeState> {


    format = "{0:n2}";
    postfix = "";


    formatNumber(num: number): string {
        return (
            format(this.format ? this.format : "{0:0}", [num], "en") +
            (this.postfix ? this.postfix : "")
        );
    }

    setValue1(value1: number) {
        this.updateState('value1', value1);
    }

    setValue2(value2: number) {
        this.updateState('value2', value2);
    }

    setValue1IsInclusive(value1IsInclusive) {
        this.updateState('value1IsInclusive', value1IsInclusive);
    }

    setValue2IsInclusive(value2IsInclusive: boolean) {
        this.updateState('value2IsInclusive', value2IsInclusive);
    }

    setMasterSelection(masterSelection: string) {
        this.updateState('masterSelection', masterSelection);
    }

    constructor() {
        super({
            value1IsInclusive: true,
            value2IsInclusive: true,
            isSingleValue: false,
            masterSelection: FILTERING_OPERATION.filter(f => f.label.includes("between"))[0].id
        })
        this._runTests();
    }

    private _runTests() {
        this._testStateToExpression({ masterSelection: FILTERING_OPR_DICTIONARY.EQUALS, value1: 1, value2: undefined, value1IsInclusive: undefined, value2IsInclusive: undefined, isSingleValue: undefined }, "[1 TO 1]");
        this._testStateToExpression({ masterSelection: FILTERING_OPR_DICTIONARY.NOT_EQUALS, value1: 1, value2: undefined, value1IsInclusive: undefined, value2IsInclusive: undefined, isSingleValue: undefined }, "!\"1\"");
        this._testStateToExpression({ masterSelection: FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN, value1: undefined, value2: 1, value1IsInclusive: undefined, value2IsInclusive: undefined, isSingleValue: undefined }, "[* TO 1]");
        this._testStateToExpression({ masterSelection: FILTERING_OPR_DICTIONARY.LESS_THAN, value1: undefined, value2: 1, value1IsInclusive: undefined, value2IsInclusive: undefined, isSingleValue: undefined }, "{* TO 1}");
        this._testStateToExpression({ masterSelection: FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN, value1: 1, value2: undefined, value1IsInclusive: undefined, value2IsInclusive: undefined, isSingleValue: undefined }, "[1 TO *]");
        this._testStateToExpression({ masterSelection: FILTERING_OPR_DICTIONARY.MORE_THAN, value1: 1, value2: 1, value1IsInclusive: undefined, value2IsInclusive: undefined, isSingleValue: undefined }, "{1 TO *}");
        this._testStateToExpression({ masterSelection: FILTERING_OPR_DICTIONARY.NUMERIC_NOT_BLANK, value1: undefined, value2: undefined, value1IsInclusive: undefined, value2IsInclusive: undefined, isSingleValue: undefined }, "{ NULL TO NULL }");
        this._testStateToExpression({ masterSelection: FILTERING_OPR_DICTIONARY.NUMERIC_BLANK, value1: undefined, value2: undefined, value1IsInclusive: undefined, value2IsInclusive: undefined, isSingleValue: undefined }, "!{ NULL TO NULL }");

        this._testExpression("[1 TO 1]", FILTERING_OPR_DICTIONARY.EQUALS);
        this._testExpression("!\"1\"", FILTERING_OPR_DICTIONARY.NOT_EQUALS);
        this._testExpression("[* TO 1]", FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN);
        this._testExpression("[* TO 1}", FILTERING_OPR_DICTIONARY.LESS_THAN);
        this._testExpression("[1 TO *]", FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN);
        this._testExpression("{1 TO *]", FILTERING_OPR_DICTIONARY.MORE_THAN);
        this._testExpression("[ NULL TO NULL ]", FILTERING_OPR_DICTIONARY.NUMERIC_NOT_BLANK);
        this._testExpression("![ NULL TO NULL ]", FILTERING_OPR_DICTIONARY.NUMERIC_BLANK);
        this._testExpression("[1 TO 2}", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
        this._testExpression("{1 TO 2}", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
        this._testExpression("{1 TO 2]", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
        this._testExpression("[1 TO 2]", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
    }

    protected override validateFormState(state: NumericRangeState): boolean {
        switch (state.masterSelection) {
            case FILTERING_OPR_DICTIONARY.IS_BETWEEN:
                return Number.isFinite(state.value1) && Number.isFinite(state.value2);
            case FILTERING_OPR_DICTIONARY.NUMERIC_BLANK:
                return true;
            case FILTERING_OPR_DICTIONARY.NUMERIC_NOT_BLANK:
                return true;
            case FILTERING_OPR_DICTIONARY.MORE_THAN:
            case FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN:
            case FILTERING_OPR_DICTIONARY.EQUALS:
            case FILTERING_OPR_DICTIONARY.NOT_EQUALS:
                return !!state.masterSelection && Number.isFinite(+state.value1);
            case FILTERING_OPR_DICTIONARY.LESS_THAN:
            case FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN:
                return !!state.masterSelection && Number.isFinite(+state.value2);
            default:
                return false;
        }
    }

    protected override convertFilterToFormState(filter: IDRFilter): NumericRangeState {
        if (!filter || !filter.expression) {
            return {
                masterSelection: FILTERING_OPR_DICTIONARY.IS_BETWEEN,
                value1IsInclusive: true,
                value2IsInclusive: true,
                isSingleValue: true,
                value1: null,
                value2: null,
            };
        } else {
            const expression = filter.expression[0];
            if (!!expression) {
                const values = expression
                    .replace(/"/g, "")
                    .replace("!", "")
                    .replace("[", "")
                    .replace("{", "")
                    .replace("}", "")
                    .replace("]", "")
                    .split(" TO ")
                    .map(s => s.trim());

                const leftIsANumber = Number.isFinite(+values[0]);
                const rightIsANumber = Number.isFinite(+values[1]);
                const leftIsInclusive = expression.includes('[');
                const rightIsInclusive = expression.includes(']');
                const isNotEqual = expression.startsWith('!');
                const leftIsStar = values[0]?.includes("*");
                const rightIsStar = values[1]?.includes("*");
                const leftIsBlank = values[0] == "NULL";
                const rightIsBlank = values[1] == "NULL";

                let masterSelection = FILTERING_OPR_DICTIONARY.NOT_SELECTED;


                if (values.length == 1 && isNotEqual) {
                    masterSelection = FILTERING_OPR_DICTIONARY.NOT_EQUALS;
                }
                else if (leftIsBlank && rightIsBlank) {
                    masterSelection = isNotEqual ? FILTERING_OPR_DICTIONARY.NUMERIC_BLANK : FILTERING_OPR_DICTIONARY.NUMERIC_NOT_BLANK;
                }
                else if (leftIsANumber && rightIsANumber) {
                    masterSelection = values[0] == values[1] ?
                        (isNotEqual ? FILTERING_OPR_DICTIONARY.NOT_EQUALS : FILTERING_OPR_DICTIONARY.EQUALS)
                        : FILTERING_OPR_DICTIONARY.IS_BETWEEN;
                }
                else if (leftIsANumber && rightIsStar) {
                    masterSelection = leftIsInclusive ? FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN : FILTERING_OPR_DICTIONARY.MORE_THAN
                } else if (rightIsANumber && leftIsStar) {
                    masterSelection = rightIsInclusive ? FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN : FILTERING_OPR_DICTIONARY.LESS_THAN;
                }

                return {
                    value1IsInclusive: leftIsInclusive,
                    value2IsInclusive: rightIsInclusive,
                    masterSelection: masterSelection,
                    isSingleValue: masterSelection != FILTERING_OPR_DICTIONARY.IS_BETWEEN,
                    value1: +values[0],
                    value2: +values[1]
                }
            }
        }
    }

    protected override convertFormStateToFilter(state: NumericRangeState, facetKey: string): IDRFilter {
        const masterSelection = state.masterSelection;
        const isBetween = masterSelection == FILTERING_OPR_DICTIONARY.IS_BETWEEN;
        const isEqualsOrNotEquals = masterSelection == FILTERING_OPR_DICTIONARY.EQUALS ||
            masterSelection == FILTERING_OPR_DICTIONARY.NOT_EQUALS;

        const value1IsInclusive = isBetween ? state.value1IsInclusive
            : masterSelection.includes('EQUALS');
        const value2IsInclusive = isBetween ? state.value2IsInclusive
            : masterSelection.includes('EQUALS');

        const isBlank = masterSelection.includes('BLANK');

        const value1 = (isBetween || isEqualsOrNotEquals || masterSelection.includes("MORE")) ?
            +state.value1 : '*';
        const value2 = (isBetween || isEqualsOrNotEquals || masterSelection.includes("LESS")) ?
            (isEqualsOrNotEquals ? +state.value1 : +state.value2) : '*';

        const fromExp = (value1IsInclusive ? "[" : "{") + (isBlank ? " NULL" : value1);
        const toExp = (isBlank ? "NULL " : value2) + (value2IsInclusive ? "]" : "}");
        const isNumericBlank = (masterSelection === FILTERING_OPR_DICTIONARY.NUMERIC_BLANK);
        const isNotEqual = masterSelection === FILTERING_OPR_DICTIONARY.NOT_EQUALS;
        const facetValue = new FacetValue(
            new DRFacetOption(
                value1 + " TO " + value2,
                isNotEqual ? `!"${+value1}"` : (isNumericBlank ? "!" : "") + fromExp + " TO " + toExp + "",
                this._getFilterDisplay(value1, value2, masterSelection)
            )
        );
        //facetValue.isExclusion =  ;
        facetValue.isChecked = true;

        let filter = new DRFilter(
            "facet",
            [facetKey],
            [facetValue],
            []
        );

        return filter;
    }

    private _getFilterDisplay(
        val1: string | number,
        val2: string | number,
        masterSelection: string
    ) {
        switch (masterSelection) {
            case FILTERING_OPR_DICTIONARY.LESS_THAN:
                return `Less than ${this.formatNumber(+val2)}`;
            case FILTERING_OPR_DICTIONARY.MORE_THAN:
                return `More than ${this.formatNumber(+val1)}`;
            case FILTERING_OPR_DICTIONARY.EQUALS_MORE_THAN:
                return `Equals or more than ${this.formatNumber(+val1)}`;
            case FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN:
                return `Equals or less than ${this.formatNumber(+val2)}`;
            case FILTERING_OPR_DICTIONARY.EQUALS:
                return `Equals ${this.formatNumber(+val1)}`;
            case FILTERING_OPR_DICTIONARY.NOT_EQUALS:
                return `Not Equals ${this.formatNumber(+val1)}`;
            case FILTERING_OPR_DICTIONARY.IS_BETWEEN:
                return `Between ${this.formatNumber(+val1)} to ${this.formatNumber(+val2)}`;
            case FILTERING_OPR_DICTIONARY.NUMERIC_BLANK:
                return `Is Blank`;
            case FILTERING_OPR_DICTIONARY.NUMERIC_NOT_BLANK:
                return `Is Not Blank`;
        }
    }

    private _testStateToExpression(state: NumericRangeState, expectedExpression: string) {
        var expression = (this.convertFormStateToFilter(state, 'TEST'))?.expression[0];
        var passes = expression === expectedExpression;
        console.log(`${passes} - testing masterSelection: ${state.masterSelection} `, expression);
    }

    private _testExpression(expression: string, expectedMaster: string) {
        const facetValue = new FacetValue(
            new DRFacetOption(
                expression,
                expression,
                expression
            )
        );

        let filter = new DRFilter(
            "facet",
            ['TEST'],
            [facetValue],
            []
        );

        var formState = this.convertFilterToFormState(filter);
        var passes = formState.masterSelection === expectedMaster;
        console.log(`${passes} - testing expression: ${expression}`, formState)
    }
}


