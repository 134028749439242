<div class="form-group">
       <mat-label>Credit Card</mat-label>
       <mat-form-field>
              <input creditCardNumber  type="text" id="creditcard" formControlName="ccNumber"
                     placeholder="Card Number"
                     (onCreditCardType)="onCardType($event)" (onCreditCardError)="onCardError($event)" matInput />
              <span *ngIf="cardError === CreditCardErrors.INVALID_LENGTH">
                     <strong>Length Invalid</strong>
              </span>
              <span *ngIf="cardError === CreditCardErrors.INVALID_NUMBER">
                     <strong>Invalid Card Number</strong>
              </span>
       </mat-form-field>
</div>
