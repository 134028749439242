import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DatasetTypes } from '../../models/dataset.model';
import { DatasetService } from '../../services/api/dataset.service';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../home/edit-title-dialog/edit-title-dialog';


@Component({
    selector: 'dataset-list',
    templateUrl: './dataset-list.component.html',
    styleUrls: ['./dataset-list.component.scss']
})
export class DatasetListComponent implements OnInit {

    constructor(
        public dialog: MatDialog,
        private _datasetService: DatasetService,
        private _router: Router,
        private _snackbar: MatSnackBar
    ) { }
    public async ngOnInit() {
        await this.getDatasets();
    }

    public async getDatasets() {
        try {
            var { isSuccess, result } = await this._datasetService.getAll();
            if (!isSuccess)
                this._showMsg(`Error fetching datasets`, ERROR_SNACKBAR_OPTIONS);
            else {
                const workspace = result?.find(x => x.Type === DatasetTypes.WORKSPACE);
                if (workspace) {
                    this._datasetService.updateDatasetDetails(workspace);
                    this._router.navigate(['datasets', workspace?.Id, 'queue']);
                } else {
                    this._showMsg(`No datasets found`, ERROR_SNACKBAR_OPTIONS);
                }
            }

        } catch { }
    }

    private _showMsg(msg: string, option: MatSnackBarConfig = SUCCESS_SNACKBAR_OPTIONS) {
        this._snackbar.open(msg, 'close', option)
    }
}
