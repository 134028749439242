import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DialogsManagerService } from '../../dialogs/dialogs-manager.service';
import {
    DataFormattingService, IKanbanViewState,
    BaseController, DiscovererQueryService, EsQueryService, IList,
    LoadingState, StateService, KanbanViewDataService, TabSettingsService, IColumSetting, IColumnSetting
} from '@discoverer/core/services';
import { first } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';



@Component({
    selector: 'kanban-view',
    templateUrl: './kanban-view.component.html',
    styleUrls: ['./kanban-view.component.scss'],
})

export class KanbanViewComponent extends BaseController implements OnInit {

    lists: IList[];
    @Input() public kanbanViewState: StateService<IKanbanViewState>;
    @Input() public queryService: DiscovererQueryService;
    @Input() public isPreview: boolean = false;
    columns: IColumnSetting[] = [];
    public kanbanViewDataService: KanbanViewDataService;

    constructor(
        protected dataFormattingService: DataFormattingService,
        protected http: HttpClient,
        protected apollo: Apollo,
        private cdRef: ChangeDetectorRef,
        protected dialogsManagerService: DialogsManagerService,
        private esQueryService: EsQueryService,
        protected tabSettings: TabSettingsService,
    ) {
        super();
    }

    async ngOnInit() {
        const currentTab = await this.tabSettings.getCurrentTab();
        this.columns = await this.tabSettings.getAllColumns();
        this.kanbanViewDataService = new KanbanViewDataService(
            this.http,
            this.apollo,
            this.queryService,
            this.kanbanViewState,
            this.columns,
            currentTab.serviceUrl,
            currentTab,
        );
        this.kanbanViewDataService.intKanbanViewListCardsDataService(this.esQueryService, this.dataFormattingService);

        this.subscriptions.push(this.kanbanViewDataService.$oListsWithCards.subscribe(listCards => {
            if (listCards) {
                this.updateKanbanList(listCards);
                this.cdRef.detectChanges();
            }
        }));
        this.subscriptions.push(this.kanbanViewDataService.$oAllListsWithCards.subscribe(lists => {
            if (lists) {
                const { field } = this.kanbanViewState?.getState()?.groupByColumn;
                const groupType = this.columns.find(col => col.fieldName === field)?.dataType;
                this.lists = lists.map(l => ({ ...l, name: this.dataFormattingService.getValue(l.name, groupType) }))
                        .sort((a, b) => (a.order > b.order) ? 1 : -1);
                this.cdRef.detectChanges();
            }
        }));
    }

    dropList(event: CdkDragDrop<IList[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
            event.previousContainer.data.forEach((list, index) => {
                list.order = index
            })
        }
        event.container.data.forEach((list, index) => {
            list.order = index
        });

    }

    protected updateKanbanList(list: IList): void {
        const previousKanbanViewState = this.kanbanViewState.getState();
        const currentKanbanViewState = previousKanbanViewState.listsInfo.map(li => {
            if (li.id === list.id) {
                li = list;
            }
            return li;
        });
        this.lists = currentKanbanViewState;
    }

    public async addDataToList(list: IList) {
        const currentQuery = await this.queryService.oQuery.pipe(first()).toPromise();
        const listCards = await this.kanbanViewDataService.getCards(currentQuery, list, list.cards.length)
        list.cards = list.cards.concat(listCards);
        this.cdRef.detectChanges();
    }
}
