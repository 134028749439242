import {
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    Router
} from '@angular/router';
import {
    AuthService
} from '@discoverer/app-core';
import {
    BaseController,
    DiscovererQueryService,
    ReportPersistService,
    RequestFilingService,
    TabSettingsService,
    UserPermissionService,
    Permission,
    ITab2,
    disLogger,
    AppSettingsService,
    IApp,
    ITableHeader
} from '@discoverer/core/services';
import {
    DialogsManagerService
} from '@discoverer/dynamic-reports/dialogs';
import {
    PopoverService
} from '@discoverer/dynamic-reports/popover/popover.service';
import {
    filter,
    first,
    map,
    take
} from 'rxjs/operators';
import {
    ExportReportComponent, MAX_EXCEL_SIZE
} from '../export-to-excel/export.component';
import { CHART_TYPES } from '@discoverer/dynamic-reports/models';

@Component({
    selector: 'report-actions',
    templateUrl: './report-actions.component.html',
    styleUrls: ['./report-actions.component.scss'],
})

export class ReportActionsComponent extends BaseController implements OnInit, OnDestroy {
    @Input() public queryService: DiscovererQueryService;
    @Input() tableHeaderConfig: ITableHeader;
    @ViewChild('exportReport', { static: false }) exportReport: ExportReportComponent;

    public canSwitchReport: boolean = false;
    public canEditReport: boolean = false;
    public canDeleteReport: boolean = false;
    public canAddReport: boolean = false;
    public canExportReport: boolean = false;
    public currentTab: ITab2;
    public directoryId = 0
    public currentApp: IApp;
    public MAX_EXCEL_SIZE = MAX_EXCEL_SIZE;

    constructor(
        private _tabSettingsService: TabSettingsService,
        private _appSettingsService: AppSettingsService,
        private _cdRef: ChangeDetectorRef,
        private _dialogsManagerService: DialogsManagerService,
        private _authService: AuthService,
        private _router: Router,
        public reportPersistService: ReportPersistService,
        private _requestFilingService: RequestFilingService,
        private _userPermissionService: UserPermissionService,
        private _popoverService: PopoverService,
        private _snackBar: MatSnackBar
    ) {
        super();
    }

    async ngOnInit() {
        this.currentTab = await this._tabSettingsService.getCurrentTab();
        this.currentApp = await this._appSettingsService.getCurrentApp();
        this.subscriptions.push(
            this.reportPersistService.oLastRequestData.pipe(filter(r => !!r)).subscribe(async report => {
                const requestOwnerUserId = report.mainView?.userId;
                const dirId = report.mainView?.dirId;
                await this._setReportPermissions(requestOwnerUserId, dirId);
                this._cdRef.markForCheck();
            })
        );
    }

    public async reset() {
        await this.reportPersistService.resetRequestFromDB();
    }
    public switchFilter() {
        this.reportPersistService.mainViewState.setValue({isVerticalFacets: !this.reportPersistService.mainViewState.getState().isVerticalFacets})
    }
    public async shareCurrentReport() {
        const newRequestId = (await this.reportPersistService.createRequest());
        const requestCopy = this.reportPersistService.oLastRequestData.getValue();
        requestCopy.mainView.dirId = null;
        await this.reportPersistService.updateRequest(newRequestId, requestCopy, false);
        this._dialogsManagerService.openShareDialog(this.currentTab.app.key, this.currentTab.key, newRequestId);
    }

    public closePopover() {
        this._popoverService.setState(true);
    }

    public async deleteReport() {
        const dialogMsg = `Are you sure you want to delete this report? This cannot be undone.`;
        const confirm = await this._dialogsManagerService.openConfirmDialog('Delete Report ?', dialogMsg).toPromise();
        if (confirm) {
            const isDeleted: boolean = await this.reportPersistService.deleteRequest(this.reportPersistService.loadReqId);
            if (isDeleted) {
                const defaultRequestId = this.currentTab.defaultRequestId;
                await this.reportPersistService.loadRequest(defaultRequestId);
                this._requestFilingService.sReloadRequests.next(true);
                this._router.navigateByUrl(`${this.currentTab.app.key}/reports/${this.currentTab.key}/default`);
                this._openSnackBar('Report has been deleted.');
            } else {
                this._openSnackBar(`Not authorized.`);
                disLogger('logged in userid does not match request owner, delete error');
            }
        }
    }

    public async openSaveReportDialog(isUpdate: boolean) {
        const originalTitle = this.reportPersistService.mainViewState.getState().mainTitle;
        const { reportName, dirId, isSave } = await this._getSaveReportNameAndDir(isUpdate);
        if(!isSave) {
            console.log('**********************************')
            return
        }
        const requestId = isUpdate ? this.reportPersistService.loadReqId : this.reportPersistService.currentReqId;
        if (!!(reportName?.trim())) {
            try {
                const user = await this._authService.gatewayIdentityUserObservable.pipe(first()).toPromise();
                await this._requestFilingService.setRequestDirectory(requestId, reportName, isUpdate ? -1 : dirId, this.currentTab.app.key, this.currentTab.key)
                await this.reportPersistService.persistRequestOwner(isUpdate, false, user.userId);
                this._router.navigateByUrl(`${this.currentTab.app.key}/reports/${this.currentTab.key}/${requestId}`);
            } catch (err) {
                this._titleError(originalTitle, dirId, err.error);
            }
        } else {
            this._openSnackBar("Please Select a Valid Report Name");
        }
        this._cdRef.markForCheck();
    }
    async addToDashboard(request) {
        this._router.navigateByUrl(`${this.currentTab.app.key}/dashboard/${request}?tab=${this.currentTab.key}&newReqId=${this.reportPersistService.loadReqId}`);
    }
    private async _getSaveReportNameAndDir(isUpdate: boolean) {
        let dirId = this.directoryId, reportName, isSave = isUpdate;
        const chartId = await this.reportPersistService.chartState.oState.pipe(map(state => state?.chartFormat?.chartId), take(1)).toPromise();
        const chartType =  CHART_TYPES.find(type => type.id === chartId);
        if (isUpdate) {
            const state = this.reportPersistService.mainViewState.getState();
            this.reportPersistService.mainViewState.setValue({subType: chartType?.subType || null}, false);
            reportName = state.mainTitle;

        } else {
            const result = await this._dialogsManagerService.openSaveReportDialog(this.currentTab.key).toPromise();
            if (!!result) {
                reportName = result.reportName;
                dirId = result.directoryId;
                isSave = result.isSave;
                this.reportPersistService.mainViewState.setValue({ mainTitle: reportName, reportDir: result.reportDir, subType: chartType?.subType || null}, false);
            }
        }
        return { reportName, dirId, isSave };
    }
    private async _setReportPermissions(requestOwnerUserId, dirId) {
        this.canEditReport = await this._hasPermission(Permission.EditReport, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${this.reportPersistService.loadReqId}`);
        this.canDeleteReport = await this._hasPermission(Permission.DeleteRequest, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${this.reportPersistService.loadReqId}`);
        this.canSwitchReport = await this._hasPermission(Permission.SwitchReport, `${this.reportPersistService.loadReqId}`);
        this.canAddReport = await this._hasPermission(Permission.AddReport, `${this.reportPersistService.loadReqId}`);
        this.canExportReport = await this._hasPermission(Permission.ExportReport, `${this.reportPersistService.loadReqId}`);
    }

    private async _hasPermission(permissionKey: string, resourceKey?: string): Promise<boolean> {
        return await this._userPermissionService.hasPermission(this.currentTab.key, permissionKey, resourceKey);
    }
    private _openSnackBar(msg: string) {
        this._snackBar.open(msg, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }
    private _titleError(mainTitle: string, reportDir: number, errorMsg: string) {
        this.reportPersistService.mainViewState.setValue({ mainTitle, reportDir });
        this._openSnackBar(errorMsg);
    }
}
