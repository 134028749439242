import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl, ValidationErrors } from '@angular/forms';


@Directive({
  selector: '[creditCardNumber]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CreditCardNumberFormatValidatorDirective, multi: true }]
})
export class CreditCardNumberFormatValidatorDirective implements Validator {

  validate(c: FormControl): ValidationErrors {
    const isValidCreditCardNumber = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$`/.test(c.value);
    const message = {
      'creditCardNumber': {
        'message': 'The credit card number must be valid (XXX-XXX-XXX, where X is a digit)'
      }
    };
    return isValidCreditCardNumber ? null : message;
  }
}

import {
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output
} from '@angular/core';
import { CCTypes } from '../shared/cc-types';
import { isCCLengthValid } from '../shared/libs/is-length-valid';
import { getCardType } from '../shared/libs/get-card-type';
import { isValidLuhn } from '../shared/libs/is-valid-luhn';
export enum CREDIT_CARD_ERRORS {
  INVALID_LENGTH = 'invalid_length',
  UNSUPPORTED_CARD = 'unsupported card',
  INVALID_NUMBER = 'invalid_number',
  INVALID_MONTH = 'invalid_month',
  INVALID_CCV = 'invalid_ccv',
  NONE = 'none',
}
