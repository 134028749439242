import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';


const MAP_COL_SETTINGS_URL = 'UpdateIndexMappingAsync/';
const UPDATE_COL_SETTINGS_URL = 'InsertDocInESAsync/';
const UPDATE_RECORD_BY_PROPERTY = 'api/es/ESIndexing';

@Injectable()
export class EsearchSettingsService {
    constructor(private http: HttpClient) { }

    public async indexTabData(appId: string, tabId: string): Promise<any> {
        const url = `/api/es/ESIndexing/${appId}/${tabId}`;
        return this.http.get(url).toPromise();
    }

    public async createIndexwithMapping(appId: string, tabId: string): Promise<any> {
        const url = `/api/es/details/${MAP_COL_SETTINGS_URL}${appId}/${tabId}`;
        return this.http.post(url, null).toPromise();
    }

    public async updateRecordIndexing(appId: string, tabId: string, property: string, values: string[]): Promise<HttpResponse<any>> {
        const url = `${UPDATE_RECORD_BY_PROPERTY}/${appId}/${tabId}/updateIndexing`;
        const data = { column: property, values };
        return this.http.post<any>(url, data, { observe: 'response' }).toPromise();
    }
    public save(appId: string, tabId: string, data: any, id?: string): Observable<any> {
        let url = `/api/es/details/${UPDATE_COL_SETTINGS_URL}${appId}/${tabId}`;
        if (!!id) {
            url += `/${id}`;
        }
        return this.http.post(url, data);
    }
}
