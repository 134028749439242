import { FacetValue, IDRFilter } from '@discoverer/core/services'

export const FILTERING_MODES = {
    ALL: 'All',
    ANY: 'Any',
    WHERE: 'Where'
};

export const FILTERING_OPR_DICTIONARY = {
    NOT_SELECTED: 'NOT_SELECTED',
    NOT_EQUALS: 'NOT_EQUALS',
    EQUALS: 'EQUALS',
    CONTAINS: 'CONTAINS',
    STARTS_WITH: 'STARTS_WITH',
    REGEX_MATCH: 'REGEX_MATCH',
    LESS_THAN: 'LESS_THAN',
    MORE_THAN: 'MORE_THAN',
    EQUALS_LESS_THAN: 'EQUALS_LESS_THAN',
    EQUALS_MORE_THAN: 'EQUALS_MORE_THAN',
    DATE_ON: 'DATE_ON',
    DATE_AFTER: 'DATE_AFTER',
    DATE_ON_AFTER: 'DATE_ON_AFTER',
    DATE_BEFORE: 'DATE_BEFORE',
    DATE_ON_BEFORE: 'DATE_ON_BEFORE',
    DATE_BLANK: 'DATE_BLANK',
    DATE_NOT_BLANK: 'DATE_NOT_BLANK',
    NUMERIC_BLANK: 'NUMERIC_BLANK',
    NUMERIC_NOT_BLANK: 'NUMERIC_NOT_BLANK',
    DATE_HIGH_VALUE: 'DATE_HIGH_VALUE',
    DATE_NOT_HIGH_VALUE: 'DATE_NOT_HIGH_VALUE',
    IS_BETWEEN:'IS_BETWEEN'
};

export const FIELDS_TYPES = {
    ALL: ['text', 'numeric', 'boolean'],
    TEXT: 'text',
    NUMERIC: 'numeric',
    BOOLEAN: 'boolean',
    DATE: 'date'
};


export const DATE_OPR_DICTIONARY = {
    THE_DATE: 'THE_DATE',
    DAYS_IN_PAST: 'DAYS_IN_PAST',
    DAYS_IN_FUTURE: 'DAYS_IN_FUTURE',
    MONTHS_IN_PAST: 'MONTHS_IN_PAST',
    MONTHS_IN_FUTURE: 'MONTHS_IN_FUTURE',
    YEARS_IN_PAST: 'YEARS_IN_PAST',
    YEARS_IN_FUTURE: 'YEARS_IN_FUTURE'
};

const DATE_RANGE_ALLOWED_OPERATIONS = [
    FILTERING_OPR_DICTIONARY.DATE_ON,
    FILTERING_OPR_DICTIONARY.DATE_AFTER,
    FILTERING_OPR_DICTIONARY.DATE_BEFORE,
    FILTERING_OPR_DICTIONARY.DATE_ON_AFTER,
    FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE
];
export const FUTURE_FILTERING_OPR_DICTIONARY = [
    FILTERING_OPR_DICTIONARY.DATE_ON,
    FILTERING_OPR_DICTIONARY.DATE_AFTER,
    FILTERING_OPR_DICTIONARY.DATE_ON_AFTER
];
export const LESS_FILTERING_OPR_DICTIONARY = [
    FILTERING_OPR_DICTIONARY.LESS_THAN,
    FILTERING_OPR_DICTIONARY.EQUALS_LESS_THAN
];

export const DATE_OPERATION: IDateOperation[] = [
    { id: 'THE_DATE', label: 'The Date', allowedTypes: DATE_RANGE_ALLOWED_OPERATIONS },
    {
        id: 'DAYS_IN_PAST', label: 'Days in the past', expression: '-', filteringOn: 'DAYS',
        allowedTypes: DATE_RANGE_ALLOWED_OPERATIONS
    },
    {
        id: 'DAYS_IN_FUTURE', label: 'Days in the future', expression: '+', filteringOn: 'DAYS',
        allowedTypes: DATE_RANGE_ALLOWED_OPERATIONS
    },
    {
        id: 'MONTHS_IN_PAST', label: 'Months in the past', expression: '-', filteringOn: 'MONTHS',
        allowedTypes: DATE_RANGE_ALLOWED_OPERATIONS
    },
    {
        id: 'MONTHS_IN_FUTURE', label: 'Months in the future', expression: '+', filteringOn: 'MONTHS',
        allowedTypes: DATE_RANGE_ALLOWED_OPERATIONS
    },
    {
        id: 'YEARS_IN_PAST', label: 'Years in the past', expression: '-', filteringOn: 'YEARS',
        allowedTypes: DATE_RANGE_ALLOWED_OPERATIONS
    },
    {
        id: 'YEARS_IN_FUTURE', label: 'Years in the future', expression: '+', filteringOn: 'YEARS',
        allowedTypes: DATE_RANGE_ALLOWED_OPERATIONS
    }
];

export const FILTERING_OPERATION: IFilterOperation[] = [
    { id: '', label: '--- Filter By ---', allowedTypes: FIELDS_TYPES.ALL },
    { id: 'EQUALS', label: 'equal to', allowedTypes: FIELDS_TYPES.ALL },
    { id: 'NOT_EQUALS', label: 'not equal to', allowedTypes: FIELDS_TYPES.ALL },
    { id: 'CONTAINS', label: 'contains', allowedTypes: [FIELDS_TYPES.TEXT] },
    { id: 'STARTS_WITH', label: 'starts with', allowedTypes: [FIELDS_TYPES.TEXT] },
    { id: 'REGEX_MATCH', label: 'regex match', allowedTypes: [FIELDS_TYPES.TEXT] },
    { id: 'LESS_THAN', label: 'less than', allowedTypes: [FIELDS_TYPES.NUMERIC],character:"<" },
    { id: 'MORE_THAN', label: 'more than', allowedTypes: [FIELDS_TYPES.NUMERIC],character:">" },
    { id: 'EQUALS_LESS_THAN', label: 'less than or equals', allowedTypes: [FIELDS_TYPES.NUMERIC],character:"≤" },
    { id: 'EQUALS_MORE_THAN', label: 'more than or equals', allowedTypes: [FIELDS_TYPES.NUMERIC],character:"≥" },
    { id: 'NUMERIC_BLANK', label: 'blank', allowedTypes: [FIELDS_TYPES.NUMERIC] },
    { id: 'NUMERIC_NOT_BLANK', label: 'not blank', allowedTypes: [FIELDS_TYPES.NUMERIC] },
    { id: 'DATE_ON', label: 'on', allowedTypes: [FIELDS_TYPES.DATE] },
    { id: 'DATE_AFTER', label: 'after', allowedTypes: [FIELDS_TYPES.DATE] },
    { id: 'DATE_ON_AFTER', label: 'on or after', allowedTypes: [FIELDS_TYPES.DATE] },
    { id: 'DATE_BEFORE', label: 'before', allowedTypes: [FIELDS_TYPES.DATE] },
    { id: 'DATE_ON_BEFORE', label: 'on or before', allowedTypes: [FIELDS_TYPES.DATE] },
    { id: 'DATE_BLANK', label: 'blank', allowedTypes: [FIELDS_TYPES.DATE] },
    { id: 'DATE_NOT_BLANK', label: 'not blank', allowedTypes: [FIELDS_TYPES.DATE] },
    { id: 'DATE_HIGH_VALUE', label: 'high value', allowedTypes: [FIELDS_TYPES.DATE] },
    { id: 'DATE_NOT_HIGH_VALUE', label: 'not high value', allowedTypes: [FIELDS_TYPES.DATE] },
    { id:'IS_BETWEEN',label:'between',allowedTypes:[FIELDS_TYPES.NUMERIC]}
];

export const FILTER_CHANGES = {
    NO_CHANGE: 'NO_CHANGE',
    VALUE_A: 'VALUE_A',
    VALUE_B: 'VALUE_B',
    OPERATION_A: 'OPERATION_A',
    OPERATION_B: 'OPERATION_B',
    FIELD: 'FIELD'
};

export interface IFilterOperation {
    id: string;
    label: string;
    allowedTypes: string[];
    expression?: string;
    character?:string;
}

export interface IDateOperation extends IFilterOperation {
    filteringOn?: string;
}

export interface IDisplayFilter extends IDRFilter {
    id: string;
    operationA: string;
    valueA: string;
    valueB?:string
    fromOperatorValue?:string;
    toOperatorValue?:string;
    operationB?: string;
    isValid: boolean;
    isDynamicFacet?: boolean;
    optionListId?: number;
    tableName?: string;
    displayA?: string;
}
