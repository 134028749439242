import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AppSettingsService, BaseController, DiscovererQueryService, IDirRequest, IDynamicReport, ITab2, Permission, ReportPersistService, RequestFilingService, TabSettingsService, UserPermissionService } from '@discoverer/core/services';
import { ViewTypes } from '../common';
import { combineLatest } from 'rxjs';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WorkspaceComponent } from '../workspace/workspace.component';
import { AuthService } from '@discoverer/app-core';
import { first, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-new-report',
    templateUrl: './new-report.component.html',
    styleUrls: ['./new-report.component.scss']
})
export class NewReportComponent implements OnInit {
    public tab: ITab2;
    public reqId: string;
    public loading = true;
    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) public data: { tabId: string, reportId: string },
        @Optional() public dialogRef: MatDialogRef<any>,
        private _appSettings: AppSettingsService,
        private _authService: AuthService,
        private _reportPersist: ReportPersistService,
        private _snackBar: MatSnackBar,
        private _userPermissionService: UserPermissionService,
        private _requestFilingService: RequestFilingService,
        public queryService: DiscovererQueryService) {
    }

    async ngOnInit() {
        try {
            this.tab = (await this._appSettings.getCurrentAppTabs()).find(tab => tab.key === this.data.tabId);
            this.reqId = (this.tab && !this.data.reportId) ? await this._generateNewReport() : this.data.reportId
        } catch (error) {
            this._openSnackBar(error.message);
            console.error('Error',error)
        } finally {
            this.loading = false;
        }
    }
    public async addToDashboard(reportPersist: ReportPersistService) {
        const { dirId, userId, mainTitle } = reportPersist.mainViewState.getState();
        if (!mainTitle) {
            this._openSnackBar("Please Select a Valid Report Name");
            return;
        }
        const canEditReport = await this._hasPermission(Permission.EditReport, `dirId:${dirId}/requestOwnerUserId:${userId}/requestId:${this.data.reportId}`);
        if (!canEditReport) {
            this._openSnackBar("Edit Permission Denied");
            return;
        }
        try {
            this.loading = true;
            const requestId = await reportPersist.createRequest();
            await this._requestFilingService.setRequestDirectory(reportPersist.loadReqId, mainTitle, -1, this.tab.app.key, this.tab.key);
            await reportPersist.persistRequest(!!this.data.reportId, false);
            this.dialogRef.close({ tab: this.tab.key, newReqId: requestId, reportMainTitle: mainTitle });
        } catch (error) {
            this._openSnackBar(error.message);
            console.error('Error',error)
        } finally {
            this.loading = false;
        }
    }
    public close() {
        this.dialogRef.close();
    }

    private _openSnackBar(msg: string) {
        this._snackBar.open(msg, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }
    private async _hasPermission(permissionKey: string, resourceKey?: string): Promise<boolean> {
        return await this._userPermissionService.hasPermission(this.tab.key, permissionKey, resourceKey);
    }
    private async _generateNewReport(): Promise<string> {
        const { defaultRequestId } = this.tab;
        const defaultReq: IDynamicReport = await this._reportPersist.getRequest(defaultRequestId);
        const newRequestId = (await this._reportPersist.createLocalRequest());
        defaultReq.mainView.type = ViewTypes.Chart;
        defaultReq.mainView.dirId = null;
        defaultReq.mainView.userId = (await this._authService.gatewayIdentityUserObservable.pipe(take(1)).toPromise()).userId;
        await this._reportPersist.updateRequest(newRequestId, defaultReq, true);
        return newRequestId;
    }
}
