import {
    Injectable
} from '@angular/core';
import {
    DefaultPivotLimit,
    DiscovererDataService,
    ICategories,
    IChartDataConfig,
    IChartFormatConfig,
    IChartState,
    IColumnSetting,
    NUMBER_OF_ROWS_METRIC,
    StateService,
    getNearestValueToGap,
    mapFunctionName,
    roundUpToHighestDigit
} from '@discoverer/core/services';
import {
    ChartSort,
    KendoChartControlState
} from './chart-control.model';
import {
    debounceTime,
    take,
    takeUntil,
} from 'rxjs/operators';
import {
    CategoryConfigurationService,
    Commands,
    GenericChartControlService
} from './chart-control.service';
import {
    CHART_TYPES,
    ChartAxis,
    ChartControlAxis,
    IChartType
} from '@discoverer/dynamic-reports/models';
import {
    SeriesLimit
} from '@discoverer/dynamic-reports/customize-report';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class KendoChartControlService extends GenericChartControlService<KendoChartControlState> {
    protected categoryConfiguration$: CategoryConfigurationService<IChartState>;

    protected groupByDataService$: DiscovererDataService<any>;

    constructor(
        private snackBar: MatSnackBar
    ) { super(new KendoChartControlState()) }

    public async setService(chartState: StateService<IChartState>, columnDictionary: { [key: string]: IColumnSetting }) {
        this.categoryConfiguration$.setChartService(chartState);
        const state = await chartState.oState.pipe(take(1)).toPromise();
        this.configStateHandler(state, columnDictionary);
    }
    public showMsg(msg: string) {
        this.snackBar.open(msg, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
    }

    protected whenFormInputChangesSendQuery() {
        this.command$
            .pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe(async (v) => {
                if (v == Commands.Update) {

                    const formSnapshot = this.formState$.getState();
                    const { isInputValid, invalidMsg } = this.validateFormState(formSnapshot);
                    if (isInputValid) {
                        const service = (await this.categoryConfiguration$.oQueryService.pipe(take(1)).toPromise()).service;
                        let state = service.getState();
                        let { chartType, groupBys, sort, summarizeBy, countSubRecords, isLogarithmScale } = formSnapshot;
                        if (!state?.chartConfig || !state?.chartFormat) {
                            state = {
                                chartConfig: {} as IChartDataConfig,
                                chartFormat: {} as IChartFormatConfig
                            }
                        }
                        groupBys?.forEach(x => x?.minMaxSubscription?.unsubscribe())
                        state.chartConfig
                        state.chartConfig.metrics = summarizeBy;
                        state.chartConfig.categories = groupBys?.length > 0
                            ? { ...groupBys[0], ...sort }
                            : this._getDefaultFacet();
                        state.chartConfig.series = (groupBys || [])[1];
                        state.chartConfig.countSubRecords = countSubRecords;
                        state.chartFormat.isLogarithmScale = isLogarithmScale;
                        state.chartFormat.categories = (groupBys || [])[0]?.display;
                        state.chartFormat.series = (groupBys || [])[1]?.display;
                        state = this._checkChartType(state, chartType);
                        service.setValue(state);
                    } else {
                        this.showMsg(`Invalid Chart Setup: ${invalidMsg}`);
                    }
                } else if (v == Commands.Reset) {

                } else if (v == Commands.UpdateChartType) {
                    const service = (await this.categoryConfiguration$.oQueryService.pipe(take(1)).toPromise()).service;
                    let state = service.getState();
                    const formSnapshot = this.formState$.getState();
                    state = this._checkChartType(state, formSnapshot['chartType']);
                    service.setValue(state);

                }
            });

    }

    private _checkChartType(state: IChartState, chartType: IChartType): IChartState {
        const oldChartType = CHART_TYPES.find(ct => ct?.id === state?.chartFormat?.chartId);
        const isChartDirectionChange = (oldChartType?.isHorizontal !== chartType?.isHorizontal);
        state.chartFormat.chartId = chartType?.id;
        state.chartFormat.chartType = chartType?.type;
        state.chartFormat.isStack = chartType?.isStack;
        state.chartConfig.type = chartType?.type;
        if (chartType.type === 'pie') {
            state.chartConfig.series = null;
            this.clearGroupBy(1);
        }
        if (isChartDirectionChange) {
            if (!!state.chartConfig.categories && !!state.chartConfig.series) {
                [state.chartConfig.series.limit, state.chartConfig.categories.limit] = [state.chartConfig.categories.limit, state.chartConfig.series.limit];
            }
        }
        return state;
    }
    protected validateFormState(formState: KendoChartControlState): { isInputValid: boolean, invalidMsg: string } {
        let isInputValid = true;
        let invalidMsg = "";
        const groupBys = formState['groupBys'];
        if (groupBys?.length > 0) {
            groupBys.forEach(group => {
                if (!group.colName) {
                    isInputValid = false;
                    invalidMsg = "Please Fill all Groups Fields";
                }
            })
        }
        formState['summarizeBy']?.forEach(summarizeBy => {
            if (!summarizeBy?.colName || !summarizeBy?.functionType) {
                isInputValid = false;
                invalidMsg = "Please Fill all Summarize Fields";
            }
        });
        return { isInputValid, invalidMsg };
    }
    protected configStateHandler(config: IChartState, columnDictionary: { [key: string]: IColumnSetting }) {
        const
            type = config?.chartConfig?.type,
            isStack = config?.chartFormat?.isStack;
        const chartType = CHART_TYPES.find(chart => chart?.type == type && chart?.isStack === isStack) || CHART_TYPES[0];
        const dimension = this._getDimensionFromConfig(config.chartConfig, columnDictionary);
        const seriesList = this._getSeriesListFromConfig(config.chartConfig, columnDictionary);
        const metrics = this._getMetricFromConfig(config.chartConfig, columnDictionary);
        const sort = config.chartConfig?.categories ? this._getSortFromConfig(config?.chartConfig) : { sortType: null, sortDir: null, seriesSortDir: null };
        this.updateState('chartType', chartType)
        this.updateState('summarizeBy', metrics);
        this.updateState('countSubRecords', config?.chartConfig?.countSubRecords);
        this.updateState('isLogarithmScale', config?.chartFormat?.isLogarithmScale);
        this.setCategoriesFields(dimension, 0);

        seriesList.forEach((s, i) => this.setCategoriesFields(s, i + 1))
        this.setSort(sort);
    }

    public onDestroy() {
        this.groupByDataService$?.destroy()
    }

    public setgroupByDataService(groupByDataService: DiscovererDataService<any>) {
        this.groupByDataService$ = groupByDataService;
    }

    public moveGroupByDown(i: number) {
        const groupByList = this.formState$.getState().groupBys
        if (i < groupByList.length - 1) {
            [groupByList[i], groupByList[i + 1]] = [groupByList[i + 1], groupByList[i]];
            this.updateState('groupBys', groupByList);
        }
    }

    public moveGroupByUp(i: number) {
        const groupByList = this.formState$.getState().groupBys
        if (i > 0) {
            [groupByList[i], groupByList[i - 1]] = [groupByList[i - 1], groupByList[i]];
            this.updateState('groupBys', groupByList);
        }
    }

    public setSort(sortConfig: ChartSort) {
        sortConfig.subTitle = this._setSortingSubTitle(sortConfig)
        this.updateState('sort', sortConfig);
    }

    public clearGroupBy(i: number) {
        const groupByList = this.formState$.getState().groupBys;
        groupByList.splice(i, 1);
        this.updateState('groupBys', groupByList);
    }
    public clearSummarizeBy(i: number) {
        const summarizeByList = this.formState$.getState().summarizeBy;
        summarizeByList.splice(i, 1);
        if (summarizeByList.length === 0) { }
        const list = summarizeByList.length > 0 ? summarizeByList : null;
        this.updateState('summarizeBy', list);
    }

    public setSummarizeByFields(metric: any, index: number) {
        const summarizeBy = this.formState$.getState().summarizeBy || [];
        metric.subTitle = this._summrizeBySubTitle(metric);
        if (summarizeBy[index]) {
            summarizeBy[index] = metric;
        } else {
            summarizeBy.push(metric);
        }
        this.updateState('summarizeBy', summarizeBy);
    }

    public setCategoriesFields(cat: ChartControlAxis, index: number) {
        let old = this.formState$.getState().groupBys || [];
        cat.subTitle = this._groupBySubtitle(cat);
        if (!!old[index]) {
            old[index] = cat;
        } else {
            old.push(cat);
        }
        this.updateState('groupBys', old);
    }

    public async prepareGroups(col: IColumnSetting, index: number, axisType: 'categories' | 'series' = 'categories') {

        const axis = new ChartControlAxis(axisType);
        axis.colName = col.fieldName;
        axis.display = col.display;
        axis.colType = col.type;

        if (col.type === 'text') {
            axis.availableGroups = null;
            axis.groupBy = null;
            this.setCategoriesFields(axis, index);
        } else {
            axis.availableGroups = this.getGroupByList(col);
            const groupByName = col.type === 'date' ? 'YEAR' : '1';
            this.setGroupby(axis, groupByName, 24, index);
        }
    }
    public async setGroupby(
        axis: ChartAxis,
        groupByName: string,
        groupByNumber: number = 24,
        index: number
    ) {
        axis.groupByName = groupByName;
        axis.numberOfGroups = groupByNumber;

        if (axis.colType === 'numeric') {
            this.isLoading$.next(true);
            axis.minMaxSubscription = await this._setGroupByMinMaxFacet(axis.colName, axis, groupByNumber, index);
        } else {
            axis.offset = 0;
            axis.compareOffset = 0;
            axis.groupBy = this.getGroupBy(axis.colType, groupByName, groupByNumber);
            this.setCategoriesFields(axis, index)
        }
    }

    public onGroupsChange(axis: ChartAxis, index) {
        axis.groupBy = this.getGroupBy(
            axis.colType,
            +axis?.groupBy?.gap || axis.groupByName,
            axis.numberOfGroups,
            +axis?.groupBy?.start || 0,
            +axis?.groupBy?.end || 0,
            axis.offset,
            axis.compareOffset,
            axis.compareType);
        this.setCategoriesFields(axis, index)
    }

    private async _setGroupByMinMaxFacet(
        fieldName: string,
        axis: ChartAxis,
        months: number,
        index: number
    ) {
        this.groupByDataService$.setDataIsRequested('All', true);
        this.groupByDataService$.resetStatFacet();
        this.groupByDataService$.setStat(fieldName, 'min');
        this.groupByDataService$.setStat(fieldName, 'max');
        await this.groupByDataService$.refresh();
        return this._getAxisGroupBySubsription(axis, months, index);
    }

    private _getAxisGroupBySubsription(axis: ChartAxis, months, index: number) {
        const sub = this.groupByDataService$.oFacetResults.pipe(takeUntil(this.destroy$)).subscribe((facets) => {
            const facet = facets.find((x) => x.field === 'All');
            if (facet && (facet?.getKeyMap())[axis.colName]) {
                axis.groupBy = { start: null, end: null, gap: null, orginalGap: null };
                const min: number = +facet.getValueStringState('stat1') || 0;
                const max: number = +facet.getValueStringState('stat2') || 10000;
                const gap = Math.ceil((max - min) / 10);
                const roundedGap = roundUpToHighestDigit(gap);
                axis.groupBy.gap = roundedGap.toString();
                const roundedMax = getNearestValueToGap(min, roundedGap);
                axis.groupBy = this.getGroupBy(
                    axis.colType,
                    +axis.groupBy.gap,
                    months,
                    roundedMax,
                    max
                );
                this.setCategoriesFields(axis, index);
                this.isLoading$.next(false);
            }
        })
        return sub
    }

    private _summrizeBySubTitle(metric) {
        let subTitle = ''
        if (metric.colName === NUMBER_OF_ROWS_METRIC.fieldName) {
            subTitle = NUMBER_OF_ROWS_METRIC.display +
                ' (' + mapFunctionName(metric.functionType) + '.)';
        } else {
            subTitle = metric.display +
                ' (' + mapFunctionName(metric.functionType) + '.)';
        }
        return subTitle;
    }

    private _groupBySubtitle(groupBy: ChartControlAxis) {
        let seriesGap = ''
        if (groupBy?.colType === 'date' && !!groupBy?.groupBy) {
            seriesGap = `${seriesGap}${groupBy.display} (${this._mapFromGap(groupBy.groupBy.orginalGap)}.)`;
        } else if (groupBy.colType === 'numeric' && !!groupBy.groupBy) {
            seriesGap = `${seriesGap}${groupBy.display} (${groupBy.groupBy.orginalGap})`;
        } else {
            seriesGap = `${seriesGap}${groupBy.display}`;
        }
        return seriesGap;

    }

    private _setSortingSubTitle(sort: ChartSort): string {
        const { sortType, sortDir, seriesSort, seriesSortDir } = sort;
        return `${sortType || '--'} (${sortDir || '--'}), Series: ${seriesSort || '--'} (${seriesSortDir || '--'})`;
    }

    private _mapFromGap(functionType: string) {
        switch (functionType) {
            case 'QUARTER':
                return 'Quarter';
            case 'YEAR':
                return 'Year';
            case 'MONTH':
                return 'Month';
            case 'WEEK':
                return 'Week';
            case 'DAY':
                return 'Day';
            case 'DAILY WEEK':
                return 'Daily Week'
            case 'DAILY MONTH':
                return 'Daily Month';
        }
    }

    async setSubChartType(chart: IChartType) {
        this.updateState('chartType', chart);
    }

    private _getDimensionFromConfig(config, columnDictionary) {
        let dimension = new ChartControlAxis('categories');
        const defaultPivotLimit = DefaultPivotLimit;
        dimension.limit = defaultPivotLimit;
        dimension.sortType = 'label';
        if (config?.categories) {
            const { categories } = config;
            const { colName, groupBy, sortDir, sortType, limit, minCount } = categories;
            dimension = {
                ...dimension, colName, groupBy, sortDir, sortType, limit, minCount,
                availableGroups: this.getGroupByList(columnDictionary[colName]),
                colType: columnDictionary[colName].type,
                numberOfGroups: groupBy?.numberOfGroup,
                compareOffset: groupBy?.compareOffse,
                offset: groupBy?.offse,
                groupByName: groupBy?.orginalGap,
                compareType: groupBy?.compareType,
                display: columnDictionary[colName]?.display
            }
        }

        return dimension;
    }

    private _getMetricFromConfig(config, columnDictionary) {
        return config?.metrics?.map(metric => {
            metric.display = columnDictionary[metric.colName]?.display || metric.colName;
            metric.subTitle = this._summrizeBySubTitle(metric);
            return metric;
        })
    }

    private _getSeriesListFromConfig(config, columnDictionary) {
        if (!!config?.series) {
            let series = new ChartControlAxis('series')
            let sf = config?.series;
            series.colName = sf.colName;
            series.groupBy = sf?.groupBy;
            series.sortDir = sf.sortDir || 'asc';
            series.limit = sf.limit ?? SeriesLimit;
            series.minCount = sf.minCount;
            series.index = 0;
            series.colType = columnDictionary[series.colName].type;
            series.availableGroups = this.getGroupByList(columnDictionary[series.colName]);
            series.numberOfGroups = sf?.groupBy?.numberOfGroups;
            series.groupByName = sf?.groupBy?.orginalGap;
            series.display = columnDictionary[sf.colName]?.display
            return [series];
        }
        return [];
    }

    private _getSortFromConfig(config) {
        const { sortType, sortDir, seriesSortDir } = config?.categories;
        return { sortType, sortDir, seriesSortDir };
    }
    private _getDefaultFacet(): ICategories {
        return {
            colName: 'DefaultColumn',
            groupBy: null,
            sortDir: 'asc',
            sortType: 'label',
            limit: 1,
            seriesSortDir: 'asc',
            seriesSort: 'series'
        }
    }
}
