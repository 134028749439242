import { SortDescriptor } from '@progress/kendo-data-query';
import { GroupBy } from './chart-groupby.class';
import { IColumnSetting } from './discoverer.class';

export class ChartConfig {
    title: string;
    dimension: string;
    metric?: string;
    view?: string;
    type: string;
    datasets?: string[] = [];
}


export interface IColumn {
    fieldName: string;
    display: string;
    format?: string;
    type: string;
    dataType?: string;
}

export interface IChartFormatConfig {
    chartTitle: string;
    series: string;
    categories: string;
    metrics: string[];
    tooltipFormat: string;
    isStack: boolean;
    chartId: number;
    isLogarithmScale: boolean;
    chartType: string
}

export interface IChartResults {
    categories: any[];
    series: any[];
    values: Array<{ name: string, stat: string, type: string, values: number[], axis: string, dataType?: string }>;
    categoriesDataType?: string;
    totals?: ChartTotal[]
}

export interface ICategories {
    colName: string;
    colType?: string;
    groupBy?: GroupBy;
    sortDir: ('asc' | 'desc');
    sortType?: ('label' | 'value');
    seriesSort?: string;
    seriesSortDir?: ('asc' | 'desc');
    limit: number;
    minCount?: number,
    width?: number
}

export interface IMetric {
    colName: string;
    functionType: 'count' | 'sum' | 'avg' | 'min' | 'max' | 'stddev' | 'sumsq';
    overrideField?: string;
    label?: string;
    display?: string;
}

export interface IChartDataConfig {
    categories?: ICategories;
    series?: ICategories;
    metrics?: IMetric[];
    type?: string;
    countSubRecords: boolean;
}

export interface IBucket {
    key: string;
    stat1: number;
    value?: number;
    buckets: IBucket[];
}
export interface IChartTable {
    columns: string[];
    groups: string[];
    sort: SortDescriptor[];
}

export interface IPivotChartResultColumn extends IColumnSetting {
     field: string;
     title: { val1: string, val2: string };
     format?: string;
     category?: { val1: string, val2: string };
     fieldVal1: string;
     fieldVal2?: string;
     fieldCompare?: string;
     locked?: boolean;
     width: number;
     isGroupBy: boolean;
     isTotalColumn: boolean;
}

export interface IPivotChartResult {
    result: any[];
    columns: IPivotChartResultColumn[];
    footer: any;
    // groups: any;
    // flatData: any[];
}
export interface IPivotConfig {
    categories?: ICategories;
    subFacets?: ICategories[];
    metric?: IMetric;
    groups?: any;
    display?: string;
}

export class ChartTotal {
    metric: ChartMetric;
    total: number;
}
export class ChartMetric {
    name: string;
    col: string;
    functionType: "count" | "sum" | "avg" | "min" | "max" | "stddev" | "sumsq";
}
