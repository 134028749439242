<div id="advanced-modal">
  <div class="header color-primary-bg">
    <div> Advanced Filter</div>
    <mat-icon (click)="close()">close</mat-icon>
  </div>

  <div class="main">
    <advanced-filters *ngIf="tabSettings && queryService" [mqService]="queryService" [tabSettings]="tabSettings"
      [advancedFilterMode]="filterMode" (invalidFilterValue)="checkValidateAdvancedFilter($event)">
    </advanced-filters>
  </div>

  <div class="footer">
    <button mat-flat-button class="submit rounded" color="accent" (click)="save()">Done</button>
    <button mat-stroked-button class="cancel rounded" (click)="clearAdvancedFilter()">Clear</button>
  </div>

</div>
