import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IColumnSetting, TabSettingsService } from '@discoverer/core';
import { FieldArrayType } from '@ngx-formly/core';
import { AddEvent, CellClickEvent, GridComponent, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';

const ColumnTypeToKendoMapper = {
    'boolean': 'boolean',
    'string': 'text',
    'text': 'text',
    'html': 'text',
    'link': 'text',
    'link_website': 'text',
    'string_array': 'text',
    'string_set': 'text',
    'icon': 'text',
    'phone': 'text',
    'int': 'numeric',
    'integer': 'numeric',
    'double': 'numeric',
    'currency': 'numeric',
    'percent': 'numeric',
    'number': 'numeric',
    'date': 'date',
    'timestamp': 'date',
    'timestamptz': 'date'
};
@Component({
    selector: 'dyn-table',
    templateUrl: './dyn-table.component.html',
    styleUrls: ['./dyn-table.component.scss']
})
export class DynTableComponent extends FieldArrayType implements OnInit {
    public nestedColumns: IColumnSetting[] = [];
    public tableColumns: { display: string, fieldName: string, type: 'boolean' | 'text' | 'numeric' | 'date' }[] = [];
    public formGroup!: FormGroup;
    public editedRowIndex: number;
    public model: any[] = [];

    @ViewChild('grid', { static: true }) grid!: GridComponent;

    constructor(
        private tabSettingsService: TabSettingsService,
        private formBuilder: FormBuilder,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        await this._loadColumns();
        this._addEmptyLine();
    }

    private async _loadColumns(): Promise<void> {
        if (!!this.field.expressionProperties && !!this.field.expressionProperties.useStaticColumns) {
            this.tableColumns = this.field.fieldGroup[0]?.fieldGroup.map(field => ({
                display: field.templateOptions.label,
                fieldName: field.key.toString(),
                type: this._mapColumnTypeToKenodType(field.templateOptions.type)
            }));
        } else {
            const fieldLabel = this.to.label || this.field.parent.templateOptions.label;
            const columns = await this.tabSettingsService.getAllColumns();
            this.nestedColumns = columns.filter(col => col.fieldName.startsWith(`${this.field.key}.`));
            this.tableColumns = this.nestedColumns.map(col => ({
                display: col.display.replace(`${fieldLabel} - `, ''),
                fieldName: col.fieldName.replace(`${this.field.key}.`, ''),
                type: this._mapColumnTypeToKenodType(col.dataType)
            }));
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (this.editedRowIndex !== undefined && !this._isClickWithinCurrentEditRow(event)) {
            const inputs = this.grid.wrapper.nativeElement.querySelector(`tr.k-grid-edit-row`).querySelectorAll('input, select, textarea');
            const oldRow = this.model[this.editedRowIndex]
            const currentRowData = { ...oldRow };
            inputs.forEach((input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
                const columnName = this.tableColumns[Array.from(inputs).indexOf(input)]?.fieldName;
                if (columnName && oldRow.hasOwnProperty(columnName)) currentRowData[columnName] = input.value;
            });
            this.saveHandler({ sender: this.grid, rowIndex: this.editedRowIndex, formGroup: this.createFormGroup(currentRowData) } as SaveEvent);
        }
    }

    private _isClickWithinCurrentEditRow(event: MouseEvent): boolean {
        const clickTarget = event.target as HTMLElement;
        return !!clickTarget.closest('.k-grid-edit-row') || !!clickTarget.closest('.k-edit-cell, .k-grid-edit-command');
    }

    public removeHandler(args: RemoveEvent): void {
        const formArray = this._getNestedFormArray(this.form.controls, '.', this.field.key.toString());
        formArray.removeAt(args.rowIndex);
        this.model.splice(args.rowIndex, 1);
    }

    private _addModelRow(args: any): void {
        const { formGroup, rowIndex } = args;
        const { value } = formGroup;
        this.model[rowIndex] = value;
        this._updateModel();
    }

    private _updateModelRow(args: any): void {
        const { formGroup, rowIndex } = args;
        const formArray = this._getNestedFormArray(this.form.controls, '.', this.field.key.toString());
        const rowControl = formArray.controls[rowIndex];
        const { value } = formGroup;
        this.model[rowIndex] = value;
        delete this.model[rowIndex][`${this.field.key}`.split('.')[0]]
        var innerRowControl = this._getNestedFormArray((rowControl as FormGroup).controls, '.', this.field.key.toString());
        innerRowControl?.setValue(JSON.parse(JSON.stringify(value)));
        delete this.model[rowIndex][`${this.field.key}`.split('.')[0]] // this is a hack innerRowControl.setValue(value); add a nested object to model
        this._updateModel();
    }

    public cellClickHandler(args: CellClickEvent): void {
        if (!args.isEdited) {
            this._closeEditor(args.sender);
            this.editedRowIndex = args.rowIndex;
            args.sender.editRow(args.rowIndex, this.createFormGroup(this.model[args.rowIndex]));
        }
    }

    public onTabKeyDown(event: KeyboardEvent): void {

        if (event.key !== 'Tab') return;

        event.preventDefault();

        const currentCell = this.grid.activeCell;
        if (!currentCell) return;

        const { rowIndex, colIndex } = currentCell;


        this.grid.closeCell();

        let nextRowIndex = rowIndex - 1;
        let nextColumnIndex = colIndex + 1;


        if (nextColumnIndex >= this.tableColumns.length) {
            nextRowIndex += 1;
            nextColumnIndex = 0;
        }

        if (nextRowIndex <= this.model.length) {
            var form = this.createFormGroup(this.model[nextRowIndex])
            this.grid.focusCell(nextRowIndex, nextColumnIndex)
            this.grid.editCell(nextRowIndex, nextColumnIndex, form);

        }

    }

    public addHandler(args: AddEvent): void {
        this._closeEditor(args.sender);
        args.sender.addRow(this.createFormGroup(this.model[args.rowIndex]));
    }

    public editHandler(args: any): void {
        this._closeEditor(args.sender);
        this.editedRowIndex = args.rowIndex;
        args.sender.editRow(args.rowIndex, this.createFormGroup(this.model[args.rowIndex]));
    }

    public saveHandler(args: SaveEvent): void {
        const isLastLine = (args.rowIndex === (this.model.length - 1))
        if (isLastLine) {
            this._addModelRow(args);
            this._addEmptyLine();
        } else {
            this._updateModelRow(args);
        }
        this.cdr.detectChanges();
        this._closeEditor(args.sender);
    }

    public createFormGroup(data: any): FormGroup {
        return this.formBuilder.group(data);
    }

    public trackByIndex(index: number, item: any): any {
        return index;
    }

    private _getNestedFormArray(
        rootFormControls: { [key: string]: AbstractControl } | AbstractControl,
        splitKey: string,
        fieldKey: string
    ): FormArray {
        const keys = fieldKey.split(splitKey);
        const currentKey = keys[0];
        const currentControl = rootFormControls[currentKey];
        if (keys.length === 1) {
            return currentControl as FormArray;
        }
        const remainingKey = keys.slice(1).join(splitKey);
        return this._getNestedFormArray(currentControl?.controls, splitKey, remainingKey);
    }

    private _addEmptyLine(): void {
        if (!this.model) {
            this.model = [];
        }
        const emptyLine = this._createEmptyLine();
        const formArray = this._getNestedFormArray(this.form.controls, '.', this.field.key.toString());
        formArray.push(this.formBuilder.group(emptyLine));
        this.model.push(emptyLine);
    }

    private _createEmptyLine(): any {
        return this.tableColumns.reduce((acc, key) => {
            acc[key.fieldName] = '';
            return acc;
        }, {} as any);
    }

    private _updateModel(): void {
        this.model = this.model.filter(row => Object.values(row).some(value => value !== ''));
    }

    public isLastRow(rowIndex: number): boolean {
        return Object.values(this.model[rowIndex]).every(value => value === "" || value === null || value === undefined);
    }

    private _mapColumnTypeToKenodType(dataType: string) {
        return ColumnTypeToKendoMapper[dataType] || 'text'
    }

    private _closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
        if (rowIndex !== undefined) {
            grid.closeRow(rowIndex);
            this.editedRowIndex = undefined;
        }
    }

    public exitEditMode(rowIndex: number): void {
        this._closeEditor(this.grid, rowIndex);
    }
}
