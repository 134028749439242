<div class="left-list-container">
    <div class="noselect filter-column ">
        <div class="filter-title">
            <div class="left-title">
                <mat-form-field class="search">
                    <input #searchField [autoFieldFocus]="searchField" placeholder="Lookup Field Name" matInput type="text"
                        [formControl]="searchFieldControl">
                    <mat-icon class="icon" *ngIf="!!searchTerm" matSuffix mat-icon-button (click)="clearSearch()">close</mat-icon>
                </mat-form-field>
            </div>
            <div class="right-title">
                <button mat-button (click)="showAdvancedModal()">ADVANCED</button>
                <button mat-icon-button [matMenuTriggerFor]="filterActions">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu xPosition="before" #filterActions="matMenu">
                    <button mat-menu-item (click)="openFilterSettingsDialog()">Edit Side Filters</button>
                </mat-menu>
            </div>
        </div>

        <facet-list [queryService]="queryService" [columns]="1" mode="side" [serviceUrl]="serviceUrl" [width]="width"
            [searchTerm]="searchTerm" (close)="close.emit()">
        </facet-list>
    </div>
</div>
