import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'formly-wrapper-panel',
    template: `
    <div class="card">
      <h3  [ngClass]="to?.fieldGroupClassName" class="card-header" *ngIf="to?.label" style="color: gray">{{ to.label }}</h3>
      <div class="card-body">
        <ng-container #fieldComponent></ng-container>
      </div>
    </div>
  `,
})
export class PanelWrapperComponent extends FieldWrapper {

}
