import {
  Component,
  Input, Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DiscovererDataService, NoDataRow } from '../services'
import { PageParams } from '../services/classes';
@Component({
  selector: 'pagination',
  styleUrls: ['./pagination.component.css'],
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input()
  pageSize = 20;

  @Input()
  currentRow = 1;

  @Input()
  dataService: DiscovererDataService<any> ;

  public total = 0;

  // @Input()
  // scrollArea: any;

  get currentPage(): number {
    return (this.currentRow - 1 / this.pageSize) + 1;
  }

  @Output()
  pageChange: EventEmitter<PageParams> = new EventEmitter<PageParams>();

  pageSeries: number[] = new Array<number>();

/*
  @HostListener('window:mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    // this.mouseWheelFunc(event);
  }

  @HostListener('window:DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    // this.mouseWheelFunc(event);
  }

  @HostListener('window:onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    // this.mouseWheelFunc(event);
  }

  mouseWheelFunc(event) {
    // Guess the delta.
    let delta = 0;
    if (event.wheelDelta) {
      delta = -1 * event.wheelDelta / 120;
    } else if (event.detail) {
      delta = event.detail / 3;
    }

    if (this.scrollArea != null && event.target != null) {
      if (!this.isDescendant(this.scrollArea, event.target)) {
        return false;
      } else {
        if (event.preventDefault) {
          event.preventDefault();
        }
      }
    }

    const newRow = (this.currentRow + ((delta > 0 ? Math.ceil(delta) : Math.floor(delta)) * this.pageSize));
    this.gotoRow(newRow);
  }

  isDescendant(parent, child): boolean {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }
*/

  public min(v: number, v2: number) {
    if (v < v2) {
      return v;
    } else {
      return v2;
    }
  }


  private gotoRow(row: number) {
    // set min
    if (row < 1) {
      row = 1;
    }

    // set max
    if (row > (this.total - this.pageSize + 1)) {
      row = (this.total - this.pageSize + 1);
    }

    this.pageChange.emit(new PageParams(row, this.pageSize));
    this.onPageChange( new PageParams(row, this.pageSize) );
  }

  public addToCurrent(val: number) {
    this.gotoRow(this.currentRow + (val * this.pageSize));
  }

  constructor() {
  }

  ngOnInit() {
    this.dataService.oResultLength.subscribe( result => {
      this.total = result;
    });
    this.dataService.baseQueryService.oQuery.subscribe( q => {
      if ( this.currentRow !== 1) {
        this.currentRow = 1;
        this.dataService.setPageStart(1, this.pageSize);
        this.dataService.refresh();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( ( !!changes['pageSize'] ) && !!this.dataService ) {
      this.dataService.setPageStart( this.currentRow, this.pageSize);
    }
  }

  public onPageChange(pageParams: PageParams) {
    this.dataService.setPageStart(pageParams.start , pageParams.pageSize );
    this.dataService.refresh();
    this.currentRow = pageParams.start;
  }
}

