import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BlocPatternServiceBase } from './bloc-pattern-service-base';
import { IOption } from '../models/option';
import { OptionDataServiceInterface } from './data-service';

@Injectable()
export class MutateOptionBloc extends BlocPatternServiceBase<{ option: IOption, operation: 'add' | 'update' | 'delete' | 'update-order' }, {
  result: string, message?: string, output?: IOption
}> {
  constructor(private dataService: OptionDataServiceInterface) {
    super();
    this._$input.pipe(switchMap(
      data => {
        let list;
        switch (data.operation) {
          case 'add':
            list = this.dataService.add(data.option);
            break;
          case 'update':
            list = this.dataService.update(data.option);
            break;
          case 'delete':
            list = this.dataService.delete(data.option);
            break;
          case 'update-order':
            list = this.dataService.updateOptionOrder(data.option);
            break;
        }
        return list;
      }
    )).pipe().subscribe(async (r: any) => {
      if (r && r.mutationResult?.errors) {
        let message = r.mutationResult.errors[0].message;
        if (message.includes('Uniqueness violation')) {
          message = 'Option with the same name already exists';
        }
        if (message.includes('Foreign key violation')) {
          message = 'This option is related to other records';
        }
        this._$output.next({ result: 'Error', message, output: { description: '', id: 0, optionListId: 0 } });
      }
      this._$output.next({ result: 'OK', output: (r.mutationResult) });
    });
  }
  public addOrUpdateOption(option: IOption) {
    if (!option.id || option.id === 0) {
      const optionClone = Object.assign({}, option);
      optionClone.id = null;
      this._$input.next({ option: optionClone, operation: 'add' });
    } else {
      this._$input.next({ option, operation: 'update' });
    }
  }
  public updateOptionOrder(option: IOption) {
    this._$input.next({ option, operation: 'update-order' });
  }

  public deleteOption(option: IOption) {
    this._$input.next({ option, operation: 'delete' });
  }
}

@Injectable()
export class GetOptionsBloc extends BlocPatternServiceBase<{ searchText?: string, optionListId: number }, { options: IOption[] }> {

  constructor(private dataService: OptionDataServiceInterface) {
    super();

    this.getAllOptions();
  }
  _searchText: string;
  _optionListId: number;
  public set(optionListId: number, searchText?: string) {
    this._searchText = searchText;
    this._optionListId = optionListId;
    this._$input.next({ optionListId: this._optionListId, searchText: this._searchText });
  }
  public refresh() {
    this._$input.next({ optionListId: this._optionListId, searchText: this._searchText });
  }
  private getAllOptions() {
    this._$input.pipe(switchMap(q =>
      this.dataService.getByOptionListId(this._optionListId, this._searchText))).subscribe((s: any) => {
        this._$output.next({ options: s });
      });
  }
}


