import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@discoverer/fuse/components/navigation';
import { Observable } from 'rxjs';

export interface Navigation
{
    items: FuseNavigationItem[];
    mainTitle: string;
}

@Injectable()
export abstract class AbstractNavigationItemsService {
     abstract getNavigationItems(): Observable<Navigation>;
     abstract loadItems(option): Promise<Navigation>;
}
