<div>
  <form class="custom-auto-complete" cdkScrollable>
    <mat-form-field class="auto-full-width">
      <mat-label *ngIf="!!label">{{label}}</mat-label>
      <input class="input-text" matInput type="text" [matAutocomplete]="auto" [formControl]="colCtrl">
      <mat-icon mat-icon-button (click)="clear()" class="close-button">clear</mat-icon>
      <mat-autocomplete panelWidth="auto" (opened)="handleOpen()" (closed)="handleClosed()" #auto="matAutocomplete"
        (optionSelected)="optionChange($event.option.value)">
        <mat-option *ngIf="showSelectField" [disabled]="readonly" [value]="''">--- Select a field ---
        </mat-option>
        <mat-option *ngFor="let col of filteredColumns | async" [value]="col.display">{{col.display}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</div>
