import { Component, OnInit, ViewChild } from '@angular/core';

import { FieldType } from '@ngx-formly/core';
import { FormControl, Validators } from '@angular/forms';
import { CREDIT_CARD_ERRORS } from './credit-card-number-validation';
import { CreditCardData } from '../shared/cc-data/card-data';
import { CCTypes } from '../shared/cc-types';
import { CreditCardNumberDirective } from '../shared/directives/credit-card-number.directive';
@Component({
    selector: 'credit-card-number',
    templateUrl: './credit-card.component.html'
})
export class CreditCardNComponent extends FieldType implements OnInit {
    public ccNumber = new FormControl('');

    public CreditCardErrors = CREDIT_CARD_ERRORS;
    public creditCardData = CreditCardData;
    public cardError: string;

    @ViewChild(CreditCardNumberDirective, { static: true })
    protected _creditCardNumberInput: CreditCardNumberDirective;

    public cardType: string;

    constructor() {
        super();
        this.cardError = CREDIT_CARD_ERRORS.NONE;
        this.cardType = CCTypes.UNKNOWN;
        // tslint:disable-next-line:no-unused-expression
        ccNumber: new FormControl('', Validators.required);

    }
    ngOnInit() {
        this.field.formControl.valueChanges.subscribe(value => {
            this.ccNumber.setValue(null);
        });
    }
    public get isValidCardNumber(): boolean {
        return this.cardType !== CCTypes.UNKNOWN && this._creditCardNumberInput.length > 0 && this.cardError === CREDIT_CARD_ERRORS.NONE;
    }

    public onCardType(type: CCTypes): void {
        if (type !== CCTypes.UNKNOWN) {
            this.cardType = type;
        }
    }

    public onCardError(evt: string): void {
        this.cardError = evt;
    }

}
