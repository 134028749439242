<div class="actions-area">
    <div *ngIf="showClearButton || showDoneButton" class="footer-upper-line">
        <mat-divider></mat-divider>
    </div>
    <div class="footer">
        <ng-container *ngIf="!showClearButton">
            &nbsp; <!-- this space to make the done button aligned to the right side if there's no clear button  -->
        </ng-container>
        <button *ngIf="showClearButton" (click)="clearButtonClicked.emit()" class="clear-button" mat-button color="primary">Clear</button>
        <button *ngIf="showDoneButton" (click)="doneButtonClicked.emit()" class="done-button" mat-flat-button>Done</button>
    </div>
</div>