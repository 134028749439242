import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DataFormattingService, TabSettingsService } from '@discoverer/core/services';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-disco-form',
  templateUrl: './disco-form.component.html',
  styleUrls: ['./disco-form.component.scss'],
})
export class DiscoFormComponent {
  @ViewChild('dynForm') dynForm: DynFormComponent;
  @Input() set data(value: any) {
    this._data = value;
    this.setDisplayData();
  }

  get data(): any {
    return this._data;
  }

  @Input()
  formKey: string;

  @Input()
  title;

  @Input()
  horizontalMode: boolean;

  @Input()
  autoSave;

  @Output() valueChanged = new EventEmitter();

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onSubmit = new EventEmitter();

  @Input()
  isEditMode = false;

  @Input()
  updatedOnKey: string;
  displayData: any = null;
  private _data: any = null;

  constructor(
    private tabSettings: TabSettingsService,
    private dataFormattingService: DataFormattingService,
  ) {
  }

  onSubmitted(event) {
    if (event && !this.autoSave) {
      this.isEditMode = false;
    }
    this.onSubmit.emit(event);
  }

  changed(event) {
    this.valueChanged.emit(event);
  }

  public print() {
    this.dynForm.print();
  }

  private async setDisplayData() {
    this.displayData = await this.formatData(this.data);
  }

  private async formatData(originalData: any): Promise<any> {
    const columnSettings = await this.tabSettings.oDefaultColumns.pipe(first()).toPromise();
    const formattedData = {};
    Object.keys(originalData).forEach(key => {
      const dataType = columnSettings.find(col => col.fieldName === key)?.dataType;
      formattedData[key] = this.dataFormattingService.getValue(originalData[key], dataType);
    });
    return formattedData;
  }

}
