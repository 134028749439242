import { Injectable } from "@angular/core";
import { FacetValue, FacetFieldAccumulator } from "../services";
import { BlocPatternServiceBase } from "../services/bloc/bloc-service-base";

@Injectable()
export class FacetBloc extends BlocPatternServiceBase<
  FacetData,
  { sortedFacets: FacetValue[]; filteredCount }
> {
  private _input = new FacetData();

  constructor() {
    super();

    this._$input.subscribe((s: FacetData) => {
      let sortedFacetValues = [];
      const allFilteredValues = s.searchText
        ? s.facetValues.filter((facet) =>
            facet.title.toLowerCase().includes(s.searchText.toLowerCase())
          )
        : s.facetValues;
      if (s.facetData != null) {
        const dictionary = {};
        if (s.selectedFacetValues && s.selectedFacetValues.length) {
          s.selectedFacetValues.forEach((f) => {
            dictionary[f.facetKey] = f;
          });
        }
        const sorted = allFilteredValues
          .sort(
            (a, b) =>
              s.facetData.getValue(b.facetKey) -
              s.facetData.getValue(a.facetKey)
          )
          .sort(
            (a, b) =>
              (!dictionary[a.facetKey] ? 1 : 0) -
              (!dictionary[b.facetKey] ? 1 : 0)
          )
          .slice(s.pageFrom, s.pageTo);
        sortedFacetValues = sorted.filter(
          (f) => s.facetData.getValue(f.facetKey) >= 0
        );
      } else {
        sortedFacetValues = allFilteredValues;
      }
      this._$output.next({
        input: s,
        output: {
          filteredCount: allFilteredValues.length,
          sortedFacets: sortedFacetValues,
        },
      });
    });
  }

  public setFacetInputs(input: FacetData) {
    Object.keys(input).forEach((key) => (this._input[key] = input[key]));
    this._$input.next(Object.assign({}, this._input));
  }
}

export class FacetData {
  pageFrom?: number;
  pageTo?: number;
  searchText?: string;
  facetValues?: FacetValue[];
  selectedFacetValues?: FacetValue[];
  facetData?: FacetFieldAccumulator;
}
