import { Component, Input, OnInit, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';

import {
    DiscovererDataService,
    DiscovererQueryService,
    BaseController,
    FILTERING_OPERATION,
    FILTERING_OPR_DICTIONARY,
    DATE_OPERATION,
    FIELDS_TYPES,
    DATE_OPR_DICTIONARY,
    ReportPersistService,
    IFacetDefinition
} from '../services';
import { Moment } from 'moment';
import { HttpClient } from '@angular/common/http';
import { EsQueryService } from '../services/core-data-services/discoverer-esquery-service';
import { LoadingState } from '../services/classes/loading-state';
import { DateRangeFilterService } from './services/date-range-filter.service';


@Component({
    // tslint:disable-next-line: component-selector
    selector: 'date-range-facet',
    templateUrl: './date-range-facet.component.html',
    styleUrls: ['./date-range-facet.component.scss'],
    providers: [DatePipe,
        { provide: DateRangeFilterService }
    ]
})
export class DateRangeFacetComponent extends BaseController implements OnInit, OnDestroy, OnChanges {

    @ViewChild('dateRangePicker') dateRangePicker;
    @Input()
    title: string;
    @Input()
    serviceUrl: string;
    @Input()
    facetKey: string;
    @Input()
    queryService: DiscovererQueryService;
    @Input()
    allKey: string;
    isRangeRelative: boolean = false;

    @Input()
    groupIndex: string;

    @Input()
    filterMode: string;

    @Input()
    isOpen: boolean;
    @Input() isCollapsible = true;

    @Input()
    clearButtonKey: boolean
    @Input() isPinned = false;
    @Input() showPin: boolean = false;
    @Input() showDoneButton: boolean = false;


    @Output() pinFilter: EventEmitter<IFacetDefinition> = new EventEmitter<IFacetDefinition>();
    @Output() doneButtonClicked: EventEmitter<any> = new EventEmitter<any>();

    loadingState: LoadingState;
    showClearButtonWhenCardIsNotOpen: boolean;
    public minRangeDate: Moment;
    private dataService: DiscovererDataService<any>;
    public minMaxLoaded = false;
    public FILTERING_OPERATION_BETWEEN = { id: 'IS_BETWEEN', label: 'is between', allowedTypes: FIELDS_TYPES.DATE };
    public DATE_FILTERING_OPERATION = FILTERING_OPERATION.filter(f => f.allowedTypes.includes(FIELDS_TYPES.DATE));
    public dateFilterOperation = FILTERING_OPR_DICTIONARY.DATE_ON_AFTER;
    public dateFilterOperationB = 'THE_DATE';
    public DATE_OPR_DICTIONARY = DATE_OPR_DICTIONARY;
    public DATE_OPERATION = DATE_OPERATION;
    public FILTERING_OPR_DICTIONARY = FILTERING_OPR_DICTIONARY;
    public selectedDate;
    
    constructor(
        private datePipe: DatePipe,
        private http: HttpClient,
        private esQueryService: EsQueryService,
        private cd: ChangeDetectorRef,
        private _reportPersistService: ReportPersistService,
        private dateRangeFilterService: DateRangeFilterService
    ) {
        super();
    }

    ngOnInit() {

        console.log("==== Date Rang =====")
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (
            !!changes["queryService"] ||
            !!changes["facetKey"] ||
            !!changes["isOpen"]
        ) {
            this.dateRangeFilterService.setQueryService(this.queryService);
            this.dateRangeFilterService.setFacetKey(this.facetKey);
          }
    }


    isFilterApplied() {
        return this.queryService?.filters?.find(filter => filter?.fields?.every(field => field === this.facetKey));
    }

    getFilterDisplay() {
        return this.queryService?.filters?.find(
            (filter) => filter?.fields?.every((field) => field === this.facetKey)
            )?.facetValues?.map((field) => field.title).join(', ');
    }
    public clearFilter() {
        this.dateRangeFilterService.resetState();
    }

    dateTitleClicked() {
        this.isOpen = !this.isOpen;

        if (!!this.dataService) {
            this.dataService.enabled = this.isOpen;
            if (this.isOpen) {
                this.dataService.refresh();
            }
        }
        this.cd.detectChanges();
    }
    public addPinnedFilter() {
        this.pinFilter.emit({ group: "0", isPinned: !this.isPinned, name: this.facetKey });
    }
}
