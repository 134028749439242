<disco-data [appId]="appId" [tabId]="tabId" [requestId]="requestId" [columnList]="[]" [start]="start" [size]="1"
    [queryService]="queryService" (context)="context.emit($event)">
    <ng-template let-context>
        <ng-container *ngIf="context?.data[0] as details" #pdfContainer>
            <div class="page-container">
                <!-- Header -->
                <div  *ngIf="showHeader"
                    class="header flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
                    <div class="flex-1 min-w-0">
                        <ng-container
                            *ngTemplateOutlet="headerTemplate || defaultHeader; context: {$implicit: context}">
                        </ng-container>
                    </div>
                    <!-- Actions -->
                    <div class="flex flex-shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                        <ng-container
                            *ngTemplateOutlet="actionTemplate || defaultActions; context: {$implicit: context}">

                        </ng-container>
                        <button (click)="close()" class="ml-3 close-button" mat-flat-button>
                            <mat-icon class="icon-size-5 mr-2" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Body -->
                <div  class="flex-auto body">
                    <ng-container *ngTemplateOutlet="bodyTemplate || defaultBody; context: {$implicit: context}">
                    </ng-container>
                </div>
            </div>

        </ng-container>

    </ng-template>
</disco-data>
<ng-template #defaultHeader let-context>
    <h1 class="title">{{context?.data[0]?.Name}}</h1>
</ng-template>
<ng-template #defaultBody let-context>
    <mat-card class="card details">
        <div class="flex-auto h-full pt-6 sm:px-10 sm:pb-10 overflow-y-auto" cdkScrollable>
            <app-disco-form #detailForm formKey="details" [data]="context.data[0]"
                (onSubmitted)="submit($event)"></app-disco-form>
        </div>

    </mat-card>
</ng-template>
<ng-template #defaultActions let-context>
    <button mat-flat-button color="primary" class="edit-button">
        <mat-icon class="icon-size-5 mr-2" [svgIcon]="'heroicons_solid:pencil-alt'"></mat-icon>
        Edit
    </button>
</ng-template>
