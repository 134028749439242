import {
    NgModule
} from "@angular/core";
import {
    CommonModule
} from "@angular/common";
import {
    PopoverDirective
} from "./popover.directive";
import {
    Overlay,
    OverlayModule
} from "@angular/cdk/overlay";
import {
    PopoverService
} from "./popover.service";

@NgModule({
    imports: [
        CommonModule,
        OverlayModule
    ],
    declarations: [
        PopoverDirective
    ],
    exports: [
        PopoverDirective
    ],
    providers: [
        Overlay,
        PopoverService
    ]
})
export class PopoverModule { }
