import { Component, ViewEncapsulation, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { IDynForm } from '../common/models';
import { DynFormsUserDataServiceInterface } from '../services';
import { GetDynFormsBloc } from './dyn-forms-list.blocs';

@Component({
  selector: 'app-dyn-list',
  templateUrl: './dyn-forms-list.component.html',
  styleUrls: ['./dyn-forms-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    GetDynFormsBloc
  ]
})

export class DynFormsListComponent implements OnInit, OnChanges {
  @ViewChild('dynForm') dynForm;

  @Input()
  public data;

  @Input()
  set directory(val: string) {
    this.getDynForms.setDirectory(val);
  }

  @Input()
  public showInGroups = false;

  @Input()
  public rolePrefix: string;

  @Output()
  isSubmitted = new EventEmitter<{ isDone: boolean }>();

  @Output()
  isCanceled = new EventEmitter<any>();

  public selectedFormKey;
  public model = {} as any;
  public isDone;
  public selectedDir;
  public selectedDirForms;
  private userRoles;

  constructor(
    public getDynForms: GetDynFormsBloc,
    public userDataService: DynFormsUserDataServiceInterface
  ) { }

  async ngOnInit() {
    const user = await this.userDataService.getUser();
    this.userRoles = user.roles;
  }

  ngOnChanges(changes): void {
    if (changes.data) {
      this.model = Object.assign(this.data);
    }
  }


  selectForm(form: IDynForm) {
    this.model = Object.assign(this.data);
    this.selectedFormKey = form.Key;
    this.model.Subject = form.Title;
    this.model.RelatedToId = this.data.RelatedToId;
    this.model.Description = this.data.Description;
    this.model.FormKey = form.Key;
    this.model.TargetUrl = form.TargetUrl;
  }

  close() {
    this.isCanceled.emit(true);
  }

  onSubmit() {
    this.isSubmitted.emit({ isDone: this.isDone });
  }

  selectDir(dir) {
    this.selectedDir = dir.group;
    this.selectedDirForms = dir.items;
  }

  filterDirectories(directories: { group: string, items: any[] }[]) {
    return directories.filter(d => this.userRoles
      .has(`${this.rolePrefix}${d.group.toLowerCase()}`));
  }

}
