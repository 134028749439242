<button [matMenuTriggerFor]="exportMenuJson"
    *ngIf="!isLoading && tableHeaderConfig?.resultLength <= MAX_EXCEL_SIZE" color="primary" class="import-doc">
    <mat-icon>download</mat-icon>
    <mat-menu #exportMenuJson="matMenu" class="custom-menu">
        <button mat-menu-item (click)="exportToJson()">
            <mat-icon class="small-icon">description</mat-icon>
            Export to Json
        </button>
        <button mat-menu-item (click)="exportTableData(MAX_EXCEL_SIZE)">
            <mat-icon class="small-icon">insert_drive_file</mat-icon>
            Export to Excel
        </button>
    </mat-menu>
</button>

<button *ngIf="!isLoading && tableHeaderConfig?.resultLength > MAX_EXCEL_SIZE"
    [matMenuTriggerFor]="exportMenu" color="primary" class="import-doc">
    <mat-icon>download</mat-icon>
    <mat-spinner diameter="30" *ngIf="isLoading"></mat-spinner>
    <mat-menu #exportMenu="matMenu" class="custom-menu">
        <button mat-menu-item (click)="exportToJson()">
            <mat-icon class="small-icon">description</mat-icon>
            Export to Json
        </button>
        <button (click)="exportTableData(MAX_EXCEL_SIZE)"
            matTooltip="The maximum number of records in Excel is {{MAX_EXCEL_SIZE}}." mat-menu-item>
            <mat-icon class="small-icon">insert_drive_file</mat-icon>
            Export to Excel
        </button>
        <button mat-menu-item (click)="exportTableData(tableHeaderConfig?.resultLength)">
            <mat-icon class="small-icon">insert_drive_file</mat-icon>
            Export to CSV
        </button>
    </mat-menu>
</button>

<export-report #exportReport *ngIf="true" [currentTab]="currentTab" [queryService]="queryService">
</export-report>
