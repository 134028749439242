import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';


import _moment  from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { FieldType } from '@ngx-formly/core';
import { BaseCustomController } from '../shared/base-custom-controller';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'datepicker-withoutday-selection',
  templateUrl: 'datepicker-withoutday-selection.html',
  styleUrls: ['datepicker-withoutday-selection.scss'],
  providers: [

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
// tslint:disable-next-line:component-class-suffix
export class DatepickerWithoutDaySelection extends BaseCustomController implements OnInit {
  public dateControl = new FormControl();
  chosenYearHandler(event) {
    this.dateControl = new FormControl(moment());
    const ctrlValue = this.dateControl.value;
    ctrlValue.year(event._i.year);
    this.dateControl.setValue(ctrlValue);
    const year = event._i.year;
    const month = this.field.formControl.value ? this.field.formControl.value.split('/')[0] : 1;
    this.updateValueAndValidity(this.field, `${month}/${year}`);
  }

  chosenMonthHandler(event, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.dateControl.value;
    ctrlValue.month(event._i.month);
    this.dateControl.setValue(ctrlValue);
    const month = event._i.month + 1;
    const year = this.field.formControl.value.split('/')[1];
    this.updateValueAndValidity(this.field, `${month}/${year}`);
    datepicker.close();
  }
  ngOnInit() {
    if (this.field.formControl.value) {
      const dateControl: any = new FormControl(moment).value;

      const ctrlValue = dateControl.value;

      ctrlValue.month(this.field.formControl.value.split('/')[0]);
      ctrlValue.year(this.field.formControl.value.split('/')[1]);

      ctrlValue.month = this.field.formControl.value.split('/')[0];
      ctrlValue.year = this.field.formControl.value.split('/')[1];
      this.dateControl.setValue(ctrlValue);
    } else {
      this.updateValueAndValidity(this.field, '--/--');
    }
  }
}
