<div *ngIf="!isVertical else VERTICAL">
    <span>Multiple filters selected </span>
    <button [popoverTrigger]="popover" [closeOnClickOutside]="true" type="button">
        <a>Info</a>
    </button>
</div>
<ng-template #VERTICAL>
    <div class="vertical-adv-filter">
        <span class="title"> Advanced Filter </span>
        <button type="button"  [popoverTrigger]="popover" [closeOnClickOutside]="true">
            <mat-icon class="icon">info_outline</mat-icon>
        </button>
    </div>
</ng-template>

<!-- This template displays the overlay content and is connected to the button -->
<ng-template #popover>
    <mat-card color="primary" class="card advanced-filter-card">
        <mat-card-content>
            <div class="date-facet-values">
                <search-header-advanced-filter [filter]="filter" [advFilterService]="advFilterService"
                    [columnDictionary]="columnDictionary">
                </search-header-advanced-filter>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>
