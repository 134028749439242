import {
    Injectable
} from '@angular/core';
import {
    DateGroupBy,
    DefaultPivotLimit,
    DiscovererDataService,
    ICategories,
    IChartState,
    IColumnSetting,
    IMetric,
    IPivotConfig,
    NUMBER_OF_ROWS_METRIC,
    StateService,
    getNearestValueToGap,
    mapFunctionName,
    roundUpToHighestDigit
} from '@discoverer/core/services';
import {
    BehaviorSubject,
    Observable,
    Subject
} from 'rxjs';
import {
    ChartSort,
    PivotChartControlState,
    PivotSummarizeBy,
    SummarizeBy,
} from './chart-control.model';
import {
    ViewTypes
} from '../control-bar';
import {
    debounceTime,
    take,
    takeUntil
} from 'rxjs/operators';
import {
    ChartAxis, ChartControlAxis
} from '@discoverer/dynamic-reports/models';
import {
    BaseChartDetails
} from '@discoverer/dynamic-reports/customize-report/base-chart-details';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum Commands { Update = 'update', Reset = 'reset', UpdateChartType = 'updateChartType' }

export class DiscovererChartStateInputs<T> {
    service: StateService<T>;
    chartType: ViewTypes.Chart | ViewTypes.Pivot;
}

export class CategoryConfigurationService<T> {
    private _chartState$ = new StateService<DiscovererChartStateInputs<T>>({ chartType: null, service: null });
    public oQueryService: Observable<DiscovererChartStateInputs<T>> = this._chartState$.oState;

    public setChartService(service: StateService<IPivotConfig | IChartState>) {
        this._chartState$.setValue({ service });
    }

    public setChartType(chartType: ViewTypes.Chart | ViewTypes.Pivot) {
        this._chartState$.setValue({ chartType });
    }
}

export abstract class GenericChartControlService<T> extends BaseChartDetails {
    oState: Observable<T>;
    protected destroy$ = new Subject<boolean>();
    protected command$ = new BehaviorSubject<string>('init');
    protected categoryConfiguration$: CategoryConfigurationService<any>;
    protected formState$: StateService<T>;
    private _initalState: T;
    public isLoading$ = new Subject<boolean>();

    constructor(initialState: T) {
        super();
        this._initalState = initialState;
        this.formState$ = new StateService<T>(initialState);
        this.oState = this.formState$.oState;

        this.categoryConfiguration$ = new CategoryConfigurationService();

        this.whenFormInputChangesSendQuery();
    }
    protected abstract validateFormState(formState: T): { isInputValid: boolean, invalidMsg: string };

    protected abstract whenFormInputChangesSendQuery()
    protected abstract configStateHandler(pivotState: (IPivotConfig | IChartState), columnDictionary: { [key: string]: IColumnSetting })
    protected abstract setService(pivotState: StateService<IPivotConfig | IChartState>, columnDictionary: { [key: string]: IColumnSetting })

    public setChartType(chartType: ViewTypes.Chart | ViewTypes.Pivot) {
        this.categoryConfiguration$.setChartType(chartType);
    }

    public updateState(key: string, value: any) {
        this.formState$.setField(key, value);
    }
    public commandUpdate() {
        this.command$.next(Commands.Update);
    }
    public commandUpdateChartType() {
        this.command$.next(Commands.UpdateChartType);
    }
    public resetState() {
        this.formState$.setValue(this._initalState);
        this.command$.next(Commands.Reset);
    }
    public destroy() {
        this.destroy$.next(true);
    }

}
@Injectable()
export class PivotChartControlService extends GenericChartControlService<PivotChartControlState> {

    protected categoryConfiguration$: CategoryConfigurationService<IPivotConfig>;
    protected groupByDataService$: DiscovererDataService<any>;
    constructor(private snackBar: MatSnackBar) { super(new PivotChartControlState()) }


    public showMsg(msg) {
        this.snackBar.open(msg, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'right'
        });
    }
    public async setService(pivotState: StateService<IPivotConfig>, columnDictionary: { [key: string]: IColumnSetting }) {
        this.categoryConfiguration$.setChartService(pivotState);
        const state = await pivotState.oState.pipe(take(1)).toPromise();
        this.configStateHandler(state, columnDictionary);
    }

    protected whenFormInputChangesSendQuery() {
        this.command$
            .pipe(takeUntil(this.destroy$), debounceTime(100))
            .subscribe(async (v) => {
                if (v == Commands.Update) {
                    const formSnapshot = this.formState$.getState();
                    const { isInputValid, invalidMsg } = this.validateFormState(formSnapshot);
                    if (isInputValid) {
                        const pivotService = (await this.categoryConfiguration$.oQueryService.pipe(take(1)).toPromise()).service;
                        let groupBys = formSnapshot['groupBys'] || [];
                        groupBys?.forEach(x => x?.minMaxSubscription?.unsubscribe())
                        let categories = groupBys.length !== 0 ?
                            this._buildCatFromAxis(groupBys[0]) :
                            this._getDefaultFacet();
                        const sort = formSnapshot['sort'];
                        if (sort) {
                            categories.sortType = sort.sortType;
                            categories.seriesSortDir = sort.seriesSortDir;
                            categories.seriesSort = sort.seriesSort;
                            categories.sortDir = sort.sortDir;
                        }
                        const subFacets = groupBys.filter((_, i) => i > 0).map((group) => this._buildCatFromAxis(group))
                        const metric = {
                            colName: formSnapshot['summarizeBy'][0].colName,
                            functionType: formSnapshot['summarizeBy'][0].functionType,
                            overrideField: formSnapshot['summarizeBy'][0].overrideField
                        }
                        pivotService.setValue({ metric, categories, subFacets });
                    } else {
                        this.showMsg(`Invalid Pivot Setup: ${invalidMsg}`);
                    }
                } else if (v == Commands.Reset) { }
            });

    }
    protected validateFormState(formState: PivotChartControlState): { isInputValid: boolean, invalidMsg: string } {
        let isInputValid = true;
        let invalidMsg = '';
        const groupBys = formState['groupBys'];
        if (groupBys?.length > 0) {
            groupBys.forEach(group => {
                if (!group.colName) {
                    isInputValid = false;
                    invalidMsg = 'Please Fill all Group By Fields';
                }
            })
        }
        const summarizeBy = formState['summarizeBy'][0];
        if (!summarizeBy?.colName || !summarizeBy?.functionType) {
            isInputValid = false;
            invalidMsg = 'Please Fill all Summarize By Fields';
        }
        return { isInputValid, invalidMsg };
    }

    public onDestroy() {
        this.groupByDataService$?.destroy()
    }

    public setgroupByDataService(groupByDataService: DiscovererDataService<any>) {
        this.groupByDataService$ = groupByDataService;
    }

    public moveGroupByDown(i: number) {
        const groupByList = this.formState$.getState().groupBys
        if (i < groupByList.length - 1) {
            [groupByList[i], groupByList[i + 1]] = [groupByList[i + 1], groupByList[i]];
            this.updateState('groupBys', groupByList);
        }
    }

    public moveGroupByUp(i: number) {
        const groupByList = this.formState$.getState().groupBys
        if (i > 0) {
            [groupByList[i], groupByList[i - 1]] = [groupByList[i - 1], groupByList[i]];
            this.updateState('groupBys', groupByList);
        }
    }

    public setSort(sortConfig: ChartSort) {
        const val = { ...sortConfig, subTitle: this._setSortingSubTitle(sortConfig) };
        this.updateState('sort', val);
    }

    public clearGroupBy(i: number) {
        const groupByList = this.formState$.getState().groupBys;
        groupByList.splice(i, 1);
        this.updateState('groupBys', groupByList);
    }

    public setSummarizeByFields(metric: IMetric, index: number) {
        const summarizeBy: SummarizeBy[] = this.formState$.getState().summarizeBy || [];
        const val = {
            ...new PivotSummarizeBy(metric),
            subTitle: this._summrizeBySubTitle(metric)
        };
        if (summarizeBy[index]) {
            summarizeBy[index] = val;
        } else {
            summarizeBy.push(val);
        }
        this.updateState('summarizeBy', summarizeBy);
    }
    public clearSummarizeBy(i: number) {
        const summarizeByList = this.formState$.getState().summarizeBy;
        summarizeByList.splice(i, 1);
        this.updateState('summarizeBy', summarizeByList);
    }
    public setCategoriesFields(cat: ChartControlAxis, index: number) {
        let old = this.formState$.getState().groupBys || [];
        cat.subTitle = this._groupBySubtitle(cat);
        if (!!old[index]) {
            old[index] = cat;
        } else {
            old.push(cat);
        }
        this.updateState('groupBys', old);
    }

    public getGroupBysFromState() {
        return this.formState$.getState().groupBys || [];
    }

    public async prepareGroups(col: IColumnSetting, index: number, axisType: 'categories' | 'series' = 'categories') {
        const axis = new ChartControlAxis(axisType);
        axis.colName = col.fieldName;
        axis.display = col.display;
        axis.colType = col.type;

        if (col.type === 'text') {
            axis.availableGroups = null;
            axis.groupBy = null;
            this.setCategoriesFields(axis, index);
        } else {
            axis.availableGroups = this.getGroupByList(col);
            const groupByName = col.type === 'date' ? 'YEAR' : '1';
            this.setGroupby(axis, groupByName, 24, index);
        }
    }
    public async setGroupby(axis: ChartAxis, groupByName: string, groupByNumber: number = 24, index: number) {
        axis.groupByName = groupByName;
        axis.numberOfGroups = groupByNumber;
        if (axis.colType === 'numeric') {
            this.isLoading$.next(true);
            axis.minMaxSubscription = await this._setGroupByMinMaxFacet(axis.colName, axis, groupByNumber, index);
        } else {
            axis.offset = 0;
            axis.compareOffset = 0;
            axis.groupBy = this.getGroupBy(axis.colType, groupByName, groupByNumber);
            this.setCategoriesFields(axis, index);
        }
    }
    public onGroupsChange(axis: ChartAxis, index: number) {
        axis.groupBy = this.getGroupBy(
            axis.colType,
            +axis?.groupBy?.gap || axis.groupByName,
            axis.numberOfGroups,
            +axis?.groupBy?.start || 0,
            +axis?.groupBy?.end || 0,
            axis.offset,
            axis.compareOffset,
            axis.compareType);
        this.setCategoriesFields(axis, index)
    }

    public setSubChartType(type) { }

    private async _setGroupByMinMaxFacet(
        fieldName: string,
        axis: ChartAxis,
        months: number,
        index: number
    ) {
        this.groupByDataService$.setDataIsRequested('All', true);
        this.groupByDataService$.resetStatFacet();
        this.groupByDataService$.setStat(fieldName, 'min');
        this.groupByDataService$.setStat(fieldName, 'max');
        await this.groupByDataService$.refresh();
        if (!!axis.minMaxSubscription) {
            axis.minMaxSubscription.unsubscribe();
        }

        return this._getAxisGroupBySubsription(axis, months, index);
    }

    private _buildCatFromAxis(axis: ChartAxis): ICategories {
        return {
            colName: axis.colName,
            groupBy: axis.groupBy,
            sortDir: axis.sortDir as any,
            sortType: axis.sortType,
            limit: axis.limit,
            seriesSortDir: axis.seriesSortDir as 'asc' | 'desc',
            seriesSort: axis.seriesSort,
            minCount: axis.minCount
        };
    }

    private _getDefaultFacet(): ICategories {
        return {
            colName: 'DefaultColumn',
            groupBy: null,
            sortDir: 'asc',
            sortType: 'label',
            limit: 1,
            seriesSortDir: 'asc',
            seriesSort: 'series'
        }
    }
    private _getAxisGroupBySubsription(axis: ChartAxis, monthsNo: number, index: number) {
        const sub = this.groupByDataService$.oFacetResults.pipe(takeUntil(this.destroy$)).subscribe((facets) => {
            const facet = facets.find((x) => x.field === 'All');
            if (facet && (facet?.getKeyMap())[axis.colName]) {
                axis.groupBy = { start: null, end: null, gap: null, orginalGap: null };
                const min: number = +facet.getValueStringState('stat1') || 0;
                const max: number = +facet.getValueStringState('stat2') || 10000;
                const gap = Math.ceil((max - min) / 10);
                const roundedGap = roundUpToHighestDigit(gap);
                axis.groupBy.gap = roundedGap.toString();
                const roundedMax = getNearestValueToGap(min, roundedGap);
                axis.groupBy = this.getGroupBy(
                    axis.colType,
                    +axis.groupBy.gap,
                    monthsNo,
                    roundedMax,
                    max
                );
                this.setCategoriesFields(axis, index);
                this.isLoading$.next(false);
            }
        })
        return sub;
    }
    private _summrizeBySubTitle(metric: IMetric) {
        let subTitle = ''
        if (metric.colName === NUMBER_OF_ROWS_METRIC.fieldName) {
            subTitle = mapFunctionName(metric.functionType) + ' of ' + NUMBER_OF_ROWS_METRIC.display;
        } else {
            subTitle = mapFunctionName(metric.functionType) + ' of ' + metric.display;
        }
        return subTitle;
    }

    private _groupBySubtitle(groupBy: ChartControlAxis) {
        let seriesGap = ''
        if (groupBy.colType === 'date' && !!groupBy.groupBy) {
            seriesGap = `${seriesGap}${groupBy.display} (${this._mapFromGap(groupBy.groupBy.orginalGap)}.)`;
        } else if (groupBy.colType === 'numeric' && !!groupBy.groupBy) {
            seriesGap = `${seriesGap}${groupBy.display} (${groupBy.groupBy.orginalGap})`;
        } else {
            seriesGap = `${seriesGap}${groupBy.display}`;
        }
        return seriesGap;

    }
    private _setSortingSubTitle(sort: ChartSort): string {

        const {sortType, sortDir, seriesSort, seriesSortDir} = sort;
        return `Sort by ${sortType || '--'} (${sortDir || '--'}), Series Sort by: ${seriesSort || '--'} (${seriesSortDir || '--'})`;
    }

    private _mapFromGap(functionType: string) {
        switch (functionType) {
            case 'QUARTER':
                return 'Quarter';
            case 'YEAR':
                return 'Year';
            case 'MONTH':
                return 'Month';
            case 'WEEK':
                return 'Week';
            case 'DAY':
                return 'Day';
            case 'DAILY WEEK':
                return 'Daily Week'
            case 'DAILY MONTH':
                return 'Daily Month';
    }
    }

    protected configStateHandler(config: IPivotConfig, columnDictionary: { [key: string]: IColumnSetting }) {

        const dimension = this._getDimensionFromConfig(config, columnDictionary);
        const seriesList = this._getSeriesListFromConfig(config, columnDictionary);
        const metric = this._getMetricFromConfig(config, columnDictionary);
        const sort = this._getSortFromConfig(config);
        this.setSummarizeByFields(metric, 0);
        this.setCategoriesFields(dimension, 0);

        seriesList.forEach((s, i) => this.setCategoriesFields(s, i + 1));
        this.setSort(sort);
    }
    private _getDimensionFromConfig(config: IPivotConfig, columnDictionary: { [key: string]: IColumnSetting }) {
        let dimension = new ChartControlAxis('categories');
        const defaultPivotLimit = DefaultPivotLimit;
        dimension.limit = defaultPivotLimit;
        dimension.sortType = 'label';
        if (config?.categories) {
            const { categories } = config;
            const { colName, groupBy, sortDir, sortType, limit, minCount } = categories;
            dimension = {
                ...dimension, colName, groupBy, sortDir, sortType, limit, minCount,
                availableGroups: this.getGroupByList(columnDictionary[colName]),
                colType: columnDictionary[colName].type,
                numberOfGroups: (groupBy as DateGroupBy)?.numberOfGroups,
                compareOffset: (groupBy as DateGroupBy)?.compareOffset,
                offset: (groupBy as DateGroupBy)?.offset,
                groupByName: groupBy?.orginalGap,
                compareType: groupBy?.compareType,
                display: columnDictionary[colName]?.display
            }
        }

        return dimension;
    }

    private _getMetricFromConfig(config: IPivotConfig, columnDictionary: { [key: string]: IColumnSetting }) {

        let metric = { colName: null, functionType: null, display: null, overrideField: null };
        if (config?.metric) {
            return { ...config.metric, display: columnDictionary[config.metric.colName]?.display || config?.metric?.colName };
        } else {
            return { colName: null, functionType: null, display: null, overrideField: null };
        }
    }
    private _getSeriesListFromConfig(config: IPivotConfig, columnDictionary: { [key: string]: IColumnSetting }) {
        let seriesList = [];

        if (!!config.subFacets) {
            seriesList = [];
            config.subFacets.forEach(async (sf, i) => {
                const series = new ChartControlAxis('series');
                series.colName = sf.colName;
                series.groupBy = sf?.groupBy;
                series.sortDir = sf.sortDir || 'asc';
                series.limit = sf.limit ?? DefaultPivotLimit;
                series.minCount = sf.minCount;
                series.index = i;
                series.colType = columnDictionary[series.colName].type;
                series.availableGroups = this.getGroupByList(columnDictionary[series.colName]);
                series.numberOfGroups = (sf?.groupBy as DateGroupBy)?.numberOfGroups;
                series.groupByName = sf?.groupBy?.orginalGap;
                series.display = columnDictionary[sf.colName]?.display;
                seriesList.push(series);
            });
        }
        return seriesList;
    }

    private _getOneDefaultSeries() {
        const initFacet = new ChartAxis('series');
        initFacet.limit = DefaultPivotLimit;
        initFacet.index = 0;
        return initFacet;
    }
    private _getSortFromConfig(config: IPivotConfig) {
        if (config?.categories) {
            const { sortType, sortDir, seriesSort, seriesSortDir } = config?.categories;
            return { sortType, sortDir, seriesSort, seriesSortDir };
        }
    }
}
