import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MaterialModule } from '@discoverer/MaterialModule';
import { DynamicReportsModule } from '../dynamic-reports.module';
import { DiscoFormModule } from '../disco-form/disco-form.module';
import { DiscoDetailsComponent } from './disco-details.component';
import { FILES_SERVICE_URL } from './files-service';


@NgModule({
  declarations: [DiscoDetailsComponent],
  imports: [
    CommonModule,
    DynamicReportsModule,
    MaterialModule,
    DiscoFormModule
  ],
  exports: [DiscoDetailsComponent, DiscoFormModule],
  entryComponents: [
    DiscoDetailsComponent
  ],
})
export class DiscoDetailsModule {
  static forRoot(filesServiceUrl: string): ModuleWithProviders<DiscoDetailsModule> {
    return {
        ngModule: DiscoDetailsModule,
        providers: [
            { provide: FILES_SERVICE_URL, useValue: filesServiceUrl }
        ]
    };
}
}
