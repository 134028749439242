import { Component, OnInit, Input, Output, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IOption } from '../models/option';
import { IOptionList } from '../models/option-list';
import { MutateOptionBloc, GetOptionsBloc } from '../shared-services/options.bloc';
import { MutateCategoryBloc } from '../shared-services/option-list.bloc';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { OptionDialogComponent } from '../options-dialog/options-dialog';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'option-list-details',
  templateUrl: './option-list-details.component.html',
  styleUrls: ['./option-list-details.component.scss']
})
export class OptionListDetailsComponent implements OnInit, OnDestroy {
  @Input()
  set selectedCategory(value: IOptionList) {
    this.setSelectedCategory(value);
  }
  get selectedCategory() {
    return this._selectedCategory;
  }

  @Output()
  public selectedOption: IOption;

  public optionListDetailsForm: FormGroup;
  public sortChanges = false;
  public selectedGroup: string;
  private _selectedCategory: IOptionList;
  public searchControl: FormControl = new FormControl('');
  public filteredGroups = new Array<any>();
  public optionChangesSubscription: Subscription;
  // public options: any;
  public groups: any[];
  constructor(
    private _formBuilder: FormBuilder,
    public mutateCategoryBloc: MutateCategoryBloc,
    public optionBloc: GetOptionsBloc,
    public optionMutation: MutateOptionBloc,
    public optionListMutation: MutateCategoryBloc,
    public cdRef: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.searchGroups();
    this.onOptionChanges();
    this.optionBloc.result.subscribe(value => {
      const allOptions = value.options.map(o => {
        if (o.group == null || o.group === '') {
          o.group = '(No Group)';
        }
        return o;
      }
      );
      const groups = this.groupBy(allOptions, option => option.group);
      this.groups = (Array.from(groups, ([name, options]) => ({ name, options }))).sort((n1, n2) => n2.name - n1.name);
      this.getFilteredOptions(this.searchControl.value);
      this.cdRef.detectChanges();
    });

  }

  private groupBy(list, groupKey) {
    const map = new Map();
    list.forEach((item) => {
      const key = groupKey(item).toUpperCase();
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  private onOptionChanges() {
    this.optionChangesSubscription = this.optionMutation.result.subscribe(x => {
      if (x && x.result && x.result === 'Error') {
        this.openOptionDialog(x.message);
      } else {
        this.optionBloc.set(this._selectedCategory.id);
      }
    });
  }

  public listChanged(refreshNeeded: boolean) {
    if (refreshNeeded) {
      this.setSelectedCategory(this._selectedCategory);
    } else {
      this.selectedOption = null;
    }
  }

  public saveChanges(data: IOptionList) {
    data.id = this._selectedCategory ? this._selectedCategory.id : null;
    if (data.id) {
      this.mutateCategoryBloc.updateOptionList(data);
    } else {
      this.mutateCategoryBloc.addOptionList(data);
    }
  }
  public drop(event: CdkDragDrop<string[]>, options, groupName: string) {
    this.sortChanges = true;
    this.selectedGroup = groupName;
    moveItemInArray(options, event.previousIndex, event.currentIndex);
  }
  public saveSorting(list: IOption[]) {
    for (let i = 0; i < list.length; i++) {
      list[i].order = i + 1;
      this.optionMutation.updateOptionOrder(list[i]);
    }
    this.sortChanges = false;
  }
  private initForm() {
    this.optionListDetailsForm = this._formBuilder.group({
      description: ['', Validators.required],
    });
  }

  private setSelectedCategory(value: IOptionList) {
    if (!!value && value.id) {
      this._selectedCategory = value;
      this.optionBloc.set(value.id);
    }
    this.selectedOption = null;
  }
  private openOptionDialog(message) {
    return this.dialog.open(OptionDialogComponent, {
      data: { message },
      autoFocus: false
    });
  }

  public addOptionToGroup(selectedCategoryId, groupName = '') {
    this.selectedGroup = groupName;
    this.selectedOption = { id: 0, optionListId: selectedCategoryId, description: '', code: '', value: null, group: groupName, order: null };
  }
  public addGroup(selectedCategoryId) {
    this.selectedGroup = '';
    this.selectedOption = { id: 0, optionListId: selectedCategoryId, description: '', code: '', value: null, group: '', order: null };
  }
  private searchGroups() {
    this.searchControl.valueChanges.subscribe(s => {
      this.getFilteredOptions(s);
    });
  }

  private getFilteredOptions(s: any) {
    if (s) {
      this.filteredGroups = this.groups.map(g => {
        const group = Object.assign({}, g);
        group.options = g.options.filter(o => o.description.toLowerCase().includes(s));
        return group;
      }).filter(x => x.options && x.options.length);
    } else {
      this.filteredGroups = this.groups;
    }
  }

  ngOnDestroy(): void {
    this.optionChangesSubscription.unsubscribe();
  }
}
