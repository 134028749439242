import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'dyn-link-input',
    templateUrl: `./dyn-link-input.component.html`,
    styleUrls: ['./dyn-link-input.component.scss']
})
export class DynamicLinkInputComponent extends FieldType {
    constructor() {
        super();


    }
}


