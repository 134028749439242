import { Component, OnInit, Input } from '@angular/core';
import { BaseController, DiscovererQueryService, IColumnSetting } from '@discoverer/core/services'
import { TabSettingsService } from '@discoverer/core/services/dynamic-reports-services';
import { disLogger } from '@discoverer/core/services/functions';

@Component({
    selector: 'sorting-container',
    styleUrls: ['./sorting-container.component.scss'],
    templateUrl: './sorting-container.component.html'
})
export class SortingContainerComponent extends BaseController implements OnInit {

    @Input() public queryService: DiscovererQueryService;
    public allColumns: IColumnSetting[] = [];
    public sortColumns: any[];
    public sortOrderList = [
        {
            dir: 'asc',
            title: 'sort from low to high by'
        },
        {
            dir: 'desc',
            title: 'sort from high to low by'
        }
    ];

    constructor(private tabSettings: TabSettingsService) {
        super();
    }

    public async ngOnInit() {
        this.allColumns = await this.tabSettings.getAllColumns();

        const columnDictionary = await this.tabSettings.getColumnDictionary();

        this.queryService.oQuery.subscribe(query => {
            this.sortColumns = query.sorts.map(s => ({
                column: columnDictionary[s.sortField],
                dir: s.dir
            }));
        });


    }

    public addSort() {
        this.sortColumns.push({});
    }

    public removeSort(i: number) {
        this.sortColumns.splice(i, 1);
        this.sortColumns.forEach(s => {
            this.queryService.setOrderBy(s.column.filedName, s.dir);
        });
        this.queryService.refresh();
    }

    sortOrderChange(vector: any) {
        disLogger('sortOrderChange - vector ', vector);
    }

    public setSortColumn(col) {
        console.warn('setSortColumn is not implemented')
    }
}
