import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DatasetFileService } from '../../../../../services/api/dataset-file.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MimeTypesService } from '../../../../../services/mime-types.service';
import { HttpResponse } from '@angular/common/http';
import { EmailDetailsDto } from '../../../../../models/datasetFile.models';


@Component({
    selector: 'app-email-viewer',
    templateUrl: './email-viewer.component.html',
    styleUrls: ['./email-viewer.component.scss']
})
export class EmailViewerComponent implements OnInit {
    @Input() fileId: string;
    @Input() datasetId: string;

    emailDetails: EmailDetailsDto;
    sanitizedBodyUrl: any;
    iframeHeight: number;
    body: SafeHtml;
    constructor(
        private _datasetFileService: DatasetFileService,
        private _sanitizer: DomSanitizer,
        private _mimeTypesService: MimeTypesService
    ) {
        // Create a blob URL from the HTML content to display in the iframe
    }

    async ngOnInit(): Promise<void> {
        const {isSuccess, result: emailDetails} = await this._datasetFileService.getEmailDetails(this.datasetId, this.fileId).toPromise();
        if (!isSuccess) {
            console.error('Failed to fetch email details');
        }
        this.emailDetails = emailDetails;
        this.body = this._sanitizer.bypassSecurityTrustHtml(emailDetails.EmailHTMLBody);

    }

    adjustIframeHeight(): void {
        const frame = document.querySelector('iframe');
        if (frame && frame.contentWindow) {
            this.iframeHeight = frame.contentWindow.document.body.scrollHeight;
        }
    }

    getFileSize(size: number): string {
        if (size > 1024 * 1024) {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        }
        if (size > 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        }
        return `${size} B`;
    }

    async downloadEmail(): Promise<void> {
        const emailBlob = await this._datasetFileService.downloadOriginalFile(this.datasetId, this.fileId).toPromise()
        const downloadLink = document.createElement('a');
        downloadLink.download = `${this.fileId}.eml`;
        downloadLink.href = window.URL.createObjectURL(emailBlob.body);
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
}
