import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoggerService } from './logger-service';
import { LoggerConfig } from './logger.constants';

@NgModule({
  imports: [
    HttpClientModule
  ]
})

export class LoggerModule
{
    constructor(private _loggerService: LoggerService){}


    static forRoot(config: any): ModuleWithProviders<LoggerModule>
    {
        return {
            ngModule : LoggerModule,
            providers: [
                {
                    provide : LoggerConfig,
                    useValue: config
                }
            ]
        };
    }
}
