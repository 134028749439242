import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { RoleChecker } from '@discoverer/core/services';
import { UserDataService, UserData } from './user-data.service';
import { filter } from 'rxjs/operators';

@Injectable()
export class RolesService implements RoleChecker {

  constructor(private _userService: UserDataService) {

  }

  public hasRole(role: string): Promise<boolean> {
    return new Promise(resolve => {
      this._userService.loggedInUser.pipe(filter( f=> !!f && !!f.roles ))
        .subscribe(
          (user: UserData) => {
            resolve(user.roles.has(role));
          });
    });
  }
}
