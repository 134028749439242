<div class="body pl-4">
    <div class="title">
        <div>Test Results:-</div>
        <button mat-icon-button (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="test-result">
        <div class="test-result-box" *ngIf="!errorMsg else ERROR">
            <div class="spinner-container" *ngIf="loading else LIST">
                <mat-spinner></mat-spinner>
            </div>
            <ng-template #LIST>
                <pre *ngFor="let test of testResult; let i = index">{{i+1}} - {{test  }}</pre>
            </ng-template>
        </div>
        <ng-template #ERROR>
            <div class="error-message">{{errorMsg}}</div>
        </ng-template>
    </div>
</div>
