import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FadeInOutOptimizedAnimation, heightExpand } from '@app/app.animation';

import { switchMap, filter } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ITab2, ITableHeader, ReportPersistService, TabSettingsService, BaseController, disLogger } from '@discoverer/core/services';
import { TableHeaderService } from '@discoverer/core/services/dynamic-reports-services/table-header.service';

declare var $: any;

@Component({
  selector: 'workspace-mobile',
  templateUrl: './workspace-mobile.component.html',
  styleUrls: ['./workspace-mobile.component.scss'],
  animations: [FadeInOutOptimizedAnimation, heightExpand],
  providers: []
})
export class WorkspaceMobileComponent extends BaseController implements OnInit {

  public currentTab: ITab2;
  public hideReports = true;
  public expandRightSide = false;
  public selectedView = null;
  public exportData = false;
  public reportTitle:string
  public tableHeaderConfig: ITableHeader;

  @Output()
  public rowClick: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    public tableHeaderService: TableHeaderService,
    public reportPersist: ReportPersistService,
    private _tabSettings: TabSettingsService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    super();

  }

  async ngOnInit() {
   this.currentTab = await this._tabSettings.getCurrentTab();

   this.subscriptions.push(
      this.reportPersist.oRequestLoaded.pipe(
        filter( loaded => !!loaded),
        switchMap( () => this.reportPersist.mainViewState.oState  )
      ).subscribe((state) => {
          if(!!state) {
            this.reportTitle = state.mainTitle;
            this.selectedView = (state.type) ? state.type : 'table-view';
          }
      })
    );

    this.whenRequestIsLoadedUpdateRoute();
  }


  private whenRequestIsLoadedUpdateRoute() {
    this.subscriptions.push(this.reportPersist.oRequestLoaded.pipe(filter(request => !!request)).subscribe(request => {
      if(this.reportPersist.loadReqId != this.currentTab.defaultRequestId)
      {
        this._router.navigate(['..', this.reportPersist.loadReqId], { relativeTo: this._activatedRoute });
      }
    }));
  }

  // tslint:disable-next-line:use-life-cycle-interface
  public ngAfterViewInit() {

  }
  goBack() {
		this._router.navigate([`suretrack/mobile/reports/${this.currentTab.key}/report-list`]);
	  }
}

