<div class="metrics">
    <div class="description">
        <span>{{description}}</span>
    </div>
    <div class="row">
        <div class="diffrence" *ngIf="displayMode === 'Values'; else PERCENTAGE">{{ formatMetrics(getAbs(valDiff)) }}</div>
        <ng-template #PERCENTAGE>
            <div class="percentage" *ngIf="displayMode === 'Percentage'">{{ getAbs(valDiff) | number:'1.0-2' }}%</div>
        </ng-template>
        <div class="arrow">
            <i *ngIf="current < prev" class=" down material-icons">
                arrow_downward
            </i>
            <i *ngIf="current > prev" class="up material-icons">
                arrow_upward
            </i>
        </div>
    </div>
    <div class="value">
        <div mat-flat-button class="link" [matMenuTriggerFor]="menu1">
            {{ current ? formatMetrics(current) : 0 }}
        </div>
        <mat-menu #menu1="matMenu">
            <button mat-menu-item (click)="openRecords('table-view', 'val1')">View Records</button>
            <button mat-menu-item (click)="openRecords('pivot-view', 'val1')">View Pivot</button>
        </mat-menu>
        <div>&nbsp;vs&nbsp;</div>
        <div mat-flat-button class="link" [matMenuTriggerFor]="menu2">
            {{ prev ? formatMetrics(prev) : 0 }}
        </div>
        <mat-menu #menu2="matMenu">
            <button mat-menu-item (click)="openRecords('table-view', 'val2')">View Records</button>
            <button mat-menu-item (click)="openRecords('pivot-view', 'val2')">View Pivot</button>
        </mat-menu>
    </div>
</div>
