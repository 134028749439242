<div class="header flex">

    <div class="close-icon">
        <button mat-icon-button (click)="onCloseDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <app-document-header-title 
                [isLoading]="isLoading"
                [fileName]="fileName"
                [fileExtension]="fileExtension"  
                [dateReceived]="dateReceived" 
                [titles]="titles" 
    ></app-document-header-title>

    <app-document-header-actions
                [result]="result"
                [fileId]="fileId"
                [resultIndex]="resultIndex"
                [headerWidth]="headerWidth"
                [isShowLogsEnabled]="isShowLogsEnabled"
                [isEditedValuesValid]="isEditedValuesValid" 
                (toggleShowLogs)="onToggleShowLogs($event)"
                (toggleIsSaving)="onToggleIsSaving($event)"
                (toggleEditMode)="onToggleEditMode($event)"
                (queryServiceChanged)="onQueryServiceChanged()"
                (closeDialog)="onCloseDialog()"
                (limitChanged)="onLimitChanged()"
        ></app-document-header-actions>


    <app-document-header-navigation 
            [isEditMode]="isEditMode"
            [start]="start"
            [total]="total"
            (moveToPrevious)="onMoveToPrevious()"
            (moveToNext)="onMoveToNext()"
    ></app-document-header-navigation>
</div>