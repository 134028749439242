<div *ngIf="isLoading" class="screen-center">
    <mat-spinner diameter="100"></mat-spinner>
</div>

<mat-sidenav-container *ngIf="!isLoading">
    <mat-sidenav #sidenav [mode]="sidenavMode" position="start" [opened]="isSidenavOpen">
        <div>
            <div class="back-row">
                <a routerLink="/datasets">
                    <div class="back-btn">
                        <div class="arrow-back">
                            <mat-icon>arrow_back</mat-icon>
                        </div>
                        Back
                    </div>
                </a>
                <button *ngIf="showCloseIcon" mat-icon-button (click)="sidenav.close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>

            <div *ngFor="let item of sidenavItems">
                <a *ngIf="item?.route" [routerLink]="['./', item?.route]" [routerLinkActive]="'active'" class="item">
                    <mat-icon matOutline>{{item?.icon}}</mat-icon>
                    <div>
                        <p class="title">{{item?.title}}</p>
                        <p class="description">{{item?.description}}</p>
                    </div>
                </a>
                <a *ngIf="item?.href" [href]="item?.href" [routerLinkActive]="'active'" class="item">
                    <mat-icon>{{item?.icon}}</mat-icon>
                    <div>
                        <p class="title">{{item?.title}}</p>
                        <p class="description">{{item?.description}}</p>
                    </div>
                </a>
            </div>

        </div>
    </mat-sidenav>

    <mat-sidenav-content>
        <div class="component">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>