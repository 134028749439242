<div class="email-container flex flex-col gap-4 p-6 h-full" *ngIf="emailDetails">
    <!-- Header -->
    <div class="email-content">
        <div class="email-header">
            {{ emailDetails.EmailSubject }}
        </div>
        <div class="top">
            <div class="from-recipients">
                <div class="from">From: {{ emailDetails.EmailSender }}</div>
                <div class="recipients">
                    <div class="to" *ngIf="emailDetails.EmailReceiver">
                        To: {{emailDetails.EmailReceiver}}
                    </div>
                    <!-- <div class="cc" *ngIf="email.cc?.length">
                        Cc:
                        <ng-container *ngFor="let recipient of email.cc; let last = last">
                            {{ recipient.name }} &lt;{{ recipient.address }}>{{ !last ? '; ' : '' }}
                        </ng-container>
                    </div>
                    <div class="bcc" *ngIf="email.bcc?.length">
                        Bcc:
                        <ng-container *ngFor="let recipient of email.bcc; let last = last">
                            {{ recipient.name }} &lt;{{ recipient.address }}>{{ !last ? '; ' : '' }}
                        </ng-container>
                    </div> -->
                </div>
            </div>
            <div class="time-download">
                <button mat-icon-button matTooltip="Download Email" matTooltipPosition="below"
                    (click)="downloadEmail()">
                    <mat-icon>download</mat-icon>
                </button>
                <!-- <div class="text-sm text-gray-500">{{ email.date | date: 'medium'}}</div> -->
            </div>
        </div>

        <app-file-card-viewer [datasetId]="datasetId" [fileId]="fileId"></app-file-card-viewer>

        <!-- Email Body -->
        <div class="email-body h-full bg-white dark:bg-zinc-800 rounded-lg">
            <div class="border-0" [innerHtml]="body"></div>
        </div>
    </div>
</div>