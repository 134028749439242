import { Component, OnInit } from '@angular/core';
import { BaseCustomController } from '../shared/base-custom-controller';

//  Custom text field with extra classes and all
@Component({
    selector: 'app-formly-field-dyn-star-rating',
    templateUrl: './dyn-star-rating.component.html',
    styleUrls: ['./dyn-star-rating.component.scss']
})
export class DynStarRatingComponent extends BaseCustomController implements OnInit {
    public stars = [1, 2, 3, 4, 5];
    public selectedValue;
    public hoveredValue;

    ngOnInit() {
        this.selectedValue = this.field.formControl.value;
        this.field.formControl.valueChanges.subscribe(value => {
            this.selectedValue = value;
        });

    }
    public isDisabled() {
        return this.field.formControl.status === 'DISABLED';
    }
    public fillStars(star) {
        if (!this.isDisabled()) {
            this.selectedValue = star;
            this.updateValueAndValidity(this.field, this.selectedValue);
        }
    }

}
