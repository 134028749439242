<div class="wrapper">
    <header>
        <h1 mat-dialog-title>Confirmation</h1>
    </header>
    <main>
        <p class="text-lg">{{data?.message}}</p>
        <p *ngIf="data?.note">{{data?.note}}</p>
        <div class="actions">
            <button mat-stroked-button (click)="onYesClick()" cdkFocusInitial>Yes</button>
            <button mat-stroked-button (click)="onNoClick()">No</button>
        </div>
    </main>
</div>
