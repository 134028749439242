import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ITableHeader } from '../classes/report-state'

@Injectable({
  providedIn: 'root'
})
export class TableHeaderService {
  public tableHeader: ReplaySubject<ITableHeader> = new ReplaySubject<ITableHeader>(null);

  constructor() {
   }
}
