
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IMainViewState, TabSettingsService } from '@discoverer/core/services';
import { first } from 'rxjs/operators';
import { BaseController, StateService, DiscovererQueryService, IColumnSetting } from '@discoverer/core/services';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'sorting',
    templateUrl: './sorting.component.html',
    styleUrls: ['./sorting.component.scss']
})
export class SortingComponent extends BaseController implements OnInit {

    @Input() public mainViewState: StateService<IMainViewState>;
    @Input() public queryService: DiscovererQueryService;
    @Input() public tabSettings: TabSettingsService;
    @Output() public sortFieldChanged = new EventEmitter<{
        fieldName: string,
        dir: 'asc' | 'desc'
    }[]>();
    @Output() public defaultSort = new EventEmitter<boolean>();
    public allColumns: IColumnSetting[] = [];
    public defaultSorts: IColumnSetting[];
    public sortColumns: {
        fieldName: string,
        dir: 'asc' | 'desc'
    }[];
    public isDefaultSorts = true;

    constructor() {
        super();
    }

    public async ngOnInit() {
        let subs = null;
        subs = this.tabSettings.oDefaultSorts.subscribe((sortList) => {
            this.defaultSorts = (sortList || []).map(s => s.field);
            if (!!subs) {
                subs.unsubscribe();
            }
            if (this.defaultSorts.length === 0) {
                this.sortColumns = [{
                    fieldName: '',
                    dir: 'asc'
                }];

            }
        });
        this.sortColumns = (await this.queryService.oQuery.pipe(first()).toPromise()).sorts.map(s => ({
            fieldName: s.sortField,
            dir: s.dir
        }));
        if (this.sortColumns.length === 0) {
          this.sortColumns = [{
              fieldName: '',
              dir: 'asc'
          }];
        }
        this.subscriptions.push(this.mainViewState.oState.subscribe((state) => {
            this.isDefaultSorts = state.isDefaultSorts;
        }));
    }
    public setSortField() {
        this.sortFieldChanged.emit(this.sortColumns);
    }

    public updateDefaultSorts(isDefaultSorts: boolean) {
        this.defaultSort.emit(isDefaultSorts);
    }
}
