<div class="flex logs w-full">
    <div class="overflow-auto main px-11 py-5 flex-col">
        <div class="flex align-center mb-5 gap-2">
            <button mat-icon-button (click)="onToggleShowLogs()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <p class="text-xl font-medium">Logs</p>
        </div>
        <div class="logs-container flex flex-col gap-4">

            <div class="steps-container flex flex-col gap-3" *ngIf="fileId && !isLoading">
                <div class="header-container flex items-center gap-3">
                    <h1 class="header font-bold text-lg">Steps</h1>
                    <mat-spinner [diameter]="20" *ngIf="isLoadingLogs"></mat-spinner>
                </div>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let log of stepsLog">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="panel-header">
                                    {{log.stepName}}
                                    <button (click)="copy($event, log)" matTooltip="Copy">
                                        <mat-icon *ngIf="!log.isCopied">content_copy</mat-icon>
                                        <mat-icon *ngIf="log.isCopied">check</mat-icon>
                                    </button>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="content">
                            <ngx-json-viewer [json]="log.jsonObject"></ngx-json-viewer>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <mat-divider></mat-divider>

            <div class="actions-container flex flex-col gap-3" *ngIf="fileId && !isLoading">
                <div class="header-container flex items-center gap-3">
                    <h1 class="header font-bold text-lg">Actions</h1>
                    <mat-spinner [diameter]="20" *ngIf="isLoadingLogs"></mat-spinner>
                </div>
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let log of actionsLog">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="panel-header">
                                    {{log.stepName}}
                                    <button (click)="copy($event, log)" matTooltip="Copy">
                                        <mat-icon *ngIf="!log.isCopied">content_copy</mat-icon>
                                        <mat-icon *ngIf="log.isCopied">check</mat-icon>
                                    </button>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="content">
                            <ngx-json-viewer [json]="log.jsonObject"></ngx-json-viewer>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

        <!-- <mat-accordion *ngIf="fileId && !isLoading">
            <mat-expansion-panel *ngFor="let log of logSteps">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="panel-header">
                            {{log.stepName}}
                            <button (click)="copy($event, log)" matTooltip="Copy">
                                <mat-icon *ngIf="!log.isCopied">content_copy</mat-icon>
                                <mat-icon *ngIf="log.isCopied">check</mat-icon>
                            </button>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="content">
                    <ngx-json-viewer [json]="log.jsonObject"></ngx-json-viewer>
                </div>
            </mat-expansion-panel>
        </mat-accordion> -->
    </div>
</div>