<div style="display: flex;">
    <input tab-directive type="text" matInput [formControl]="$any(field).formControl" [formlyAttributes]="field"
        [matAutocomplete]="auto" [tabIndex]="to?.tabindex">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getDisplayFn()" autoActiveFirstOption="true">
        <mat-option *ngFor="let option of filteredOptions"
            [value]="option?option[valueMember]:null">
            {{getDisplayValue(option)}}
        </mat-option>
    </mat-autocomplete>
    <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>
</div>
