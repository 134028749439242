import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';
import { Moment } from 'moment';
import { DRFacetOption, DRFilter, FacetValue, GroupBy, ICategories, IChartDataConfig, IChartFormatConfig, IChartState, IDynamicReport, IMetric, IPivotConfig, MainViewStateViewType } from '../classes';
import { DataFormattingService } from './data-formatting-service';
import { ReportPersistService } from './report-persist.service';
import { DefaultPivotLimit } from '../core-data-services';
import { DEFAULT_AXIS_LIMIT } from '@discoverer/dynamic-reports/models/chart-axis';

export class ChartFilter {
    fieldName: string;
    fieldType: string;
    values: string[];
    gap: string;
}

@Injectable()
export class ChartRoutingService {
    constructor(
        private dataFormattingService: DataFormattingService,
        protected router: Router) { }

    public async openSelectedRecords(
        reportPersist: ReportPersistService,
        currentApp: string,
        currentTab: string,
        filters: { categoriesFilter: ChartFilter, seriesFilters: ChartFilter[] },
        requestCopy: IDynamicReport,
        view: MainViewStateViewType = 'table-view',
        col?: { colName: string, groupBy: GroupBy }
    ) {
        await reportPersist.persistTempRequest(false, true);
        const oldRequestId = reportPersist.currentReqId;
        const newRequestId = (reportPersist.createLocalRequest());

        const advFilter = requestCopy.query.filters.filter(filter => filter.fields.includes("Advanced"));
        if (advFilter.length === 0) {
            advFilter.push(new DRFilter('All', ['Advanced'], []));
        }
        filters.categoriesFilter && advFilter[0].filters.push(...this._getFilters(filters.categoriesFilter));
        filters.seriesFilters.forEach(rowsFilter => {
            advFilter[0].filters.push(...this._getFilters(rowsFilter));
        });
        if (filters.categoriesFilter || filters?.seriesFilters?.length > 0) requestCopy.query.filters.push(...advFilter);
        if (view === 'pivot-view') {
            if (!requestCopy?.pivotChart?.categories) {
                requestCopy.pivotChart = this._getDefaultPivotView(requestCopy.pivotChart, requestCopy.chart, col);
            } else {
                requestCopy.pivotChart.subFacets = null;
                requestCopy.pivotChart.categories.colName = col.colName;
                requestCopy.pivotChart.categories.limit = DEFAULT_AXIS_LIMIT;
                requestCopy.pivotChart.categories.groupBy = col.groupBy;
            }
        }
        if (view === 'chart-view') {
            if (!requestCopy.chart?.chartConfig?.categories) {
                requestCopy.chart = this._getDefaultChartView(requestCopy.chart, col);
            } else {
                requestCopy.chart.chartConfig.series = null;
                requestCopy.chart.chartConfig.categories.colName = col.colName;
                requestCopy.chart.chartConfig.categories.limit = DEFAULT_AXIS_LIMIT;
                requestCopy.chart.chartConfig.categories.groupBy = col.groupBy;
            }
        }
        requestCopy.mainView.type = view;
        await reportPersist.updateRequest(newRequestId, requestCopy, true, true);

        const url = `${currentApp}/reports/${currentTab}/${newRequestId}`;
        await this.router.navigate([], { state: { ignoreMe: true }, queryParams: { requestId: oldRequestId }, replaceUrl: true });
        await this.router.navigateByUrl(url);

    }

    private _getFullRange(values: Moment[], gap?): { min: Moment, max: Moment } {
        const rangeGap = gap === 'DAILY WEEK' || gap === 'DAILY MONTH' ? 'day' : gap.toLowerCase();
        const max = moment.max(values).clone();
        const min = moment.min(values).clone();
        return {
            min: min,
            max: max.add(1, rangeGap)
        };
    }

    public _getNumRange(values: string[], gap: string): { maxNumber, minNumber } {
        const numbers = values.map((x) => +x.split('-')[0]);
        const minNumber = Math.min(...numbers);
        const maxNumber = Math.max(...numbers) + +gap;
        return {
            minNumber,
            maxNumber
        };
    }

    private _getFilters(filter): DRFilter[] {
        const reportFilters: DRFilter[] = [];
        switch (filter.fieldType) {
            case 'timestamp':
            case 'timestamptz':
            case 'date':
                const dates = filter.values.map(x => this.dataFormattingService.deformatDate(x, filter.gap));
                const dateRange = this._getFullRange(dates, filter.gap);
                const minDate = dateRange.min.format();
                const maxDate = dateRange.max.format();
                reportFilters.push(new DRFilter('facet', [filter.fieldName], [new FacetValue(new DRFacetOption(`[${minDate} TO *]`, `[${minDate} TO *]`, `[${minDate} TO *]`))]));
                reportFilters.push(new DRFilter('facet', [filter.fieldName], [new FacetValue(new DRFacetOption(`[* TO ${maxDate}}`, `[* TO ${maxDate}}`, `[* TO ${maxDate}}`))]));
                break;
            case 'currency':
            case 'double':
            case 'percent':
            case 'int':
                const { minNumber, maxNumber } = this._getNumRange(filter.values, filter.gap);
                reportFilters.push(new DRFilter('facet', [filter.fieldName], [new FacetValue(new DRFacetOption(`[${minNumber} TO *]`, `[${minNumber} TO *]`, `[${minNumber} TO *]`))]));
                reportFilters.push(new DRFilter('facet', [filter.fieldName], [new FacetValue(new DRFacetOption(`[* TO ${maxNumber}}`, `[* TO ${maxNumber}}`, `[* TO ${maxNumber}}`))]));
                break;
            default:
                reportFilters.push(new DRFilter('facet', [filter.fieldName], filter.values.map(val => new FacetValue(new DRFacetOption(`${val}`, `${val}`, `${val}`)))));
                break;
        }
        return reportFilters;
    }
    private _getDefaultChartView(chartState: IChartState, col: { colName: string, groupBy: GroupBy }) {
        chartState = (chartState ?? {} as IChartState);
        chartState.chartConfig = (chartState.chartConfig ?? {} as IChartDataConfig);
        const colName = col.colName
        chartState.chartConfig.categories = (chartState.chartConfig.categories ?? {
            colName,
            sortDir: 'asc',
            limit: DefaultPivotLimit,
            groupBy: col.groupBy
        } as ICategories);
        chartState.chartFormat = {
            categories: chartState.chartConfig.categories.colName,
            chartId: 0,
            chartTitle: null,
            chartType: 'column',
            isStack: false,
            series: null,
            metrics: [],
            tooltipFormat: "",
            isLogarithmScale: false
        } as IChartFormatConfig;
        return chartState;
    }
    private _getDefaultPivotView(pivotChartState: IPivotConfig, chartState: IChartState, col: { colName: string, groupBy: GroupBy }) {
        if (!pivotChartState?.categories) {
            pivotChartState = (pivotChartState ?? {} as IPivotConfig);
            pivotChartState.categories = (pivotChartState.categories ?? {
                colName: col.colName,
                sortDir: 'asc',
                limit: DefaultPivotLimit,
            } as ICategories);
        }
        if (!pivotChartState?.metric) {
            pivotChartState.metric = (chartState.chartConfig.metrics[0] ?? pivotChartState.metric ?? {
                colName: col.colName,
                functionType: 'count',
            } as IMetric)
        }
        return pivotChartState;
    }
}
