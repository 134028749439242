import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { BaseController, DiscovererQueryService, FILTERING_MODES, IColumnSetting } from '@discoverer/core/services';
import { ReportPersistService, TabSettingsService } from "@discoverer/core/services/dynamic-reports-services";
import 'rxjs/add/observable/fromEvent';
import { Subscription, fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { AdvancedDialog, ColumnSettingsCustomizationDialog } from "@discoverer/dynamic-reports/dialogs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { IFacet } from "../facet-list/facet-list.component";

@Component({
    selector: "facet-list-container",
    templateUrl: "./facet-list-container.component.html",
    styleUrls: ["./facet-list-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FacetListContainerComponent extends BaseController implements OnInit {


    @Input() public queryService: DiscovererQueryService;
    @Input() public serviceUrl: string;
    @Input() public hiddenFilters = true;


    @Output() tilted = new EventEmitter<boolean>();
    @Output() close = new EventEmitter();

    width: number;

    searchTerm = '';
    searchFieldControl = new FormControl();

    constructor(
        private dialog: MatDialog,
        private cdref: ChangeDetectorRef,
        private tabSettings: TabSettingsService,
        private reportPersist: ReportPersistService) {
        super();
        this.subscriptions.push(this.searchFieldControl.valueChanges.pipe(debounceTime(500))
            .subscribe(newValue => this._updateSearchTerm(newValue)));
    }


    public ngAfterViewInit() { }

    public ngOnDestroy() { }

    public ngOnInit() { }

    public toggleAreas() {
        this.hiddenFilters = !this.hiddenFilters;
        this.tilted.emit(this.hiddenFilters);
    }

    public clearSearch() {
        this.searchTerm = '';
        this.searchFieldControl.setValue('');
    }
    private _updateSearchTerm(value: string) {
        console.log(value);
        this.searchTerm = value;
        this.cdref.markForCheck();
    }

    public filterMode = FILTERING_MODES.ALL;
    public async showAdvancedModal() {
        const dialogRef: MatDialogRef<AdvancedDialog, boolean> = this.dialog.open(AdvancedDialog, {
            panelClass: 'advanced-dialog-container',
            data: {
                queryService: this.queryService,
                tabSettings: this.tabSettings,
                filterMode: this.filterMode
            },
            width: '100vh',
            height: '90vh',
            autoFocus: false
        });
        const userSaved = await dialogRef.afterClosed().toPromise();
        this.handleDialogResponse(userSaved);
    }
    private handleDialogResponse(userSaved?: boolean) {
        if (!userSaved) {
            this.queryService.unSetFilter('Advanced');
        }
        this.queryService.refresh();
    }

    public async openFilterSettingsDialog() {
        const { isDefaultFacets, facets } = (this.reportPersist.mainViewState.getState());
        const columnsDictionary = await this.tabSettings.getColumnDictionary();
        const tab = await this.tabSettings.getCurrentTab();
        if (!!facets) {
            let facetNames = isDefaultFacets ? tab.defaultFacets : facets.map(f => f.name);
            const originalFacetList = facetNames.map((f) => columnsDictionary[f] as IFacet).filter(f => f != null);
            const resp = await this.openColumnsSettingsDialog(originalFacetList as any).pipe(take(1)).toPromise();

            if (resp) {
                const facets: { group: string, name: string }[]
                    = resp?.rightColumns?.map(col => ({ name: col.fieldName, group: "0" })) || [];
                if (facets?.length > 0) {
                    this.reportPersist.mainViewState.setValue({ facets, isDefaultFacets: resp.isDefault }, false);
                }
            }
            this.cdref.markForCheck();
        }
    }
    public openColumnsSettingsDialog(reportColumns: IColumnSetting[], isColumns = false) {
        return this.dialog.open(ColumnSettingsCustomizationDialog, {
            panelClass: 'column-settings-customization-dialog-container',
            data: {
                reportColumns,
                isColumns,
                tabSettings: this.tabSettings,
                queryService: this.queryService,
                selectedSetting: 'Side Filters'
            },
            autoFocus: false
        }).afterClosed();
    }

}
