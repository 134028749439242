import {
  Component,
  Input,
  OnInit,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IColumnSetting, searchForText } from '@discoverer/core/services';

@Component({
  selector: 'auto-complete-search',
  styleUrls: ['./auto-complete-search.component.scss'],
  templateUrl: './auto-complete-search.component.html',
})
// tslint:disable-next-line:component-class-suffix
export class AutoCompleteSearchWidget implements OnInit, OnChanges {
  @Input()
  public availableColumns: IColumnSetting[];
  @Input()
  public readonly?: boolean;
  @Input()
  public fieldName: string;

  @Input()
  public label: string;
  @Input()
  public showSelectField: boolean = true;
  @Output()
  public changeFieldName: EventEmitter<string> = new EventEmitter<string>();

  public colCtrl = new FormControl();
  public filteredColumns: Observable<IColumnSetting[]>;

  constructor() {}

  ngOnInit() {
    this.filteredColumns = this.colCtrl.valueChanges.pipe(
      startWith(''),
      map((x) => (x ? this.filterColumns(x) : this.availableColumns))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes && !!changes['fieldName']) {
      if (!!changes['fieldName'].currentValue) {
        this.colCtrl.setValue(
          this.availableColumns?.find((x) => x.fieldName === this.fieldName)
            ?.display
        );
      } else {
        this.colCtrl.setValue(null);
      }
    }
  }

  private filterColumns(value: string): IColumnSetting[] {
    const filterValue = value.toLowerCase();
    return this.availableColumns.filter(
      (x) =>
        !!x &&
        (searchForText(filterValue, x?.display) ||
          searchForText(filterValue, x?.fieldName))
    );
  }

  // TODO app-root unknown constant and maybe will be change in the future
  handleOpen() {
    const bodyEle = document.getElementById('app-root');
    bodyEle?.classList?.add('disable-scroll');
  }

  handleClosed() {
    const bodyEle = document.getElementById('app-root');
    bodyEle?.classList?.remove('disable-scroll');
  }

  optionChange(event: any) {
    // NOTE: The event leaks on clicking outside the input breaking down the filter setting type to string doesnt help
    if (!!event?.isTrusted) {
      return;
    } else {
      const col = this.availableColumns.find((x) => x.display === event);
      if (!!col) {
        this.changeFieldName.emit(col.fieldName);
      } else {
        this.changeFieldName.emit(null);
      }
    }
  }

  clear() {
    console.log('clear input');
    this.colCtrl.setValue(null);
    this.changeFieldName.emit(null);
  }
}
