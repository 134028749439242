import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-facet-actions',
  templateUrl: './facet-actions.component.html',
  styleUrls: ['./facet-actions.component.scss']
})
export class FacetActionsComponent implements OnInit {
  @Input() showFooterLine: boolean;
  @Input() showClearButton: boolean;
  @Input() showDoneButton: boolean;

  @Output() clearButtonClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() doneButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
