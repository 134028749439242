import { Component, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FieldType } from '@ngx-formly/material/form-field';

@Component({
  selector: 'input-mask-field',
  templateUrl: './masked-input.component.html',
})
export class InputMaskFieldComponent extends FieldType{
    @ViewChild(MatInput) formFieldControl: MatInput;
}
