import { Component } from '@angular/core';
import { OptionListDataServiceInterface, OptionDataServiceInterface } from '@discoverer/dynamic-forms';
import { HasuraOptionListService } from '@discoverer/dynamic-forms';
import { HasuraOptionService } from '@discoverer/dynamic-forms';

@Component({
    selector: 'app-option-list',
    template: '',
    providers: [{ provide: OptionListDataServiceInterface, useClass: HasuraOptionListService },
    { provide: OptionDataServiceInterface, useClass: HasuraOptionService }]
})
export class OptionListComponent {

    constructor() {
    }


}
