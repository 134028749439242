<div *ngIf="!loading" class="wrapper h-screen overflow-y-auto">
    <header>
        {{(datasetService.oDatasetDetails | async)?.Type === datasetTypes.DATASET ?'Dataset' : 'Workspace'}} Settings
    </header>

    <main>
        <section>
            <div class="setting-wrapper" *ngIf="(datasetService.oDatasetDetails | async)?.Type === datasetTypes.DATASET">
                <form [formGroup]="generalSettingsForm" (ngSubmit)="onGeneralSettingsSubmit()">
                    <div class="header">
                        Extraction Settings
                    </div>

                    <div class="flex gap-x-12 align-center flex-wrap pt-2">
                        <div class="flex-1 w-64 pt-2">
                            
                            <mat-checkbox formControlName="use_textract"
                                [disabled]="generalSettingsForm.get('advanced_forms')?.value || generalSettingsForm.get('advanced_tables')?.value">
                                Use Textract
                            </mat-checkbox>
                            <div class="checkbox-description">
                                This will use AWS Textract to extract data from <strong>PDF</strong> documents
                            </div>
                        </div>
                        <div class="flex-1 w-64 pt-2">
                            <mat-checkbox formControlName="advanced_forms">Advanced Forms</mat-checkbox>
                            <div class="checkbox-description">
                                Detect and extract key-value pairs from forms for easy data processing.
                            </div>
                        </div>
                        <div class="flex-1 w-64 pt-2">
                            <mat-checkbox formControlName="advanced_tables">Advanced
                                Tables</mat-checkbox>
                            <div class="checkbox-description">
                                Extract structured data from tables, including rows, columns, and cell contents.
                            </div>
                        </div>
                    </div>
                    <div class="pt-2">
                        <mat-checkbox formControlName="extract_signatures">Extract Signatures</mat-checkbox>
                        <div class="checkbox-description">
                            This allow you to extract the signatures from the documents
                        </div>
                    </div>
                    <div class="header pt-6">
                        Spreadsheet Settings
                    </div>
                    <div class="pt-2">
                        <mat-checkbox formControlName="spreadsheet_mappers">Use Mappers</mat-checkbox>
                        <div class="checkbox-description">
                            Use mappers to extract data from Sheet files. Suitable for larger files.
                        </div>
                    </div>
                    <div class="row-actions">
                        <button [disabled]="isGeneralSettingsLoading" color="primary" mat-raised-button class="save-btn"
                            type="submit">
                            <mat-spinner *ngIf="isGeneralSettingsLoading" diameter="20"></mat-spinner>
                            <span>{{isGeneralSettingsLoading ? 'Saving' : 'Save'}}</span>
                        </button>
                    </div>
                </form>
            </div>
            <div class="setting-wrapper">
                <div class="header">
                    Danger Zone
                </div>

                <p class="alert-msg">
                    Once you delete the dataset, all documents and parsed data will be lost. If you have <b>email
                        auto-forwarding</b> enabled, <b>turn it off before </b> deleting this inbox!
                </p>

                <div class="row-actions">
                    <button [disabled]="isDeleteLoading" class="delete-btn" mat-stroked-button
                        (click)="openConfirmDialog()">
                        <mat-spinner *ngIf="isDeleteLoading" diameter="20"></mat-spinner>
                        <span>{{isDeleteLoading ? 'Deleting' : 'Delete'}} {{(datasetService.oDatasetDetails | async)?.Type === datasetTypes.DATASET ?'Dataset' : 'Workspace'}}</span>
                    </button>
                </div>
            </div>
        </section>
    </main>
</div>
