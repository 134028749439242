<b *ngIf="to.label" class="label">{{to.label}}</b>
<div [ngClass]="field?.templateOptions?.fieldGroupClassName" *ngFor="let field of field.fieldGroup; let i = index">
    <div [ngClass]="field?.templateOptions?.itemWrapper">
        <formly-group [field]="field"></formly-group>
        <div [ngClass]="field?.templateOptions?.removeButtonClass" *ngIf="
            !field?.templateOptions?.disabled &&
            !field?.templateOptions?.hideButtons && hideRemoveBtn && getFieldGroupLength() - 1 != i; else EmptyBox
          " class="col-sm-2 d-flex align-items-center remove-btn-wrapper">
            <button mat-raised-button *ngIf="!!field?.templateOptions?.removeBtnText; else Default" color="warn"
                type="button" (click)="remove(i)">{{ field.templateOptions.removeBtnText }}
            </button>
            <ng-template #Default>
                <button mat-icon-button (click)="remove(i)" [matTooltip]="to.removeButtonTooltip">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </div>
        <ng-template #EmptyBox>
            <div *ngIf="field?.fieldArray?.templateOptions?.autoRepeat" class="w-10"></div>
        </ng-template>
    </div>
</div>
<div class="add-btn-wrapper" *ngIf="
        !field?.fieldArray?.templateOptions?.disabled &&
        !hideExpression && hideAddBtn && !field?.fieldArray?.templateOptions?.autoRepeat
      ">
    <button mat-raised-button color="primary" *ngIf="!!field?.fieldArray?.templateOptions?.addBtnText; else Default"
        class="btn btn-primary" type="button" (click)="add()">{{
        field?.fieldArray?.templateOptions?.addBtnText}}
    </button>
    <ng-template #Default>
        <button mat-icon-button (click)="add()" [matTooltip]="to.addButtonTooltip">
            <mat-icon>add_circle_outline </mat-icon>
        </button>
    </ng-template>
</div>