import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { BaseController, searchForText } from '@discoverer/core/services'
import { DragulaService } from 'ng2-dragula';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'left-right-columns-settings',
    templateUrl: './left-right-columns-settings.component.html',
    styleUrls: ['./left-right-columns-settings.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DragulaService]
})
// tslint:disable-next-line:component-class-suffix
export class LeftRightColumnsSettingsWidget extends BaseController implements OnInit {

    @Input() filterType: boolean;
    @Input()
    public set allColumns(columns: any[]) {
        const columnsCopy = JSON.parse(JSON.stringify(columns)) as any[];
        if (!columnsCopy) {
            this._allColumns = [];
        } else {
            this._allColumns = columnsCopy.sort((a, b) => (a.displayOrder - b.displayOrder));
            //if the dialog type is "Dynamic filters" then get visible fields.
            if (!this.filterType) {
                this._allColumns = this._allColumns.filter((x: any) => x.showInFilter == true);
            }

        }
        this._setLeftColumns();
    }

    public get allColumns(): any[] {
        return this._allColumns;
    }
    @Input()
    public set rightColumns(columns: any[]) {
        if(this.rightColumns.length == 0){
            this.originalRightColumns = [...columns];
        }
          this._rightColumns = columns.filter(x => !!x);

        this.filteredRightColumns = [...this.rightColumns];
        this.filterRightColumns(this.rightSearchText);
        this._setLeftColumns();
    }

    public get rightColumns(): any[] {
        return this._rightColumns;
    }

    @Input() public label: string;
    @Input() public defaultLabel: string;
    @Input() public initialIsDefaultSelected: boolean;
    @Output() public rightColumnsOutput = new EventEmitter<any[]>();
    @Output() public setDefaultSettingsEvent = new EventEmitter<boolean>();

    public isDefaultMode = false;
    public leftSearchText = '';
    public rightSearchText = '';
    public ctrlClick = false;
    public leftColumns: any[];
    public originalRightColumns:any[];
    public filteredLeftColumns: any[];
    // public set filteredRightColumns(cols: any[]){
    //     this._filteredRightColumns = cols;
    // }
    // public get filteredRightColumns(): any[]{
    //     this._filteredRightColumns = this.rightColumns.filter(x => searchForText(this.rightSearchText, x.display) );
    //     return this._filteredRightColumns;
    // };
    public columnsGroupedByPath: Map<string, any[]> = new Map<string, any[]>();
    public columnsGroup: string = "ROOT";
    public nestedPathsList: string[];
    private _rightColumns: any[] = [];
    public filteredRightColumns: any[] = [];
    private _allColumns: any[] = [];
    private selected = [];
    private shiftClicked: boolean;
    private minIndexLeft: number;
    private maxIndexLeft: number;
    private minIndexRight: number;
    private maxIndexRight: number;
    private cloneFrom: string;
    constructor(
        private dragulaService: DragulaService) {
        super();
    }

    public ngOnInit() {
        this.isDefaultMode = this.initialIsDefaultSelected;
        this.resetSelected();
        this.dragulaService.createGroup<any>('DRAGULA_FACTS', {
            moves: (el, source) => {
                this.cloneFrom = source.id;
                return el.classList.contains('items');
            },
            invalid: (el, handle) => {
                return el.classList.contains('key');
            },
            revertOnSpill: true
        });
        this.subscriptions.push(this.dragulaService.dragend('DRAGULA_FACTS')
            .subscribe(({ name, el }) => {
                if (this.cloneFrom !== el.parentElement.id) {
                    const elementWithAttr = el.querySelector('[dragula-col-id]') || el;
                    const fieldName = elementWithAttr.getAttribute('dragula-col-id')
                    const col = this.allColumns.find(x => x.fieldName === fieldName);
                    if (!!col) {
                        col.isSelected = true;
                    }
                    const dragendColFieldName: string = (!!col) ? col.fieldName : null;
                    if (el.parentElement.id === 'left') {
                        this.moveFromRightToLeft(false, dragendColFieldName);
                    } else {
                        this.moveFromLeftToRight(false, dragendColFieldName);
                    }
                    this.isDefaultMode = false;
                } else {
                    if (el.parentElement.id === 'right') {
                        this.dragMultiRightColumnsUpAndDown(el);
                    }
                }
            })
        );

        // this.subscriptions.push(this.oShiftClick.subscribe(isClick => {
        //     this.shiftClicked = isClick;
        // }));
    }

    private dragMultiRightColumnsUpAndDown(el: Element): void {
        const selected = this.rightColumns.filter((x: any) => !!x && x.isSelected);

        if (selected.length > 0) {
            const index = this.rightColumns.findIndex(x => x.fieldName === el.getAttribute('dragula-col-id'));
            let item;

            if (index !== 0) {
                item = this.rightColumns[index - 1].fieldName;
            } else {
                item = this.rightColumns[index].fieldName;
            }

            const backup = [...this.rightColumns];
            for (let i = 0; i < backup.length; i++) {
                if (!!backup[i] && backup[i].isSelected) {
                    backup.splice(i, 1); // remove all selected
                    i--;
                }
            }

            const newIndex = backup.findIndex(x => x.fieldName === item);
            // extract the top to the index , concat the selected and complete the rest
            const newOnes = backup.slice(0, newIndex + 1).concat(selected).concat(backup.slice(newIndex + 1));

            this._setRightColumns(newOnes);
        } else {
            this._setRightColumns(this.rightColumns);
        }

        this.isDefaultMode = false;
    }


    public setDefaultSettings():void {
        this.isDefaultMode = true;
        this.resetSelected();
        this.setDefaultSettingsEvent.emit(true);
    }

    public resetSelected():void {
        this.rightColumns.map(x => x.isSelected = false);
        this.leftColumns.map(x => x.isSelected = false);
    }
    @HostListener('window:keydown', ['$event'])
    onKeyPress($event: KeyboardEvent) {
        this.ctrlClick = $event.ctrlKey;
        this.shiftClicked = $event.shiftKey;
    }
    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        this.ctrlClick = false;
        this.shiftClicked = false;
        this.selected = [];
    }

    public selectGroup(nestedPathKey: string) {
        this.leftColumns.map(col => col.isSelected = false);
        var colsGroup = this.columnsGroupedByPath.get(nestedPathKey);
        var firstEle = colsGroup[0];
        var lastEle = colsGroup[colsGroup.length - 1];
        this.minIndexLeft = this.leftColumns.findIndex(x => x === firstEle);
        this.maxIndexLeft = this.leftColumns.findIndex(x => x === lastEle);
        this._selectMultiWhenShiftClick(this.minIndexLeft, this.maxIndexLeft, this.leftColumns);
    }
    public selectElement(ele: any):void {
        const postionOfEle = this.rightColumns.findIndex(x => x === ele);
        if (!this.ctrlClick) {
            if (postionOfEle !== -1) {
                this.rightColumns.map(x => x.isSelected = false);
            } else {
                this.leftColumns.map(x => x.isSelected = false);
            }
        }
        ele.isSelected = !ele.isSelected;
        if (postionOfEle !== -1) {
            this._getMinAndMaxRight(ele);
        } else {
            this._getMinAndMaxLeft(ele);
        }
    }

    private _getMinAndMaxRight(ele: any):void {
        const ind = this.rightColumns.findIndex(x => x === ele);
        if (ind !== -1) {
            this.minIndexRight = (ind <= this.minIndexRight || this.minIndexRight === undefined) ? ind : this.minIndexRight;
            this.maxIndexRight = (ind >= this.maxIndexRight || this.maxIndexRight === undefined ||
                ind > this.minIndexRight && ind < this.maxIndexRight) ? ind : this.maxIndexRight;
            if (this.shiftClicked) {
                this._setRightColumns(this.rightColumns);
                this._selectMultiWhenShiftClick(this.minIndexRight, this.maxIndexRight, this.rightColumns);
            } else {
                if (this.selected.length === 1) {
                    this.selected = [this.minIndexRight];
                    this.minIndexRight = this.maxIndexRight = ind;
                } else {
                    if (this.selected.length !== 0) {
                        this.maxIndexRight = undefined;
                        this.minIndexRight = undefined;
                    } else {
                        this.minIndexRight = this.maxIndexRight = ind;
                    }
                }
            }
        }
    }
    private _getMinAndMaxLeft(ele: any):void {
        const ind = this.leftColumns.findIndex(x => x === ele);
        if (ind !== -1) {
            this.minIndexLeft = (ind <= this.minIndexLeft || this.minIndexLeft === undefined) ? ind : this.minIndexLeft;
            this.maxIndexLeft = (ind >= this.maxIndexLeft || this.maxIndexLeft === undefined ||
                ind > this.minIndexLeft && ind < this.maxIndexLeft) ? ind : this.maxIndexLeft;
            if (this.shiftClicked) {
                this._setLeftColumns();

                this._selectMultiWhenShiftClick(this.minIndexLeft, this.maxIndexLeft, this.leftColumns);

            } else {
                if (this.selected.length === 1) {
                    this.selected = [this.minIndexLeft];
                    this.minIndexLeft = this.maxIndexLeft = ind;
                } else {
                    if (this.selected.length !== 0) {
                        this.maxIndexLeft = undefined;
                        this.minIndexLeft = undefined;
                    } else {
                        this.minIndexLeft = this.maxIndexLeft = ind;
                    }
                }
            }
        }
    }
    private _selectMultiWhenShiftClick(minIndex: number, maxIndex: number, col: any[]):void {
        for (let i = 0; i <= col.length - 1; i++) {
            if (i >= minIndex && i <= maxIndex) {
                col[i].isSelected = true;
                this.selected.push(i);
            }
        }
    }

    public moveUp():void {
        const backup = Object.assign([], this.rightColumns);
        let prevIdx = -1;
        const selected = backup.filter((x: any) => x.isSelected);
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < selected.length; i++) {
            const idx = backup.indexOf(selected[i]);
            if (idx - 1 === prevIdx) {
                prevIdx = idx;
            } else if (idx > 0) {
                const itemToMove = backup.splice(idx, 1);
                backup.splice(idx - 1, 0, itemToMove[0]);
            }
        }
        this.rightColumns = backup;
        this._setRightColumns(this.rightColumns);
    }

    public moveDown() {
        const backup = Object.assign([], this.rightColumns);
        let prevIdx = backup.length;
        const selected = backup.filter((x: any) => x.isSelected);
        const revPerson = selected.reverse();
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < revPerson.length; i++) {
            const idx = backup.indexOf(revPerson[i]);
            if (idx + 1 === prevIdx) {
                prevIdx = idx;
            } else if (idx < backup.length - 1) {
                const itemToMove = backup.splice(idx, 1);
                backup.splice(idx + 1, 0, itemToMove[0]);
            }
        }
        this.rightColumns = backup;
        this._setRightColumns(this.rightColumns);
    }

    private getSelectedField(source: string) {
        const cols = ((source === 'right') ? this.rightColumns : this.leftColumns);
        const col = cols.filter(
            (x: any) => x.isSelected).map(s => {
                return s.fieldName;
            }
            );
        return col;
    }

    public moveFromRightToLeft(isNotDragEnd: boolean, dragendColFieldName: string) {
        this.originalRightColumns = this.originalRightColumns.map(ogCol => {
            ogCol.isSelected = this.rightColumns.find(col => col.fieldName === ogCol.fieldName)?.isSelected;
            return ogCol;
        })

        this.originalRightColumns = this.originalRightColumns.filter(col => col.fieldName != dragendColFieldName);
        const notSelected = this.originalRightColumns.filter((x: any) => !x.isSelected );
        this._setRightColumns(notSelected, true);
        this._setLeftColumns();
        this.resetColumns(this.leftColumns);
        // this.setNextItemToSelected(isNotDragEnd, this.rightColumns, lastSelectedIndex);
        // this.setSelectColumnsToSelected(fieldNames, this.leftColumns);
        // if (!isNotDragEnd && !!dragendColFieldName) {
        //     const item = this.leftColumns.find(x => x.fieldName === dragendColFieldName) as any;
        //     item.isSelected = true;
        // }
        this.isDefaultMode = false;
    }
    public moveFromLeftToRight(isNotDragEnd: boolean, dragendColFieldName: string) {
        const fieldNames = this.getSelectedField(this.cloneFrom);
        if (fieldNames.length === 0 && !isNotDragEnd && !!dragendColFieldName) {
            const index = this.leftColumns.find(x => x.fieldName === dragendColFieldName) as any;
            this._setRightColumns(this.rightColumns, true);
            // this.setNextItemToSelected(isNotDragEnd, this.leftColumns, index);
        } else {
            const lastSelectedIndex = this.getLastSelectedIndex(this.leftColumns);
            const selected = this.leftColumns.filter((x: any) => x.isSelected);
            this.resetColumns(this.rightColumns);
            if (selected.length > 0) {
                let newOnes;
                if (!isNotDragEnd && !!dragendColFieldName) {
                    const backup = Object.assign([], this.rightColumns);
                    const index = this.rightColumns.findIndex(x => x.fieldName === dragendColFieldName);
                    const item = this.rightColumns.find(x => x.fieldName === dragendColFieldName);
                    if (!!item) {
                        selected.push(item);
                    }
                    newOnes = backup.slice(0, index).concat(this.sortColumnsAlphabetic(selected)).concat(backup.slice(index));
                    newOnes = Array.from(new Set(newOnes));
                } else {
                    newOnes = this.rightColumns.concat(selected);
                }
                this._setRightColumns(newOnes, true);
                // this.setNextItemToSelected(isNotDragEnd, this.leftColumns, lastSelectedIndex);
                // this.setSelectColumnsToSelected(fieldNames, this.leftColumns);
            }
            // if (!isNotDragEnd && !!dragendColFieldName) {
            //     const item = this.rightColumns.find(x => x.fieldName === dragendColFieldName) as any;
            //     item.isSelected = true;
            // }
        }
        this._setRightColumns(this.rightColumns, true);
        this.isDefaultMode = false;
    }

    private getLastSelectedIndex(columns: any[]): string {
        let fieldName: string = null;
        if (!!columns && columns.length > 0) {
            let length = columns.length;
            let nedeedIndex = -1;
            if (!(columns[length - 1] as any).isSelected) {
                // find last one
                while (length--) {
                    if ((columns[length] as any).isSelected && length !== (columns.length - 1)) {
                        nedeedIndex = length;
                        break;
                    }
                }
                fieldName = (nedeedIndex !== -1 && !!columns[nedeedIndex++]) ? columns[nedeedIndex++].fieldName : null;
            } else {
                // find first one
                for (let i = columns.length - 1; i >= 0; i--) {
                    if (!columns[i].isSelected) {
                        fieldName = columns[i].fieldName;
                        break;
                    }
                }
            }
        }
        return fieldName;
    }

    private setSelectColumnsToSelected(colNames: string[], columns: any) {
        columns.forEach((col: any) => {
            colNames.filter((fieldName) => {
                if (col.fieldName === fieldName) {
                    col.isSelected = true;
                }
            });
        });
    }

    private resetColumns(cols: any[]) {
        const selected = cols.filter((x: any) => x.isSelected);
        selected.forEach((x: any) => {
            x.isSelected = false;
        });
    }
    setNextItemToSelected(isNotDragEnd: boolean, columns: any[], index: string) {
        let nextItem;
        if (isNotDragEnd) {
            nextItem = columns.find(x => x.fieldName === index);
        } else {
            nextItem = (columns.length > 0) ? columns[0] : null;
        }
        if (!!nextItem) {
            columns.forEach((col: any) => {
                if (col.fieldName === nextItem.fieldName) {
                    col.isSelected = true;
                }
            });
        }
    }


    public filterLeftColumns(searchText: string) {
        this.leftSearchText = searchText;
        var filteredColumnsList : any[];
        filteredColumnsList = this.leftColumns;

        if (!!searchText && searchText.length > 0) {
            filteredColumnsList = filteredColumnsList.filter(col =>
                searchForText(searchText, col.display)
            );
        }
        this.nestedPathsList = (filteredColumnsList.map(col=>{
            if(col.nestedPath){
               return col.nestedPath?.split(".")[0]
            }else{
                return "";
            }
        })).filter((value, index, arr) => arr.indexOf(value) === index);

        this.columnsGroupedByPath = this._groupColumnsByNestedPath(filteredColumnsList);

        if (this.columnsGroupedByPath.size === 1 && this.columnsGroupedByPath.has('')) {
            this.filteredLeftColumns = [...this.columnsGroupedByPath.get('')];
        } else {
            this.filteredLeftColumns = [];
            this.columnsGroupedByPath.forEach((columns: any[], path: string) => {
                this.filteredLeftColumns.push(path);
                this.filteredLeftColumns.push(...columns);
            })
        }
    }

    public filterRightColumns(searchText: string) {
        this.rightSearchText = searchText;

        if (!!searchText && searchText.length > 0) {
            this.filteredRightColumns = this.rightColumns.filter(x => searchForText(searchText, x.display) );
        } else {
            this.filteredRightColumns = [...this.rightColumns];
        }
    }

    public selectColumnsByNestedPath(nestedPath : string) : any[] {
        this.filteredLeftColumns = this.columnsGroupedByPath.get(nestedPath);
        return this.filteredLeftColumns;
    }

    private _groupColumnsByNestedPath(filtered : any[]): Map<string, any[]> {
        let map = new Map<string, any[]>();
        this.nestedPathsList.forEach( nestedPath =>
            nestedPath === '' ?
                map.set(nestedPath, filtered?.filter(col => !col.nestedPath)) :
                map.set(nestedPath, filtered?.filter(col => col.nestedPath?.split(".")[0] == nestedPath))
        );
        return map;
    }

    private sortColumnsAlphabetic(list: any[]): any[] {
        return list.sort((a, b) => a.display.localeCompare(b.display));
    }

    private _setLeftColumns():void {
        if (!!this.allColumns && !!this.rightColumns) {
            const missing = this.allColumns.filter(x => this.rightColumns
                .filter(r => !!r && r.fieldName === x.fieldName).length === 0);
            const rootCols = missing.filter(col => !col.nestedPath);
            const others = missing.filter(col => col.nestedPath);
            this.leftColumns = this.sortColumnsAlphabetic(rootCols).concat(this.sortColumnsAlphabetic(others));
            this.filterLeftColumns(this.leftSearchText);
        }
    }

    private _setRightColumns(columns: any[], setOriginal: boolean = false):void {
        columns.forEach((col: any, i) => {
            col.order = i;
            col.isSelected = false;
        });
        this.rightColumnsOutput.emit(columns);
        this.rightColumns = columns;
        if(setOriginal){
            this.originalRightColumns = columns;
        }
        this.filterRightColumns(this.rightSearchText);
    }
}
