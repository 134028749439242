import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[layoutDir]'
})
export class LayoutDirective {
  @Input() layoutDir: string;
  @HostBinding('style.display') display = 'flex';

  @HostBinding('style.flex-direction')
  get direction() {
    return (this.layoutDir === 'column') ? 'column' : 'row';
  }
}
@Directive({
  selector: '[flex]'
})
export class FlexDirective {
  @Input() shrink: number = 1;
  @Input() grow: number = 1;
  @Input() flex: string;

  @HostBinding('style.flex')
  get style() {
    return `${this.grow} ${this.shrink} ${this.flex === '' ? '0' : this.flex}%`;
  }
}
