
import { NgModule } from '@angular/core';
import { DateRangeFacetComponent } from './date-range-facet.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DiscoHistogramModule } from '../histogram/histogram.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDaterangePicker } from './mat-daterange-picker/mat-daterange-picker.component';
import { DateOperationField } from './mat-daterange-picker/date-operation-field/date-operation-field.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FacetActionsModule } from '../facet-actions/facet-actions.module';
@NgModule({
    imports: [

        MatIconModule,
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatSelectModule,
        MatDatepickerModule,
        DiscoHistogramModule,
        MatDividerModule,
        MatButtonToggleModule,
        FacetActionsModule
    ],
    declarations: [
        DateRangeFacetComponent,
        MatDaterangePicker,
        DateOperationField,
        
    ],
    entryComponents: [],
    providers: [],
    exports: [
        DateRangeFacetComponent
    ]
})
export class DateRangeFacetModule {
    constructor() {
    }
}
