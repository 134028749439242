import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {KendoReactWrapperComponent} from './kendo-react-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        KendoReactWrapperComponent
    ],
    entryComponents: [],
    providers: [],
    exports: [
        KendoReactWrapperComponent
    ]
})
export class KendoReactWrapperModule {
    constructor() {

    }
}
