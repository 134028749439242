<!-- Attachments -->
<div class="attachments" *ngIf="fileNames?.length">
    <div class="attachments-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 max-h-40 overflow-auto">
        <div *ngFor="let filename of fileNames"
            class="attachment flex items-center gap-2 p-3 rounded-lg border border-gray-200 dark:border-gray-700">
            <div class="flex-1">
                <div class="font-medium text-sm">{{ filename }}</div>
            </div>
            <button (click)="downloadAttachment(filename)"
                class="download-btn p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full">
                <mat-icon>download</mat-icon>
            </button>
        </div>
    </div>

    <div class="flex items-center gap-4 mt-2 ml-2 text-sm">
        <button (click)="saveAllAttachments()" class="text-primary hover:underline">Save All
            Attachments</button>
    </div>
</div>