import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import {
    AppSettingsService,
    DiscovererQueryService,
    FacetValue,
    IColumnSetting,
    IDashboardFilter,
    ITab2,
    ReportPersistService,
    TabSettingsService
} from '@discoverer/core/services';
import { DialogsManagerService } from '@discoverer/dynamic-reports/dialogs';

@Component({
    selector: 'applied-dashboard-filters',
    templateUrl: './applied-dashboard-filters.component.html',
    styleUrls: ['./applied-dashboard-filters.component.scss']
})
export class AppliedDashboardFiltersComponent implements OnInit {
    @Input() public queryService: DiscovererQueryService;
    @Input() editMode: boolean;
    public currentTabs: { [key: string]: ITab2 } = {};
    public columns: IColumnSetting[] = [];

    constructor(
        public reportPersistService: ReportPersistService,
        private _dialogManagerService: DialogsManagerService,
        private _tabSettings: TabSettingsService,
        private _appSettings: AppSettingsService
    ) { }

    async ngOnInit() {
        await this.loadTabsAndColumns();
    }
    private async loadTabsAndColumns() {
      const tabs = await this._appSettings.getCurrentAppTabs();
      this.currentTabs = Object.assign({}, ...tabs.map((tab) => ({ [tab.key]: tab })));
  
      for (const tab of tabs) {
        const cols = await this._tabSettings.getTabColumns(tab);
        this.columns.push(...cols);
      }
    }
    public getAppliedTabs(tabKeys: string[]): string {
        return tabKeys.map(tabKey => this.currentTabs[tabKey]?.name).join(', ');
    }
    public getTitle(facetValues: FacetValue[]) {
        return facetValues.map(val => val.title);
    }
    public removeFilter(key: string) {
        const filters = this.reportPersistService.dashboardViewState.getState().filters || [];
        const newFilters = filters.filter(filter => filter.filter.fields[0] !== key);
        this.reportPersistService.dashboardViewState.setField('filters', newFilters);
    }
    async editFilter(filter: IDashboardFilter) {
        const updatedFilter = await this._dialogManagerService.openFilterSelectorDialog(filter).toPromise();
        if (updatedFilter) {
            const filters = this.reportPersistService.dashboardViewState.getState().filters || [];
            const index = filters.findIndex(filter => filter.filter.fields[0] === updatedFilter.filter.fields[0]);
            if (index >= 0) {
                filters[index] = updatedFilter;
            } else {
                filters.push(updatedFilter);
            }
            this.reportPersistService.dashboardViewState.setField('filters', filters);
        }
    }
    public trackByFields(index: number, dashboardFilter: IDashboardFilter) {
        return dashboardFilter.filter.fields[0];
    }
    public getCol(key) {
        return this.columns?.find(col => col.fieldName === key);
    }
}
