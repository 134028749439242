import { GroupBy, IColumn } from '..';
import { fixTimeZone } from '../functions';
import { BaseController } from '../base-controller';


const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',
    'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

interface MyDate extends Date {
    getWeek(): number;
    getWeekYear(): number;
}

Date.prototype['getWeek'] = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype['getWeekYear'] = function () {
    var date = new Date(this.getTime());
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
}

export class BaseDataFacetService extends BaseController {

    protected columns: { [key: string]: IColumn } = {};


    constructor(private colArray: IColumn[],
        protected serviceUrl: string) {
        super();
        this.setColumns(colArray);
    }

    public destroy() {
        this.subscriptions.forEach(sub => {
            if (sub != null) {
                sub.unsubscribe();
                sub = null;
            }
        });
    }

    protected mapGroupedData(groupByConfig: { colName: string, groupBy?: GroupBy }, a: string) {
        if (groupByConfig && groupByConfig.colName && !!a) {
            const col = this.columns[groupByConfig.colName];
            switch (col.type) {
                case 'text':
                case 'boolean':
                    return a;
                case 'date':
                    const mDate = fixTimeZone(a.split('T')[0]) as MyDate;
                    if (groupByConfig.groupBy) {
                        switch (groupByConfig.groupBy.orginalGap) {
                            case 'QUARTER':
                                const quarter = Math.floor(mDate.getMonth() / 3) + 1;
                                return 'Q' + quarter + ' ' + mDate.getFullYear();
                            case 'YEAR':
                                return mDate.getFullYear() + '';
                            case 'WEEK':
                            case 'DAILY WEEK':
                                return monthNames[mDate.getMonth()] + ' ' + mDate.getDate() + '-' + mDate.getFullYear();
                            case 'DAILY MONTH':
                                return mDate.getDate() + '-' + monthNames[mDate.getMonth()] + " " + mDate.toLocaleDateString('en-US', { weekday: 'short' });
                            case 'MONTH':
                                return monthNames[mDate.getMonth()] + '-' + mDate.getFullYear();
                            case 'DAY':
                                return monthNames[mDate.getMonth()] + ' ' + mDate.getDate() + '-' + mDate.getFullYear();
                        }
                    } else {
                      return monthNames[mDate.getMonth()] + '-' + mDate.getFullYear();
                    }
                    break;
                case 'numeric':
                    return a; // + '-' + (parseFloat(a) + parseFloat((groupByConfig.groupBy) ? groupByConfig.groupBy.orginalGap : '1') - 1);
            }
        }
        return a;
    }

    protected getSingleFacetDef(colName: string, groupBy: GroupBy, limit: number, minCount: number = 1, statSortDir: number = 0, includeAllFilters = false) {
        const column = this.columns[colName];
        const facetDef = {
            type: '',
            name: colName,
            field: column?.fieldName,
            limit,
            statSortDir,
            mincount: minCount,
            sort: 'stat1 asc',
            includeAllFilters,
            subFacet: null
        };

        switch (column?.type) {
            case 'text':
                facetDef.type = 'terms';
                facetDef['mincount'] = minCount;
                break;
            case 'boolean':
                facetDef.type = 'terms';
                facetDef['mincount'] = minCount;
                break;
            case 'date':
                facetDef.type = 'range';

                facetDef['start'] = (groupBy) ? groupBy.start : 'NOW/MONTH-24MONTHS';
                facetDef['end'] = (groupBy) ? groupBy.end : 'NOW/MONTH';
                facetDef['gap'] = (groupBy) ? groupBy.gap : '+1MONTH';
                break;
            case 'numeric':
                facetDef.type = 'range';
                facetDef['start'] = (groupBy) ? groupBy.start : 0;
                facetDef['end'] = (groupBy) ? groupBy.end : 1;
                facetDef['gap'] = (groupBy) ? groupBy.gap : 1;
                break;
            default:
                facetDef.type = 'terms';
                facetDef['mincount'] = minCount;
                break;
        }
        return facetDef;
    }


    private setColumns(colArray: IColumn[]) {
        this.columns = {};
        colArray.forEach(col => {
            this.columns[col.fieldName] = col;
        });
    }


}
