<div class="options-container" *ngIf="option && mode!='display'; else display">
    <form [formGroup]="optionDetailsForm" style="display: flex;">
        <mat-form-field class="input-section">
            <mat-label class="title">Display</mat-label>
            <input matInput type="text" [formControl]="$any(optionDetailsForm).controls.description" autocomplete="off">
            <mat-error class="error-messsage"
                *ngIf="!optionDetailsForm.controls.description.valid && optionDetailsForm.controls.description.touched">
                Option
                Description is required.</mat-error>
        </mat-form-field>

        <mat-form-field class="input-section" style="width: 80px;">
            <mat-label class="title"> Code </mat-label>
            <input matInput type="text" [formControl]="$any(optionDetailsForm).controls.code" autocomplete="off">
        </mat-form-field>
        <mat-form-field class="input-section" style="width: 80px;">
            <mat-label class="title"> Value </mat-label>
            <input matInput type="text" [formControl]="$any(optionDetailsForm).controls.value" autocomplete="off">
        </mat-form-field>
        <mat-form-field class="input-section" style="width: 80px;">
            <mat-label class="title"> Group </mat-label>
            <input matInput type="text" [formControl]="$any(optionDetailsForm).controls.group" autocomplete="off">
        </mat-form-field>
    </form>

    <div class="buttons-row">
        <button type="submit" mat-button (click)="saveChanges();$event.stopPropagation();" color="primary">
            <span class="save-button">SAVE</span>
        </button>
        <button mat-button (click)="cancelChanges();$event.stopPropagation();">CANCEL</button>
        <button *ngIf="mode == 'edit'" mat-button class="delete-button"
            (click)="deleteOption($event,option)">DELETE</button>
    </div>

</div>
<ng-template #display>
    <div style="height: 30px;">
        {{option?.description}}
    </div>
</ng-template>