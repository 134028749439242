import { Pipe, PipeTransform } from '@angular/core';
import { searchForText } from '@discoverer/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, key: string): any[] {
        if (!items) {
            return items;
        }
        if (!searchText) {
            return items;
        }
        return items.filter(item => searchForText(searchText, item[key]));
    }
}
