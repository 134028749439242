<div class="stats-dialog-container">
    <div class="state-content">
        <div class="series-clear">
            <auto-complete-search label="Field Name" *ngIf="StatColmuns" class="select-field" [fieldName]="colName"
                [availableColumns]="StatColmuns" (changeFieldName)="setStatColumn($event)">
            </auto-complete-search>
        </div>
        <mat-form-field class="state-function">
            <mat-label>Function</mat-label>
            <mat-select placeholder="Function type" [(value)]="functionType">
                <mat-option *ngFor="let Stat of StatList" [value]="Stat" (click)="setStatFunction(Stat)">{{Stat}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="state-label">
            <mat-label>Label</mat-label>
            <input matInput placeholder="Function type" [(ngModel)]="label">
        </mat-form-field>
    </div>
    <div mat-dialog-actions class="footer">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button class="state-dialog-button" mat-raised-button (click)="save()">Done</button>
    </div>
</div>
