import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AbstractNavigationItemsService, Navigation } from '@discoverer/app-core/navigation/navigation.types';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(public _navigationItemsService: AbstractNavigationItemsService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._navigationItemsService.getNavigationItems().pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }

    set(navigation: Navigation) {
        this._navigation.next(navigation);
    }
}
