<div id="dynamic-filter">
  <div class="main-wrapper">
    <div *ngIf="hasFacet">
      <div class="form-group">
        <mat-radio-group [ngModel]="isDefaultFilter" (change)="useCustomFilters($event.value)">
          <mat-radio-button class="radio-button first" [value]="true">Default Filter
          </mat-radio-button>
          <mat-radio-button class="radio-button" [value]="false">Custom Filter
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="default-columns" layoutDir="column" *ngIf="isDefaultFilter">
        <div class="default-area">
          <div class="item" *ngFor="let col of defaultColumns; let i=index">{{col?.display}}
          </div>
        </div>
      </div>
      <div class="custom-columns" *ngIf="!isDefaultFilter">
        <left-right-columns-settings [label]="'Select the dynamic filters'" [allColumns]="filterAllColumns"
          [rightColumns]="rightColumns" [defaultLabel]="'Reset filters'"
          (rightColumnsOutput)="updateCustomFilter($event)" (setDefaultSettingsEvent)="updateDefaultFilter($event)">
        </left-right-columns-settings>
      </div>

    </div>
    <div *ngIf="!hasFacet">
      <span>there is no default filter </span>
    </div>
  </div>

</div>
