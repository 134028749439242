import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptorService } from './services/interceptor.service';
import { LandingComponent } from './landing/landing.component';
import { AuthService } from './services/auth.service';
import { AUTH_SERVER_URL, AUTH_PRODUCT_ID, AUTH_USE_PRODUCT_LOGIN,AUTH_MAIN_ACCOUNT_ID } from './services/auth.model';


@NgModule({
  declarations: [LandingComponent],
  imports: [
    HttpClientModule
  ],
  providers: [
  ],
  exports: [
    LandingComponent
  ]
})
export class GatewayAuthModule {

  static forRoot(authServerUrl: string, authProductId: string, authUseProductLogin?: boolean, authMainAccountId?: number): ModuleWithProviders<GatewayAuthModule> {

    return {
      ngModule: GatewayAuthModule,
      providers: [
        { provide: AUTH_SERVER_URL, useValue: authServerUrl },
        { provide: AUTH_PRODUCT_ID, useValue: authProductId },
        { provide: AUTH_USE_PRODUCT_LOGIN, useValue: authUseProductLogin },
        { provide: AUTH_MAIN_ACCOUNT_ID, useValue: authMainAccountId },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        AuthService
      ]
    };
  }
}
