<kendo-grid class="grid" #grid [data]="model" [resizable]="true" [trackBy]="trackByIndex"
    matTooltip="Click on row to start editing" (cellClick)="cellClickHandler($event)" (remove)="removeHandler($event)"
    (save)="saveHandler($event)" (edit)="editHandler($event)" (add)="addHandler($event)">|
    (keydown)="onTabKeyDown($event)">
    <kendo-grid-column *ngFor="let col of tableColumns" field="{{ col.fieldName }}" [editor]="col.type" [width]="150"
        title="{{ col.display }}"></kendo-grid-column>
    <kendo-grid-command-column [width]="100">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
            <button kendoGridRemoveCommand *ngIf="!isLastRow(rowIndex)" matTooltip="Delete this row"
                aria-label="Delete this row">
                <mat-icon>delete</mat-icon>
            </button>
            <button class="cancel-button" (click)="exitEditMode(rowIndex)" *ngIf="editedRowIndex === rowIndex"
                matTooltip="Cancel editing" aria-label="Cancel editing">
                <mat-icon>close</mat-icon>
            </button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
