import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationStart, RouterEvent } from '@angular/router';
import { merge } from 'rxjs';
import { tap, takeUntil, take, mapTo, filter } from 'rxjs/operators';
import { DialogRouteConfig } from './models/dialog-route-config.model';
import { Location } from '@angular/common';

@Injectable()
export class DialogResolverService implements Resolve<MatDialogRef<any>> {
    dialogRef: MatDialogRef<any>;
    constructor(
        public dialog: MatDialog,
        public router: Router,
        public location: Location) { }

    resolve(nextRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
        let redirect: string[];
        let cfg: DialogRouteConfig;
        let replace: boolean;
        let isBackOnly = false;
        const { data } = nextRoute;
        if (!!data && !!data.dlg) {
            const { redirectPath, replaceUrl, ...dlgCfg } = data.dlg as DialogRouteConfig;
            redirect = redirectPath;
            cfg = dlgCfg;
            replace = replaceUrl;
            isBackOnly = cfg.data?.isBack;
        } else {
            const { redirectPath, replaceUrl, ...dlgCfg } = {} as DialogRouteConfig;
            redirect = redirectPath;
            cfg = dlgCfg;
            replace = replaceUrl;
        }
        cfg.data.routeSnapshot = nextRoute;
        const baseUrl = routerState.url.substring(0, routerState.url.lastIndexOf('('));
        const returnUrl = redirect || (nextRoute.queryParams && nextRoute.queryParams.returnUrl);
        let navigateAfterClose = (!!returnUrl && returnUrl != 'default' ? returnUrl : baseUrl).split('/').filter(x => x != '');

        this.dialogRef = this.dialog.open(nextRoute.routeConfig.component, { ...cfg, autoFocus: cfg.autoFocus || false, closeOnNavigation: true });
        merge(this.dialogRef.backdropClick(),
            this.dialogRef.afterClosed()).pipe(
                take(1),
            ).subscribe(async result => {
                if (!!this.dialogRef) {
                    if (isBackOnly) {
                        this.location.back()
                    } else {
                        await this.router.navigate([{ outlets: { dialog: null } }], { replaceUrl: true });
                        this.router.navigate(['/', ...navigateAfterClose], { replaceUrl: true, queryParams: { refresh: result?.refresh ?? false } });
                    }
                }
            });
        this.router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationStart),
                filter(() => !!this.dialogRef),
                takeUntil(this.dialogRef.afterClosed()),
                filter((event: NavigationStart) => {
                    const currentUrl = this.router.url;
                    const nextUrl = event.url;
                    return !nextUrl.includes('detail');
                })
            )
            .subscribe(() => {
                this.dialogRef.close();
                this.dialogRef = null;
            });

        return this.dialogRef.afterOpened().pipe(
            mapTo(this.dialogRef),
            takeUntil(merge(this.dialogRef.backdropClick(),
                this.dialogRef.afterClosed())),
        );
    }
}
