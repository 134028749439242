<mat-chip-list #chipList>
    <ng-container *ngIf="selectedOptions">
        <mat-chip *ngFor="let option of selectedOptions">
            {{option?.description}}
        </mat-chip>
    </ng-container>
    <mat-chip  [title]="editButtonText">
        <mat-icon class="icon-size-5 text-secondary " [svgIcon]="'heroicons_outline:pencil-alt'"
            (click)="edit()" ></mat-icon>
    </mat-chip>
</mat-chip-list>