import { Component, Input, ViewChild, EventEmitter, OnInit, Output, ElementRef } from '@angular/core';

import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import {
    TabSettingsService,
    AppSettingsService,
    ReportPersistService
} from '../services';
import { disLogger } from '../services/functions';
import { DiscovererQueryService, DiscovererDataService, ComponentUtilityService, IColumnSetting } from '../services';
import { HttpClient } from '@angular/common/http';
import { EsQueryService } from '../services/core-data-services/discoverer-esquery-service';

const LIMIT_DATA_SIZE_IN_BYTE = 300771563;
const LIMIT_DATA_SIZE_IN_PAGE_COUNT = 10000;


@Component({
    selector: 'kendo-excel-export',
    styleUrls: ['./kendo-excel-export.component.scss'],
    templateUrl: './kendo-excel-export.component.html'
})
export class KendoExcelExportComponent implements OnInit {

    @Input() public mainQueryService: DiscovererQueryService;
    @Input() public excelName: string;
    @Input() public excelPageSize: number;


    @Output() DownloadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('excelexport', { static: true }) public excelExport: ExcelExportComponent;
    @ViewChild('form', { static: true }) public formElement: ElementRef;



    public columnSettings: IColumnSetting[];
    public currentReqId: any;
    public baseHref: string;
    public deflated: any;
    public inflated: any;
    dataService: DiscovererDataService<any>;

    private dialogOpen = false;


    constructor(
        private http: HttpClient,
        private reportSettings: ReportPersistService,
        private tabSettings: TabSettingsService,
        private utility: ComponentUtilityService,
        private esQueryService:EsQueryService) {
    }

    public ngOnInit() {
        if (!!this.mainQueryService) {
            if (this.excelPageSize <= LIMIT_DATA_SIZE_IN_PAGE_COUNT) {
                this.exportToExcel();
            } else {
                if (!this.dialogOpen) {
                    this.dialogOpen = true;
                    this.utility.openMessageDialog('Excel export failed',
                        'Production exports are limited to < 20000 rows. Filter the table data to less than this to continue.',
                        true).subscribe((res) => {
                            setTimeout(() => this.dialogOpen = false, 1000);
                        });
                }
            }
        }
    }

    private async exportToExcel() {

        this.DownloadingEvent.emit(true);
        this.excelExport.data = null;
        this.columnSettings = await this.getExportColumns();
        this.dataService = await this.createDataService();
        const fields: Array<string> = this.columnSettings.map(s => s.fieldName).filter(n => !this.isEmpty(n));

        this.dataService.setFields(fields);

        const start = new Date();
        const subs = this.dataService.oData.subscribe(async exportedData => {
            if (exportedData.length > 0) {
                const duration = (new Date()).getTime() - start.getTime();
                const options = await this.addExcelOptions(exportedData);
                this.excelExport.data = exportedData;
                // this.excelExport.save(options);

                const dataURL = await this.excelExport.toDataURL(options);
                this.postToProxy(dataURL, this.excelExport.fileName, {
                    proxyURL: this.excelExport.proxyURL
                });

                setTimeout(() => {
                    this.DownloadingEvent.emit(false);
                }, duration / 1.3);

            } else if (exportedData.length <= LIMIT_DATA_SIZE_IN_PAGE_COUNT) {
                disLogger('Too much Data to Export');
                this.DownloadingEvent.emit(false);
            }
            if (!!subs) {
                subs.unsubscribe();
            }
            this.dataService.destroy();
        });
        this.dataService.refresh();
    }




    private postToProxy(dataURI, fileName, options) {
        if (!options.proxyURL) {
            return;
        }
        const form = document.createElement('form');
        form.setAttribute('action', options.proxyURL);
        form.setAttribute('method', 'POST');
        form.setAttribute('target', options.proxyTarget || '_self');

        const formData = options.proxyData || {};
        formData.fileName = fileName;

        const parts = dataURI.split(';base64,');
        formData.contentType = parts[0].replace('data:', '');
        formData.base64 = parts[1];

        for (const name in formData) {
            if (formData.hasOwnProperty(name)) {
                const input = document.createElement('input');
                input.setAttribute('type', 'hidden');
                input.setAttribute('name', name);
                input.setAttribute('value', formData[name]);
                form.appendChild(input);
            }
        }

        this.formElement.nativeElement.appendChild(form);
        form.submit();
        this.formElement.nativeElement.removeChild(form);

        return form;
    }

    private async addExcelOptions(excelData: any[]) {
        const options = this.excelExport.workbookOptions();
        const rows = options.sheets[0].rows;
        const header = (!!rows[0]) ? rows[0].cells : [];
        excelData.forEach((row) => {
            const newCells = [];

            header.forEach((col) => {
                const colKey = this.keyFromFieldName(col.value);
                newCells.push({ value: row[colKey] });
            });
            rows.push({
                cells: newCells
            });
        });
        return options;
    }


    private keyFromFieldName(fieldName) {
        const col = this.columnSettings.find(c => c.fieldName === fieldName);
        return (!!col) ? col.fieldName : null;
    }

    private async createDataService(): Promise<DiscovererDataService<any>> {
        const currentTab = await this.tabSettings.getCurrentTab();
        this.dataService = new DiscovererDataService(this.http,this.esQueryService, await this.tabSettings.getExcelDataMapper());
        this.dataService.init(currentTab.serviceUrl, this.mainQueryService, 'excel-full');
        this.dataService.enabled = true;
        this.dataService.setPageParams(1, 10000);
        return this.dataService;
    }

    private async getExportColumns() {
        if (this.reportSettings.mainViewState.getState().isDefaultColumns) {
            this.columnSettings = await this.tabSettings.getDefaultColumns();
        } else {
            if (!!this.reportSettings.tableViewState.getState().columnSettings) {
                this.columnSettings = this.reportSettings.tableViewState.getState().columnSettings.sort((a, b) => (a.order - b.order));
            }
        }
        const newCols = Object.assign([], this.columnSettings);
        return newCols;
    }

    private isEmpty(str: string): boolean {
      return ( !str || str.length === 0 || /^\s*$/.test(str));
    }
}
