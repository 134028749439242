<div *ngIf="histogramData.length > 0">
    <kendo-chart (seriesClick)="seriesClick($event)" [seriesColors]="['var(--base-color)']"
        [chartArea]="{background: 'var(--chart-area)'}" class="facet-chart">
        <kendo-chart-series>
            <kendo-chart-series-item type="column" [data]="histogramData" field="value" categoryField="tag">
                <kendo-chart-series-item-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate
                        let-dataItem="dataItem" let-value="value" let-category="category">
                        <div>{{value | number }}</div>
                        <div>{{category}}</div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>
        </kendo-chart-series>

        <kendo-chart-category-axis>
            <kendo-chart-category-axis-item>
                <kendo-chart-category-axis-item-labels [visible]="false">
                </kendo-chart-category-axis-item-labels>
            </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
    </kendo-chart>

    <div class="range">
        <span class="tag">{{range?.min}}</span>
        <span class="tag">{{range?.max}}</span>
    </div>
</div>
