<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<ng-container *ngIf="isScreenSmall">
    <button class="toggle-button" mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
</ng-container>

<!-- Navigation -->
<fuse-vertical-navigation id="fuse-vertical-navigation-main" class="dark bg-gray-900 print:hidden"
    [appearance]="'compact'" [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'"
    [navigation]="navigation.items" [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20 mt-3 mb-4">
            <img class="w-20" src="assets/images/logo/logo.png" alt="Logo image">
        </div>
    </ng-container>
    <ng-container fuseVerticalNavigationFooter>
        <!-- User -->
        <div class="flex items-center w-full px-6 py-8 border-t">
            <div class="flex flex-col w-full">
                <user></user>
                <span class="user-name">{{user.name}}</span>
            </div>
            <!-- <div class="flex flex-col w-full ml-4 overflow-hidden">
                 <div
                     class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden leading-normal text-current opacity-80">

                 </div>
                 <div
                     class="w-full mt-0.5 whitespace-nowrap text-sm overflow-ellipsis overflow-hidden leading-normal text-current opacity-50">
                     brian.hughes@company.com
                 </div>
             </div> -->
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">



    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>


</div>
