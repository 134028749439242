
    <ng-container *ngIf="loading" class="loading-area ">
        <ngx-skeleton-loader *ngIf="loading" count="1" animation="progress-dark" [theme]="{
        height: '100%',
        opacity: '0.8'
        }">
        </ngx-skeleton-loader>
    </ng-container>
    <ng-container *ngIf="!loading">


        <div class="h-full" *ngIf="originalFileDetails && originalFileDetails?.type === FileViewerType.Normal">
            <iframe width="100%" height="100%" [src]="originalFileDetails?.url"></iframe>
        </div>

        <div *ngIf="originalFileDetails && originalFileDetails.type  === FileViewerType.Downloaded">
            <div (click)="downloadFile()" class="download-file-view">
                <div class='title'>
                    Download File
                </div>
                <div *ngIf="fileName && fileExtension" class="file-info">
                    {{fileName}}.{{fileExtension}}
                </div>
                <button class="download-button">
                    <mat-icon>cloud_download</mat-icon>
                </button>
            </div>
        </div>

        <div class="tree-file-view" *ngIf="originalFileDetails && originalFileDetails.type  === FileViewerType.Nested">
            <file-tree-viewer (fileDownload)="downLoadNestedType($event)"
                *ngIf="fileId && !loading && extractedFileList " [fileNodes]="extractedFileList">
            </file-tree-viewer>
        </div>
        <div class="h-full" *ngIf="originalFileDetails && originalFileDetails.type == FileViewerType.Custom">
            <app-email-viewer [fileId]="fileId" [datasetId]="datasetId"></app-email-viewer>
        </div>

    </ng-container>
