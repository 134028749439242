import { Component, Input, OnInit } from '@angular/core';
import { BaseController, DiscovererQueryService, ITab2, TabSettingsService } from '../services';
import { interval } from 'rxjs';

@Component({
  selector: 'timed-refresh',
  template: '',
  styleUrls: ['./timed-refresh.component.scss']
})
export class TimedRefreshComponent extends BaseController implements OnInit {

  @Input() queryService: DiscovererQueryService

  currentTab: ITab2

  constructor(
    private _tabSettingsService: TabSettingsService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.currentTab = await this._tabSettingsService.getCurrentTab()
    if (!!this.currentTab.refreshTimeout) {
      console.log(' timeout found', this.currentTab.refreshTimeout)
      this.subscriptions.push(interval(this.currentTab.refreshTimeout * 1000).subscribe(
        () => this.queryService.refresh()
      ))
    }
  }
}
