import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, TemplateRef, ContentChild, ViewChild } from '@angular/core';
import { DialogsManagerService } from '../../dialogs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ControlBarDataService, IDynamicReport, ReportPersistService, ITab2, IChartState, ITableViewState, TabSettingsService, IKanbanViewState, MultiSelectService, DefaultPivotLimit, EsQueryService } from '@discoverer/core/services';
import { BaseController, DiscovererQueryService, ICategories, IChartDataConfig, IChartFormatConfig, IColumnSetting, IMetric, IPivotConfig, RequestFilingService } from '@discoverer/core/services';
import { Permission, UserPermissionService, disLogger } from '@discoverer/core/services';
import { Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthService, RolesService } from '@discoverer/app-core';
import { RoleChecker } from '@discoverer/core/services';
import { Router } from '@angular/router';
import { ExportReportComponent } from '../export-to-excel/export.component';
import { PopoverService } from '@discoverer/dynamic-reports/popover/popover.service';
import { MatDialog } from '@angular/material/dialog';

export enum ViewTypes {
    Table = 'table-view',
    Chart = 'chart-view',
    Pivot = 'pivot-view',
    Kanban = 'kanban-view',
    Dashboard = 'dashboard-view'
}
export interface IViewType {
    name: string;
    icon: string;
    title: string;
}
@Component({
    selector: 'control-bar',
    styleUrls: ['./control-bar.component.scss'],
    templateUrl: './control-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: RoleChecker, useClass: RolesService }
    ]
})

export class ControlBarComponent extends BaseController implements OnInit, OnDestroy {
    @Input() public queryService: DiscovererQueryService;
    @Input() public set currentTab(value: ITab2) {
        if (!!value) {
            this.tabName = value.name;
            this.tabKey = value.key;
            this.defaultRequestId = value.defaultRequestId;
            this.currentAppKey = value.app.key;
        }
        this._currentTab = value;
    }
    public get currentTab(): ITab2 {
        return this._currentTab;
    }
    @Input() public enableSelect;
    @Input() public reportType = '';
    @Input() public enableExport = true;
    @ContentChild(TemplateRef) template: TemplateRef<ElementRef>;
    @Output() public exportToExcelEvent = new EventEmitter<boolean>();
    @Output() public enableMultiSelect = new EventEmitter<boolean>();

    @ViewChild('exportReport', { static: false }) exportReport: ExportReportComponent;


    public tabName: string;
    public tabKey: string;
    public defaultRequestId;
    public currentAppKey;
    public isNewReport = false;
    public isAdmin = false;
    public mainTitle: string;
    public subTitle: string;
    public reportDir: string;
    public directoryId: number = null;
    public processingRequest = false;
    public viewType: IViewType;

    //report permissions
    public canEditReport: boolean;
    public canDeleteReport: boolean;
    public canSwitchReport: boolean;
    public canAddReport: boolean;

    public multiSelect: boolean = false;
    public viewTypes: IViewType[] = [{ name: ViewTypes.Table, icon: 'table_chart', title: 'Table' },
    { name: ViewTypes.Chart, icon: 'bar_chart', title: 'Chart' },
    { name: ViewTypes.Pivot, icon: 'pivot_table_chart', title: 'Pivot' },
    { name: ViewTypes.Kanban, icon: 'view_column', title: 'Kanban' }];

    private reportNameSubject: Subject<string> = new Subject();
    private _currentTab: ITab2;

    constructor(
        public reportPersist: ReportPersistService,
        private dialog: MatDialog,
        private requestFilingService: RequestFilingService,
        private dialogsManagerService: DialogsManagerService,
        private snackBar: MatSnackBar,
        private controlBarDataService: ControlBarDataService,
        private _cdRef: ChangeDetectorRef,
        private _tabSettings: TabSettingsService,
        private _eref: ElementRef,
        private _roleCheck: RoleChecker,
        private _router: Router,
        private _authService: AuthService,
        private _multiSelect: MultiSelectService,
        private _userPermissionService: UserPermissionService,
        private esQueryService : EsQueryService,
        private popoverService: PopoverService) {
        super();

    }

    public async changeReportType(toType: string) {
        this.reportPersist.mainViewState.setField('type', toType);
        var mainViewState = this.reportPersist.mainViewState.getState();
        var tableViewState = this.reportPersist.tableViewState.getState();
        var kanbanViewState = this.reportPersist.kanbanViewState.getState();
        var pivotChartState = this.reportPersist.pivotChartState.getState();
        var chartState = this.reportPersist.chartState.getState();
        var colsDictionary = await this._tabSettings.getColumnDictionary();
        var groupByCandidate = (reportType: string): string => {
            if (reportType === 'chart-view' && !!chartState?.chartConfig?.categories) {
                return chartState.chartConfig.categories.colName;
            }
            if (reportType === 'pivot-view' && !!pivotChartState?.categories) {
                return pivotChartState.categories.colName;
            }
            if (reportType === 'kanban-view' && !!kanbanViewState?.groupByColumn?.field) {
                return kanbanViewState.groupByColumn.field
            }
            var groupByColumn = null;
            if (!!this.reportPersist.mainQueryService?.groups?.length) {
                // get first group key
                var keyName = Object.keys(this.reportPersist.mainQueryService.groups)[0];
                groupByColumn = this.reportPersist.mainQueryService.groups[+keyName].field;
            }
            if (!!this.reportPersist.mainQueryService?.filters?.length) {
                // get first filter key
                var keyName = Object.keys(this.reportPersist.mainQueryService.filters)[0];
                groupByColumn = this.reportPersist.mainQueryService.filters[keyName].fields[0];
            }
            if (!!mainViewState?.facets?.length) {
                groupByColumn = mainViewState?.facets[0].name;
            }
            if (!!tableViewState.columnSettings?.length) {
                groupByColumn = tableViewState.columnSettings.filter(f => f.fieldName != '')[0].fieldName;
            }
            if (reportType === 'kanban-view' && (groupByColumn == 'timestamp' || groupByColumn == 'date' || groupByColumn == 'timestamptz')) {
                return tableViewState.columnSettings.filter(f => f.fieldName != '' && f.dataType != 'timestamp' && f.dataType != 'date' && f.dataType != 'timestamptz')[0].fieldName;
            }
            return groupByColumn;
        };

        switch (toType) {
            case 'table-view':
                if (!tableViewState?.columnSettings && !tableViewState?.columnSettings.length) {
                    tableViewState = (tableViewState ?? {} as ITableViewState);
                    if (!!chartState?.chartConfig?.categories) {
                        tableViewState.columnSettings = [colsDictionary[chartState.chartConfig.categories.colName]]
                    } else if (!!pivotChartState?.categories) {
                        tableViewState.columnSettings = [colsDictionary[pivotChartState.categories.colName]]
                    } else if (!!kanbanViewState?.groupByColumn) {
                        tableViewState.columnSettings = [colsDictionary[kanbanViewState?.groupByColumn?.field]]
                    }
                    this.reportPersist.tableViewState.setValue(tableViewState);

                }
                break;
            case 'chart-view':
                if (!chartState?.chartConfig?.categories) {
                    chartState = (chartState ?? {} as IChartState);
                    chartState.chartConfig = (chartState.chartConfig ?? {} as IChartDataConfig);
                    chartState.chartConfig.categories = (chartState.chartConfig.categories ?? {
                        colName: groupByCandidate('chart-view'),
                        sortDir: 'asc',
                        limit: DefaultPivotLimit,
                    } as ICategories);
                    chartState.chartFormat = {
                        categories: chartState.chartConfig.categories.colName,
                        chartId: 0,
                        chartTitle: null,
                        chartType: 'column',
                        isStack: false,
                        series: null,
                        metrics: [],
                        tooltipFormat: "",
                        isLogarithmScale: false
                    } as IChartFormatConfig;
                    this.reportPersist.chartState.setValue(chartState);
                }
                break;
            case 'pivot-view':
                if (!pivotChartState?.categories) {
                    pivotChartState = (pivotChartState ?? {} as IPivotConfig);
                    pivotChartState.categories = (pivotChartState.categories ?? {
                        colName: groupByCandidate('pivot-view'),
                        sortDir: 'asc',
                        limit: DefaultPivotLimit,
                    } as ICategories);
                }
                if (!pivotChartState?.metric) {
                    pivotChartState.metric = (pivotChartState.metric ?? {
                        colName: groupByCandidate('pivot-view'),
                        functionType: 'count',
                    } as IMetric)
                }
                this.reportPersist.pivotChartState.setValue(pivotChartState);
                break;
            case 'kanban-view':
                if (!kanbanViewState?.groupByColumn?.field){
                    this.dialogsManagerService.openKanbanDialog(this._tabSettings,this.reportPersist.mainQueryService,this.reportPersist.kanbanViewState,this.reportPersist.mainViewState).subscribe(kanbanState => {
                        if (kanbanState) {
                            this.reportPersist.kanbanViewState.setValue(kanbanState);
                        }
                    });
                }
                else {
                    this.reportPersist.kanbanViewState.setValue(kanbanViewState);
                }
                break;
        }

        this._cdRef.markForCheck();
    }

    async ngOnInit() {

        this.subscriptions.push(
            this.reportPersist.oLastRequestData.pipe(filter(r => !!r)).subscribe(async report => {
                await this.reportLoaded(report);
                this._cdRef.markForCheck();
            }));
        this.isAdmin = await this._roleCheck.hasRole(this.currentTab.claim);
        if (this.enableSelect) {
            this.enableMultipleSelect();
        }
        this.subscriptions.push(this.reportNameSubject.subscribe(searchTextValue => {
            this.reportPersist.mainViewState.setValue({ mainTitle: searchTextValue }, false);
        }));

        this.subscriptions.push(this.controlBarDataService.detailsTitle.subscribe((subTitle) => {
            this.subTitle = subTitle;
            this._cdRef.markForCheck();
        }));
    }

    private async reportLoaded(requestLoaded: IDynamicReport) {
        const requestOwnerUserId = requestLoaded.mainView?.userId;
        const dirId = requestLoaded.mainView?.dirId;
        //permissions
        await this.setReportPermissions(requestOwnerUserId, dirId);
        this.viewType = this.viewTypes.find(t => t.name == requestLoaded.mainView.type);
        this.reportType = requestLoaded.mainView.type;
        this.mainTitle = requestLoaded.mainView.mainTitle.trim();
        this.reportDir = requestLoaded.mainView.reportDir;

    }

    private async setReportPermissions(requestOwnerUserId, dirId) {
        this.canEditReport = await this.hasPermission(Permission.EditReport, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${this.reportPersist.loadReqId}`);
        this.canDeleteReport = await this.hasPermission(Permission.DeleteRequest, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${this.reportPersist.loadReqId}`);
        this.canSwitchReport = await this.hasPermission(Permission.SwitchReport, `${this.reportPersist.loadReqId}`);
        this.canAddReport = await this.hasPermission(Permission.AddReport, `${this.reportPersist.loadReqId}`);
    }

    private async hasPermission(permissionKey: string, resourceKey?: string): Promise<boolean> {
        return await this._userPermissionService.hasPermission(this.currentTab.key, permissionKey, resourceKey);
    }

    titleChange(searchTextValue: string) {
        this.reportNameSubject.next(searchTextValue);
    }

    editReportName() {
        this.dialogsManagerService.openEditTitleDialog(this.mainTitle).subscribe(async (newReqName: any) => {
            if (newReqName) {
                this.titleChange(newReqName);
                this._cdRef.detectChanges();
            }
        });
    }
    get Math() {
        return Math;
    }

    public async reset() {
        await this.reportPersist.resetRequest();
    }

    public getfiltersCount(): number {
        let count = 0;
        Object.keys(this.queryService.filters).map((key) => {
            count += this.queryService.filters[key].expression.length;
        });
        return count;
    }

    // save as will create new one and keep the old one
    public async openSaveReportDialog(isUpdate: boolean) {
        const { reportName, dirId } = await this.getSaveReportNameAndDir(isUpdate);
        const requestId = isUpdate ? this.reportPersist.loadReqId : this.reportPersist.currentReqId;
        if (!!reportName) {
            const user = await this._authService.gatewayIdentityUserObservable.pipe(first()).toPromise();
            await this.reportPersist.persistRequestOwner(isUpdate, false, user.userId);
            await this.requestFilingService.setRequestDirectory(requestId, reportName, isUpdate ? -1 : dirId, this.currentAppKey, this.currentTab.key);
            this.openSnackBar('Report Saved!');
            this._router.navigateByUrl(`${this.currentAppKey}/reports/${this.currentTab.key}/${requestId}`);
        }
        this._cdRef.markForCheck();
    }



    private async getSaveReportNameAndDir(isUpdate: boolean) {
        let dirId = this.directoryId;
        let reportName;

        if (isUpdate) {
            const state = this.reportPersist.mainViewState.getState();
            reportName = state.mainTitle;

        } else {
            const result = await this.dialogsManagerService.openSaveReportDialog(this.tabKey).toPromise();
            if (!!result) {
                reportName = result.reportName;
                dirId = result.directoryId;
                this.reportPersist.mainViewState.setValue({ mainTitle: reportName, reportDir: result.reportDir });
            }
        }
        return { reportName, dirId };
    }


    // TODO helper service for reporting errors
    private openSnackBar(msg: string) {
        this.snackBar.open(msg, 'X', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }

    public deleteReport() {
        this.dialogsManagerService
            .openConfirmDialog('Delete Report ?', `Are you sure you want to delete this report? This cannot be undone.`)
            .subscribe(async (confirm: boolean) => {
                if (confirm) {
                    const isDeleted: boolean = await this.reportPersist.deleteRequest(this.reportPersist.loadReqId);
                    if (isDeleted) {
                        const defaultRequestId = this.currentTab.defaultRequestId;
                        await this.reportPersist.loadRequest(defaultRequestId);
                        this.requestFilingService.sReloadRequests.next(true);
                        this._router.navigateByUrl(`${this.currentAppKey}/reports/${this.currentTab.key}/default`);
                        this._cdRef.markForCheck();
                        this.openSnackBar('Report has been deleted.');
                    } else {
                        this.openSnackBar(`Not authorized.`);
                        disLogger('logged in userid does not match request owner, delete error');
                    }
                }
            });
    }

    public enableMultipleSelect() {
        this.multiSelect = !this.multiSelect;
        if (!this.multiSelect) {
            this._multiSelect.markListEmpty();
        }

        this.enableMultiSelect.emit(this.multiSelect);
    }

    public ngOnDestroy() {
        this.unsubscribeAll();
    }
    public async shareCurrentReport() {
        const newRequestId = (await this.reportPersist.createRequest());
        const requestCopy = this.reportPersist.oLastRequestData.getValue();
        await this.reportPersist.updateRequest(newRequestId, requestCopy, false);
        this.dialogsManagerService.openShareDialog(this.currentTab.app.key, this.currentTab.key, newRequestId)
    }
    public closePopover() {
        this.popoverService.setState(true);
    }
}
