import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DatasetFileService } from './api/dataset-file.service';

@Injectable({ providedIn: 'root' })
export class EmitterService {

    private _datasetId$ = new ReplaySubject<string>(1);
    oDatasetId = this._datasetId$.asObservable();

    private _isReprocessing$ = new Subject<boolean>();
    oIsReprocessing = this._isReprocessing$.asObservable();

    private _reprocessingCount$ = new ReplaySubject<number>(1);
    oReprocessingCount = this._reprocessingCount$.asObservable();

    constructor(private _datasetFileService: DatasetFileService) { }

    public setDatasetId(value: string) {
        this._datasetId$.next(value);
    }

    async startReprocessing() {
        this._isReprocessing$.next(true)
        interval(1000).pipe(takeUntil(this._isReprocessing$)).subscribe(async () => await this.getTotalNeedsReprocessingFilesCount());
    }

    public async getTotalNeedsReprocessingFilesCount() {
        var datasetId = await this.oDatasetId.pipe(take(1)).toPromise();

        var { isSuccess, result } = await this._datasetFileService.getTotalNonTestAndNeedsReprocessingFileCount(datasetId, true).toPromise();
        if (!isSuccess) return;

        this._reprocessingCount$.next(result);
        if (!(result > 0)) {
            this._isReprocessing$.next(false);
        }
    }
}
