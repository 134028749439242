<div class="category-list">
    <div class="title-bar">
        <span>Configurable Categories</span>
        <div class="controls">
            <mat-search-bar [formControl]="searchControl"></mat-search-bar>
            <button mat-icon-button (click)="addOptionList()" color="primary">
                <mat-icon class="material-icons">
                    add_circle_outline
                </mat-icon>
            </button>
        </div>
    </div>
    <div class="lists-container">
        <form *ngIf="selectedCategory && selectedCategory.id===0" [formGroup]="categoryDetailsForm"
            class="option-details-form section">
            <mat-form-field class="input-section" style="width: 370px;">
                <mat-label class="title"> Description </mat-label>
                <input matInput type="text" [formControl]="$any(categoryDetailsForm)?.controls?.description"
                    autocomplete="off">
                <mat-error class="error-messsage"
                    *ngIf="!categoryDetailsForm.controls.description.valid && categoryDetailsForm.controls.description.touched">
                    Category Description is required.</mat-error>
            </mat-form-field>
            <div class="buttons-row">
                <button type="submit" mat-button (click)="saveChanges();$event.stopPropagation();" color="primary">
                    <span class="save-button">SAVE</span>
                </button>
                <button mat-button (click)="selectedCategory = null;$event.stopPropagation();">CANCEL</button>
            </div>
        </form>
        <div class="main-content" *ngIf="(getCategoriesBloc?.result | async)?.lists as optionList">
            <div class="list-card pointer" [ngClass]="{'selected-item': selectedCategory == item}"
                *ngFor="let item of filteredLists" (click)="selectedCategory = item">

                <span class="list-description">
                    {{item.description}}
                </span>
                <mat-icon class="checkbox">
                    check
                </mat-icon>
            </div>

        </div>
    </div>