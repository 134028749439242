import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/material';
import { MatInput } from '@angular/material/input';
import { Observable } from 'rxjs/Observable';
import { map, startWith, switchMap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { of } from 'rxjs';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-form-autocomplete-type',
  templateUrl: './autocomplete.component.html'
})
export class AutocompleteTypeComponent extends FieldType implements OnInit {
  @ViewChild(MatInput) formFieldControl: MatInput;

  filter: any[];
  labelProp: string;
  valueProp: string;
  useOption: boolean;
  filteredOptions: any[];
  allOptions: any[];
  public optionSearchControl = new FormControl();
  ngOnInit() {
    this.labelProp = this.to.labelProp;
    this.valueProp = this.to.valueProp;
    this.useOption = this.to.useOption;
    this.allOptions = this.to.options as any[];
    this.filteredOptions = this.to.options as any[];

    this.subscribeToOptionsSearch();
  }
  private subscribeToOptionsSearch() {
    this.optionSearchControl.valueChanges.pipe(
      map(value => this.search(this.allOptions, value))
    ).subscribe(x => {
      this.filteredOptions = x;
    });

  }

  private search(options, value) {
    return value ? options?.filter(x => x[this.labelProp]?.toLowerCase().includes(value?.toString().toLowerCase())) : options;
  }

  compareWithFn = (o1: any, o2: any) => {
    return (!!o1 && !!o2 && o1[this.valueProp] && o2[this.valueProp]) ? (o1[this.valueProp] === o2[this.valueProp]) : isEqual(o1, o2);
  }

  asObservable(input: any[] | Observable<any[]>): Observable<any[]> {
    return Array.isArray(input) ? of(input) : input;
  }

}
