
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
@Injectable()
export class GroupByPipe implements PipeTransform {

  transform<T>(list: T[], keyGetter: (item, arg1?: any, arg2?: any) => string, arg1?: any, arg2?: any): Map<string, T[]> {
    const map = new Map();
    list?.forEach((item) => {
        var key = keyGetter(item, arg1, arg2);
        if (!key) {
            key = '';
        }
        else {
            key = key.charAt(0).toUpperCase() + key.slice(1);
        }
        var collection = map.get(key);
        if (!collection) {
            collection = [];
            map.set(key, collection);
        }

        collection.push(item);
    });
    return map;
  }
}
