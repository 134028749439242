import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseController, DiscovererQueryService, DRFacetOption, DRFilter, DRQuery, FacetValue, searchForText, TabSettingsService } from '@discoverer/core/services';
import { AdvancedDialog } from '@discoverer/dynamic-reports/dialogs';
import { first, map, startWith } from 'rxjs/operators';

@Component({
    selector: 'filter-list-control',
    templateUrl: './filter-list-control.component.html',
    styleUrls: ['./filter-list-control.component.scss']
})
export class FilterListControlComponent extends BaseController implements OnInit {
    filteredcolumns
    public filtersListControl = new FormControl('');
    availableColumns
    columnDictionary
    @Input() queryService: DiscovererQueryService;
    @Output() openFiltersClick: EventEmitter<boolean> = new EventEmitter<boolean>()
    constructor(private tabSettings: TabSettingsService,
        private dialog: MatDialog,) { super(); }

    async ngOnInit() {
        this.availableColumns = await this.tabSettings.getAllColumns();
        this.columnDictionary = await this.tabSettings.getColumnDictionary();
        this.filteredcolumns = this.filtersListControl.valueChanges.pipe(
            startWith(''),
            map(v => this.availableColumns
                .sort((a, b) => (a?.display > b?.display) ? 1 : -1)
                .filter(option => (option?.display?.trim() ?? "").length > 0)
                .filter(option => searchForText(v, option?.display?.toLowerCase()))
            ));
        // this.subscriptions.push(.subscribe(s => this.currentQuery = s));
    }

    public async addEmptyFilter(value) {
        // this.blurAll();

        const col = this.availableColumns.find(col => col.display === value);
        var facetValues = [];
        switch (col.type) {
            case 'date':
                facetValues = [new FacetValue(new DRFacetOption('NULL TO NULL', '[NULL TO NULL]', 'Is not Blank'))];
                break;
            case 'numeric':
                facetValues = [new FacetValue(new DRFacetOption('NULL TO NULL', '[NULL TO NULL]', 'Is not Blank'))];
                break;
        }
        const newFilter = new DRFilter('facet', [col.fieldName], facetValues, [], '0');
        newFilter.openFacet = true;
        this.queryService.setFilter(col.fieldName, newFilter, false);
        this.queryService.refresh();
        this.filtersListControl.reset();
    }
    public async showAdvancedModal(index: number = 0) {
        const currentQuery = await this.queryService.oQuery.pipe(first()).toPromise();
        const advFilter = (currentQuery.filters as DRFilter[]).filter(f => f.type == 'Any' || f.type == 'All')[0];
        const groups = (advFilter?.filters as DRFilter[]);
      
        const filterMode = advFilter?.type || 'All';

        const dialogRef: MatDialogRef<AdvancedDialog, boolean> = this.dialog.open(AdvancedDialog, {
            panelClass: 'advanced-dialog-container',
            data: {
                queryService: this.queryService,
                tabSettings: this.tabSettings,
                filterMode
            },
            width: '120vh',
            height: '90vh',
            autoFocus: false
        });
        const userSaved = await dialogRef.afterClosed().toPromise();
        this.handleDialogResponse(userSaved);
    }

    private handleDialogResponse(userSaved?: boolean) {
        if (!userSaved) {
            this.queryService.unSetFilter('Advanced');
        }
        this.queryService.refresh();
    }
}
