import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { SignaturePad } from 'ngx-signaturepad/signature-pad';
import { BaseCustomController } from '../shared/base-custom-controller';
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'dyn-sign',
    template: `
    <div class ="sig-container">
     <signature-pad #signatureCanvas [options] ="signaturePadOptions" id ="signatureCanvas" (onBeginEvent) ="drawStart()"  (onEndEvent)="drawComplete()"> </signature-pad>
     <button mat-raised-button color="primary" type="button" (click)="signaturePad.clear()" >Clear</button>
    </div>`,
    styleUrls: ['./dyn-sign-pad.component.scss']
})
export class DynSignPadComponent extends BaseCustomController implements AfterViewInit {
    @ViewChild('signatureCanvas', { static: true }) signaturePad: SignaturePad;
    public signaturePadOptions = {
        minWidth: 2,
        canvasWidth: 550,
        canvasHeight: 200,
        minDistance: 2
    };
    constructor() {
        super();
    }

    ngAfterViewInit() {
        this.signaturePad.set('minWidth', 2);
        this.signaturePad.clear();
    }

    drawComplete() {
        this.updateValueAndValidity(this.field, this.signaturePad.toDataURL());
    }

    drawStart() {
        // will be notified of szimek/signature_pad's onBegin event
    }

}
