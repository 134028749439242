import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileNode } from '../../../../models/common';
import { FileDetailsDto, OriginalFileViewerDetails } from '../../../../models/datasetFile.models';
import { FileViewerType, NodeType } from '../../../../models/enums';
import { DatasetFileService } from '../../../../services/api/dataset-file.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ERROR_SNACKBAR_OPTIONS } from '../../../home/edit-title-dialog/edit-title-dialog';

const WORD_DOCS_TYPES = [
    'docx',
    'doc'
];

const DOWNLOAD_FILE_TYPES = [
    'xlsx',
    'csv',
    'xls'
];

const NESTED_FILE_TYPES = [
    "zip"
]

const CUSTOM_FILE_TYPES_VIEWER = [
    "eml"
]

@Component({
    selector: 'app-document-files-viewer',
    templateUrl: './document-files-viewer.component.html',
    styleUrls: ['./document-files-viewer.component.scss']
})
export class DocumentFilesViewerComponent implements OnInit {
    @Input() fileExtension: string;
    @Input() fileName: string;
    @Input() datasetId: string;
    @Input() fileId: string;

    private readonly maxRetries = 5;
    private readonly retryDelayMs = 2000;

    public loading: boolean = false;
    public FileViewerType = FileViewerType;
    public originalFileDetails: OriginalFileViewerDetails;
    public extractedFileList: FileNode[] = [];
    public fileDetails: FileDetailsDto;

    constructor(private _datasetFileService: DatasetFileService, private sanitizer: DomSanitizer, private _snackbarService: MatSnackBar) { }

    async ngOnInit(): Promise<void> {
        this.fileDetails = await this.getFileDetails();
        await this.loadData();
    }

    public async downLoadNestedType(fileNode: FileNode,) {

        if (fileNode.type == NodeType.folder)
            this.downloadFileFromUrl(fileNode.url, fileNode.name);
        else {
            var fileResponse = await this._datasetFileService.downloadFileFromPath(this.datasetId, fileNode.path).toPromise();

            const blob = new Blob([fileResponse.body], { type: fileResponse.body.type });
            var fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
            const sanitizedUrl = this.sanitizeUrl(fileUrl);
            if (sanitizedUrl)
                this.downloadFileFromUrl(sanitizedUrl, `${fileNode.name}`);
        }

    }
    public async downloadFile(): Promise<void> {

        await this.loadOriginalFile();

        const sanitizedUrl = this.sanitizeUrl(this.originalFileDetails.url);

        if (sanitizedUrl) {
            this.downloadFileFromUrl(sanitizedUrl, `${this.fileName}.${this.fileExtension}`);
        }
    }

    private async loadData() {
        this.setLoading(true);

        this.originalFileDetails = {
            type: FileViewerType.Normal,
            id: this.fileId
        };
        await this.loadOriginalFile();
        this.setLoading(false);
    }
    private downloadFileFromUrl(fileUrl: string, fileName: string) {

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    private async loadOriginalFile(): Promise<void> {
        try {
            if (this.doFetchOriginalFile()) {
                const fileResponse = await this.fetchOriginalFileWithRetry();
                if (fileResponse)
                    this.setOriginalFileContent(fileResponse.body);
                else
                    console.error('Failed to fetch the file after maximum retries.');
            } else {
                this.originalFileDetails.type = FileViewerType.Custom;
            }

        } catch (error) {
            console.error('Error while fetching the file:', error);
        }
    }
    private getFileViewerType(bodyType: string) {
        if (NESTED_FILE_TYPES.includes(bodyType)) {
            console.log("File Type is Nested")
            return FileViewerType.Nested;
        }

        if (DOWNLOAD_FILE_TYPES.includes(bodyType)) {
            console.log("File Type is Downloaded")

            return FileViewerType.Downloaded;
        }

        if (CUSTOM_FILE_TYPES_VIEWER.includes(bodyType)) {
            console.log("File Type is Custom")
            return FileViewerType.Custom;
        }

        console.log("File Type is Normal")
        return FileViewerType.Normal;
    }
    private async fetchOriginalFileWithRetry(): Promise<any | null> {

        let response;

        for (let retryCount = 0; retryCount < this.maxRetries; retryCount++) {
            response = await this._datasetFileService.downloadOriginalFile(this.datasetId, this.fileId).toPromise();

            if (response.body.size !== 0 && !WORD_DOCS_TYPES.includes(response.body.type)) {
                return response;
            }

            await this.delay(this.retryDelayMs);
        }

        return response;
    }
    private setOriginalFileContent(response: any): void {

        const blob = new Blob([response], { type: response.type });
        this.originalFileDetails.id = this.fileId;
        console.log(`Response Type is ${response.type}`)
        this.originalFileDetails.type = this.getFileViewerType(this.fileDetails.FileExtension);
        this.originalFileDetails.url = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));

        if (this.originalFileDetails.type === FileViewerType.Nested)
            this.setExtractedFileList()

    }
    private async setExtractedFileList() {
        var extractedFileResponse = await this._datasetFileService.getExtractedFiles(this.datasetId, this.fileId).toPromise();

        if (extractedFileResponse.isSuccess) {

            var extractedFilesResult = extractedFileResponse.result;

            this.extractedFileList = [{
                id: extractedFilesResult.id,
                type: NodeType.folder,
                name: extractedFilesResult.originalExtractedFolderName,
                url: this.sanitizeUrl(this.originalFileDetails.url),
                path: `${extractedFilesResult.extractedFolderName}`,
                children: extractedFilesResult.extractedFiles.map(fileName => {
                    var item: FileNode = {
                        id: fileName,
                        type: NodeType.file,
                        name: fileName,
                        path: `${extractedFilesResult.extractedFolderName}/${fileName}`,
                        extension: this.getFileExtension(fileName),
                    };
                    return item;
                })
            }];
        }
    }
    private delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    private sanitizeUrl(url: SafeResourceUrl): string | null {
        return this.sanitizer.sanitize(SecurityContext.URL, url);
    }
    private getFileExtension(fileName: string): string {
        const lastDotIndex = fileName.lastIndexOf('.');
        if (lastDotIndex !== -1) {
            return fileName.substring(lastDotIndex + 1);
        }
        return '';
    }

    private async getFileDetails() {
        const { isSuccess, result: fileDetails } = await this._datasetFileService.getFileDetails(this.datasetId, this.fileId).toPromise();
        if (!isSuccess) this._showMsg('Failed fetching file information', ERROR_SNACKBAR_OPTIONS);

        return fileDetails;
    }
    doFetchOriginalFile(): boolean {
        return !CUSTOM_FILE_TYPES_VIEWER.includes(this.fileDetails.FileExtension)
    }
    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this._snackbarService.open(msg, 'Close', config);
    }

    private setLoading(value: boolean) {
        this.loading = value;
    }
}
