<ng-container>
    <ng-template #drillinto let-item="item" let-category="category">
        <div class="list">
            <div>Drill Into: </div>
            <button (click)="openChartRecords(category, item, 'table-view')" class="list-button"
                mat-button>Records</button>
            <button (click)="openChartRecords(category, item, 'pivot-view')" class="list-button"
                mat-button>Pivot</button>
            <button (click)="openChartRecords(category, item, 'chart-view')" class="list-button"
                mat-button>Chart</button>
        </div>
    </ng-template>
    <ng-template #header let-titles="titles">
        <div class="chart">
            <div class="chart-header">
                <ng-container *ngFor="let title of titles">
                    <div class="value" *ngIf="title?.header">
                        <div class="title"> {{title?.header}}</div>
                        <div class="result">
                            {{title?.value || '0'}}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

    </ng-template>
    <div #target class="height-100" id="chart-item" *ngIf="!isIndicator && !isVerticalIndicator">


        <ng-container *ngTemplateOutlet="header; context: { titles }"></ng-container>
        <kendo-chart class="height-100-68" [transitions]="false" #chart [chartArea]="{background: 'var(--chart-area)'}"
            [seriesColors]="seriesColors">
            <kendo-chart-legend position="bottom" [labels]="legendLabels"
                [inactiveItems]="{labels: {color: 'var(--inactive-text)'}}" orientation="horizontal">
            </kendo-chart-legend>
            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item *ngFor="let item of formattedMetrics" [name]="item.name"
                    [title]="{ text: item.title.subTitle }" color="var(--text-color)"
                    [majorGridLines]="{color:'var(--grid-lines)'}" [type]="(isLogarithmScale) ?'log' : 'numeric'">
                    <kendo-chart-value-axis-item-labels [content]="formatAxis"></kendo-chart-value-axis-item-labels>
                </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>

            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item color="var(--text-color)" [majorGridLines]="{color:'var(--grid-lines)'}"
                    [categories]="categories"
                    [title]="{ text: category.title + ((!!formatSeries.title) ? (' / ' + formatSeries.title):'') }"
                    [labels]="categoriesLabels">
                </kendo-chart-category-axis-item>

            </kendo-chart-category-axis>

            <kendo-chart-series *ngIf="!isCircular">
                <kendo-chart-series-item [stack]="isStack" *ngFor="let item of seriesArray; index as i"
                    [name]="item.name" field="value" [data]="item.values" [color]="item.color" [type]="item.type"
                    [axis]="item.axis">
                    <kendo-chart-series-item-labels [visible]="(!isStack || seriesArray.length==1)"
                        [content]="labelTotalContent"></kendo-chart-series-item-labels>
                    <kendo-chart-series-item-tooltip [format]="item.format">
                        <ng-template style="background-color: white !important;" kendoChartSeriesTooltipTemplate
                            let-dataItem="dataItem" let-value="value" let-category="category">
                            <div class="pie-tooltip-wrapper">
                                <div class="value"> {{formattingService.getValue(value , item.dataType)}}
                                    ({{getPercentage(value, category) | number:'1.0-2'}}%) </div>
                                <div class="category">{{ item?.name?.Display }}</div>
                            </div>
                            <ng-container
                                *ngTemplateOutlet="drillinto;context:{item:item,category:category}"></ng-container>
                        </ng-template>
                    </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
                <!-- shows the points above the stack-->
                <kendo-chart-series-item *ngIf="isStack && seriesArray.length > 1" type="line" [data]="seriesTotalList"
                    field="value" [labels]="{ visible: true }" [style]="'step'" [width]="0">
                    <kendo-chart-series-item-labels [content]="labelTotalContent"></kendo-chart-series-item-labels>
                    <kendo-chart-series-item-tooltip>
                        <ng-template style="background-color: white !important;" kendoChartSeriesTooltipTemplate
                            let-dataItem="dataItem" let-value="value" let-category="category">
                            <div class="pie-tooltip-wrapper" style="min-width:100px">
                                <div class="value"> {{formattingService.getValue(value , dataItem.dataType)}} </div>
                                <div class="category">{{category?.Display}}</div>
                            </div>
                            <ng-container
                                *ngTemplateOutlet="drillinto;context:{item:value,category:category}"></ng-container>
                        </ng-template>
                    </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
            </kendo-chart-series>

            <kendo-chart-series *ngIf="isCircular">
                <kendo-chart-series-item type="pie" color="var(--text-color)" [data]="pieData" field="value"
                    categoryField="category">

                    <kendo-chart-series-item-labels [content]="labelContent" color="var(--text-color)" [padding]="0"
                        background="none">
                    </kendo-chart-series-item-labels>
                    <kendo-chart-series-item-tooltip>
                        <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category"
                            let-numberValue="dataItem.numberValue" let-dataItem="dataItem"
                            let-dataType="dataItem.dataType">
                            <div class="pie-tooltip-wrapper">
                                <div class="value" *ngIf="facet">#: {{ facet.getValue(category?.Display, 'count') }} ({{
                                    value |
                                    number:'1.2-2'}}) %</div>
                                <div class="value" *ngIf="!facet"> {{ formattingService.getValue(numberValue , dataType)
                                    }} ({{
                                    value | number:'1.0-0'}} %)</div>
                                <div class="category">{{ category?.Display }}</div>
                                <ng-container
                                    *ngTemplateOutlet="drillinto;context:{item:dataItem,category:category}"></ng-container>
                            </div>
                        </ng-template>
                    </kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
            </kendo-chart-series>

        </kendo-chart>
    </div>
    <div [@fadeInOut] *ngIf="chartData && (isIndicator || isVerticalIndicator)"
        [ngClass]="isIndicator ? 'indicator' : 'vertical-indicator'">

        <div *ngIf="isSingleIndicator else MULTIPLE" class="single-value">
            <div class="value" *ngFor="let met of formattedMetrics" [matMenuTriggerFor]="menu">
                <ng-container *ngIf="findSeries(met, series[0]) as ser">
                    <div class="title"> {{met.title.subTitle}}</div>
                    <div class="result" *ngFor="let val of ser.values">
                        {{val?.stringValue||'0'}}
                    </div>
                </ng-container>
            </div>
            <mat-menu #menu="matMenu">
                <ng-container *ngTemplateOutlet="drillintoindicator;"></ng-container>
            </mat-menu>
        </div>
        <ng-template #MULTIPLE>
            <div class="multi-value">
                <div *ngFor="let series of series; index as i" class="card-wrapper">
                    <div class="series-title" *ngIf="series?.Display !== 'Series1'">
                        <span>{{series?.Display || '(Blank)'}}</span>
                    </div>
                    <div class="cards-row">
                        <div class="card-main" [matMenuTriggerFor]="menu">
                            <div class="card" *ngIf="categories.length > 1">
                                <div class="card-header"><span>Total</span></div>
                                <div>
                                    <div *ngFor="let metric of formattedMetrics; index as m" class="main-description">
                                        <span>{{formattingService.getValue(findSeries(metric, series)?.total || '0' ,
                                            metric.dataType)}} </span>
                                    </div>
                                </div>
                            </div>
                            <mat-menu #menu="matMenu">
                                <ng-container *ngTemplateOutlet="drillintoindicator;context:{series}"></ng-container>
                            </mat-menu>

                        </div>
                        <div *ngFor="let category of categories; index as c" class="card-main"
                            [matMenuTriggerFor]="menu">
                            <div class="card">
                                <div class="card-header"><span>{{category?.Display ? category?.Display :
                                        '(Blank)'}}</span>
                                </div>
                                <div>
                                    <div *ngFor="let metric of formattedMetrics; index as m" class="main-description">
                                        <span>
                                            {{findSeries(metric,series)?.values[c]?.stringValue || '0'}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <mat-menu #menu="matMenu">
                                <ng-container
                                    *ngTemplateOutlet="drillintoindicator;context:{series,category}"></ng-container>
                            </mat-menu>

                        </div>
                    </div>

                </div>
            </div>
        </ng-template>
        <ng-template #drillintoindicator let-series="series" let-category="category">
            <button mat-menu-item (click)="openIndicatorRecords(series, category, 'table-view')">View
                Records</button>
            <button mat-menu-item (click)="openIndicatorRecords(series, category, 'chart-view')">View
                Chart</button>
            <button mat-menu-item (click)="openIndicatorRecords(series, category, 'pivot-view')">View
                Pivot</button>
        </ng-template>
    </div>
</ng-container>