import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IColumnSetting, IMetric, TabSettingsService } from "@discoverer/core";


@Component({
    selector: 'stat-dialog',
    templateUrl: './stat.dialog.html',
    styleUrls: ['./stat.dialog.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        TabSettingsService
    ]
})


export class StatDialog implements OnInit {

    public stats: IMetric;
    public StatList = ['sum', 'avg', 'min', 'max', 'count'];
    public StatColmuns: IColumnSetting[];
    public availableColumns: IColumnSetting[];
    public colName: string;
    public functionType: string = 'sum';
    public label: string;
    constructor(private dialogRef: MatDialogRef<StatDialog>,
        @Inject(MAT_DIALOG_DATA) private dialogData
    ) {
    }
    async ngOnInit() {
        this.availableColumns = this.dialogData.availableColumns;
        if (!!this.dialogData.functionType) {
            this.functionType = this.dialogData.functionType;
        }
        if (!!this.dialogData.colName) {
            this.colName = this.dialogData.colName;
        }
        if (!!this.dialogData.label) {
            this.label = this.dialogData.label;
        }
        this.StatColmuns = this.availableColumns.filter(x => x.type === 'numeric');
    }

    public setStatColumn(colName) {
        this.colName = colName.toString();
        this.setLabelDefault();
    }

    onNoClick() {
        this.dialogRef.close({ functionType: null, colName: null, label: null });
    }

    save() {
        if (!!this.functionType && !!this.colName && !!this.label) {
            this.dialogRef.close({ functionType: this.functionType, colName: this.colName, label: this.label });
        }
    }

    public async setStatFunction(Stat) {
        this.functionType = Stat.toString();
        this.setLabelDefault();
    }
    private setLabelDefault() {
        this.label = `${this.functionType.toUpperCase()} of ${this.StatColmuns.find(x =>x.fieldName === this.colName).display}`;
    }



}
