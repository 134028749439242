<div class="main-content">
    <div class="section">
        <category-list #categoryList></category-list>
    </div>

    <div class="section details" *ngIf="categoryList.selectedCategory?.id">
        <option-list-details [selectedCategory]="categoryList.selectedCategory"></option-list-details>
    </div>


</div>