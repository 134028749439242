// export type DatasetType = 'DATASET' | 'WORKSPACE';
export enum DatasetTypes {
    DATASET = 'DATASET',
    WORKSPACE = 'WORKSPACE'
}
export class BaseDatasetDetailsDto {
    Id: string;
    Title: string;
    DatasetIcon: string;
    EmailAlias: string;
    Type: DatasetTypes;
    Description: string;
    ProcessedDocs: number;
    ProcessingDocs: number;
    ReceivedDocs: number;
}
export class DatasetDetailsDto extends BaseDatasetDetailsDto {
    IsCopied?: boolean;
    Datasets: DatasetDetailsDto[];
    WorkspaceId?: string;
}

export class DatasetCountDto {
    datasetId?: string;
    queueLength: number;
    resultLength: number;
    updatedAt?: any;
}
