<div class="wrapper">
    <disco-data [appId]="'docpipeline'" [tabId]="tabId === 'datasetId' ? datasetId : tabId" [requestId]="currentReqId"
        class="flex-1" [columnList]="columnList" [start]="start" [size]="1" [queryService]="queryService"
        (context)="setContext($event)">
        <ng-template let-context>
            <div class="flex h-full overflow-auto">
                <div class="flex h-full w-full">
                    <div class="result-viewer flex flex-col" appResizable (widthAreaChanging)="setHeaderWidth($event)"
                        (isDragging)="dragging = $event">

                        <app-document-header
                            [isLoading]="isLoading"
                            [fileName]="fileName"
                            [fileExtension]="fileExtension" 
                            [isEditedValuesValid]="isEditedValuesValid" 
                            [isShowLogsEnabled]="isShowLogsEnabled"
                            [dateReceived]="dateReceived" 
                            [titles]="titles" 
                            [fileId]="fileId"
                            [result]="result"
                            [resultIndex]="resultIndex"
                            [headerWidth]="headerWidth"
                            [isEditMode]="isEditMode"
                            [start]="start"
                            [total]="dataSourceContext?.total"
                            (toggleShowLogs)="toggleShowLogs($event)"
                            (toggleIsSaving)="toggleIsSaving($event)"
                            (toggleEditMode)="toggleEditMode($event)"
                            (moveToPrevious)="moveToPrevious()"
                            (moveToNext)="moveToNext()"
                            (limitChanged)="limitChanged()"
                            (queryServiceChanged)="queryServiceChanged()"
                            ></app-document-header>

                        <app-document-logs-content *ngIf="isShowLogsEnabled"
                            [fileId]="fileId"
                            [datasetId]="datasetId"
                            [isLoading]="isLoading"
                            (toggleShowLogs)="toggleShowLogs($event)" 
                        ></app-document-logs-content>

                        <app-document-main-content *ngIf="!isShowLogsEnabled"
                            [result]="result"
                            [limit]="limit"
                            [fileId]="fileId"
                            [tabId]="tabId"
                            [datasetId]="datasetId"
                            [SplitFiles]="SplitFiles"
                            [isLoading]="isLoading"
                            [isEditMode]="isEditMode"
                            [resultIndex]="resultIndex"
                            [isSaving]="isSaving"
                            (checkValidity)="checkValidity($event)"
                            (dataFetched)="dataFetched($event)"
                        ></app-document-main-content>

                    </div>
                    <div class="pdf-viewer">
                        <app-document-files-viewer  
                            [ngClass]="{'hidden': dragging}" *ngIf="fileId && !isLoading"
                            [datasetId]="datasetId" 
                            [fileId]="fileId" [fileName]="fileName"
                            [fileExtension]="fileExtension"
                        ></app-document-files-viewer>
                    </div>
                </div>
            </div>
        </ng-template>
    </disco-data>
</div>