<div class="one-hand-field">
    <ng-container *ngIf="numericMinOrMaxOperations.length > 0">
        <div class="moreThan-lessThan">
            <ng-container *ngFor="let operation of numericMinOrMaxOperations">
                <button (click)="emitValueIsInclusiveChangeEvent(!operation.id.includes('EQUALS'))"
                    *ngIf="operation.id == (valueIsInclusive ? minMaxOperationWithEqual.id : minMaxOperationWithoutEqual.id)">
                    {{ operation.character }}
                </button>
            </ng-container>
        </div>
    </ng-container>

    <mat-form-field [ngClass]="{'date-operations': !numericMinOrMaxOperations.length}" floatLabel="never">
        <mat-select [value]="dateOperationShortcut.id" (selectionChange)="emitdateOperationShortcutChangeEvent($event)">
            <mat-option *ngFor="let opr of OPERATION_SHORTCUTS" [value]="opr.id">{{
                opr.label
                }}</mat-option>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="selectedShortcut === DATE_OPR_DICTIONARY.THE_DATE">
        <mat-form-field floatLabel="never">
            <input matInput [matDatepicker]="myDatepicker" placeholder="Select Date" [ngModel]="valueDate"
                (dateChange)="emitDateValueChangeEvent($event)" />
            <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #myDatepicker></mat-datepicker>
        </mat-form-field>
    </ng-container>

    <mat-form-field *ngIf="selectedShortcut !== DATE_OPR_DICTIONARY.THE_DATE" floatLabel="never">
        <input matInput type="number" placeholder="the value" [ngModel]="value"
            (ngModelChange)="emitNumberValueChangeEvent($event)" />
    </mat-form-field>

</div>