import { Component, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';

@Component({
  selector: 'percent-bar',
  styleUrls: ['./percent-bar.component.css'],
  templateUrl: './percent-bar.component.html'
})


export class PercentBarComponent {
  @Input('title')
  title: string;

  @Input('value1')
  value1: number = 1;
  @Input('value1-title')
  value1Title: string;

  @Input('value2')
  value2: number = 0;
  @Input('value2-title')
  value2Title: string;

  @Input('length')
  length: number = 100;

  @Input('mini')
  mini: boolean;

  @Input('bar-color')
  barColor: string = 'Green';

  constructor() {

  }

  ngOnChanges(changes: any[]) {
    // this.length = 100 * ( Math.max(this.value1,0) /Math.max(this.value2,1) ) ;

  }

  ngOnInit() {
    // this.length = 100* ( Math.max(this.value1,0)/Math.max(this.value2,1)) ;
  }
}
