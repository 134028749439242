
import { FieldDataType } from './enums';
export const DataTypeIcons = new Map<string, string>([
    [FieldDataType.STRING, 'text_fields'],
    [FieldDataType.NUMBER, 'tag'],
    [FieldDataType.DATE, 'calendar_today'],
    [FieldDataType.CURRENCY, 'attach_money'],
    [FieldDataType.LINK_WEBSITE, 'link'],
    [FieldDataType.EMAIL, 'alternate_email'],
    [FieldDataType.PHONE, 'phone'],
    [FieldDataType.BOOLEAN, 'check_box'],
    [FieldDataType.IMG, 'image'],
    [FieldDataType.HTML, 'code'],
    [FieldDataType.STRING_ARRAY, 'view_list'],
    [FieldDataType.DOUBLE, 'numbers'],
    [FieldDataType.PERCENT, 'percent'],
    [FieldDataType.TIMESTAMP, 'schedule'],
    [FieldDataType.TIMESTAMPTZ, 'schedule'],
    [FieldDataType.INT, 'tag'],
    [FieldDataType.LINK, 'link'],
    [FieldDataType.OBJECT_ARRAY, 'table_chart'],
    [FieldDataType.OBJECT, 'segment'],
]);

export const getLinkFieldTypes = (): string[] => {
    return [
        FieldDataType.URL.toString(),
        FieldDataType.LINK.toString(),
        FieldDataType.LINK_WEBSITE.toString()
    ];
}