import { Injectable } from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { first } from 'rxjs/operators';


export class UserData {
  public id: number;
  public email: string;
  public name: string;
  public token: string;
  public md5sum?: string;
  public labCodes: string[];
  public projects: string[];
  public roles: Set<string>;
  public clientName?: string;
  public permissions?: string[];
  public accountId?: number
}

export abstract class UserDataServiceInterface {
  public abstract loggedInUser: Observable<UserData>;
  public abstract getUser();
}


@Injectable()
export class UserDataService  {
  public loggedInUser: Observable<UserData>;
  private _loggedInUser: ReplaySubject<UserData> = new ReplaySubject(1);

  constructor() {
    this.loggedInUser = this._loggedInUser.asObservable();
    this._loggedInUser.next(new UserData());
  }

  public setUser(user: UserData) {
    this._loggedInUser.next(user);
  }

  public async getUser() {
    return this.loggedInUser.pipe(filter( f=> !!f && !!f.roles ), first()).toPromise();
  }
}
