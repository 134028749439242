import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseController, DiscovererQueryService, MultiSelectService } from '@discoverer/core';
import { DatasetTypes } from '../../../models/dataset.model';
import { MultiFileDeletionDto } from '../../../models/datasetFile.models';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { DatasetService } from '../../../services/api/dataset.service';
import { ConfirmationDialogComponent } from '../../attoms/confirmation-dialog/confirmation-dialog.component';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';
import { ActionModel } from '../../../models/action.model';
import { ActionService } from '../../../services/api/action.service';

@Component({
    selector: 'app-multi-select-handler',
    templateUrl: './multi-select-handler.component.html',
    styleUrls: ['./multi-select-handler.component.scss']
})
export class MultiSelectHandlerComponent extends BaseController implements OnInit, OnDestroy {
    enableSelect: boolean = false
    @Input() businessKey: string = 'Id';
    @Input() datasetId: string;
    @Output() toggleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() queryService: DiscovererQueryService
    actionsList: ActionModel[] = []
    datasetTypes = DatasetTypes;
    constructor(
        public multiSelectService: MultiSelectService,
        private _datasetFileService: DatasetFileService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        public _datasetService: DatasetService,
        private _actionService: ActionService
    ) { super(); }

    async ngOnInit(): Promise<void> {
        this.actionsList = (await this._actionService.getAll(this.datasetId).toPromise()).result;
    }

    ngOnDestroy(): void {
        this.multiSelectService.markListEmpty();
        super.ngOnDestroy();
    }
    async rerunAction(action) {
        const actionIndex = this.actionsList.findIndex(a => a.id === action.id);
        const actionsAfter = this.actionsList.slice(actionIndex ).map(x =>x.name);
        const msg = `This will rerun the actions (${actionsAfter.join(', ')})  on the selected files. Are you sure you want to proceed?`;

        const confirm = await this._confirm(msg);
        if (!confirm) return;

        //temp Fix
        var selectedItems = this.multiSelectService.getItemsSelected();
        const key = (this.businessKey === 'File.file_id' || this.businessKey === 'File.file_index_id') ? 'File.file_id': 'Id' ;
        var selectedIds = selectedItems.map(item => this.multiSelectService.isNested ? item[key][0] : item[key]);
        const {isSuccess, result} = await this._actionService.runSequential(this.datasetId, action.id, selectedIds).toPromise();
        if (isSuccess) {
            this._snackBar.open(`Actions started on (${selectedIds.length}) files!`, null, SUCCESS_SNACKBAR_OPTIONS);
        } else {
            this._snackBar.open('Error Reruning actions', null, ERROR_SNACKBAR_OPTIONS);
        }
    }
    async reprocessSelected() {
        //temp Fix: Fix this in a correct way
        const key = (this.businessKey === 'File.file_id' || this.businessKey === 'File.file_index_id') ? 'File.file_id': 'Id' ;

        const selected = [... new Set(this.multiSelectService.getItemsSelected().map(item => this.multiSelectService.isNested ? item[key][0] : item[key])
            .reduce((acc, value) => acc.concat(value), []))] as string[];
        if (!selected.length) {
            this._snackBar.open('No files selected', null, ERROR_SNACKBAR_OPTIONS);
            return;
        }
        const confirm = await this._confirm(`Are you sure you want to reprocess (${selected.length}) files?`);
        if (!confirm) return;

        var { isSuccess } = await this._datasetFileService.reprocessMultipleFiles(this.datasetId, selected).toPromise();

        if (isSuccess) {
            this.multiSelectService.markListEmpty();
            this._snackBar.open(`(${selected.length}) sent to reprocess!`, null, SUCCESS_SNACKBAR_OPTIONS);
        } else {
            this._snackBar.open('Error reprocessing files', null, ERROR_SNACKBAR_OPTIONS);
        }
        this.queryService.refresh();
    }
    private _confirm(message, note = null) {

        return this._dialog.open(ConfirmationDialogComponent, { data: { message, note } })
            .afterClosed().toPromise();
    }
    async deleteSelected() {
        var selectedItems = this.multiSelectService.getItemsSelected();
        var mappedSelectedItems = selectedItems.map(item => ({
            fileId: this.multiSelectService.isNested ? item[this.businessKey][0] : item[this.businessKey],
            resultIndex: this.multiSelectService.isNested ? item["File.result_index"][0] : item["File.result_index"]
        }) as MultiFileDeletionDto)
        var uniqueMappedItems = new Set(mappedSelectedItems);
        const selected = [...uniqueMappedItems];

        if (!selected.length) {
            this._snackBar.open('No files selected', null, ERROR_SNACKBAR_OPTIONS);
            return;
        }

        var confirm = await this._confirm(
            `Are you sure you want to delete (${this.multiSelectService.getItemsSelected()?.length}) files?`,
            `P.S:- This will delete all selected files (${selected.length}) and can\'t be undone!!`);

        if (!confirm) return;

        var { isSuccess } = await this._datasetFileService.deleteRange(this.datasetId, selected).toPromise();

        if (isSuccess) {
            this.multiSelectService.markListEmpty();
            this._snackBar.open(`(${selected.length}) files were deleted!`, null, SUCCESS_SNACKBAR_OPTIONS);
        } else {
            this._snackBar.open('Error deleting files', null, ERROR_SNACKBAR_OPTIONS);
        }
        this.queryService.refresh();
    }
}
