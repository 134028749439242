import { IDRFilter, IFilter } from './filter.interface';
import { FacetValue } from './facet-value.class';
import moment from 'moment';
import { DiscovererQueryService } from '../core-data-services';
import { GroupDescriptor } from '@progress/kendo-data-query';

export const FILTER_EXCLUDED_QUERY_PARAMS = [
    'refresh', 'token', 'returnUrl', 'userId', 'key', 'isNew', 'requestId'
];

export function roundUpToHighestDigit(num: number) {
        const length = num.toString().length - 1;
        const digits = Math.pow(10, length);
        return Math.ceil((num/digits))*digits;
};

export function getNearestValueToGap (num: number, gap: number) {
    let start = num;
    if (num > 0) {
        let x = 0;
        while(x <= num) x = x + gap;
        start = x - gap;
    } else if(num < 0) {
        let x = 0;
        while(x >= num) x = x - gap;
        start = x;
    }
    return start;
};
export const buildFacetFilter = (fieldName: string, values: string[]) => {
    return new DRFilter('facet', [fieldName], values.map(val => new FacetValue(new DRFacetOption(`${val}`, `${val}`, `${val}`))));
};

export const buildDateFacetFilter = (fieldName: string, values: string[]) => {
    const facetValues = values.map(value => {
        const start = moment(value.split(' TO ')[0].slice(1));
        const end = moment(value.split(' TO ')[1].slice(0, -1));
        const startDate = (start.format('YYYY-MM-DD')) + 'T00:00:00Z';
        const endDate = (end.format('YYYY-MM-DD')) + 'T00:00:00Z';
        const displayFromDate = start.format('MM-DD-YY');
        const displayToDate = end.format('MM-DD-YY');
        const facetValue: FacetValue = new FacetValue(
            new DRFacetOption((startDate + ' TO ' + endDate),
                (value.slice(0, 1) + startDate + ' TO ' + endDate + value.slice(-1)),
                displayFromDate + ' - ' + displayToDate));
        facetValue.isExclusion = false;
        facetValue.isChecked = true;
        return facetValue;
    });
    return new DRFilter('facet', [fieldName], facetValues);
};
export const buildRangeFacetFilter = (fieldName: string, values: string[]) => {
    const facetValues = values.map(value => {
        const from = value.split(' TO ')[0];
        const to = value.split(' TO ')[1];
        const facetValue: FacetValue = new FacetValue(
            new DRFacetOption(from.slice(1) + ' TO ' + to.slice(0, -1),
                from + ' TO ' + to,
                from.slice(1) + ' to ' + to.slice(0, -1)));
        facetValue.isExclusion = false;
        facetValue.isChecked = true;
        return facetValue;
    });
    return new DRFilter('facet', [fieldName], facetValues);
};


export const buildQueryFromParams = (query: DiscovererQueryService, params: object) => {
    Object.keys(params).filter(k => FILTER_EXCLUDED_QUERY_PARAMS.indexOf(k) === -1).map(pKey => {
        const paramValue = `${params[pKey]}`;
        let values = [ `${paramValue}` ];
        let type = '';
        if (pKey.includes('->')) {
            type = pKey.split('->')[0];
            pKey = pKey.split('->')[1];
        }
        if (paramValue.includes(',')) {
            values = paramValue.split(',');
        }
        switch (type) {
            case 'DateRange':
                query.setFilter(`Param:${pKey}`, buildDateFacetFilter(pKey, values));
                break;
            case 'NumRange':
                query.setFilter(`Param:${pKey}`, buildRangeFacetFilter(pKey, values));
                break;
            default:
                query.setFilter(`Param:${pKey}`, buildFacetFilter(pKey, values));
                break;
        }
    });
}

export class DRFacetOption {
    constructor(
        public key: string,
        public query: string,
        public display: string) {
    }
}

export class DRFilter implements IDRFilter, IFilter {
    public readonly expression: Array<string>;
    public openFacet = false;
    public getFacetValues(isChecked?: boolean): Array<FacetValue> {
        if (!!isChecked) {
            return this.facetValues.filter(x => x.isChecked === isChecked);
        }
        return this.facetValues;
    }

    public filter(val: any): boolean {
        return true;
    }

    constructor(
        public readonly type: string,
        public readonly fields: string[],
        public facetValues: Array<FacetValue>,
        public readonly filters: Array<DRFilter> = [],
        public filterMode?: string,
        public hidden: boolean = false
    ) {
        this.expression = facetValues.filter(x => (x instanceof FacetValue)).map(x => {
            if (x.isAnded)
                return '+' + x.id
            else if (x.isExclusion)
                return '!' + x.id
            else
                return x.id
        })
        this.filterMode = filterMode == null ? type == 'Any' || type == 'All' ? type : 'All' : filterMode;
    }
}


export class DSearchFilter implements IDRFilter, IFilter {

    public get value() {
        const textString = this.expression[0];
        let newString = '';
        if ((textString.match(/\)/g) || []).length === 1) {
            newString = textString.replace('or', '')
                .replace('OR', '')
                .replace('and', '')
                .replace('AND', '');
        }
        newString = textString.replace(/\)/g, '')
            .replace(/\(/g, '')
            .replace(/\*/g, '');
        return newString;
    }

    public filter(val: any): boolean {
        return true;
    }

    public getOriginalValue() {
        return this.originalValue;
    }

    constructor(
        public readonly type: string,
        public readonly fields: string[],
        public readonly expression: string[],
        public readonly originalValue: string
    ) {
    }
}

export class DRSortCriteria {

    public constructor(
        public sortField: string,
        public dir: 'asc' | 'desc') {
    }
}

export class DRQuery {
    public start = 0;
    public size = 20;
    public randomSeed? = 0;
    public numberOfRecords?: number;
    public changeId = '';
    public filters: Array<IDRFilter> = [];
    public facets: Array<{ type: string, name: string }> = [];
    public stats: Array<{ type: string, name: string, functionType?: string, field?: string }> = [];
    public sorts: Array<DRSortCriteria> = [];
    public fields: Array<string> = [];
    public groups: GroupDescriptor[] = [];
    public dataFlattenBy:string= '';
    public countSubRecords: boolean = false;
    public constructor() {
    }
}

export class DRResponseHeader {
    public status: number;
    public qtime: number;
}

export class DRResponse<ROW> {
    public numFound: number;
    public start: number;
    public docs: Array<ROW> = new Array<ROW>();
}

export class DRResult<ROW> {
    public responseHeader: DRResponseHeader = new DRResponseHeader();
    public response: DRResponse<ROW> = new DRResponse<ROW>();
    public facets: any = {};
    public queryChangeId: string;
    public esQuery: string;
}

export class DRStat {
    constructor(
        public display: string,
        public functionType: string,
        public field: string,
        public name: string,
        public type = 'stat') {

    }
}

export class DRStatDictionary {
    constructor(public stats: Array<DRStat>) {
        stats.forEach(stat => {
            this[stat.name] = stat;
        });
    }
}

export interface IReferenceLink {
    sourceIdColumn: string;
    targetTab: string;
    targetIdColumn: string;
    targetNameColumn: string;
}

export type SpecificDataType = 'text' | 'int' | 'currency' | 'percent' | 'string' | 'date' | 'integer' | 'boolean' 
                        | 'double' | 'html' | 'link' | 'img' | 'link_website' | 'string_array' | 'string_set' 
                        | 'icon' | 'timestamp' | 'phone' | 'timestamptz' | 'object_array' | 'object';

export type GeneralDataType = 'boolean' | 'date' | 'text' | 'numeric' | 'icon' | 'object' | 'object_array';
                        
export interface IColumnSetting {
    fieldName: string;
    type: GeneralDataType;
    display: string;
    displayOrder?: number;
    showInFilter?: boolean;
    width: number;
    dataType: SpecificDataType;
    format?: string;
    hidden?: boolean;
    order?: number;
    isSelected?: boolean;
    referenceLink?: IReferenceLink;
    detailColumn?: boolean;
    isDynamicFacet?: boolean;
    tableName?: string;
    nestedPath?:string;
    form: any;
    customStyle?: CustomColumnStyle
}
class CustomColumnStyle {
    display?: string;
    color?: string;
    textColor?: string;
}
