import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GraphQlOptionDataServiceInterface } from '../../option-lists/shared-services/data-service';
import { GraphQlController } from '../shared/base-graphql-controller';
import { MatSelectionList } from '@angular/material/list';

@Component({
    selector: 'app-formly-field-dyn-selection-list',
    templateUrl: './dyn-selection.component.html',
    styleUrls: ['./dyn-selection.component.scss']
})
export class DynSelectionComponent extends GraphQlController implements OnInit, OnDestroy {

    public isLoading = false;
    public dynOptions: any[];
    public useOption = false;
    @ViewChild(MatSelectionList, { static: true }) selectionList;
    constructor(
        public optionsService: GraphQlOptionDataServiceInterface,
        private cdRef: ChangeDetectorRef
    ) {
        super(optionsService);
    }
    ngOnInit() {
        this.useOption = this.to.useOption;
        this.subscribeTofilter();
        this.subscribeToOptions();

    }
    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
    private async subscribeToOptions() {
        this.isLoading = true;
        this.$gqOptions.subscribe(options => {
            this.dynOptions = options;
            this.isLoading = false;
            if (options && options.length) {
                if (!this.formControl.value || !this.formControl.value.length) {
                    if (this.field.templateOptions.selectAll) {
                        this.updateValueAndValidity(this.field, this.dynOptions.map(x => x[this.valueMember]));
                    } else {
                        this.updateValueAndValidity(this.field, []);
                    }
                } else {
                    const key = Array.isArray(this.field.key) ? this.field.key[0] : this.field.key;
                    this.updateValueAndValidity(this.field, this.field.model[key]);
                }
            }
            if (!this.cdRef['destroyed']) {
                this.cdRef.markForCheck();
            }
        });
    }
}


