import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatTooltip } from "@angular/material/tooltip";

@Directive({
selector: '[appEllipsifyText]',
providers: [MatTooltip]
})
export class EllipsifyTextDirective {
  private subject = new Subject<boolean>();
  domElement: any;

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private tooltip: MatTooltip)
    {
        this.domElement = this.elementRef.nativeElement;
        const elipsifyme = {
             'text-overflow': 'ellipsis',
             'overflow': 'hidden',
             'white-space': 'nowrap',
            };
        Object.keys(elipsifyme).forEach(element => {
           this.renderer.setStyle(
           this.domElement, `${element}`, elipsifyme[element]
           );
        });
    }
@HostListener('mouseenter') onMouseEnter(): void {
    if(this.domElement.offsetWidth < this.domElement.scrollWidth){
       this.tooltip.message = this.domElement.textContent;
        this.tooltip.show();
    }
}
@HostListener('mouseleave') onMouseLeave(): void {
    this.tooltip.hide();
}

}
