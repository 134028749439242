
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IMainViewState, TabSettingsService } from '@discoverer/core/services';
import { first } from 'rxjs/operators';
import { BaseController, StateService, DiscovererQueryService, IColumnSetting } from '@discoverer/core/services';
import { GroupDescriptor } from '@progress/kendo-data-query/dist/npm/grouping/group-descriptor.interface';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'grouping',
    templateUrl: './grouping.component.html',
    styleUrls: ['./grouping.component.scss']
})
export class GroupingComponent extends BaseController implements OnInit {

    @Input() public groups: GroupDescriptor[];
    @Input() public tabSettings: TabSettingsService;
    @Input() public selectedView: string;
    @Output() public groupFieldChanged = new EventEmitter<{
        fieldName: string,
        dir: 'asc' | 'desc'
    }[]>();
    public groupColumns: {
        fieldName: string,
        dir: 'asc' | 'desc'
    }[];

    constructor() {
        super();
    }

    public async ngOnInit() {
        const groups = this.groups;
        this.groupColumns = groups && groups.length ? groups.map(g => ({
            fieldName: g.field,
            dir: g.dir
        })) : [{
            fieldName: '',
            dir: 'asc'
        }];
    }
    public setGroupField() {
        this.groupFieldChanged.emit(this.groupColumns);
    }
    isNested() {
        return this.groupColumns.some(g => g.fieldName.includes('.'));
    }
}
