import { Injectable } from '@angular/core';
import { FormsDataServiceInterface } from '../../../../discoverer/dynamic-forms/dyn-forms/services/forms-data-service.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import ActionForms from '../common/actions/actions.form.json';
import { of } from 'rxjs';
import 'rxjs/add/operator/delay';
import { ActionService } from './api/action.service';

@Injectable()
export class ActionFormService implements FormsDataServiceInterface {

    constructor(private _httpClient: HttpClient, private _actionService: ActionService) { }

    public getFormByKey(key: string): Observable<any> {
        const form = ActionForms.filter(f => f.Key === key)[0];
        const obj = {
            Key: key,
            Title: form.Title,
            Configuration: JSON.stringify(form.Configuration)
        };
        return of(obj).delay(1);
    }

    public postFormData(data: any): Observable<any> {
        const actionNameTitle = this._actionService.getActionNameTitle();
        data.name = actionNameTitle;
        if (data.storeAs?.display === '' || !data.storeAs?.display) {
            data.storeAs = null;
        }
        if (!!data.id) {
            return this._actionService.update(data);
        }
        return this._actionService.create(data);
    }
}
