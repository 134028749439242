<facet-data-source (context)="updateFacets($event)" [facetName]="facetName" [title]="title" [serviceUrl]="serviceUrl"
    [queryService]="queryService" [facetKey]="facetKey" [singleMode]="singleMode" [enableQuery]="true">
    <ng-template let-facetContext>
        <div class="flex align-center" *ngIf="facetContext && facetContext?.facetValues">
            <mat-button-toggle-group aria-label="title" multiple="singleMode">
                <button mat-button [ngClass]="{checked: !facetContext.selectedFacetValues?.length}" (click)="facetContext.unselectAllFacets()"> All </button>
                <mat-button-toggle *ngFor="let facetVal of displayFacets" [checked]="facetContext?.isChecked(facetVal)"
                    value="{{facetVal?.facetKey}}" (change)="facetContext.selectFacet(facetVal)">
                    <span> {{ (!(facetVal?.title)) ? '(Blank)' : facetVal?.title}} </span>
                    <span *ngIf="facetContext.facetData?.getValue(facetVal?.facetKey) && showCount">
                        {{facetContext.facetData?.getValue(facetVal?.facetKey) | formattedNumber}}
                    </span>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </ng-template>
</facet-data-source>
