<!-- Use ng-container to conditionally wrap the content -->
<ng-container *ngIf="level == 0; else contentTemplate">
  <div class="search-header-advanced-filter-container">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
</ng-container>
<ng-template #contentTemplate>

  <div *ngIf="isFilterPrintable()" class="filter-item" layout="row" [ngClass]="{'color-warn':filter?.requestHover}"
       (mouseenter)="filter.requestHover=true" (mouseleave)="filter.requestHover=false">
    <div class="title" [ngClass]="{'color-warn':filter?.requestHover}">
      {{ title }} <span class="operation" [innerHTML]="operation"></span>
    </div>
  </div>
  <ng-container *ngIf="filter.filters && filter.filters.length">
    <span *ngIf="shouldPrintParentheses()"> (</span>
    <ng-container *ngFor="let subFilter of filter.filters; let i = index">
      <div style="display: flex">
        <span *ngIf="i > 0"
              class="filter-item-operation"
              [ngClass]="{'color-warn': subFilter?.requestHover}">{{ separator }}&nbsp;</span>
          <search-header-advanced-filter [filter]="subFilter" [columnDictionary]="columnDictionary"
                                         [advFilterService]="advFilterService"
                                         [level]="level + 1">
          </search-header-advanced-filter>

      </div>
    </ng-container>
    <span [style.margin-left.px]="level > 2 ? (level) * -4 : -22" *ngIf="shouldPrintParentheses()"> )</span>
  </ng-container>
</ng-template>






