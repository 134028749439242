<disco-tab [appId]="appId" [tabId]="tabId">
    <ng-template let-tabContext>
        <!-- <textarea rows="20" cols="60">{{tabContext?.currentTab | json}}</textarea> -->
        <disco-report [queryService]="queryService" *ngIf="tabContext" [requestId]="requestId">
            <ng-template let-reportContext>
                <!-- <textarea rows="20" cols="60">{{reportContext?.currentReport | json}}</textarea>  -->
                <div  class="hover-area flex p-1 flex-col flex-auto">
                    <div style="height: 16px;" class="flex mt-2 flex-row flex-auto place-items-center place-content-between">
                        <h3 class="ml-2 font-medium">{{reportContext?.currentReport?.mainView?.mainTitle}}</h3>
                        <button class="show-on-hover"  *ngIf="enableNavigation" (click)="navigateToReport()" mat-button>
                            Open
                            <mat-icon class="material-icons">launch</mat-icon>
                        </button>
                    </div>
                    <reports-view [visibleDetailsHeader]="visibleDetailsHeader" [enableMultiSelect]="enableMultiSelect" [navigateToDetailDirectly]="true" class="related-list" [filters]="filters" #report1
                        [chartMaxLabels]="10"></reports-view>
                </div>
            </ng-template>
        </disco-report>
    </ng-template>
</disco-tab>
