import { Injectable } from '@angular/core';
import { disLogger } from '../functions';

export const REQUEST_PREFIX = 'request_';

@Injectable({
    providedIn: 'root',
})
export class RequestCacheService {
    public setItem(key: string, value: any, isTempRequest = false) {
        const storage = isTempRequest ? sessionStorage : localStorage;
        let storeKey = REQUEST_PREFIX + key;
        storage.setItem(storeKey, JSON.stringify(value));
    }

    public getItem(key: string): any {
        const storeKey = REQUEST_PREFIX + key;
        const item = localStorage.getItem(storeKey) || sessionStorage.getItem(storeKey);
        try {
            return JSON.parse(item);
        } catch (e) {
            disLogger(e.message)
            return null;
        }
    }

    public removeItem(key: string, includeTemp = false): void {
        let storeKey = REQUEST_PREFIX + key;
        localStorage.removeItem(storeKey);
        includeTemp && sessionStorage.removeItem(storeKey);
    }

    public removeAllCachedReuests(includeTemp = false) {
        Object.keys(localStorage)
            .filter(key => key.startsWith(REQUEST_PREFIX))
            .forEach(key => {
                localStorage.removeItem(key);
                includeTemp && sessionStorage.removeItem(key);
            });
    }
}
