<mat-form-field  class="search-bar">
    <input #searchField [autoFieldFocus]="searchField" [(ngModel)]="searchText" (input)="filterTemplates()" matInput type="text" placeholder="Search for template" />
</mat-form-field>

<div class="templates-container">
    <p class="not-found-hint" *ngIf="!templates.length">No templates found</p>
    <ng-container *ngFor="let template of templates">
        <div class="template-card" (click)="updateDocumentFields(template?.fields)">
            <p class="template-name">{{ template?.display }}</p>
        </div>
    </ng-container>
</div>
