import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { PopoverService } from "@discoverer/dynamic-reports/popover/popover.service";

@Component({
  selector: "search-header-advanced-filter-container",
  styleUrls: ["./search-header-advanced-filter.component.scss"],
  templateUrl: "./search-header-advanced-filter-container.component.html",
})
export class SHAFContainerComponent implements OnChanges, OnInit {
  @Input()
  filter;

  @Input()
  columnDictionary;

  @Input()
  advFilterService;

  @Input()
  separator;

  @Input()
  isOpen: boolean;

  @Input()
  isVertical = false;
  constructor(public popoverService: PopoverService) {}
  ngOnChanges(changes: SimpleChanges): void {}

  public dateTitleClicked() {
    this.isOpen = !this.isOpen;
  }

  async ngOnInit() {}
}
