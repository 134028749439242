import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { GraphQlOptionDataServiceInterface } from './data-service';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { map } from 'rxjs/operators';

@Injectable()
export class GraphQueryOptionService
    implements GraphQlOptionDataServiceInterface
{
    constructor(@Inject(forwardRef(() => Apollo)) private apollo: Apollo) {}
    getByOptions(query: string, filterBy?: any, fieldName: string = ""): Observable<any[]> {
        try {
            const queryObservable = this.apollo.query({
                query: gql`
                    ${query}
                `,
                variables: filterBy !== undefined ? { filterBy: filterBy[fieldName].value } : {},
            });
            return queryObservable.pipe(
                map((x: any) => x.data[Object.keys(x.data)[0]])
            );
        } catch (ex) {
            return of();
        }
    }
}
