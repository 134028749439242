export enum DatasetActionType {
    Webhook = "webhook",
    AiActionField = "aiActionField",
    AiGenerate = "aiGenerate"
}

export enum FileViewerType {
    Normal = 1,
    Downloaded = 2,
    Nested = 3,
    Custom = 4
}

export enum NodeType {
    file = 'file',
    folder = 'folder'

}

export enum FieldCategoryEnum {
    Default = 'default',
    Action = 'action'
}

export enum ParsedTabs {
    AllRecordTabName = 'All Records',
    QueueTabName = 'Queue',

}

