import { GenericFacetFilterService } from '../../numeric-range-filter/numeric-range-filter.service';
import { Injectable } from '@angular/core';
import { DateRangeState } from '../model/model';
import { FILTERING_OPERATION, IDRFilter, DATE_OPERATION } from '../../services';
import { IDateOperation } from "@discoverer/core/services/classes/advanced-filters";
import { FILTERING_OPR_DICTIONARY, FacetValue, DRFacetOption, DRFilter } from "./../../../core/services";


@Injectable()
export class DateRangeFilterService extends GenericFacetFilterService<DateRangeState> {

    protected validateFormState(state: DateRangeState): boolean {
        switch (state.masterSelection) {
            case FILTERING_OPR_DICTIONARY.IS_BETWEEN:
                return (state.dateOperationShortcut1.id === "THE_DATE" ? this.validateDateFormat(state.value1Date) : Number.isFinite(state.value1)) &&
                    (state.dateOperationShortcut2.id === "THE_DATE" ? this.validateDateFormat(state.value2Date) : Number.isFinite(state.value2));
            case FILTERING_OPR_DICTIONARY.DATE_BLANK:
            case FILTERING_OPR_DICTIONARY.DATE_NOT_BLANK:
            case FILTERING_OPR_DICTIONARY.DATE_HIGH_VALUE:
            case FILTERING_OPR_DICTIONARY.DATE_NOT_HIGH_VALUE:
                return true;
            case FILTERING_OPR_DICTIONARY.DATE_AFTER:
            case FILTERING_OPR_DICTIONARY.DATE_ON_AFTER:
            case FILTERING_OPR_DICTIONARY.DATE_ON:
                if (state.dateOperationShortcut1.id === "THE_DATE") { return this.validateDateFormat(state.value1Date) } else { return !!state.masterSelection && Number.isFinite(+state.value1); }
            case FILTERING_OPR_DICTIONARY.DATE_BEFORE:
            case FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE:
                if (state.dateOperationShortcut2.id === "THE_DATE") { return this.validateDateFormat(state.value2Date) } else { return !!state.masterSelection && Number.isFinite(+state.value2); }
            default:
                return false;
        }
    }

    protected convertFilterToFormState(filter: IDRFilter): DateRangeState {
        if (!filter || !filter.expression) {
            return {
                masterSelection: FILTERING_OPR_DICTIONARY.DATE_NOT_BLANK,
                dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
                dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
                value1IsInclusive: false,
                value2IsInclusive: false,
                isSingleValue: true,
                value1: 0,
                value2: 0,
            };
        } else {
            const expression = filter.expression[0];
            if (!!expression) {
                const values = expression
                    .replace(/"/g, "")
                    .replace("!", "")
                    .replace("[", "")
                    .replace("{", "")
                    .replace("}", "")
                    .replace("]", "")
                    .split(" TO ")
                    .map(s => s.trim());

                const leftIsInclusive = expression.includes('[');
                const rightIsInclusive = expression.includes(']');
                const isNotEqual = expression.startsWith('!');
                const leftIsStar = values[0]?.includes("*");
                const rightIsStar = values[1]?.includes("*");
                const leftIsBlank = values[0] == "NULL";
                const rightIsBlank = values[1] == "NULL";
                const leftIsHighValue = values[0] == '9999-12-31T00:00:00Z';
                const rightIsHighValue = values[1] == '9999-12-31T00:00:00Z';


                const dateOperationShortcut1 = this._getDateOperation(values[0]);
                const dateOperationShortcut2 = this._getDateOperation(values[1]);


                let masterSelection = FILTERING_OPR_DICTIONARY.NOT_SELECTED;

                if (leftIsBlank && rightIsBlank) {
                    masterSelection = isNotEqual ? FILTERING_OPR_DICTIONARY.DATE_BLANK : FILTERING_OPR_DICTIONARY.DATE_NOT_BLANK;
                }
                else if (!leftIsStar && !rightIsStar) {
                    masterSelection = values[0] === values[1] ?
                        FILTERING_OPR_DICTIONARY.DATE_ON
                        : FILTERING_OPR_DICTIONARY.IS_BETWEEN;
                }
                else if (!leftIsStar && rightIsStar) {
                    masterSelection = leftIsHighValue ? FILTERING_OPR_DICTIONARY.DATE_HIGH_VALUE : leftIsInclusive ? FILTERING_OPR_DICTIONARY.DATE_ON_AFTER : FILTERING_OPR_DICTIONARY.DATE_AFTER
                } else if (!rightIsStar && leftIsStar) {
                    masterSelection = rightIsHighValue ? FILTERING_OPR_DICTIONARY.DATE_NOT_HIGH_VALUE : rightIsInclusive ? FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE : FILTERING_OPR_DICTIONARY.DATE_BEFORE;
                }


                return {
                    value1IsInclusive: leftIsInclusive,
                    value2IsInclusive: rightIsInclusive,
                    masterSelection: masterSelection,
                    isSingleValue: masterSelection != FILTERING_OPR_DICTIONARY.IS_BETWEEN,
                    value1: values[0].includes('NOW') ? +values[0].match(/\d/g).join("") : 0,
                    value2: values[1].includes('NOW') ? +values[1].match(/\d/g).join("") : 0,
                    value1Date: values[0].includes('NOW') ? null : values[0],
                    value2Date: values[1].includes('NOW') ? null : values[1],
                    dateOperationShortcut1,
                    dateOperationShortcut2

                }
            }
        }
    }

    private _getDateOperation(expression: string) {
        if (expression?.toLowerCase().includes('now')) {
            return DATE_OPERATION.find(operation =>
                expression.includes(operation.expression)
                && expression.includes(operation.filteringOn));
        } else {
            return DATE_OPERATION.find(op => op.id === 'THE_DATE');
        }
    }

    protected convertFormStateToFilter(state: DateRangeState, facetKey: string): IDRFilter {
        const masterSelection = state.masterSelection;
        const isBetween = masterSelection == FILTERING_OPR_DICTIONARY.IS_BETWEEN;
        const isON = masterSelection == FILTERING_OPR_DICTIONARY.DATE_ON;
        const isBlank = masterSelection.includes('BLANK');
        const isHighValue = masterSelection == FILTERING_OPR_DICTIONARY.DATE_HIGH_VALUE;
        const isNotHighValue = masterSelection == FILTERING_OPR_DICTIONARY.DATE_NOT_HIGH_VALUE;

        const value1IsInclusive = isBetween ? state.value1IsInclusive :
            (masterSelection != FILTERING_OPR_DICTIONARY.DATE_AFTER && !isBlank);

        const value2IsInclusive = isNotHighValue ? false :
            isBetween ? state.value2IsInclusive
                : (masterSelection != FILTERING_OPR_DICTIONARY.DATE_BEFORE && !isBlank);

        const shortCut1IsDate = state.dateOperationShortcut1.id === 'THE_DATE';
        const shortCut2IsDate = state.dateOperationShortcut2.id === 'THE_DATE';

        let composedValue1;
        let composedValue2;

        if (!shortCut1IsDate) {
            const dateOprInfo1 = DATE_OPERATION.filter(f => f.id === state.dateOperationShortcut1.id)[0];
            composedValue1 = `NOW${dateOprInfo1.expression}${+state.value1}${dateOprInfo1.filteringOn}`;
        }
        if (!shortCut2IsDate) {
            const dateOprInfo2 = DATE_OPERATION.filter(f => f.id === state.dateOperationShortcut2.id)[0];
            composedValue2 = `NOW${dateOprInfo2.expression}${+state.value2}${dateOprInfo2.filteringOn}`;
        }
        var value1 = (isBetween || isON || masterSelection.includes("AFTER")) ? `${shortCut1IsDate ? state.value1Date : composedValue1}` : '*';
        var value2 = (isBetween || isON || masterSelection.includes("BEFORE")) ? (shortCut2IsDate ? (isON ? `${value1}` : `${state.value2Date}`)
            : (isON ? `${value1}` : `${composedValue2}`))
            : '*';

        value1 = isHighValue ? '9999-12-31T00:00:00Z' : value1;
        value2 = isNotHighValue ? '9999-12-31T00:00:00Z' : value2;

        const fromExp = (value1IsInclusive ? "[" : "{") + (isBlank ? " NULL" : value1);
        const toExp = (isBlank ? "NULL " : value2) + (value2IsInclusive ? "]" : "}");
        const isDateBlank = (masterSelection === FILTERING_OPR_DICTIONARY.DATE_BLANK);
        const facetValue = new FacetValue(
            new DRFacetOption(
                value1 + " TO " + value2,
                (isBlank ? (isDateBlank ? "!" : "") : "") + fromExp + " TO " + toExp + "",
                this.getFilterDisplay(value1, value2, state.dateOperationShortcut1, state.dateOperationShortcut2, masterSelection)
            )
        );

        facetValue.isChecked = true;

        let filter = new DRFilter(
            "facet",
            [facetKey],
            [facetValue],
            []
        );

        return filter;
    }

    private getFilterDisplay(
        val1: string,
        val2: string,
        shortcut1,
        shortcut2,
        masterSelection: string
    ) {
        switch (masterSelection) {
            case FILTERING_OPR_DICTIONARY.DATE_AFTER:
                return val1.includes('NOW') ? `Is After ${+val1.match(/\d/g).join("")} ${shortcut1.label}` : `Is After ${val1.split('T')[0]}`;
            case FILTERING_OPR_DICTIONARY.DATE_ON_AFTER:
                return val1.includes('NOW') ? `Is On or After ${+val1.match(/\d/g).join("")} ${shortcut1.label}` : `Is After ${val1.split('T')[0]}`;
            case FILTERING_OPR_DICTIONARY.DATE_BEFORE:
                return val2.includes('NOW') ? `Is Before ${+val2.match(/\d/g).join("")} ${shortcut2.label}` : `Is Before ${val2.split('T')[0]}`;
            case FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE:
                return val2.includes('NOW') ? `Is On or Before ${+val2.match(/\d/g).join("")} ${shortcut2.label}` : `Is On or Before ${val2.split('T')[0]}`;
            case FILTERING_OPR_DICTIONARY.DATE_ON:
                return val1.includes('NOW') ? `Is On ${+val1.match(/\d/g).join("")} ${shortcut1.label}` : `Is On ${val1.split('T')[0]}`;
            case FILTERING_OPR_DICTIONARY.IS_BETWEEN:
                const value1 = val1.includes('NOW') ? `${+val1.match(/\d/g).join("")} ${shortcut1.label}` : `${val1.split('T')[0]}`;
                const value2 = val2.includes('NOW') ? `${+val2.match(/\d/g).join("")} ${shortcut2.label}` : `${val2.split('T')[0]}`;
                return `Between ${value1} And ${value2}`;
            case FILTERING_OPR_DICTIONARY.DATE_BLANK:
                return `Is Blank`;
            case FILTERING_OPR_DICTIONARY.DATE_NOT_BLANK:
                return `Is Not Blank`;
            case FILTERING_OPR_DICTIONARY.DATE_HIGH_VALUE:
                return `Is High Value`;
            case FILTERING_OPR_DICTIONARY.DATE_NOT_HIGH_VALUE:
                return `Is Not High Value`;
        }
        return `filter display`;
    }

    private validateDateFormat(date: string): boolean {
        const regex = new RegExp(/^[0-9]{4}\-[0-9]{2}\-[0-9]{2}T00:00:00Z$/);
        return regex.test(date);
    }

    constructor() {
        super({
            value1: 0,
            value2: 0,
            value1IsInclusive: false,
            value2IsInclusive: false,
            isSingleValue: true,
            dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
            dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
            masterSelection: FILTERING_OPERATION.filter(f => f.id.includes("DATE_ON_AFTER"))[0].id
        });
                //this.runDateTest();
    }

    public setValue1(value1: number) {
        this.updateState('value1', value1);
    }

    public setValue2(value2: number) {
        this.updateState('value2', value2);
    }


    public setValue1Date(value1Date: any) {
        this.updateState('value1Date', value1Date);
    }

    public setValue2Date(value2Date: any) {
        this.updateState('value2Date', value2Date);
    }

    public setValue1IsInclusive(value1IsInclusive: boolean) {
        this.updateState('value1IsInclusive', value1IsInclusive);
    }
    public setValue2IsInclusive(value2IsInclusive: boolean) {
        this.updateState('value2IsInclusive', value2IsInclusive);
    }

    public setDateOperationShortcut1(operation1: IDateOperation) {
        this.updateState('dateOperationShortcut1', operation1);

    }

    public setDateOperationShortcut2(operation2: IDateOperation) {
        this.updateState('dateOperationShortcut2', operation2);

    }

    public setMasterSelection(masterSelection: string) {
        this.updateState('masterSelection', masterSelection);
    }



private runDateTest() {
    this._testDateStateToExpression({ masterSelection: FILTERING_OPR_DICTIONARY.DATE_ON, value1: undefined, value2: undefined, value1Date: '2021-08-13T00:00:00Z', value2Date: undefined, dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0], dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0], value1IsInclusive: undefined, value2IsInclusive: undefined, isSingleValue: undefined },
        "[2021-08-13T00:00:00Z TO 2021-08-13T00:00:00Z]");
    this._testDateStateToExpression({
        masterSelection: FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE,
        value1: undefined,
        value2: 1,
        value1IsInclusive: undefined,
        value2IsInclusive: undefined,
        value1Date: undefined,
        value2Date: undefined,
        dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
        dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("Days in the past"))[0],
        isSingleValue: undefined
    }, "[* TO NOW-1DAYS]");
    this._testDateStateToExpression({
        masterSelection: FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE,
        value1: 3,
        value2: 1,
        value1IsInclusive: undefined,
        value2IsInclusive: undefined,
        value1Date: undefined,
        value2Date: '2020-09-21T00:00:00Z',
        dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
        dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
        isSingleValue: undefined
    }, "[* TO 2020-09-21T00:00:00Z]");
    this._testDateStateToExpression({
        masterSelection: FILTERING_OPR_DICTIONARY.DATE_BEFORE,
        value1: 3,
        value2: 1,
        value1IsInclusive: undefined,
        value2IsInclusive: undefined,
        value1Date: undefined,
        value2Date: '2020-09-21T00:00:00Z',
        dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
        dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("Years in the future"))[0],
        isSingleValue: undefined
    }, "[* TO NOW+1YEARS}");
    this._testDateStateToExpression({
        masterSelection: FILTERING_OPR_DICTIONARY.DATE_ON_AFTER,
        value1: 3,
        value2: 1,
        value1IsInclusive: undefined,
        value2IsInclusive: undefined,
        value1Date: '2020-09-21T00:00:00Z',
        value2Date: '2020-09-21T00:00:00Z',
        dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
        dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
        isSingleValue: undefined
    }, "[2020-09-21T00:00:00Z TO *]");
    this._testDateStateToExpression({
        masterSelection: FILTERING_OPR_DICTIONARY.DATE_AFTER,
        value1: 3,
        value2: 1,
        value1IsInclusive: undefined,
        value2IsInclusive: undefined,
        value1Date: '2020-09-21T00:00:00Z',
        value2Date: '2020-09-21T00:00:00Z',
        dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("Months in the past"))[0],
        dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
        isSingleValue: undefined
    }, "{NOW-3MONTHS TO *]");
    this._testDateStateToExpression({
        masterSelection: FILTERING_OPR_DICTIONARY.DATE_BLANK,
        value1: 3,
        value2: 1,
        value1IsInclusive: undefined,
        value2IsInclusive: undefined,
        value1Date: '2020-09-21T00:00:00Z',
        value2Date: '2020-09-21T00:00:00Z',
        dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("Months in the past"))[0],
        dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
        isSingleValue: undefined
    }, "!{ NULL TO NULL }");
    this._testDateStateToExpression({
        masterSelection: FILTERING_OPR_DICTIONARY.DATE_NOT_BLANK,
        value1: 3,
        value2: 1,
        value1IsInclusive: undefined,
        value2IsInclusive: undefined,
        value1Date: '2020-09-21T00:00:00Z',
        value2Date: '2020-09-21T00:00:00Z',
        dateOperationShortcut1: DATE_OPERATION.filter(op => op.label.includes("Months in the past"))[0],
        dateOperationShortcut2: DATE_OPERATION.filter(op => op.label.includes("The Date"))[0],
        isSingleValue: undefined
    }, "{ NULL TO NULL }");


    this._testDateExpression("[2020-09-21T00:00:00Z TO 2020-09-21T00:00:00Z]", FILTERING_OPR_DICTIONARY.DATE_ON);
    this._testDateExpression("[* TO 2020-09-21T00:00:00Z]", FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE);
    this._testDateExpression("[* TO 2020-09-21T00:00:00Z}", FILTERING_OPR_DICTIONARY.DATE_BEFORE);
    this._testDateExpression("[2020-09-21T00:00:00Z TO *]", FILTERING_OPR_DICTIONARY.DATE_ON_AFTER);
    this._testDateExpression("{2020-09-21T00:00:00Z TO *]", FILTERING_OPR_DICTIONARY.DATE_AFTER);
    this._testDateExpression("{ NULL TO NULL }", FILTERING_OPR_DICTIONARY.DATE_NOT_BLANK);
    this._testDateExpression("!{ NULL TO NULL }", FILTERING_OPR_DICTIONARY.DATE_BLANK);
    this._testDateExpression("[2020-09-21T00:00:00Z TO 2020-09-30T00:00:00Z}", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
    this._testDateExpression("{2020-09-21T00:00:00Z TO 2020-09-30T00:00:00Z}", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
    this._testDateExpression("{2020-09-21T00:00:00Z TO 2020-09-30T00:00:00Z]", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
    this._testDateExpression("[2020-09-21T00:00:00Z TO 2020-09-30T00:00:00Z]", FILTERING_OPR_DICTIONARY.IS_BETWEEN);

    this._testDateExpression("[NOW-2DAYS TO NOW-2DAYS]", FILTERING_OPR_DICTIONARY.DATE_ON);
    this._testDateExpression("[* TO NOW-1YEARS]", FILTERING_OPR_DICTIONARY.DATE_ON_BEFORE);
    this._testDateExpression("[* TO NOW-20MONTHS}", FILTERING_OPR_DICTIONARY.DATE_BEFORE);
    this._testDateExpression("[NOW+1YEARS TO *]", FILTERING_OPR_DICTIONARY.DATE_ON_AFTER);
    this._testDateExpression("{NOW-3YEARS TO *]", FILTERING_OPR_DICTIONARY.DATE_AFTER);
    this._testDateExpression("[NOW-4YEARS TO 2020-09-30T00:00:00Z}", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
    this._testDateExpression("{2020-09-21T00:00:00Z TO NOW+1MONTHS}", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
    this._testDateExpression("{2020-09-21T00:00:00Z TO NOW-30DAYS]", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
    this._testDateExpression("[NOW+1YEAR TO 2020-09-30T00:00:00Z]", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
    this._testDateExpression("[NOW-4YEAR TO NOW+1DAYS]", FILTERING_OPR_DICTIONARY.IS_BETWEEN);
}


private _testDateExpression(expression: string, expectedMaster: string) {
    const facetValue = new FacetValue(
        new DRFacetOption(
            expression,
            expression,
            expression
        )
    );

    let filter = new DRFilter(
        "facet",
        ['TEST'],
        [facetValue],
        []
    );

    var formState = this.convertFilterToFormState(filter);
    var passes = formState.masterSelection === expectedMaster;
    console.log(`${passes} - testing expression: ${expression}`, formState)
}

private _testDateStateToExpression(state: DateRangeState, expectedExpression: string) {
    var expression = (this.convertFormStateToFilter(state, 'TEST'))?.expression[0];
    var passes = expression === expectedExpression;
    console.log(`${passes} - testing masterSelection: ${state.masterSelection} `, expression);
}
}
