<div class="wrapper">
    <div class="header flex justify-between">
        <div class="left-wrapper ">
            <div class="close-icon">
                <button mat-icon-button (click)="dialogRef.close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="action-info">
                <input [(ngModel)]="actionNameTitle" (ngModelChange)="onActionNameTitleChange($event)"
                    class="editable-title" placeholder="Enter Action Name" />
            </div>
            <div class="action ml-auto">
                <button mat-flat-button color="basic" (click)="save()" [disabled]="isSaveDisabled()">Save</button>
                <button mat-flat-button color="primary" (click)="runTest()">Test Function</button>
            </div>
        </div>
    </div>
    <div class="main">
        <div class="overlay" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>

        <mat-stepper #stepper>
            <mat-step label="Step 1" *ngIf="!isEditMode">
                <div class="main flex flex-col gap-2 p-5 h-full">
                    <div class="text-xl font-medium leading-8">Select Action</div>
                    <div class="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-3 gap-6 pt-2">
                        <ng-container *ngIf="availableActions?.length > 0">
                            <ng-container
                                *ngFor="let action of availableActions; let i = index; let first = first; let last = last;">
                                <mat-card [ngClass]="{'transform-card': !action.disabled, 'disabled': action.disabled}"
                                    class="flex flex-col flex-auto p-4 rounded-2xl overflow-auto justify-between gap-4"
                                    (click)="selectAction(action.type)">
                                    <div class="flex flex-row">
                                        <img [src]="getActionImage(action.type)" alt="logo" class="w-15 h-15">
                                        <div class="flex-0 text-xl font-medium tracking-tight leading-6 truncate p-2">
                                            {{getActionName(action.type)}}
                                        </div>
                                    </div>
                                    <p class="flex pt-2 font-medium">
                                        {{action.description}} </p>
                                    <div class="flex flex-0 ">
                                        <button mat-raised-button color="primary" [disabled]="action.disabled"
                                            class="button-connect">
                                            Choose
                                        </button>
                                    </div>
                                </mat-card>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Step 2" *ngIf="isEditMode || !!formData?.type">
                <button *ngIf="!isEditMode" mat-stroked-button matStepperPrevious>Back</button>
                <div class="flex w-full mt-2">

                    <ng-container [ngSwitch]="formData.type">
                        <ng-container *ngSwitchCase="DatasetActionType.Webhook"
                            [ngTemplateOutlet]="webhookTemplate"></ng-container>
                        <ng-container *ngSwitchCase="DatasetActionType.AiGenerate"
                            [ngTemplateOutlet]="aiGenerateTemplate"></ng-container>
                    </ng-container>

                    <ng-template #webhookTemplate>
                        <div class="flex flex-col w-full">
                            <app-dyn-form (result)="onSubmit($event)" #dynForm class="form"
                                [key]="getActionDynFormKey(formData.type)" [model]="formData" [isEditMode]="true">
                            </app-dyn-form>
                        </div>
                    </ng-template>

                    <ng-template #aiGenerateTemplate>
                        <div class="flex flex-col w-full">
                            <div class="text-base font-medium leading-8">Prompt</div>
                            <app-kendo-editor [menuItems]="promptMenuItems" [value]="promptValue"
                                (change)="editorChange($event)"></app-kendo-editor>
                            <div class='text-xl font-bold pt-4 mb-2'>Store as:</div>
                            <mat-form-field appearance="fill">
                                <mat-label>Name</mat-label>
                                <input matInput [(ngModel)]="formData.storeAs.display" required>
                            </mat-form-field>
                            <mat-checkbox [(ngModel)]="formData.alwaysRun">Always Run</mat-checkbox>
                            <span class="text-sm text-gray-600">This will make the action runs regardless of the file or previous actions statuses</span>
                        </div>

                    </ng-template>

                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>
