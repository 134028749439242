import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { AppSettingsService, IApp } from './app-settings.service';
import * as _ from 'lodash'
import wcmatch from 'wildcard-match';
import { AuthService, GatewayIdentityUser } from '@discoverer/app-core';
import { environment } from '@env/environment';


export enum Permission {
    ChangeReportType = 'CHANGE_REPORT_TYPE',
    EditFilters = 'EDIT_FILTERS',
    ExportReport = 'EXPORT_REPORT',
    SwitchReport = 'SWITCH_REPORT',
    ChangeColumnSettings = 'CHANGE_COLUMN_SETTINGS',
    EditReport = 'EDIT_REPORT',
    AddReport = 'ADD_REPORT',
    CreatePublicDirectory = 'CREATE_PUBLIC_DIRECTORY',
    EditPublicDirectory = 'EDIT_PUBLIC_DIRECTORY',
    MoveRequest = 'MOVE_REQUEST',
    DeleteRequest = 'DELETE_REQUEST',
    AddStat = 'ADD_STAT'
}

var DEFAULT_ACCESS_RULES = [
    { appId: '*', tabId: '*', key: Permission.ChangeReportType, resource: '*' },
    { appId: '*', tabId: '*', key: Permission.EditFilters, resource: '*' },
    { appId: '*', tabId: '*', key: Permission.ExportReport, resource: '*' },
    { appId: '*', tabId: '*', key: Permission.SwitchReport, resource: '*' },
    { appId: '*', tabId: '*', key: Permission.ChangeColumnSettings, resource: '*' },
    { appId: '*', tabId: '*', key: Permission.AddStat, resource: '*' },
    //save/create directories
    { appId: '*', tabId: '*', key: Permission.CreatePublicDirectory, resource: '*' },
    { appId: '*', tabId: '*', key: Permission.EditPublicDirectory, resource: 'userId:{userId}/requestId:*' },
    //save/create reports
    { appId: '*', tabId: '*', key: Permission.EditReport, resource: 'dirId:*/requestOwnerUserId:{userId}/requestId:*' },
    // { appId: '*', tabId: '*', key: Permission.EditReport, resource: 'dirId:12/userId:*/requestId:*' },
    // { appId: '*', tabId: '*', key: Permission.EditReport, resource: 'dirId:*/userId:*/requestId:b3164d02-befd-4584-9d45-8d1eea5d524a' },
    { appId: '*', tabId: '*', key: Permission.AddReport, resource: '*' },
    { appId: '*', tabId: '*', key: Permission.MoveRequest, resource: 'dirId:*/requestOwnerUserId:{userId}/requestId:*' },
    { appId: '*', tabId: '*', key: Permission.DeleteRequest, resource: 'dirId:*/requestOwnerUserId:{userId}/requestId:*' },
];

if ( environment.isDevelopment) {
    DEFAULT_ACCESS_RULES.push({ appId: '*', tabId: '*', key: Permission.EditReport, resource: '**/**' });
}

export interface IPermission {
    appId: string;
    tabId: string;
    key: string;
    resource: string;
}

export const PERMISSION_HANDLERS = new InjectionToken<IPermissionHandler[]>('PERMISSION_HANDLERS');


export interface IPermissionHandler {
    permissionKey: string;

    setUserPermissionRules(permissions: IPermission[], userId: string, accountId: string);

    hasPermission(appId: string, tabId: string, permissionKey: string, userId: string, accountId: string, resourceKey?: string): boolean;
}



export class PatternPermissionHandler implements IPermissionHandler {

    protected _permissions: IPermission[];
    protected isMatch = wcmatch([]);

    constructor(public permissionKey: string) {

    }

    setUserPermissionRules(permissions: IPermission[], userId: string, accountId: string) {
        this._permissions = permissions;
        const patterns = permissions.map(x => this.getPath(x.appId, x.tabId, x.key, userId, accountId, x.resource));
        console.log(this.permissionKey + ' patterns: ', patterns);
        this.isMatch = wcmatch(patterns)
    }

    protected getPath(appId: string, tabId: string, permissionKey: string, userId: string, accountId: string, resourceKey?: string): string {
        const path = `appid::${appId?.toLocaleLowerCase()}/tabid::${tabId?.toLocaleLowerCase()}/key::${permissionKey?.toLocaleLowerCase()}/resource::${resourceKey?.toLocaleLowerCase()}`
            .replace('{userid}', userId)
            .replace('{accountid}', accountId);
        return path;
    }

    hasPermission(appId: string, tabId: string, permissionKey: string, userId: string, accountId: string, resourceKey?: string): boolean {
        const toCheck = this.getPath(appId, tabId, permissionKey, userId, accountId, resourceKey);
        console.log(toCheck);
        return this.isMatch(this.getPath(appId, tabId, permissionKey, userId, accountId, resourceKey));
    }

}


@Injectable()
export class UserPermissionService {

    public permissionsLoaded = new BehaviorSubject(false);

    private userPermissions: IPermission[];
    private _permisisonHandlers: { [key: string]: IPermissionHandler } = {};

    private currentApp: IApp;
    private currentUser: GatewayIdentityUser;

    constructor(
        private _httpClient: HttpClient,
        _appSettings: AppSettingsService,
        _authService: AuthService,
        @Inject(PERMISSION_HANDLERS) handlers: IPermissionHandler[]
    ) {
        this._permisisonHandlers['DEFAULT'] = new PatternPermissionHandler('DEFAULT');
        handlers.forEach(h => {
            this._permisisonHandlers[h.permissionKey] = h;
        })

        combineLatest([_appSettings.oCurrentApp, _authService.gatewayIdentityUserObservable]).pipe(filter(data => !!data[0] && !!data[1]))
            .subscribe(async data => {
                this.currentApp = data[0];
                this.currentUser = data[1];
                if (this.currentApp.permissioningIsEnabled) {
                    this.userPermissions = await this.getUserPermissions(this.currentApp.key).pipe(first()).toPromise();
                } else {
                    this.userPermissions = DEFAULT_ACCESS_RULES;
                }

                // send permission rules to the right permission handler based on permission key
                const permissionsByKey = _.groupBy(this.userPermissions, x => (this._permisisonHandlers[x.key] != null ? x.key : 'DEFAULT'));
                Object.keys(permissionsByKey)
                    .map(k => ({
                        permissions: permissionsByKey[k],
                        handler: this._permisisonHandlers[k]
                    }))
                    .forEach(h => h.handler.setUserPermissionRules(h.permissions, this.currentUser.userId.toString(), this.currentUser.accountId?.toString()));
                this.permissionsLoaded.next(true);
            });
    }




    public async hasPermission(tabId: string, permissionKey: string, resourceKey: string = ''): Promise<boolean> {
        await this.permissionsLoaded.pipe(filter(p => p), first()).toPromise();
        const handler = this._permisisonHandlers[permissionKey] ?? this._permisisonHandlers['DEFAULT'];
        return handler.hasPermission(this.currentApp.key, tabId, permissionKey, this.currentUser.userId?.toString(), this.currentUser.accountId?.toString(), resourceKey);
    }

    private getUserPermissions(appId: string): Observable<IPermission[]> {
        const resourcesPrefix = `APPID::${appId.toUpperCase()}/`;
        return this._httpClient.get<IPermission[]>(`api/es/v1/user/${appId}/resources/${resourcesPrefix}`);
    }
}

