import {
    Component,
    Inject,
    OnInit
} from '@angular/core';
import {
    MatDialogRef,
    MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {
    Clipboard
} from '@angular/cdk/clipboard';
import {
    MatSnackBar
} from '@angular/material/snack-bar';
import { DASHBOARDS } from '@discoverer/dynamic-reports/common/dashboards-list/dashboards-list.component';

@Component({
    selector: 'copy-text-dialog',
    templateUrl: './copy-text.dialog.html',
    styleUrls: ['./copy-text.dialog.scss']
})
export class CopyTextDialog implements OnInit {

    public url: string;
    public title: string = 'Share Report';
    constructor(
        private dialogRef: MatDialogRef<CopyTextDialog>,
        @Inject(MAT_DIALOG_DATA) public data: { app, tab, newRequestId },
        private clipboard: Clipboard,
        private _snackBar: MatSnackBar,
    ) { }

    async ngOnInit() {
        var baseURL = window.location.origin;
        const { app, tab, newRequestId } = this.data;
        if (tab === DASHBOARDS) {
            this.url = `${baseURL}/${app}/dashboard/${newRequestId}`;
            this.title = 'Share Dashboard';
        } else {
            this.url = `${baseURL}/${app}/reports/${tab}/${newRequestId}`;
        }
    }

    private openSnackBar(msg: string) {
        this._snackBar.open(msg, '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }

    public close() {
        this.dialogRef.close();
    }

    public copy() {
        this.clipboard.copy(this.url);
        this.openSnackBar('Copied to clipboard!');
    }

    public open() {
        window.open(this.url, "_blank");
    }

}
