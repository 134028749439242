import { FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil } from 'rxjs/operators';
import { GraphQlOptionDataServiceInterface } from '../../option-lists/shared-services/data-service';
import { BaseCustomController } from './base-custom-controller';

export abstract class GraphQlController extends BaseCustomController {
    public displayMember: string | string[];
    public valueMember;
    public $gqOptions: Observable<any[]>;
    private filterHashset: { [field: string]: { value?: any, alias: string } }
    private fromParent: boolean;
    private dynOptionsSubject = new BehaviorSubject<any[]>([]);
    public _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(public optionsService: GraphQlOptionDataServiceInterface) {
        super();
        this.$gqOptions = this.dynOptionsSubject.asObservable();
    }

    public subscribeTofilter() {
        this.setupFilterParameters();
        const form = this.getForm();
        if (this.to.filterBy) {
            this.handleFilterBy(form);
        } else {
            this.getOptions();
        }
    }

    public getDisplayValue = (option) => option ? this.resolveDisplayMember(option) : '';

    private getOptions = async () => {
        let options = await this.fetchOptions();
        this.dynOptionsSubject.next(options);
    }

    private setupFilterParameters() {
        this.displayMember = this.to.displayMember;
        this.valueMember = this.to.valueMember;
        this.fromParent = this.to.fromParent;
    }

    private handleFilterBy(form: FormGroup) {
        this.buildFilters();
        this.subscribeToFormChanges(form);
        this.checkFilterValues();
    }

    private fetchOptions = async () => {
        if (this.to.filterBy) {
            this.updateFilterValues();
        }
        const response = await this.optionsService.getByOptions(this.field.templateOptions.query,
            this.filterHashset, this.to.filterBy).toPromise();
        return response ? this.processResponse(response) : this.updateValidityAndReturnEmpty();
    }

    private buildFilters() {
        this.filterHashset = this.to.filterBy ? this.createFilterHashset() : {};
    }

    private subscribeToFormChanges(form: FormGroup) {

        if (this.hasFormControls(form)) {
            combineLatest(this.filterControls(form))
                .pipe(debounceTime(100), takeUntil(this._unsubscribeAll))
                .subscribe(this.handleFormChange);
        }
    }

    private checkFilterValues() {
        if (this.hasDefinedFilterValues()) {
            this.getOptions();
        } else {
            this.clearOptions();
            this.updateValueAndValidity(this.field, undefined)
        }
    }

    private createFilterHashset() {
        const filters = Array.isArray(this.to.filterBy)
            ? this.to.filterBy.map(x => this.createFilter(x))
            : [this.createFilter(this.to.filterBy)];
        return Object.assign({}, ...filters.map((x) => ({ [x.field ? x.field : x.filterBy]: x })));
    }

    private createFilter(filterBy) {
        const value = this.resolve(filterBy.field ? filterBy.field : filterBy, this.getModel());
        return (typeof filterBy === 'object' && filterBy !== null) ? { ...filterBy, value, useFieldName: true } : { filterBy, value, useFieldName: false };
    }

    private handleFormChange = (q: any[]) => {
        if (this.isDefinedAndNotEmpty(q)) {
            this.getOptions();
        } else {
            this.clearOptions();
            this.updateValueAndValidity(this.field, undefined);
        }
    }

    filterControls(form: FormGroup): Observable<any>[] {
        return this.filtersKeys()
            .filter(x => !!this.getControl(form, x))
            .map(x => this.getControl(form, x).valueChanges.pipe(startWith(this.getControl(form, x).value)));
    }
    private processResponse(response: any[]) {
        if (response && response.length) {
            const keys = Object.keys(response[0]).filter(x => x !== '__typename');
            this.setMembersIfNotDefined(keys);
        }
        return this.field.templateOptions.options = response ?? [];
    }

    private updateValidityAndReturnEmpty() {
        this.updateValueAndValidity(this.field, null);
        return [];
    }

    private setMembersIfNotDefined(keys: string[]) {
        if (!this.displayMember) {
            this.displayMember = keys[1] ? keys[1] : keys[0];
        }
        if (!this.valueMember) {
            this.valueMember = keys[0];
        }
    }

    private getForm() {
        return this.fromParent ? this.field.parent.parent.form : this.form;
    }

    private hasFormControls(form: FormGroup) {
        return Object.keys(form.controls).some(k => this.filtersKeys().includes(k));
    }


    private getControl(form: FormGroup, fieldName: string) {
        return form.get(fieldName.split('.')[0]);
    }


    private isDefinedAndNotEmpty(q: any[]) {
        return q !== undefined && q !== null && q.some(x => x);
    }

    private hasDefinedFilterValues() {
        return this.filtersValues().some(x => x.value !== undefined || x.value !== null);
    }

    private clearOptions() {
        this.dynOptionsSubject.next([]);
    }

    private resolveDisplayMember(option) {
        return Array.isArray(this.displayMember)
            ? this.displayMember.map(x => ` ${this.resolve(x, option)} `).join(',')
            : this.resolve(this.displayMember, option);
    }

    private updateFilterValues() {
        Object.entries(this.filterHashset).forEach(([field, obj]) => {
            obj.value = this.resolve(field, this.getModel());
        });
    }

    private getModel() {
        return this.fromParent ? this.field.parent.parent.parent.model : this.model;
    }

    private filtersKeys = () => { return this.filterHashset ? Object.keys(this.filterHashset).map(key => key.split('.')[0]) : [] };

    private filtersValues = () => { return this.filterHashset ? Object.values(this.filterHashset) : [] };

    private resolve = (data: string, obj) => {
        if (!data) return null;
        const properties = data.split('.');
        return properties.length > 1
            ? properties.reduce((prev, curr) => prev && prev[curr], obj)
            : obj[data];
    }
}

