<div id="reports" style="height: 100%;">
    <div class="header">
        <div class="search">
            <mat-form-field class="report-search-bar" floatLabel="never">
                <input #Input [autoFieldFocus]="Input" placeholder="Search Reports" matInput autocomplete="off"
                    [(ngModel)]="term" />
                <mat-icon mat-icon-button matSuffix (click)="term=''" class="close-button">close</mat-icon>
            </mat-form-field>
        </div>
        <div class="row justify-content-end align-center">
            <mat-icon (click)="close()">close</mat-icon>
        </div>
    </div>
    <hr clas="divider" />
    <div class="main" *ngIf="!term else Filterd_List">
        <div class="wrapper">
            <div class="folders">
                <div class="header">
                    <h3 class="title">Tabs</h3>
                </div>
                <div (click)="setSelectedTab(tab)" class="folder" [ngClass]="{'active': selectedTab == tab}"
                    *ngFor="let tab of originalTabs; index as i;">
                    <div class="dir-title">
                        <mat-icon class="mr-4">space_dashboard</mat-icon>
                        <span class="title" matTooltip="{{tab.name}}"> {{tab.name}}</span>
                    </div>
                </div>
            </div>
            <div class="folders">
                <div class="header">
                    <h3 class="title">Directories</h3>
                </div>
                <div (click)="selectedDirectory = dir" class="folder" [ngClass]="{'active': selectedDirectory == dir}"
                    *ngFor="let dir of (selectedTab?.dirs || []); index as i;">
                    <div class="dir-title">
                        <mat-icon class="mr-4">{{+dir.userId === 0 ? 'public' : 'folder'}}</mat-icon>
                        <span class="title" matTooltip="{{dir.directoryName.split('|')[1]}} ">
                            {{dir.directoryName.split('|')[1]}} ({{(dir?.requests)?.length}})</span>
                    </div>
                </div>
            </div>
            <div class="reports ml-4" *ngIf="selectedDirectory">
                <div class="header">
                    <h3 class="title">Reports</h3>
                </div>
                <div class="report" *ngFor="let request of selectedDirectory.requests"
                    [ngClass]="{'active': reportIsSelected(request)}">
                    <div (click)="setReport(request, selectedTab)" class="report-title">
                        <mat-icon class="mr-4 report-icon" color="accent">{{(request.icon)? request.icon :
                            'table_chart_outline'}} </mat-icon>
                        <span class="item-title">{{request.requestName}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #Filterd_List>
        <div class="filtered-list">
            <filtered-reports-view [isOpenReport]="false" (clickedRequest)="setSearchedReport($event)"
                [requests]="(originalTabs | flattenTabsReq) | filterReq: term"
                [highlitedRequests]="selectedRequests"></filtered-reports-view>
        </div>
    </ng-template>
</div>