import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FilesServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/dyn-upload/files-service.interface';
import { Observable } from 'rxjs';

export const FILES_SERVICE_URL = new InjectionToken<string>('');

@Injectable()
export class FilesService implements FilesServiceInterface {

    constructor(
        private http: HttpClient,
        @Inject(FILES_SERVICE_URL) private filesServiceUrl: string,
    ) { }

    public uploadFiles(files, folderName: string): Observable<any> {
        return this.http.post(`${this.filesServiceUrl}/${folderName}`, files);
    }

    public getFile(id): Observable<any> {
        return this.http.get(`${this.filesServiceUrl}/${id}`, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        });
    }

    public getFilesInfo(ids: number[]): Observable<any> {
        return this.http.post(`${this.filesServiceUrl}/info`, ids);
    }

    public deleteFile(id): Observable<any> {
        return this.http.delete(`${this.filesServiceUrl}/${id}`);
    }
}
