import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppSettingsService, BaseController, IDirRequest, ITab2, ReportPersistService, RequestFilingService, TabSettingsService } from '@discoverer/core/services';
import { ViewTypes, resolveReportIcon } from '../common';
import { DASHBOARDS } from '../common/dashboards-list/dashboards-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FlattenedDirRequests } from '../common/filtered-reports-view/filtered-reports-view.component';
import { DialogsManagerService } from '../dialogs';
import { FormControl } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'all-reports',
    templateUrl: './all-reports.component.html',
    styleUrls: ['./all-reports.component.scss']
})
export class AllReportsComponent extends BaseController implements OnInit {
    public viewTypes = ViewTypes;
    public searchResults: FlattenedDirRequests[] = [];
    public tabs: ITab2[] = [];
    public searchFieldControl = new FormControl();
    public isLoading = false;
    public noMoreRequests = false;
    public highliterText = '';

    private _appId: string
    private _pageSize = 30;
    private _pageNo = 0;

    constructor(
        private _appSettingService: AppSettingsService,
        private activatedRoute: ActivatedRoute,
        private _router: Router,
        private _reportPersist: ReportPersistService,
        private _cdref: ChangeDetectorRef,
        private _dialogManagerService: DialogsManagerService,
        private _title: Title
    ) {
        super();
        this.subscriptions.push(this.searchFieldControl.valueChanges.pipe(
            debounceTime(500))
            .subscribe(newValue => this._searchRequests(newValue)));
    }

    public clearSearch() {
        this.searchFieldControl.setValue('');
    }
    public async ngOnInit() {
        this._title.setTitle(`All Reports`);
        this._appId = this.activatedRoute.snapshot.data.appId;
        await this._appSettingService.setActiveApp(this._appId);
        this.tabs = await this._appSettingService.getCurrentAppTabs()
        this._searchRequests('');
    }
    public openSearchedReport(request: FlattenedDirRequests) {
        const url = request.tabKey === DASHBOARDS
            ? `${this._appId}/dashboard/${request.id}`
            : `${this._appId}/reports/${request.tabKey}/${request.id}`;
        this._router.navigateByUrl(url);
    }
    public async openNewDashboard() {
        const defaultDashboardId = (await this._appSettingService.getCurrentApp()).defaultDashboard;
        const newDashboardId = await this._copyDefaultRequest(defaultDashboardId, this.viewTypes.Dashboard);
        this._router.navigateByUrl(`${this._appId}/dashboard/${newDashboardId}?editMode=true`);
    }
    public async openNewReport(viewType: ViewTypes) {
        const pickedTab: ITab2 = await this._dialogManagerService.openTabSelectorDialog(this.tabs);
        if (pickedTab) {
            const newRequestId = await this._copyDefaultRequest(pickedTab.defaultRequestId, viewType);
            this._router.navigateByUrl(`${this._appId}/reports/${pickedTab.key}/${newRequestId}`);
        }
    }
    private async _searchRequests(term: string) {
        this.isLoading = true;
        this._pageNo = 0;
        this.highliterText = term;
        this.searchResults = (await this._getRequests(term)).sort(this.compare);
        this.isLoading = false;
        this._cdref.markForCheck();
    }
    public async loadMore() {
        this._pageNo += 1;
        this.searchResults.push(...(await this._getRequests(this.searchFieldControl.value || '')).sort(this.compare));
        this._cdref.markForCheck();
    }
    private async _getRequests(term: string): Promise<FlattenedDirRequests[]> {
        const results = await this._reportPersist.globalSearchRequests(this._appId, term, this._pageNo, this._pageSize);

        this.noMoreRequests = results.length === 0;

        return results.map(request => {
            const [tabkey, directoryName] = request.directory.directoryName.split('|');
            const tabName = this.tabs.find(tab => tab.key.toLowerCase() === tabkey.toLowerCase())?.name || 'Dashboards';
            return {
                ...request,
                directoryName,
                tabKey: tabkey.toLowerCase(),
                tabName,
                isDirCommon: request?.directory?.userId === 0,
                icon: resolveReportIcon(request.type, request.subType)
            }
        });
    }
    private async _copyDefaultRequest(defaultReqId: string, viewType: ViewTypes): Promise<string> {
        const defaultReq = await this._reportPersist.getRequest(defaultReqId);
        defaultReq.mainView.type = viewType;
        defaultReq.mainView.mainTitle = `New ${viewType === ViewTypes.Dashboard ? 'Dashboard' : 'Report'}`
        const newRequestId = (await this._reportPersist.createLocalRequest());
        await this._reportPersist.updateRequest(newRequestId, defaultReq, true, true);
        return newRequestId;

    }

    public compare = (a, b) => {
        const value = this.searchFieldControl?.value?.toLowerCase();
        const aIncludesSearchTerm = a.requestName?.toLowerCase().includes(value),
            bIncludesSearchTerm = b.requestName?.toLowerCase().includes(value);
        if (aIncludesSearchTerm && !bIncludesSearchTerm) return -1;
        if (!aIncludesSearchTerm && bIncludesSearchTerm) return 1;
        return 0;
    }
}
