import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FilesServiceInterface } from './files-service.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseCustomController } from '../shared/base-custom-controller';


@Component({
    selector: 'dyn-uploader',
    templateUrl: './dyn-upload.component.html',
    styleUrls: ['./dyn-upload.component.scss']
})
export class DynUploaderComponent extends BaseCustomController implements OnInit {
    public fileIsDragged = false;
    public uploadedFiles = [];
    public singleMode = false;
    public folder = '';
    public isLoading = false;
    public fileBeingDeleted;
    constructor(
        private filesService: FilesServiceInterface,
        private snackBar: MatSnackBar,
        private cdRef: ChangeDetectorRef
    ) {
        super();
    }
    ngOnInit(): void {
        this.singleMode = this.to.singleMode;
        if (this.to && this.to.folder) {
            this.folder = this.to.folder;
        }
        this.getFilesInfo();
        this.field.formControl.valueChanges.subscribe(x => {
            this.getFilesInfo();
        });
    }
    public async getFilesInfo() {
        const ids = this.field.formControl.value;
        if (ids && (this.singleMode || ids.length)) {
            const idsArr = this.singleMode ? [ids] : [...ids];
            this.isLoading = true;
            this.filesService.getFilesInfo(idsArr).subscribe(
                files => {
                    this.isLoading = false;
                    if (files && files.length) {
                        this.uploadedFiles = [];
                        this.uploadedFiles.push(...files);
                    }
                    this.cdRef.markForCheck();
                }, error => {
                    this.isLoading = false;
                });
        } else {
            this.uploadedFiles = [];
        }
    }
    public onFilesSelect(files) {
        if (!this.formControl.disabled) {
            if (this.singleMode && files && files.length > 1) {
                this.snackBar.open('Only one file is allowed', 'OK');
            } else {
                this.fileIsDragged = false;

                var isValidFilesName = this.checkFilesNameValidity(files);
                var isFileTypeValid = this.checkFilesValidity(files)

                if (!isValidFilesName || !isFileTypeValid) {
                    this.uploadedFiles = [];

                    const errorMessage = !isFileTypeValid && this.to?.accept
                        ? `Invalid file name or type. Allowed file types are: ${this.to.accept}`
                        : 'Invalid file name or type';

                    this.snackBar.open(errorMessage, 'OK');
                } else {
                    this.upload(files);
                }

            }
        }
    }
    public onFilesDragOver() {
        this.fileIsDragged = true;
    }
    public onFilesDragLeave() {
        this.fileIsDragged = false;
    }
    public deleteAttachment(file) {
        this.fileBeingDeleted = file.id;
        this.filesService.deleteFile(file.id).subscribe(response => {
            if (response) {
                this.fileBeingDeleted = null;
                const index = this.uploadedFiles.findIndex(f => f.id === file.id);
                this.uploadedFiles.splice(index, 1);
                this.cdRef.markForCheck();
                const value = this.singleMode ? null : this.uploadedFiles.map(fi => fi.id);
                this.updateValueAndValidity(this.field, value);
            }
        });
    }
    public download(file) {
        this.filesService.getFile(file.id).subscribe(response => {
            if (response) {
                const blob = new Blob([response], { type: response.type });
                const a = document.createElement('a');
                a.href = URL.createObjectURL(blob);
                a.download = file.name;
                a.click();
            }
        });
    }
    public getLogo(extension: string) {
        return `assets/icons/${extension.toLocaleLowerCase()}.svg`;
    }

    public indelibleFiles(fileId) {
        return this.field?.templateOptions.indelibleFiles?.includes(fileId);
    }

    private async upload(files) {
        this.isLoading = true;
        const formData: FormData = new FormData();

        Object.keys(files).forEach(key => {
            formData.append(`attachment`, files[key]);
        });
        this.filesService.uploadFiles(formData, this.folder).subscribe(addedFiles => {
            this.isLoading = false;
            if (addedFiles) {

                addedFiles.forEach(f => this.uploadedFiles.push({ id: f.id, name: f.name }));
                this.cdRef.markForCheck();
            }
            const value = this.singleMode ? +this.uploadedFiles[0].id : this.uploadedFiles.map(fi => fi.id);
            this.updateValueAndValidity(this.field, value);
        });


    }

    private checkFilesNameValidity(files: any): boolean {
        const asciiRegex = /^[\x00-\x7F]*$/;

        for (const key of Object.keys(files)) {
            const fileName = files[key].name;

            if (!asciiRegex.test(fileName)) {
                this.snackBar.open(`File name contains non-ASCII characters: ${fileName}`, 'OK');
                return false;  // Immediately return false if a non-ASCII name is found
            }
        }

        return true;  // Return true if all file names are valid
    }

    private checkFilesValidity(files: any): boolean {
        if (this.to.accept) {
            const allowedTypes = this.to.accept;
            const fileArray = Array.from(files) as any[];

            for (const file of fileArray) {
                const fileType = file.type || file.name.split('.').pop();
                if (!this.isFileTypeAllowed(fileType, allowedTypes)) {
                    this.snackBar.open(`File type ${fileType} is not allowed`, 'OK');
                    return false;
                }
            }
        }
        return true;
    }

    private isFileTypeAllowed(fileType: string, allowedTypes: string): boolean {
        if (!allowedTypes) {
            return true;
        }
        const acceptedTypes = allowedTypes.split(',');
        return acceptedTypes.some(type => fileType.includes(type.trim()));
    }

}
