import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActionModel } from '@app/ignite/components/reports/models/action.model';
import { ActionService } from '@app/ignite/components/reports/services/api/action.service';
import { DatasetFileService } from '@app/ignite/components/reports/services/api/dataset-file.service';
import { DatasetService } from '@app/ignite/components/reports/services/api/dataset.service';
import { EmitterService } from '@app/ignite/components/reports/services/emitter.service';
import { TabSettingsService } from '@discoverer/core';
import { take } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../../attoms/confirmation-dialog/confirmation-dialog.component';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../../../home/edit-title-dialog/edit-title-dialog';


@Component({
    selector: 'app-document-header-actions',
    templateUrl: './document-header-actions.component.html',
    styleUrls: ['./document-header-actions.component.scss']
})
export class DocumentHeaderActionsComponent implements OnInit {

    @Input() result: any;
    @Input() fileId: string;
    @Input() resultIndex: number;
    @Input() headerWidth: number;
    @Input() isEditedValuesValid: boolean;
    @Input() isShowLogsEnabled: boolean = false;
    @Output() toggleShowLogs = new EventEmitter<boolean>();
    @Output() toggleIsSaving = new EventEmitter<boolean>();
    @Output() toggleEditMode = new EventEmitter<boolean>();
    @Output() queryServiceChanged = new EventEmitter();
    @Output() closeDialog = new EventEmitter();
    @Output() limitChanged = new EventEmitter();


    public isEditMode = false;
    public showReprocessFileButton: boolean = false;

    public isCopied: boolean = false;
    public isSaving: boolean = false;
    public isActionInProgress: boolean = false;
    public showEditButton: boolean = false;
    public datasetId: string;
    public datasetType: string;
    public tabId: string;
    public isLargeScreenMode: boolean;
    public actionsList: ActionModel[] = []

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        private _clipboard: Clipboard,
        private _snackBar: MatSnackBar,
        private _datasetFileService: DatasetFileService,
        private _tabSettingService: TabSettingsService,
        private _emitterService: EmitterService,
        private _dialog: MatDialog,
        private _cdr: ChangeDetectorRef,
        private _datasetService: DatasetService,
        private _actionService: ActionService
    ) {
        const { datasetId, subDatasetId } = data.routeSnapshot.parent.params;
        this.tabId = data.routeSnapshot.data['subDatasetId'] || data.routeSnapshot.data['tabId'];
        this.datasetId = subDatasetId || datasetId;
    }
    public async ngOnInit() {
        this.datasetType = (await this._datasetService.oDatasetDetails.pipe(take(1)).toPromise()).Type;
        this.showEditButton = this.datasetType === 'DATASET' || this.tabId != 'queue';
        this.showReprocessFileButton = this.datasetType === 'DATASET' || this.tabId != 'queue';
        this.actionsList = (await this._actionService.getAll(this.datasetId).toPromise()).result;
    }
    public copy(result) {
        const resultJson = JSON.stringify(result);
        this.isCopied = true;
        this._clipboard.copy(resultJson);
        setTimeout(() => this.isCopied = false, 2000);
        this._snackBar.open(`Content copied to clipboard!`, 'close', SUCCESS_SNACKBAR_OPTIONS);
    }
    public save() {
        this.isSaving = true;
        this.isActionInProgress = true;
        this.toggleIsSaving.emit(this.isSaving);
        setTimeout(async () => {
            try {
                var tabColumns = (await this._tabSettingService.getAllColumns())
                var arrayCols = tabColumns.filter(col => col.dataType === 'object_array')
                arrayCols.forEach(col => this._removeEmptyLines(col.fieldName));

                const { isSuccess } = await this._datasetFileService.updateFileResults(this.datasetId, this.fileId, { parser_0: this.result }, this.resultIndex).toPromise()

                this._handleResponse(isSuccess, 'File saved successfully', 'Error saving the file');

            } catch (error) {
                console.error('Error saving file:', error);

                this._snackBar.open(`Error saving the file`, 'close', ERROR_SNACKBAR_OPTIONS);

            } finally {
                this.isEditMode = false;
                this.isSaving = false;
                this.isActionInProgress = false;
                this.toggleIsSaving.emit(this.isSaving);
                this.toggleEditMode.emit(this.isEditMode);
            }
        }, 100);
    }
    public async reprocess() {

        try {
            const reprocessFile = await this._dialog.open(ConfirmationDialogComponent, {
                data: {
                    message: "Are you sure you want to reprocess this file ?"
                }
            }).afterClosed().toPromise()

            if (reprocessFile) {

                this.isActionInProgress = true;
                const { isSuccess } = await this._datasetFileService.reprocessById(this.datasetId, this.fileId).toPromise();
                this._handleResponse(isSuccess, 'File started reprocessing successfully', 'Error reprocessing the file');
            }

        } finally {

            this.isActionInProgress = false;
        }

    }
    public async delete() {
        try {
            const deleteDialogRes = await this._dialog.open(ConfirmationDialogComponent, { width: '420px', data: { message: "Are you sure you want to delete this file ?" } }).afterClosed().toPromise();
            if (deleteDialogRes) {
                this.isActionInProgress = true;
                const fileId = Array.isArray(this.fileId) ? this.fileId[0] : this.fileId;
                const deleteMethod = this.resultIndex >= 0 
                    ? this._datasetFileService.deleteByResultIndex(this.datasetId, fileId, this.resultIndex)
                    : this._datasetFileService.deleteById(this.datasetId, fileId);

                const { isSuccess } = await deleteMethod.toPromise();
                if (!isSuccess) this._showMsg(`Error deleting the file`, ERROR_SNACKBAR_OPTIONS);

                else {
                    this._showMsg(`File deleted successfully`, SUCCESS_SNACKBAR_OPTIONS);
                    await this._emitterService.getTotalNeedsReprocessingFilesCount();
                    this.closeDialog.emit();
                }
                this._cdr.detectChanges();
                this.queryServiceChanged.emit();
            }

        } finally {
            this.isActionInProgress = false;
        }

    }
    public cancel() {
        this.isEditMode = false;
        this.toggleEditMode.emit(this.isEditMode);
    }
    private _removeEmptyLines(field: string) {
        const keys = field.split('.');
        for (const key of keys) {
            this.result[this.resultIndex >= 0 ? this.resultIndex : 0][key] =
                this.result[this.resultIndex >= 0 ? this.resultIndex : 0][key].filter(data => Object.values(data).some(r => !!(r?.toString())));
        }
    }

    public onToggleShowLogs() {
        this.isShowLogsEnabled = !this.isShowLogsEnabled;
        this.toggleShowLogs.emit(this.isShowLogsEnabled)
    }

    public edit() {
        this.isEditMode = !this.isEditMode;
        this.toggleEditMode.emit(this.isEditMode);
        this.limitChanged.emit();
    }

    private _handleResponse(isSuccess: boolean, successMsg: string, errorMsg: string) {
        if (isSuccess)
            return this._snackBar.open(successMsg, 'close', SUCCESS_SNACKBAR_OPTIONS);

        return this._snackBar.open(errorMsg, 'close', ERROR_SNACKBAR_OPTIONS);
    }

    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this._snackBar.open(msg, 'close', config)
    }
    public async rerunAction(action) {
        const actionIndex = this.actionsList.findIndex(a => a.id === action.id);
        const actionsAfter = this.actionsList.slice(actionIndex).map(x => x.name);
        const message = `This will rerun the actions (${actionsAfter.join(', ')})  on the selected files. Are you sure you want to proceed?`;
        const confirm = await this._dialog.open(ConfirmationDialogComponent, { width: '420px', data: { message } }).afterClosed().toPromise();
        if (!confirm) return;
        const { isSuccess, result } = await this._actionService.runSequential(this.datasetId, action.id, [this.fileId]).toPromise();
        if (isSuccess) {
            this._snackBar.open(`Actions started on this file!`, null, SUCCESS_SNACKBAR_OPTIONS);
        } else {
            this._snackBar.open('Error Reruning actions', null, ERROR_SNACKBAR_OPTIONS);
        }
    }
}
