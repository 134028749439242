import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { FileDetailsDto, FileStatusEnum } from '../../models/datasetFile.models';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { TaskService } from '../../services/api/task.service';
import { ERROR_SNACKBAR_OPTIONS } from '../home/edit-title-dialog/edit-title-dialog';

@Component({
    selector: 'app-document-result',
    templateUrl: './document-result.component.html',
    styleUrls: ['./document-result.component.scss']
})
export class DocumentResultComponent implements OnInit, OnDestroy {
    @Input() datasetId: string
    @Input() sidenavRef: MatSidenav
    @Input() fileId: string;

    isDataExists: boolean;
    isLoading: boolean = false;
    result: any;
    subs: Subscription[] = []

    constructor(
        private _datasetFileService: DatasetFileService,
        private snackbar: MatSnackBar,
        private _taskService: TaskService
    ) { }

    async ngOnInit(): Promise<void> {
        await this._setupObservables();
        await this._getOrWaitForFileResult();
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe())
    }

    setDataExistsValue(value: boolean) {
        this.isDataExists = value;
    }

    setLoadingValue(value: boolean) {
        this.isLoading = value;
    }
    
    private async _setupObservables() {
        await this._getOrWaitForFileResult();
    }

    private async _getOrWaitForFileResult() {
        this.setLoadingValue(true);

        const fileDetails = await this._fetchFileDetails();
        if (!fileDetails) {
            this.setDataExistsValue(false);
            return;
        }

        const success = await this._processFileTask(fileDetails);
        if (!success) {
            this.setDataExistsValue(false);
            return;
        }

        const result = await this._fetchFileResults();
        if (!result) {
            this.setDataExistsValue(false);
            return;
        }

        this.result = result;
        this.setDataExistsValue(true);
        this.setLoadingValue(false);
    }

    private async _fetchFileDetails(): Promise<FileDetailsDto | null> {
        try {
            const { isSuccess, result } = await this._datasetFileService.getFileDetails(this.datasetId, this.fileId).toPromise();
            if (isSuccess) {
                return result;
            } else {
                this._showMsg('Error fetching file details', ERROR_SNACKBAR_OPTIONS);
                return null;
            }
        } catch (error) {
            this._showMsg('Error fetching file details', ERROR_SNACKBAR_OPTIONS);
            return null;
        }
    }

    private async _processFileTask(fileDetails: FileDetailsDto): Promise<boolean> {
        try {
            if (fileDetails.Status === FileStatusEnum.PROCESSED) {
                return true;
            } else {
                return await this._taskService.waitForTask(fileDetails.FileTaskId);
            }
        } catch (error) {
            this._showMsg('Error processing file task', ERROR_SNACKBAR_OPTIONS);
            return false;
        }
    }

    private async _fetchFileResults(): Promise<any | null> {
        try {
            const response = await this._datasetFileService.getResults(this.datasetId, this.fileId).toPromise();
            if (response.isSuccess) {
                return response.result;
            } else {
                this._showMsg('Error fetching file result', ERROR_SNACKBAR_OPTIONS);
                return null;
            }
        } catch (error) {
            this._showMsg('Error fetching file result', ERROR_SNACKBAR_OPTIONS);
            return null;
        }
    }

    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this.snackbar.open(msg, 'close', config)
    }
}
