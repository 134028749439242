import { Component, ViewEncapsulation, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DRFilter, DiscovererQueryService } from '@discoverer/core/services';
import { TabSettingsService } from '@discoverer/core/services/dynamic-reports-services';

interface IAdvancedModalInput {
    queryService: DiscovererQueryService;
    tabSettings: TabSettingsService;
    filterMode: string;
}

@Component({
    selector: 'advanced',
    templateUrl: './advanced.dialog.html',
    styleUrls: ['./advanced.dialog.scss'],
    encapsulation: ViewEncapsulation.None
})
// tslint:disable-next-line:component-class-suffix
export class AdvancedDialog implements OnInit {
    public isInvalidDataEntry: boolean;
    public queryService: DiscovererQueryService;
    public tabSettings: TabSettingsService;
    public filterMode: string;

    constructor(
        private dialogRef: MatDialogRef<AdvancedDialog, boolean>,
        @Inject(MAT_DIALOG_DATA) private dialogData: IAdvancedModalInput

    ) {
        this.filterMode = dialogData.filterMode;
    }

    public ngOnInit() {
        this.queryService = this.dialogData.queryService
        this.tabSettings = this.dialogData.tabSettings

    }

    public checkValidateAdvancedFilter(event) {
        this.isInvalidDataEntry = !event;
    }
    public close() {
        this.dialogRef.close();
    }
    public save() {
        this.dialogRef.close(true);
    }
    public async clearAdvancedFilter( ) {
        this.queryService.unSetFilter('Advanced');
        this.queryService.refresh();
        this.dialogRef.close();
    }
}
