import { Component, OnInit, Input, EventEmitter, Output, ElementRef, ContentChild, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { BaseController, DRFilter, DRSortCriteria, Factory } from '@discoverer/core/services';
import { DataSourceContext } from '@discoverer/core/data-source';


@Component({
    selector: 'disco-data',
    templateUrl: './disco-data.component.html'
})
export class DiscoDataComponent extends BaseController implements OnInit, OnChanges {

    @Input() appId: string;
    @Input() tabId: string;
    @Input() requestId?: string;

    @Input() public columnList?: string[];
    @Input() public dataMapper?: Factory<any>;
    @Input() public sorts?: DRSortCriteria[];
    @Input() public filters?: DRFilter[];
    @Input() public start = 0;
    @Input() public size = 1;
    @Input() public queryService;


    @ContentChild(TemplateRef) template: TemplateRef<ElementRef>;

    @Output()
    public context = new EventEmitter<DataSourceContext>();

    constructor(

    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }





}
