<div class="grouping">
    <div class="main-wrapper">
        <div class="warning" *ngIf="isNested()">
            Grouping by a nested field will force Data Flatten By to be set to the same field.
        </div>
        <columns-settings [addFieldLabel]="'Add Subgroup'" [columns]="groupColumns" [tabSettings]="tabSettings" (orderChanged)="setGroupField()" [selectedView]="selectedView"
            (fieldChanged)="setGroupField()">
        </columns-settings>
    </div>
</div>
