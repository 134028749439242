import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DatasetTypes } from '../../../models/dataset.model';
import { CreateDatasetDto } from '../../../models/dtos/create-dataset-dto';
import { DatasetService } from '../../../services/api/dataset.service';
import { IgniteTabService } from '../../../services/ignite-tab.service';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';

export const DEFAULT_DESCRIPTION = 'Classify page as {{title}} if it has information or continuation of a {{title}} page before.';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'new-dataset',
    templateUrl: './new-dataset.component.html',
    styleUrls: ['./new-dataset.component.scss'],
})
export class NewDatasetComponent {

    isCreating: boolean;
    datasetName: FormControl = new FormControl(null, [Validators.required]);
    datasetDescription: FormControl = new FormControl(null, [Validators.required]);
    type: DatasetTypes = DatasetTypes.DATASET;
    DatasetsTypes = DatasetTypes;
    constructor(
        public dialogRef: MatDialogRef<NewDatasetComponent>,
        @Inject(MAT_DIALOG_DATA) data: { type: DatasetTypes },
        private _datasetService: DatasetService,
        private _snackBar: MatSnackBar,
        private _igniteTabService: IgniteTabService,
        private _router: Router
    ) {
        this.type = data.type;
    }


    public closeDialog() {
        this.dialogRef.close({ id: null });
    }
    public async createDataset() {
        if (this.datasetName.hasError('required')) return;
        this.isCreating = true;
        let datasetId = this._igniteTabService.getGuid();
        let isDataset = this.type === DatasetTypes.DATASET;
        let response = (isDataset) ? await this.createNormalDataset(datasetId) : await this.createWorkspaceDataset(datasetId);

        if (response.isSuccess) {
            this._showMsg(`Dataset created successfully`, SUCCESS_SNACKBAR_OPTIONS);
            this.dialogRef.close(!isDataset);
            this._router.navigate(['datasets', response?.result, isDataset?'schema' : 'queue']);
        } else {
            const msg = response.result.includes("Dataset with same name already exists")
                ? `Dataset with same name already exists.`
                : `Error while creating dataset. Error: ${response.result}`;
            this._showMsg(msg, ERROR_SNACKBAR_OPTIONS);
        }
        this.isCreating = false;
    }
    updateDescription() {
        if (this.datasetDescription.value || !this.datasetName.value) return;
        this.datasetDescription.setValue(DEFAULT_DESCRIPTION.replace(/{{title}}/g, this.datasetName.value));
    }
    public async createWorkspaceDataset(datasetId: string): Promise<{ isSuccess: boolean, result: any }> {
        const datasetInput: CreateDatasetDto = { Id: datasetId, Title: this.datasetName.value, Description: this.datasetDescription.value };
        return await this._datasetService.create(datasetInput, this.type).toPromise();
    }
    public async createNormalDataset(datasetId: string): Promise<{ isSuccess: boolean, result: any }> {
        const datasetDetails = await this._datasetService.getCurrentBaseDatasetDetails()
        const workspaceId = datasetDetails.Type === DatasetTypes.WORKSPACE ? datasetDetails.Id : datasetDetails?.WorkspaceId;
        const files = await this._igniteTabService.initDefaultTab(this.datasetName.value, datasetId);
        const datasetInput: CreateDatasetDto = { Id: datasetId, Title: this.datasetName.value, Files: files, Description: this.datasetDescription.value, WorkspaceId: workspaceId };
        return await this._datasetService.create(datasetInput, this.type).toPromise();

    }

    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this._snackBar.open(msg, 'close', config);
    }
}
