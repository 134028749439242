import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Plugin, Schema, schema } from '@progress/kendo-angular-editor';
import {
  addMentionNodes,
  addTagNodes,
  getMentionsPlugin,
} from 'prosemirror-mentions';

@Component({
  selector: 'app-kendo-editor',
  templateUrl: './kendo-editor.component.html',
  styleUrls: ['./kendo-editor.component.scss'],
})
export class KendoEditorComponent implements OnDestroy, OnChanges {
  @Input() menuItems = [];
  @Input() value: string = '';
  @Output() change = new EventEmitter<{ html: string, text: string }>(true);

  @ViewChild('editor', { static: true }) editor!: any;
  public fieldsElement!: HTMLElement;
  public tools = [];

  mySchema = new Schema({
    nodes: addTagNodes(addMentionNodes(schema.spec.nodes)),
    marks: schema.spec.marks,
  });

  public plugin = getMentionsPlugin({
    hashtagTrigger: "/",
    getSuggestions: (type, text, done) => {
      done(this.menuItems);
    },

    getSuggestionsHTML: (items, type) => {
      setTimeout(() => {
        this.setMenuPosition();
      }, 100);
      return this.getMentionSuggestionsHTML(items);
    }
  });

  public myPlugins = (defaultPlugins: Plugin[]): Plugin[] => [
    this.plugin,
    ...defaultPlugins,
  ];

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue) {
      this.value = this.convertTextToHtml(changes.value.currentValue);
    }
  }

  onEditorChange(event: any) {
    this.hideMenuItems();
    const text = this.htmlToText(event);
    const textToCulryBrackets = this.convertMentionsToCurlyBrackets(text);
    this.change.emit({ html: event, text: textToCulryBrackets });
  }

  ngOnDestroy(): void {
    this.hideMenuItems();
  }


  setMenuPosition() {
    const menuElements = document.getElementsByClassName('suggestion-item-container');
    if (menuElements.length > 0) {
      const menuElement = menuElements[0] as HTMLElement;
      const position = this.calculateMenuPosition();
      menuElement.style.position = 'absolute';
      menuElement.style.top = `${position.top}px`;
      menuElement.style.left = `${position.left}px`;
      menuElement.style.display = 'block';
      menuElement.style.visibility = 'visible';
      menuElement.style.zIndex = '9999';
    }
  }

  private calculateMenuPosition() {
    const editorContainer = this.editor.element.nativeElement;
    const containerRect = editorContainer.getBoundingClientRect();

    const selection = this.editor.view.state.selection;
    const cursorCoords = this.editor.view.coordsAtPos(selection.to);

    const top = Math.max(0, cursorCoords.bottom - containerRect.top);
    const left = cursorCoords.left;

    const absoluteTop = top + containerRect.top;
    const absoluteLeft = left;

    return {
      top: absoluteTop,
      left: absoluteLeft
    };

  }



  private getMentionSuggestionsHTML(items) {
    return (
      '<div class="suggestion-item-list">' +
      items
        .map((item) => '<div class="suggestion-item">' + item.display + '</div>')
        .join('') +
      '</div>'
    );
  }

  private hideMenuItems() {
    const menuElements = document.getElementsByClassName('suggestion-item-container');
    if (menuElements.length > 0) {
      const menuElement = menuElements[0] as HTMLElement;
      menuElement.style.visibility = 'hidden';
      menuElement.remove();
    }
  }

  private htmlToText(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }


  private convertMentionsToCurlyBrackets(text) {
    // Regular expression to match mentions starting with #
    const pattern = /#([\w.]+)/g;
    // Replace matches with double curly brackets format
    return text.replace(pattern, '{{$1}}');
  }

  private convertTextToHtml(text: string): string {
    // Regular expression to match text inside double curly brackets
    const pattern = /\{\{([\w.]+)\}\}/g;
    // Replace matches with the HTML span structure
    const htmlText = text.replace(pattern, (match, tag) => {
      return `<span contenteditable="false" data-tag="${tag}" class="prosemirror-tag-node">#${tag}</span> <img class="ProseMirror-separator" alt=""> <br class="ProseMirror-trailingBreak">`;
    });
    // Wrap the entire text in a <p> tag
    return `<p>${htmlText}</p>`;
  }
}
