<mat-card>
  <mat-card-title>
    <div *ngIf="columnName" class="mat-title"> {{columnName}} </div>
  </mat-card-title>
  <mat-card-content class="list-card-content1">
    <div class="app-content" layoutDir="column" style=" margin-top:15px">
      <ng-container *ngFor="let row of rowList">
        <div layoutDir="row" [ngClass]="{selected: (row === selectedRow) }" style="cursor:pointer; min-width: 315px;">
          <div (click)="selectRow(row)" class="list-item" [style.background]="row === selectedRow ? '#ebffe6' : ''">
            <mat-icon *ngIf="materialIcon" style="margin-right: 10px; position: relative; bottom: -6px;" [style.color]="iconColor"> {{ materialIcon }} </mat-icon>
            <mat-form-field>
              <input matInput #rowName [disabled]="!canEdit && row.id !== 0" [style.cursor]="canEdit ? 'text' : 'pointer'" name="rowName"
                value="{{row?.name}}">
            </mat-form-field>
          </div>
          <div>
            <i *ngIf="canDelete && (row.id !== 0 && row === selectedRow)" matTooltip="Remove" [matTooltipPosition]="'right'" class="material-icons action-icon"
              (click)="rowDeleteConfirmDialog(row)">clear</i>

            <i *ngIf="(row.id === 0 && row === selectedRow && rowName.value.length > 0)" matTooltip="Add" [matTooltipPosition]="'right'"
              class="material-icons action-icon" (click)="rowChange(rowName.value, row)">add</i>

            <i *ngIf="(row.id !== 0 && row === selectedRow && rowName.value !== row.name && rowName.value.length > 0)" matTooltip="Update"
              [matTooltipPosition]="'right'" class="material-icons action-icon" (click)="rowChange(rowName.value, row)">edit</i>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
