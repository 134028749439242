import { DiscovererQueryService } from './discoverer-query-service.service';
import { Observable, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { StateService } from '../state';
import { map, distinctUntilChanged, tap } from 'rxjs/operators';
import {
    DRQuery,
    DRResult,
    FacetFieldAccumulator,
    IChartResults,
    IColumn,
    IChartDataConfig,
    DRFilter,
    GroupBy,
    DateGroupBy,
    IMetric,
    ChartTotal,
} from '../classes';
import { disLogger, generateHash } from '../functions';
import { BaseDataFacetService } from './base-data-facet.service';
import { HttpClient } from '@angular/common/http';

import { LoadingState } from '../classes/loading-state';
import { DataFormattingService, IChartState, TabSettingsService } from '../dynamic-reports-services';
import { DiscovererFacetTranslationService } from './discoverer-facet-translation-service.service';
import { Apollo } from 'apollo-angular';
import { EsQueryService } from './discoverer-esquery-service';
import { sortArray } from './pivot-chart.service';

export class ChartDataService extends BaseDataFacetService {
    public oData: Observable<IChartResults>;
    public oLoadingStatusResult: Observable<LoadingState>;
    public isHorizontalChart = false;

    private _changeId = 0;
    private _$Result: Subject<IChartResults> = new Subject();
    protected _bsLoadingStatus$: BehaviorSubject<LoadingState>;

    constructor(
        private _http: HttpClient,
        _serviceUrl: string,
        private _queryService: DiscovererQueryService,
        private _stateService: StateService<IChartState>,
        private dataFormattingService: DataFormattingService,
        private _tabSettingsService: TabSettingsService,
        private _facetTranslationService: DiscovererFacetTranslationService,
        colArray: IColumn[],
        private esQueryService: EsQueryService
    ) {
        super(colArray, _serviceUrl);
        this._bsLoadingStatus$ = new BehaviorSubject<LoadingState>({ status: "Busy" });
        // const x = new StateService<IChartDataConfig>(this._stateService.getState().chartConfig)
        const sub = combineLatest([this._stateService.oState, this._queryService.oQuery])
            .pipe(
                map(x => ({ state: x[0], query: x[1] })),
                //     distinctUntilChanged((a, b) => {
                //     // query change id changed without any chenges on query so i am making sure to get only changes.
                //     const aQuery = Object.assign({}, a.query);
                //     delete aQuery.changeId;
                //     const bQuery = Object.assign({}, b.query);
                //     delete bQuery.changeId;
                //     return (!!a.state && !!a.query && !!a.query && !!b.query &&
                //         (generateHash(aQuery) === generateHash(bQuery) && generateHash(a.state) === generateHash(b.state)));
                // })
            )
            .subscribe(s => {
                this._changeId = this._changeId += 1;

                if (!!s.query && !!s.state && !!s.state?.chartConfig?.categories) {
                    this.queryData(s.query, s.state.chartConfig, this._changeId);
                } else {
                    this._$Result.next({
                        categories: [],
                        series: [],
                        values: []
                    });
                }
            });
        this.subscriptions.push(sub);
        this.oData = this._$Result.asObservable();
        this.oLoadingStatusResult = this._bsLoadingStatus$.asObservable();
    }


    private getFacetDef(config: IChartDataConfig): { type: string, name: string } {
        const groupBy = this.getGroupByForQueryType(config.categories.groupBy, 'regular', config.categories.colName);
        const main = this.getSingleFacetDef(config.categories.colName, groupBy, config.categories.limit, config.categories.minCount, 0, true);
        if (!!config.series) {
            let statSortDir = 0;
            if (!!config.series.sortDir) {
                statSortDir = (config.series.sortDir === 'asc' ? 1 : -1);
            }
            const subCol = this.getSingleFacetDef(config.series.colName, config.series.groupBy, config.series.limit, config.categories.minCount, statSortDir, true);
            main.subFacet = subCol;
        }

        return main;
    }



    private getGroupByForQueryType(groupBy: GroupBy, queryType: 'regular' | 'compare', colName: string) {

        if (groupBy) {
            if ((this.columns[colName].type === 'date') || groupBy.comparasionMode) {
                const dateGroupBy = groupBy as DateGroupBy;
                const newGroupBy = new DateGroupBy(dateGroupBy.orginalGap, dateGroupBy.numberOfGroups, dateGroupBy.offset, dateGroupBy.compareOffset);
                newGroupBy.setQueryType(queryType);
                return newGroupBy;
            } else {
                return groupBy;
            }
        } else {
            return null;
        }

    }

    private queryData(query: DRQuery, config: IChartDataConfig, changeId: number) {
        // copy query - solving issue with filters having too much data
        const sendQuery = new DRQuery();
        sendQuery.countSubRecords = config.countSubRecords === undefined ? false : config.countSubRecords;
        //set loading status
        this._bsLoadingStatus$.next({ status: 'Busy' });
        query.filters.forEach(x => x.includeAllFields = true);
        sendQuery.size = 0;
        sendQuery.start = 0;
        sendQuery.sorts = query.sorts;
        sendQuery.filters = query.filters;
        if (!!config.metrics) {
            const metrics = config.metrics;
            sendQuery.stats = metrics.map((metric, i) => {
                return {
                    name: 'stat' + (i + 1),
                    type: 'stat',
                    functionType: metric.functionType,
                    field: this.columns[metric.colName] ? this.columns[metric.colName].fieldName : ''
                };
            });
        }

        sendQuery.facets = [
            this.getFacetDef(config)
        ];
       
        this.esQueryService.updateQuery(sendQuery, this.serviceUrl , "chart")
        
        
        const q: Observable<DRResult<any>> = this._http
            .post(this.serviceUrl, sendQuery)
            .pipe(
                map((res: any) => {
                    const obj: DRResult<any> = new DRResult<any>();
                    Object.assign(obj.responseHeader, res.responseHeader);
                    Object.assign(obj.facets, res.facets);
                    obj.response.numFound = res?.response?.numFound;
                    obj.response.start = res.response.start;
                    obj.esQuery = res.query;
                    return obj;
                }),
                tap({
                    next: (x) => {
                        this._bsLoadingStatus$.next({ status: "Success" })
                    },
                    error: (err) => {
                        this._bsLoadingStatus$.next({ status: "Failure", errorDescription: err })
                    }
                })
            );
        let xAxisType;
        this.subscriptions.push(q.subscribe(async x => {
            if (changeId !== this._changeId) {
                disLogger('ignored query result');
                return;
            }

            let accum: FacetFieldAccumulator;
            if (!!x) {
                const facet = Object.keys(x.facets)[0];
                if (x.facets[facet] != null && x.facets[facet].buckets !== undefined) {
                    accum = new FacetFieldAccumulator(facet);

                    const buckets = x.facets[facet].buckets as Array<any>;
                    xAxisType = this.columns[facet].dataType;
                    buckets.forEach((b: any) => {
                        accum.accumValue(b.key, b.value, 'count');
                        if (!!b.stat1) {
                            accum.accumValue(b.key, b.stat1, 'stat1');
                        }
                        if (!!b.stat2) {
                            accum.accumValue(b.key, b.stat2, 'stat2');
                        }
                        if (!!b.stat3) {
                            accum.accumValue(b.key, b.stat3, 'stat3');
                        }
                        if (!!b.stat4) {
                            accum.accumValue(b.key, b.stat4, 'stat4');
                        }
                        if (!!b.buckets) {
                            const subBuckets = b.buckets as Array<any>;
                            subBuckets.forEach((c: any) => {
                                accum.accumValue(b.key + '!>' + c.key, c.value, 'count');
                                if (!!c.stat1) {
                                    accum.accumValue(b.key + '!>' + c.key, c.stat1, 'stat1');
                                }
                                if (!!c.stat2) {
                                    accum.accumValue(b.key + '!>' + c.key, c.stat2, 'stat2');
                                }
                                if (!!c.stat3) {
                                    accum.accumValue(b.key + '!>' + c.key, c.stat3, 'stat3');
                                }
                                if (!!c.stat4) {
                                    accum.accumValue(b.key + '!>' + c.key, c.stat4, 'stat4');
                                }
                            });
                        }
                    });
                }
            }
            let data = accum.getData();
            const axisFilter = query.filters.find(m => m.fields[0] === config.categories.colName) as DRFilter;
            if (axisFilter) {
                if (this.columns[config.categories.colName].dataType === 'string_array' || this.columns[config.categories.colName].dataType === 'string_set') {
                    data = data.filter(z => axisFilter.facetValues.some(
                        g => g.facetKey === z.category
                    ));
                }
            }
            const metrics = (config?.metrics || []).map((z, i) => ({ name: 'stat' + (i + 1), col: z.colName, functionType: z.functionType })) || [];

            metrics.unshift({ name: 'count', col: '', functionType: 'count' });
            const original = this.groupByCategoryAndSeries(data, ['category', 'series']);
            var { sortedSeries, sortedCategories } = this._sortData(original, config, metrics);
            const sortedData = new Array<{ name: string, stat: string, type: string, values: number[], axis: string, format?: string, dataType?: string, total: number }>();

            sortedSeries.forEach((ser, si) => {
                const vals = new Array<any>();
                sortedCategories.forEach((cat, ci) => {
                    vals.push(original.find(o => o.category === cat && o.series === ser));
                });

                metrics.forEach((m, i) => {
                    const col = this.columns[m.col];
                    const values = vals.map(v => v ? (v[i === 0 ? 'count' : 'stat' + (i)]) : null)
                    let total = this._calcTotal(values, m.functionType);
                    sortedData.push({
                        name: config.series && this.columns[config.series.colName].dataType === 'date' ? this.dataFormattingService.getValue(ser, 'dateRange') : ser,
                        stat: i === 0 ? 'count' : (i).toString(),
                        axis: i === 0 ? 'count' : 'stat' + (i),
                        type: i <= 1 ? config.type : 'line',
                        format: this.dataFormattingService.getFormat(col ? col.dataType : ''),
                        dataType: col?.dataType || '',
                        values,
                        total
                    });
                });
            });
            const totals: ChartTotal[] = metrics?.map(metric => {
                const total = this._calcTotal(sortedData.filter(data => data.axis === metric.name).map((value) => value.total), metric.functionType)
                return { metric, total };
            });

            sortedCategories = await this.getDataTranslationValues(sortedCategories.map(a => this.mapGroupedData(config.categories, a)), config.categories.colName);
            sortedSeries = await this.getDataTranslationValues(sortedSeries.map(a => this.mapGroupedData(config.series, a)), config.series?.colName);
            this._$Result.next({
                categories: sortedCategories,
                series: sortedSeries,
                values: sortedData,
                categoriesDataType: xAxisType,
                totals
            });
        }));
    }

    private _calcTotal(values: number[], functionType: string) {
        let total;
        switch (functionType) {
            case 'avg':
                total = values.reduce((accumulator, value) => accumulator + value, 0) / values.length;
                break;
            case 'min':
                total = Math.min(...values);
                break;
            case 'max':
                total = Math.max(...values);
                break;
            default:
                total = values.reduce((accumulator, value) => accumulator + value, 0);
                break;
        }
        return total;
    }

    private async getDataTranslationValues(sortedData: any[], colName: string): Promise<any[]> {
        const currentTab = await this._tabSettingsService.getCurrentTab();
        const tabColumns = await this._tabSettingsService.getAllColumns();
        const currentColumn = tabColumns.find(c => c.fieldName === colName);
        if (currentColumn != undefined && currentColumn.isDynamicFacet === false) {
            const facetTranslationUrl = currentTab.facetTranslationUrl;
            await this._facetTranslationService.init(`${facetTranslationUrl}/${colName}/${currentColumn.tableName}`, currentColumn.form.templateOptions.optionsListId);
            const facetInfo = this._facetTranslationService.facetTranslationInfo;
            sortedData = sortedData.map(d => {
                const info = facetInfo.find(i => i.Id === d);
                if (info)
                    return info;
            });
        }
        return sortedData.map(data => { return data?.Id ? data : { Id: null, Display: data || '' } });
    }
    private groupByCategoryAndSeries(data: any[], keys: string[]) {
        const hashMap: { [key: string]: any; } = {};
        data.forEach((item) => {
            const key = keys.map(x => item[x] ? item[x] : '').join(',');
            const collection = hashMap[key];
            const type = item.type;
            const result = {
                [keys[0]]: item[keys[0]],
                [keys[1]]: item[keys[1]] === '+++' ? 'Series1' : item[keys[1]],
                count: 0, stat1: 0, stat2: 0
            };
            result[type] = item.val;
            if (!collection) {
                hashMap[key] = result;
            } else {
                hashMap[key][type] = item.val;
            }
        });
        return Object.entries(hashMap).map(([key, value]) => value);
    }

    private getSummaryByKey(array: any[], key: string) {
        return [...array.reduce((r, v, i, a, k = v[key]) => {
            const item = r.get(k) || Object.assign({}, v, {
                stat1: 0,
                stat2: 0,
                count: 0,
            });
            item.stat1 += v.stat1;
            item.stat2 += v.stat2;
            item.stat3 += v.stat3;
            item.stat4 += v.stat4;
            item.count += v.count;
            return r.set(k, item);
        }, new Map<any, any>()).values()];
    }

    private _sortData(original: any[], config: IChartDataConfig, metrics: { name: string; }[]) {
        const seriesSummary = this.getSummaryByKey(original, 'series').filter(s => s.series !== '' || s.category !== '');
        const categoriesSummary = this.getSummaryByKey(original, 'category');
        const { sortType, colType, sortDir } = config?.categories
        let sortedCategories = this._sort(categoriesSummary, sortType, colType, sortDir, 'category', metrics.length, original);
        const { seriesSort, seriesSortDir } = config.categories
        let sortedSeries = this._sort(seriesSummary, seriesSort, config?.series?.colType, seriesSortDir, 'series', metrics.length, original);
        sortedSeries = sortedSeries.length === 1 ? ['Series1'] : sortedSeries.filter(x => x !== 'Series1');
        return { sortedSeries, sortedCategories };
    }

    private _sort(
        array: any[],
        sortType: string,
        colType: string,
        sortDir: 'asc' | 'desc',
        key: string,
        metricLength: number,
        original: any[]
    ) {
        let sortedArray = [];
        switch (sortType) {
            case 'value':
                sortedArray = sortArray(array, colType, sortDir, metricLength === 1 ? 'count' : 'stat1').map(item => item[key]);
                break;
            case 'label':
            case 'series':
                sortedArray = sortArray(array, colType, sortDir, key).map(s => s[key]);
                break;
            default:
                sortedArray = [...new Set(original.map(s => s[key]))];
        }
        return sortedArray;
    }
}
