import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FacetValue, DiscovererQueryService, BaseController } from '../services';
import { FacetBloc } from './facet-bloc.service';
import { FormControl } from '@angular/forms';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'facet',
    styleUrls: ['./facet.component.scss'],
    templateUrl: 'facet.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FacetBloc]
})
export class FacetComponent extends BaseController implements OnInit {
    @Input()
    title: string;

    @Input()
    singleMode = false;

    @Input()
    facetKey: string;

    @Input()
    serviceUrl: string;

    @Input()
    isDynamic = false;

    @Input()
    queryService: DiscovererQueryService;

    @Input()
    maxShow = 20;

    @Input()
    facetName: string;

    @Input()
    isOpen = false;

    searchText = new FormControl();
    sortedFacetValues: FacetValue[] = [];
    checkedFacetValues: FacetValue[] = [];
    totalFiltered = 0;
    pageNumber = 0;
    pageFrom = 0;
    pageSize = 20;
    pageTo = 20;
    isSearchable = false;
    orderByCount = true;
    formBuilder: any;
    constructor(
        private facetBloc: FacetBloc,
    ) {
        super();
    }

    ngOnInit() {
        this.facetBloc.result.subscribe(x => {
            this.sortedFacetValues = x.output.sortedFacets;
            this.totalFiltered = x.output.filteredCount;
        });

        this.searchText.valueChanges.subscribe(x => {
            this.resetPagination();
            this.facetBloc.setFacetInputs({ searchText: x, pageFrom: 0, pageTo: this.maxShow });
        });
    }

    public getFacets(event) {
        this.checkedFacetValues = event.selectedFacetValues;
        this.resetPagination();
        this.facetBloc.setFacetInputs({
            facetValues: event.facetValues, facetData: event.facetData,
            selectedFacetValues: this.checkedFacetValues, pageFrom: this.pageFrom,
            pageTo: this.pageTo
        });
        this.setFlagsBasedOnFacetSize(event.facetValues);
    }

    facetTitleClicked() {
        this.isOpen = !this.isOpen;
    }

    nextPage(event) {
        (this.pageTo >= this.totalFiltered) ? this.pageNumber = Math.floor(this.totalFiltered / this.pageSize) : this.pageNumber++;
        this.updatePaginationControls();
    }

    prevPage(event) {
        (this.pageNumber <= 1) ? this.pageNumber = 0 : this.pageNumber--;
        this.updatePaginationControls();
    }

    private updatePaginationControls() {
        this.pageFrom = (this.pageNumber * this.pageSize);
        this.pageTo = this.maxShow + (this.pageNumber * this.pageSize);
        if (this.pageTo >= this.totalFiltered) {
            this.pageTo = this.totalFiltered;
        }
        this.facetBloc.setFacetInputs({ pageFrom: this.pageFrom, pageTo: this.pageTo });
    }


    private resetPagination() {
        this.pageNumber = 0;
        this.pageFrom = 0;
        this.pageSize = 20;
        this.pageTo = this.maxShow;
    }

    private setFlagsBasedOnFacetSize(facetValues) {
        if (facetValues && facetValues.length > this.maxShow) {
            this.orderByCount = true;
            this.isSearchable = true;
        } else {
            this.orderByCount = true;
            this.isSearchable = false;
        }
    }
}
