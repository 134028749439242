


<div class="main overflow-y-auto h-full">

    <!-- Display Mode -->
    <ng-container *ngIf="!isEditMode; else editForm">

        <app-chooser-queue-children-viewer *ngIf="isWorkspace"
            [children]="SplitFiles"></app-chooser-queue-children-viewer>


        <app-file-result-viewer *ngIf="!isWorkspace && fileId && !isLoading" [fileId]="fileId"
            [datasetId]="datasetId" [resultIndex]="resultIndex"
            (dataFetched)="onDataFetched($event)"></app-file-result-viewer>

    </ng-container>

    <ng-template #editForm>

          <app-file-result-editor *ngIf="resultIndex >=0 else editAllResults" [fileId]="fileId"
            [data]="result[resultIndex]" [datasetId]="datasetId"
            [isSaving]="isSaving"></app-file-result-editor>


        <ng-template #editAllResults>
            <div *ngFor="let res of result | slice: 0:limit; let i = index">
                <app-file-result-editor [fileId]="fileId" [data]="result[i]" [datasetId]="datasetId"
                    [isSaving]="isSaving" (valid)="oCheckValidity($event)"></app-file-result-editor>
                <mat-divider *ngIf="result?.length - 1 !== i" class="divider"></mat-divider>

            </div>

            <div class="flex justify-self-center m-4" *ngIf="limit <=  result?.length">
                <button mat-flat-button color="primary" (click)="limit = limit + 20">Load More</button>
            </div>

        </ng-template>
    </ng-template>

</div>