import { Component, EventEmitter, HostListener, Input, Output, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { ChangeDetectionStrategy } from '@angular/core';

import { CHART_TYPES } from '../../../models';
import { DialogsManagerService } from '../../../dialogs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    AppSettingsService, BaseController, 
    IDirRequest, IRequest, ITab2, Permission, ReportPersistService, ReportSubType, RequestFilingService, TabSettingsService, UserPermissionService
}
    from '@discoverer/core/services';
import { ViewTypes } from '../control-bar.component';
import { FlattenedDirRequests } from '../../filtered-reports-view/filtered-reports-view.component';

export function resolveReportIcon(type: string, subType: ReportSubType): string {
    switch (type) {
        case ViewTypes.Table:
            return 'table_chart';
        case ViewTypes.Chart:
            const chartType = CHART_TYPES.find(x => x.subType === subType);
            return (chartType) ? chartType.icon : 'pie_chart';
        case ViewTypes.Pivot:
            return 'list_alt';
        case ViewTypes.Kanban:
            return 'view_column';
        case ViewTypes.Dashboard:
            return 'dashboard';
    }
}


@Component({
    selector: 'reports',
    styleUrls: ['./reports.component.scss'],
    templateUrl: './reports.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsComponent extends BaseController implements OnInit {

    @Input() public currentAppKey: string;
    @Input() public routePath: string;
    @Input() public defaultRequestId: string;
    @Input() public reportType: string;
    @Input() public mobileView: boolean;
    @Input() public loadedRequestDirectory: string;

    @Output() public personalDirectoryId: EventEmitter<number>;
    @Output() public hideReportsEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() public saveReport: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public deleteReport: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() public loading: EventEmitter<boolean> = new EventEmitter<boolean>();


    public requestHover = false;
    public numberOfColumns = 5;
    public columnWidthPercentage = 10;
    public selectedIndex: number = undefined;
    public currentTab: ITab2;

    public reportList: IDirRequest[];
    public requestsPermission = {};
    public selectedRequest: IRequest;
    public selectedDirectory: IDirRequest;
    public canCreatePublicDirectory: boolean;
    public canEditPublicDirectory: boolean;

    public term = '';

    private originalDirs: IDirRequest[];
    private currentDirectory: string;

    constructor(

        public dialog: MatDialog,
        public router: Router,
        private tabSettings: TabSettingsService,
        private reportPersist: ReportPersistService,
        private cd: ChangeDetectorRef,
        private requestFilingService: RequestFilingService,
        private dialogsManagerService: DialogsManagerService,
        private snackbar: MatSnackBar,
        protected appSettings: AppSettingsService,
        private _userPermissionService: UserPermissionService
    ) {
        super();
        this.personalDirectoryId = new EventEmitter<number>();
    }

    public async ngOnInit() {
        this.initUserReportSubscriptions();
        this.requestFilingService.sReloadRequests.next(true);
        this.cd.markForCheck();
    }
    viewAsDashboard() {
        this.router.navigate([this.currentAppKey, 'dashboard', this.currentTab.key, this.selectedDirectory.id])
    }

    public deleteRequest(request) {
        this.reportPersist.deleteRequest(request.id);
    }

    public async initUserReportSubscriptions() {
        this.subscriptions.push(this.requestFilingService.sReloadRequests.subscribe(async reload => {
            this.currentTab = await this.tabSettings.getCurrentTab();
            this.setUserPermissions();
            if (!!reload) {
                const appKey = this.currentTab.app.key;
                const dirs = await this.requestFilingService.getDirectories(appKey, this.currentTab.key, false);
                if (!!dirs) {
                    const perDir = dirs.find(dir => dir.directoryName.toLowerCase().includes('personal'));
                    if (!!perDir) {
                        this.personalDirectoryId.emit(perDir.id);
                    }
                }

                let sum = 0;
                this.requestsPermission = {}
                dirs.forEach(async dir => {
                    dir.requests = await Promise.all(dir.requests.map(async r => {
                        r.icon = resolveReportIcon(r.type, r.subType);
                        this.requestsPermission[r.id] = {
                            canEditReq: await this.canEditReq(r.directoryId, dir.userId, r.id),
                            canMove: await this.canMoveRequest(r.directoryId, dir.userId, r.id),
                            canDelete: await this.canDeleteRequest(r.directoryId, dir.userId, r.id)
                        }
                        return r;
                    }).filter(r => !!r));
                    dir.expanded = true;

                    dir.directoryName = dir.directoryName.split('|')[1];
                    dir.width = Math.ceil(dir.requests.length / 6) * 450;
                    sum += Math.floor(dir.requests.length / 6) + 1;
                });
                this.numberOfColumns = (sum < 5) ? 5 : sum;
                this.fixColmunWidth();
                this.reportList = dirs;
                const reqId = this.reportPersist.loadReqId;
                const selectedDir = dirs.find(dir => dir.requests.find(req => req.id === reqId));
                this.selectedDirectory = selectedDir;
                this.selectedRequest = selectedDir?.requests?.find(req => req.id === reqId);
                this.originalDirs = dirs;
                this.reportList.map(x => {
                    x.requests
                        .sort((a, b) => {
                            if (!!b && !!b.requestName && !!a && !!a.requestName) {
                                return -1 * b.requestName.localeCompare(a.requestName);
                            } else {
                                return -1;
                            }
                        })
                        .sort((a, b) => a.requestOrder - b.requestOrder)
                });
                this.reportList.sort((a, b) => a.directoryName.localeCompare(b.directoryName)).sort((b, a) => +b.userId - +a.userId);
            } else {
                this.numberOfColumns = 5;
                this.fixColmunWidth();
                this.reportList = [];
                this.originalDirs = [];
            }
            this.reportList.forEach(async report => {
                report.requests = report.requests.filter(req => !!req.id);


            });
            this.cd.markForCheck();
        }));
    }

    async canEditReq(dirId: number, requestOwnerUserId: number, reqId: string) {
        return await this._userPermissionService.hasPermission(this.currentTab.key, Permission.EditReport, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${reqId}`);
    }
    async canMoveRequest(dirId: number, requestOwnerUserId: number, reqId: string) {
        return await this._userPermissionService.hasPermission(this.currentTab.key, Permission.MoveRequest, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${reqId}`);
    }
    async canDeleteRequest(dirId: number, requestOwnerUserId: number, reqId: string) {
        return await this._userPermissionService.hasPermission(this.currentTab.key, Permission.DeleteRequest, `dirId:${dirId}/requestOwnerUserId:${requestOwnerUserId}/requestId:${reqId}`);
    }

    openEditDirectoryDialog(dir) {
        this.dialogsManagerService.openEditTitleDialog(dir.directoryName).subscribe(async (directoryName: any) => {
            if (directoryName) {
                this.requestFilingService.editDirectoryName(this.currentTab.key.toUpperCase(), dir.id, directoryName);
            }
        });
    }
    public setSearchedReport(selectedReport: FlattenedDirRequests) {
        const dir = this.originalDirs.find(dir => dir.id === selectedReport.directoryId);
        this.setReport(selectedReport, dir);
    }
    async moveReportFolder(request) {
        const result = await this.dialogsManagerService.openRequestDirectoryDialog(this.currentTab.key).toPromise();
        if (!!result) {
            await this.requestFilingService.setRequestDirectory(request.id, request.requestName, result.directoryId, this.currentAppKey, this.currentTab.key)
                .catch(err => this.openSnackBar(err.error));
        }
    }

    removeReport(request) {
        this.dialogsManagerService
            .openConfirmDialog('Delete Report ?', `Are you sure you want to delete this report (${request.requestName})? This cannot be undone.`)
            .subscribe(async (confirm: boolean) => {
                if (confirm) {
                    await this.reportPersist.deleteRequest(request.id);
                    this.requestFilingService.sReloadRequests.next(true);
                    this.cd.markForCheck();
                }
            });
    }

    openEditReportName(request) {
        this.dialogsManagerService.openEditTitleDialog(request.requestName).subscribe(async (newReqName: any) => {
            if (newReqName) {
                try {
                    const req = await this.reportPersist.getRequest(request.id);
                    req.mainView.mainTitle = newReqName;
                    await this.requestFilingService.setRequestDirectory(request.id, newReqName, this.selectedDirectory.id, this.currentAppKey, this.currentTab.key);
                    await this.reportPersist.updateRequest(request.id, req, false);
                    if (this.reportPersist.loadReqId === request.id) {
                        this.reportPersist.mainViewState.setValue({ mainTitle: newReqName }, false);
                    }
                } catch (err) {
                    this.openSnackBar(err.error);
                }
            }
        });
    }

    public async createPublicDirectory(dir: IDirRequest) {
        if (this.canCreatePublicDirectory) {
            this.dialogsManagerService
                .openConfirmDialog('Make Directory Public?', `Are you sure you want to make this directory (${dir.directoryName}) and associated reports public?`)
                .subscribe(async (confirm: boolean) => {
                    if (confirm) {
                        this.requestFilingService.createPublicDirectory(dir.id);
                    }
                });
        }
    }

    openAddDirectoryDialog() {
        this.dialogsManagerService.opeAddNewDirectoryDialog().subscribe(async (directoryName: any) => {
            if (directoryName) {
                this.requestFilingService.addDirectroyForTab(this.currentTab.key.toUpperCase(), directoryName);
            }
        });
    }

    public async removeDirectory(dir) {
        this.dialogsManagerService
            .openConfirmDialog('Delete Directory ?', `Are you sure you want to delete this directory (${dir.directoryName}) and associated reports? This cannot be undone.`)
            .subscribe(async (confirm: boolean) => {
                if (confirm) {
                    const isDeleted = await this.requestFilingService.deleteDirectory(dir.id);
                    if (isDeleted) {
                        if (this.currentDirectory == null || (this.currentDirectory == this.loadedRequestDirectory)) {
                            const defaultRequestId = this.currentTab.defaultRequestId;
                            await this.reportPersist.loadRequest(defaultRequestId);
                            this.requestFilingService.sReloadRequests.next(true);
                            this.cd.markForCheck();
                        }
                        this.openSnackBar('Directory has been deleted.');
                    } else {
                        this.openSnackBar('Not authorized.');
                    }
                }
            });
    }

    public async setReport(request: IRequest, dir: IDirRequest) {
        this.hideReportsEvent.emit(true);
        this.selectedRequest = request;
        if (this.mobileView) {
            this.router.navigateByUrl(`${this.currentAppKey}/mobile/reports/${this.currentTab}/${request.id}`);
        } else {
            this.router.navigateByUrl(`${this.currentAppKey}/reports/${this.currentTab.key}/${request.id}`);
        }
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        const windowWidth = event.target.innerWidth;
        this.fixColmunWidth(windowWidth);
    }


    public toggleFolder(dir: IDirRequest) {
        dir.expanded = !dir.expanded;
    }
    public close() {
        this.hideReportsEvent.emit(true);
    }

    private fixColmunWidth(windowWidth = 0) {
        if (windowWidth === 0) {
            windowWidth = window.innerWidth;
        }
        const numOfColsPerRow = Math.floor(windowWidth / 200);
        this.columnWidthPercentage = 100 / numOfColsPerRow;
    }

    private openSnackBar(msg: string) {
        this.snackbar.open(msg, '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        });
    }
    private async setUserPermissions() {
        this.canCreatePublicDirectory = await this._userPermissionService.hasPermission(this.currentTab.key, Permission.CreatePublicDirectory);
        this.canEditPublicDirectory = await this._userPermissionService.hasPermission(this.currentTab.key, Permission.EditPublicDirectory);
    }
}


