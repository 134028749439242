<mat-card class="kanban-card">
  <mat-card-header class="kanban-card-header">
    <mat-card-title  #tooltip="matTooltip" matTooltip="{{card.header}}" class="card-container-left"> {{card.header}}</mat-card-title>
    <mat-card-title class="card-container-right">
      <button mat-icon-button (click)="navigateToDetails()" class="card-mat-icon-button">
        <mat-icon>launch</mat-icon>
      </button>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div *ngFor="let element of card.fieldNameAndValue" class="card-content">
      <div class="html-row">
        <div class='label html-row-label-cell'>{{element.fieldName}}</div>
        <div class='value '> {{element.fieldValue}} </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
