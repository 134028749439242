import { Injectable } from '@angular/core';
import { DataServiceInterface } from '../shared/data-interface';

@Injectable({ providedIn: 'root' })
export class DataServiceFactory {
    private services: Map<string, DataServiceInterface> = new Map();

    registerService(key: string, service: DataServiceInterface) {
        this.services.set(key, service);
    }

    getService(key: string): DataServiceInterface {
        const service = this.services.get(key);
        if (!service) {
            throw new Error(`No data service found for key: ${key}`);
        }
        return service;
    }
}
