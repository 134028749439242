import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DragulaService } from 'ng2-dragula';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import {
    IKanbanViewState, TabSettingsService, IMainViewState,
    BaseController, DiscovererQueryService, StateService, IList, LoadingState, DiscovererDataService, EsQueryService, ExactDataRow, List, DiscovererFacetTranslationService, IColumnSetting, FacetTranslationInfo, ITab2, DataFormattingService,
} from '@discoverer/core/services';
import { first } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import _ from 'lodash';
import { FormControl } from '@angular/forms';
export interface IKanbanDialogData {
    tabSettings: TabSettingsService;
    queryService: DiscovererQueryService;
    kanbanViewState: StateService<IKanbanViewState>;
    mainViewState: StateService<IMainViewState>;
}

@Component({
    selector: 'kanban-customization',
    templateUrl: './kanban-customization.dialog.html',
    styleUrls: ['./kanban-customization.dialog.scss'],
    providers: [DragulaService]
})

export class KanbanCustomizationDialog extends BaseController implements OnInit {

    public rightListColumns: any[] = [];
    public defaultListColumns: any[] = [];
    public allListColumns: any[] = [];
    public rightListCardsColumns: any[] = [];
    public defaultListCardsColumns: any[] = [];
    public allListCardsColumns: any[] = [];
    public groups: any[];
    private listsInfo: IList[];
    private currentTab: ITab2;
    public loadingState: LoadingState = null;
    private dataService: DiscovererDataService<ExactDataRow>;
    private facetTranslationInfo: FacetTranslationInfo[] = null;
    private tabColumns: IColumnSetting[];
    public sorts: {
        fieldName: string,
        dir: 'asc' | 'desc'
    }[];
    public isDefaultSorts = false;

    _step1Validation = new FormControl('');
    _step2Validation = new FormControl('');

    public get firstStepValidation() {
        if (this.groups?.length > 0 && this.rightListColumns?.length > 0) {
            this._step1Validation.reset();
        } else {
            this._step1Validation.setErrors({ "required": true });
        }
        return this._step1Validation;
    }
    public get seconedStepValidation() {
        if (this.rightListCardsColumns?.length > 0) {
            this._step2Validation.reset();
        } else {
            this._step2Validation.setErrors({ "required": true });
        }
        return this._step2Validation;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogInputSettings: IKanbanDialogData,
        private dialogRef: MatDialogRef<KanbanCustomizationDialog>,
        private snackBar: MatSnackBar,
        private cdRef: ChangeDetectorRef,
        private esQueryService: EsQueryService,
        private apollo: Apollo,
        private _dataFormattingService: DataFormattingService,
        protected http: HttpClient) {
        super();
    }

    private _mapListItem = (l: IList) => {
        let groupDataType = this.tabColumns.find(col => col.fieldName === this.groups[0].field)?.dataType
        return {
            order: l.order,
            fieldName: l.id === '' ? 'blank' : l.id,
            displayOrder: l.order,
            display: l.name === '' ? 'blank' : this._dataFormattingService.getValue(l.name, groupDataType),
            showInFilter: true
        }

    }
    public async ngOnInit() {
        this.currentTab = await this.dialogInputSettings.tabSettings.getCurrentTab();
        this.tabColumns = await this.dialogInputSettings.tabSettings.getAllColumns();
        const kanbanState = this.dialogInputSettings.kanbanViewState?.getState();
        if (!!kanbanState.groupByColumn?.field) {
            this.groups = [{ field: kanbanState?.groupByColumn?.field, dir: 'asc' }]
            this.listsInfo = _.cloneDeep(kanbanState?.listsInfo)
            this.rightListColumns = kanbanState?.listsInfo?.filter(lisInfo => lisInfo.isShowing === true)
                .map(l => this._mapListItem(l)).sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);


            this.allListColumns = kanbanState?.listsInfo?.map(l => this._mapListItem(l))
                .sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);

            this.defaultListColumns = this.rightListColumns.concat(this.allListColumns.filter(r => r.isShowing == false).slice(this.rightListColumns.length, 15));
        } else {
            this.groups = [{ field: '', dir: 'asc' }];
        }


        this.allListCardsColumns = this.tabColumns;

        this.rightListCardsColumns = kanbanState.columnSettings;
        if (this.rightListCardsColumns == undefined) {
            this.rightListCardsColumns = [];
        }
        this.defaultListCardsColumns = await this.dialogInputSettings.tabSettings.getDefaultColumns();

        const sortColumns = (await this.dialogInputSettings.queryService.oQuery.pipe(first()).toPromise()).sorts.map(s => ({
            fieldName: s.sortField,
            dir: s.dir
        }));
        this.sorts = sortColumns && sortColumns.length ? sortColumns.map(g => (
            {
                fieldName: g.fieldName,
                dir: g.dir
            } as {
                fieldName: '',
                dir: 'asc' | 'desc'
            })) : [];
        if (!!this.dialogInputSettings.mainViewState) {
            this.dialogInputSettings.mainViewState.setValue({ isDefaultSorts: this.isDefaultSorts });
        }

        this.dataService = new DiscovererDataService(this.http, this.esQueryService, { create: (row) => [row] });

        this.subscriptions.push(this.dataService.oFacetResults.subscribe(facetResult => {
            if (facetResult.length > 1) {
                const keyMap = facetResult[1].getKeyMap();
                this.listsInfo = Object.keys(keyMap).map((list, index) => {
                    if (this.facetTranslationInfo == null) {
                        return new List(list, list, index, facetResult[1].getValue(list), true, [])
                    } else {
                        return new List(list, this.getTranslationValue(this.facetTranslationInfo, list), index, facetResult[1].getValue(list), true, [])
                    }
                });
                this.allListColumns = this.listsInfo.map(l => this._mapListItem(l))
                    .sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
                this.defaultListColumns = this.allListColumns.slice(0, 15);
            }
            else {
                this.listsInfo = []
            }
        }));


        this.subscriptions.push(this.dataService.oLoadingStatusResult.subscribe(state => {
            this.loadingState = state;
            this.cdRef.markForCheck();
        }));
    }

    public close() {
        this.dialogRef.close();
    }

    private async getFacetTranslation(column: IColumnSetting): Promise<FacetTranslationInfo[]> {
        let facetTranslationService = new DiscovererFacetTranslationService(this.http, this.apollo);
        const queryInfoServiceUrl = (this.currentTab).facetTranslationUrl;
        await facetTranslationService.init(`${queryInfoServiceUrl}/${column.fieldName}/${column.tableName}`, column.form?.templateOptions?.optionsListId);
        const facetInfo = facetTranslationService.facetTranslationInfo;
        return facetInfo;
    }
    private getTranslationValue(facetInfo: FacetTranslationInfo[], value) {
        if (Array.isArray(value)) {
            const display = value?.map(e => {
                const info = facetInfo?.find(fi => fi.Id === e);
                return info?.Display
            });
            if (display) { return display.join(', '); }
            else { return '' }
        }
        else {
            const info = facetInfo?.find(fi => fi.Id === value);
            if (info)
                return info.Display;
            else
                return '';
        }
    }
    public save() {
        if (this.rightListColumns.length === 0 || this.rightListColumns.length > 15) {
            if (this.rightListColumns.length === 0) {
                this.fireErrorMessage(`You dont have any list to display .Please choose lists you want to show `);
            }
            else {
                this.fireErrorMessage(`You can only select 15 list to show, Please remove ${this.rightListColumns.length - 15} list\s`);
            }
        }
        else {
            if (this.rightListCardsColumns.length === 0) {
                this.fireErrorMessage(`You dont have any columns to display in list cards .Please choose columns you want `);
            }
            else {
                this.updateQuerySorts();
                var listInfo = this.listsInfo.map(li => {
                    var column = this.rightListColumns.find(l => l.fieldName === li.id);
                    if (column) {
                        li.isShowing = true;
                        li.order = column.order;
                    }
                    else {
                        li.isShowing = false;
                    }
                    return li;
                });
                this.dialogRef.close(
                    {
                        columnSettings: this.rightListCardsColumns,
                        listsInfo: listInfo,
                        groupByColumn: {
                            field: this.groups[0].field,
                            dir: 'asc'
                        },
                        sorts: this.sorts
                    }
                );
            }
        }
    }

    private updateQuerySorts() {
        this.dialogInputSettings.queryService.resetOrderBy();
        if (!!this.sorts) {
            const mainSorts = this.sorts.filter(sort => sort.fieldName != '');
            mainSorts.forEach(s => {
                this.dialogInputSettings.queryService.setOrderBy(s.fieldName, s.dir);
            });
        }
        this.dialogInputSettings.queryService.refresh();
    }

    private fireErrorMessage(msg: string) {
        this.snackBar.open(msg, null, {
            duration: 5000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            panelClass: ['error-snackbar']
        });
    }
    public setDefaultListColumns() {
        this.updateListColumnDisplay(this.defaultListColumns);
    }

    public updateListColumnDisplay(columns) {
        this.rightListColumns = Object.assign([], columns);
    }
    public async getFacetValues(groupByColum) {
        const groupColumn = groupByColum[0];
        const columnSetting = this.tabColumns.find(c => c.fieldName === groupColumn.fieldName);
        if (columnSetting.isDynamicFacet == false) {
            this.facetTranslationInfo = await this.getFacetTranslation(columnSetting);
        }
        const groupByColumFieldName = groupColumn.fieldName;
        this.groups = [{
            field: groupByColumFieldName,
            dir: 'asc'
        }];
        this.rightListColumns = [];
        this.allListColumns = [];
        this.loadingState = null;
        this.dataService.init(this.currentTab.serviceUrl, this.dialogInputSettings.queryService, '?data=kanban-view-list&qname=main');
        this.dataService.resetFacets();
        this.dataService.setFacet(groupByColum[0].fieldName, {
            name: groupByColumFieldName,
            type: 'terms',
            field: groupByColumFieldName,
            excludeTags: [groupByColumFieldName],
            mincount: 1,
            limit: -1,
            display: groupByColumFieldName,
            shortDisplay: groupByColumFieldName
        });

        this.dataService.refresh();
    }

    public setDefaultListCardsColumns() {
        this.updateListCardsColumnDisplay(this.defaultListCardsColumns);
    }

    public updateListCardsColumnDisplay(columns) {
        this.rightListCardsColumns = Object.assign([], columns);
    }

    public validateStep1() {
        if (this.firstStepValidation.valid) return;
        if (!(this.groups?.length > 0 && this.groups[0].field)) {
            this.fireErrorMessage('Please Select a Group By');
        } else if (!(this.rightListColumns?.length > 0 && this.rightListColumns[0].field)) {
            this.fireErrorMessage('Please Select Items to View');
        }
    }
    public validateStep2() {
        if (this.seconedStepValidation.valid) return;
        if (!(this.rightListCardsColumns?.length > 0)) {
            this.fireErrorMessage('Please Select a Fields to View');
        }
    }
}
