import { Component, ViewEncapsulation, OnInit, Input, EventEmitter, Output, ElementRef, ContentChild, TemplateRef, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { DiscovererQueryService, DiscovererDataService, Factory, DRSortCriteria, IColumnSetting, DRFilter, DRQuery, BaseController } from '../services';

import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';
import { LoadingState } from '../services/classes/loading-state';
import { EsQueryService } from '../services/core-data-services/discoverer-esquery-service';

export class StandardMapper {
    constructor(
        private columnSettings: IColumnSetting[],
    ) { }

    create(row: any, dataFlattenBy?: string) {
        const rowData = row.source;
        let rowResult = { esDoc: rowData };
        const getNestedProperty = (obj: any, path: string) => {
            return path.split('.').reduce((current, key) => {
                return current && current.hasOwnProperty(key) ? current[key] : undefined;
            }, obj);
        };

        if (dataFlattenBy && dataFlattenBy !== '') {
            const nestedData = getNestedProperty(rowData, dataFlattenBy);

            if (nestedData !== undefined) {
                let data = Array.isArray(nestedData) ? nestedData : [nestedData];
                let rows = data.map((content, i) => {
                    const rowCopy = Object.assign({}, rowData);
                    const keys = dataFlattenBy.split('.');
                    let lastKey = keys.pop() as string;
                    let parentObj = keys.reduce((obj, key) => obj[key], rowCopy);
                    parentObj[lastKey] = content;
                    var esDoc = {...rowData, [dataFlattenBy]: content};
                    return {...rowCopy, esDoc};
                });
                return rows.map(r => this.getRowResult(r, dataFlattenBy));
                // return rows.map((r, i) => Object.assign({esDoc: r.esDoc}, this.getRowResult(rowData)))
            }
        }

        return [Object.assign(rowResult, this.getRowResult(rowData))];
    }

    getRowResult(rowData: any, dataFlattenBy:string = '') {
        let result = {};
        this.columnSettings.forEach(column => {

            if (column != undefined) {
                var value = this.getFlattenedValuesFromObject(column.fieldName.replace(".keyword", ""), rowData);
                //flatten result array
                if (Array.isArray(value)) {
                    value = value.reduce((acc, val) => acc.concat(val), []);
                }
                result[column.fieldName] = value;
            }
        });
        if (!result[dataFlattenBy]) {
            result[dataFlattenBy] = rowData[dataFlattenBy];
        }
        return result;
    }
    getFlattenedValuesFromObject(path: string, obj: any): any {
        var splitPath = path.split(".");
        var nextObjectOrArray = obj[splitPath[0]];
        var remainingPath = splitPath.slice(1).join(".");
        if (remainingPath == "" || !nextObjectOrArray) {
            return nextObjectOrArray;
        } else {
            // dive deeper into object
            return (Array.isArray(nextObjectOrArray) ? nextObjectOrArray : [nextObjectOrArray] )
                .map(x => this.getFlattenedValuesFromObject(remainingPath, x))
        }
    }
}

export class DataSourceContext {
    constructor(
        public data: any[],
        public total: number,
        public query: DRQuery,
        public sorts: SortDescriptor[],
        public columnDictionary: { [key: string]: IColumnSetting },
        public dataService: DiscovererDataService<any>
    ) { }

    pageChange(skip: number, take: number) {
        const page = Math.min(skip + 1, this.total);
        this.dataService.setPageStart(page, take);
        this.dataService.refresh();
    }

    sortChange(sorts: SortDescriptor[]) {
        this.dataService.resetOrderBy();
        sorts.forEach(sort => {
            this.dataService.setOrderBy(sort.field, sort.dir);
        })
        this.dataService.refresh(true);
    }
}

@Component({
    selector: 'data-source',
    templateUrl: './data-source.component.html',
    styles: [`
        .loader-center {
            position: absolute;
            left: 45%;
            top: 45%;
            width: 100%;
            height: 100%;
            z-index: 9999;
        }
    `]
})
export class DataSourceComponent extends BaseController implements OnInit, OnChanges {

    @Input() public queryService: DiscovererQueryService;
    @Input() public columnSettings: IColumnSetting[];
    @Input() public serviceUrl: string;

    @Input() public columnList?: string[] = [];
    @Input() public dataMapper?: Factory<any>;
    @Input() public sorts?: DRSortCriteria[];
    @Input() public filters?: DRFilter[];
    @Input() public start = 0;
    @Input() public size = 1;


    @ContentChild(TemplateRef) template: TemplateRef<ElementRef>;

    @Output()
    public context = new EventEmitter<DataSourceContext>();

    private _dataService: DiscovererDataService<any>;
    private _columnDictionary: { [key: string]: IColumnSetting };

    public loadingState: LoadingState;

    constructor(
        private _httpService: HttpClient,
        private ref: ChangeDetectorRef,
        private esQueryService: EsQueryService
    ) {
        super();

    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!!this.columnSettings && !!this.queryService && !!this.serviceUrl) {
            if (changes['columnSettings'] || changes['columnList'] ||
                changes['queryService'] || changes['serviceUrl'] ||
                changes['dataMapper']) {
                console.log("Data Service init");
                this._columnDictionary = this.createColumnDictionary(this.columnSettings);
                const columns = this.getFilteredColumns();
                const columnNames = [...new Set(columns.filter(x => x != undefined).map(col => col.fieldName))];
                this.unsubscribeAll();
                this._dataService = this.createDataService(columnNames,
                    this.dataMapper ? this.dataMapper : new StandardMapper(columns));
                this.subscribeToData();
            }

            if (changes['filters'] && !!this._dataService) {
                if (!!this.filters) {
                    this._dataService.resetFilters();
                    this._dataService.baseQueryService.resetFilters();
                    this.filters.forEach(filter => this._dataService.setFilter(filter.fields.join('-'), filter));
                }
            }

            if (changes['sorts'] && !!this._dataService) {
                console.log("Sort change", this.sorts);

                if (!!this.sorts) {
                    this._dataService.baseQueryService.resetOrderBy()
                    this.sorts.forEach(sort => this._dataService.baseQueryService.setOrderBy(sort.sortField, sort.dir));
                }
            }

            if (!!this._dataService) {
                console.log("Data Service Refresh");
                this._dataService.enabled = true;
                this._dataService.refresh(true);
            }
        }


    }

    private getFilteredColumns() {
        if (!!this.columnList && this.columnList.length > 0) {
            return this.columnList.map(fieldName => this._columnDictionary[fieldName]);
        } else {
            return this.columnSettings;
        }
    }

    private createColumnDictionary(columnSettings: IColumnSetting[]) {
        const columnDictionary = {};
        columnSettings.forEach(column => {
            columnDictionary[column.fieldName] = column;
        });
        return columnDictionary;
    }

    ngOnInit() {
        this.subscriptions.push(this._dataService.oLoadingStatusResult.subscribe(state => {
            this.loadingState = state;
            this.ref.markForCheck();
        }))
    }

    ngAfterViewInit() {

    }

    private subscribeToData() {

        const $context =
            zip(this._dataService.oData, this._dataService.oResultLength, this._dataService.oQuery)
                .pipe(map((results) => {
                    const data = results[0];
                    const total = results[1];
                    const query = results[2];


                    if (!!data) {
                        const state = {
                            skip: this._dataService.start - 1,
                            take: this._dataService.pageSize,

                        }

                        return new DataSourceContext(
                            data, total, query,
                            query.sorts.map(s => ({ field: s.sortField, dir: s.dir })),
                            this._columnDictionary, this._dataService
                        );
                    }
                }));
        const subscription = $context.subscribe(context => this.context.emit(context));
        this.subscriptions.push(subscription);
    }


    private createDataService(fields: string[], dataMapper: Factory<any>): DiscovererDataService<any> {
        this._dataService = new DiscovererDataService(this._httpService, this.esQueryService, dataMapper);
        this._dataService.enabled = false;
        this._dataService.init(this.serviceUrl, this.queryService, 'sub-table');
        this._dataService.setFields(fields)
        if (!!this.filters) {
            this.filters.forEach(filter => this._dataService.setFilter(filter.fields.join('-'), filter));
        }
        this._dataService.setPageParams(Math.floor(this.start / this.size) + 1, this.size);

        this.dataServices.push(this._dataService);
        return this._dataService;
    }



}
