import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseController, DiscovererQueryService, DRFacetOption, DRFilter, FacetValue, ReportPersistService, TabSettingsService } from '@discoverer/core';
import { WorkspaceNotificationServiceInterface } from '@discoverer/core/services/utility-services/workspace-notification.service';
import { DiscoReportContext } from '@discoverer/dynamic-reports';
import { environment } from '@env/environment';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { EmitterService } from '../../services/emitter.service';
import { UPLOADED_ON_KEY } from '../../services/ignite-tab.service';
import { SchemaService } from '../../services/schema.service';
import { ToolbarService } from '../../services/toolbar.service';
import { VerticalSidebarService } from '../ai-layout/vertical-sidebar/services/vertical-sidebar.service';

@Component({
    selector: 'pipeline-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss', '../../../../../../../src/styles/ai-shared-styles.scss']
})
export class ResultsComponent extends BaseController implements OnInit {
    datasetId: any;
    showUploader = false
    showWorkspace = false;
    appId: string = environment.auth.productKey;
    enableSelect: boolean = false;
    public groupsToQuery = []
    public groups: {
        fieldName: string,
        dir: 'asc' | 'desc'
    }[];
    sub: Subscription;

    constructor(
        public queryService: DiscovererQueryService,
        private _activated: ActivatedRoute,
        private _toolbarService: ToolbarService,
        private _router: Router,
        private _emitterService: EmitterService,
        private _tabSettingsSer: TabSettingsService,
        private _schemaService: SchemaService,

        @Inject(WorkspaceNotificationServiceInterface) private _workspaceNotificationService: WorkspaceNotificationServiceInterface,
        private _sidebarService: VerticalSidebarService
    ) {
        super();
        this.subscriptions.push(this._activated.queryParams.pipe(filter(params => !!params?.refresh)).subscribe(params => {
            this._router.navigate([], { queryParams: { refresh: null }, queryParamsHandling: 'merge' })
        }));

        this.subscriptions.push(this._router.events
            .pipe(filter(event => event instanceof NavigationEnd))  // Filter only NavigationEnd events
            .subscribe(async (event: NavigationEnd) => {
                this._activate()
                console.log('Route changed to:', event.urlAfterRedirects);
            }));

        this.subscriptions.push(this._sidebarService.oResultsLengthChange.pipe(debounceTime(500)).subscribe(() => {
            this.queryService.refresh();
        }));

    }
    async ngOnInit(): Promise<void> {
        this._activate();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
        super.ngOnDestroy();
    }

    async displayDetailsDialog(data: any, businessKey: string, workSpaceReport: ReportPersistService) {
        const fileId = data?.currentObject[businessKey][0];
        const currentReqId = (await workSpaceReport.createRequest());
        const requestCopy = JSON.parse(JSON.stringify(workSpaceReport.oLastRequestData.getValue()));
        requestCopy.mainView.dirId = null;
        requestCopy.query.filters.push(this._currentTimeFilter);
        await workSpaceReport.updateRequest(currentReqId, requestCopy, false);
        const queryParams = { currentReqId, start: data?.rowIndex + data.skip };
        this._router.navigate([fileId], {
            queryParams,
            relativeTo: this._activated,
            queryParamsHandling: 'merge',
            state: {
                pipelineid: this.datasetId
            }
        });
    }
    private async _activate() {
        const { subDatasetId, datasetId } = this._activated.snapshot.params;
        this.datasetId = subDatasetId || datasetId;
        this._tabSettingsSer.setCurrentTab(this.datasetId);
        this._emitterService.setDatasetId(this.datasetId);
        this._schemaService.updateDatasetSchema(this.datasetId);
        await this._toolbarService.setSettings(this.datasetId, this._activated.snapshot.routeConfig.data);
        this.queryService.resetFilters();
        this.queryService.refresh();
    }

    setContext(context: DiscoReportContext) {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.sub = context.reportPersistService.mainQueryService.oQuery.pipe(debounceTime(200)).subscribe(query => {
            this._sidebarService.setLastRefreshAt(new Date().toISOString());
        });
    }

    private get _currentTimeFilter() {
        const val = "[* TO " + moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') + "]";
        const facetValue = new FacetValue(new DRFacetOption(val, val, ''));
        return new DRFilter('facet', [UPLOADED_ON_KEY], [facetValue]);
    }
}
