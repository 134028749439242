<mat-card (mouseover)="showClearButtonWhenCardIsNotOpen = true" (mouseleave)="showClearButtonWhenCardIsNotOpen = false"
    class="card" color="primary" *ngIf="isCollapsible; else List">
    <mat-card-content>
        <div class="facet-header">
            <div class="progress-bar-area">
                <mat-progress-bar *ngIf="loadingState?.status == 'Busy'" mode="indeterminate"></mat-progress-bar>
            </div>
            <div (click)="facetTitleClicked()" class="title-container">
                <button mat-icon-button mat-button>
                    <mat-icon>{{isOpen ? 'keyboard_arrow_down':'keyboard_arrow_right'}}</mat-icon>
                </button>
                <div class="facet-title">{{title}}</div>
            </div>

            <div class="sub-title" *ngIf="!isOpen && isFilterApplied()">
                <span>{{getFilterDisplay()}}</span>
                <span *ngIf="showClearButtonWhenCardIsNotOpen" class="clear-button" (click)="clearFilter()">CLEAR</span>
            </div>
        </div>
        <mat-list class="filterList" *ngIf="isOpen">
            <ng-container *ngTemplateOutlet="facetList;"></ng-container>
            <!-- <mat-spinner *ngIf="min === undefined || max === undefined" diameter="20"></mat-spinner> -->
        </mat-list>
    </mat-card-content>
</mat-card>

<ng-template #List>
    <div class="list-container">
        <div class="header">
            <div *ngIf="title" class="uncollapsible-title">
                <div>{{ title }}</div>
            </div>
        </div>
        <ng-container *ngTemplateOutlet="facetList;"></ng-container>
    </div>
</ng-template>

<ng-template #facetList>
    <div *ngIf="(numericRangeFilterService.oState | async) as output" class="numeric__facet__wrapper">
        <div>
            <!-- <disco-numeric-histogram *ngIf="minMaxLoaded" class="histogram" [fieldName]="facetKey"
                [serviceUrl]="serviceUrl" (seriesClicked)="seriesClicked($event)" [min]="value1||value1==0?value1:min"
                [max]="value2||value2==0?value2:max" [queryService]="queryService">
            </disco-numeric-histogram> -->
        </div>
        <!-- <div *ngIf="minMaxLoaded" >
            <ion-range-slider type="facetType" [disable]="!enabledMin&&!enabledMax" [prettify]="formatNumber.bind(this)" [min]="min" [max]="max" [step]="step"
                [from]="value1" [to]="value2" (onFinish)="sliderChange($event)" >
            </ion-range-slider>
        </div> -->
        <div class="flex flex-row justify-between align-center">
            <div class="numeric__facet__wrapper__masterSelector">
                <mat-form-field class="numeric__facet__wrapper__masterSelector--dropdown">
                    <mat-select [value]="output.masterSelection"
                        (selectionChange)="numericRangeFilterService.setMasterSelection($event.value)">
                        <mat-option [value]="operation.id" *ngFor="let operation of numericOperations">
                            {{operation.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="showPin" class="action-icon" (click)="addPinnedFilter()">
                <mat-icon [fontSet]="isPinned ? 'material-icons' : 'material-icons-outlined'">push_pin</mat-icon>
                <span class="pointer">{{isPinned ? 'Pinned' : 'Pin'}}</span>
            </div>
        </div>
        <div class="divider">
            <mat-divider></mat-divider>
        </div>
        <ng-container *ngIf="doubleInputsUICondition(output)">
            <div class="numeric__facet__wrapper__masterSelector--multiInputs">
                <div *ngIf="output.masterSelection?.includes('BETWEEN') || output.masterSelection?.includes('MORE') || output.masterSelection == 'EQUALS'  || output.masterSelection == 'NOT_EQUALS'  "
                    class="numeric__facet__wrapper__masterSelector--multiInputs__wrapper">
                    <ng-container *ngIf="output.masterSelection?.includes('BETWEEN')">
                        <ng-container *ngFor="let operation of numericMinOperations">
                            <button class="toggle-button"
                                (click)="numericRangeFilterService.setValue1IsInclusive(!operation.id.includes('EQUALS'))"
                                *ngIf="operation.id == (output.value1IsInclusive ? 'EQUALS_MORE_THAN' : 'MORE_THAN')">
                                {{ operation.character }}
                            </button>
                        </ng-container>
                    </ng-container>

                    <input matInput type="number" placeholder="Enter Value" class="input" [ngModel]="output.value1"
                        (ngModelChange)="numericRangeFilterService.setValue1($event)">
                </div>
                <div *ngIf="output.masterSelection?.includes('BETWEEN') || output.masterSelection?.includes('LESS')"
                    class="numeric__facet__wrapper__masterSelector--multiInputs__wrapper">
                    <ng-container *ngIf="output.masterSelection?.includes('BETWEEN')">
                        <ng-container *ngFor="let operation of numericMaxOperations">
                            <button class="toggle-button"
                                (click)="numericRangeFilterService.setValue2IsInclusive(!operation.id.includes('EQUALS'))"
                                *ngIf="operation.id == (output.value2IsInclusive ? 'EQUALS_LESS_THAN' : 'LESS_THAN')">
                                {{ operation.character }}
                            </button>
                        </ng-container>
                    </ng-container>

                    <input matInput type="number" placeholder="Enter Value" class="input" [ngModel]="output.value2"
                        (ngModelChange)="numericRangeFilterService.setValue2($event)">
                </div>
            </div>
        </ng-container>
        <app-facet-actions *ngIf="isOpen"
            [showClearButton]="(isCollapsible && clearButtonKey && isOpen && !!isFilterApplied()) || (!clearButtonKey && !!isFilterApplied())"
            (clearButtonClicked)="clearFilter()" [showDoneButton]="showDoneButton"
            (doneButtonClicked)="doneButtonClicked.emit()">
        </app-facet-actions>
    </div>
</ng-template>