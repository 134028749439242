import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { DataTypeIcons } from '@discoverer/constants';
import { FieldDataType } from '@discoverer/enums';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'field-detail',
    templateUrl: './field-detail.component.html',
    styleUrls: ['./field-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FieldDetailComponent implements OnInit {
    @Output()
    public inputChange = new EventEmitter();
    @Output()
    public delete = new EventEmitter();
    @Input()
    public formGroup: FormGroup;
    @Input()
    public index: number;
    @Input()
    public parentIndex: number;
    @Input()
    public isNested: boolean = false;
    @Input()
    public isEditMode: boolean = false;
    @Input()
    public additionalItem: boolean = false;
    @Input()
    public objectFormGroup: FormGroup = null;
    public FIELD_TYPE_OPTIONS = [];
    public rowEnabled: boolean = false;
    public isFocused = false;
    defaultDescription: string = '-';

    initializeDescriptionValue(): void {
        const descriptionControl = this.formGroup.get('description');
        if (descriptionControl) {
            const value = descriptionControl.value?.trim() || '';
            descriptionControl.setValue(this.isEditMode ? value : value || '-');
        }
    }

    constructor(@Inject(DOCUMENT) private document: Document, private cd: ChangeDetectorRef) { }

    onDragStart(): void {
        this.document.body.style.cursor = 'grabbing';
    }

    onDragEnd(): void {
        this.document.body.style.cursor = 'default';
    }

    ngOnInit(): void {
        this.updateFieldTypeOptions();
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes['objectFormGroup']) {
            this.updateFieldTypeOptions();
        }
        this.initializeDescriptionValue();
    }
    public changeEvent(controlName = null) {
        this.inputChange.emit(controlName);
    }

    public deleteField(i: any) {
        this.delete.emit(i)
    }

    enableRowEditing(dom: HTMLElement) {
        if (!this.isEditMode) {
            return;
        }
        dom.classList.add('active')
        this.rowEnabled = true;

    }

    disableRowEditing(dom: HTMLElement) {
        if (this.additionalItem) return;

        dom.classList.remove('active');
        this.rowEnabled = false;

    }

    onFocus() {
        this.isFocused = true;
    }

    onBlur() {
        this.isFocused = false;
    }

    private updateFieldTypeOptions() {
        this.FIELD_TYPE_OPTIONS =
            [
                { value: '', display: 'Type' },
                { value: FieldDataType.STRING, display: 'Text', icon: DataTypeIcons.get(FieldDataType.STRING )},
                { value: FieldDataType.NUMBER, display: 'Number' , icon: DataTypeIcons.get(FieldDataType.NUMBER ) },
                { value: FieldDataType.DATE, display: 'Date'  , icon: DataTypeIcons.get(FieldDataType.DATE)},
                { value: FieldDataType.CURRENCY, display: 'Currency' , icon: DataTypeIcons.get(FieldDataType.CURRENCY) },
                { value: FieldDataType.LINK_WEBSITE, display: 'URL' , icon: DataTypeIcons.get(FieldDataType.LINK_WEBSITE) },
                { value: FieldDataType.EMAIL, display: 'Email'  , icon: DataTypeIcons.get(FieldDataType.EMAIL )}
            ];

        if (!this.isNested) {
            this.FIELD_TYPE_OPTIONS = [...this.FIELD_TYPE_OPTIONS,
            { value: FieldDataType.OBJECT_ARRAY, display: 'Table',icon: DataTypeIcons.get(FieldDataType.OBJECT_ARRAY )  },
            { value: FieldDataType.OBJECT , display: 'Section', icon: DataTypeIcons.get(FieldDataType.OBJECT ) }
            ];
        }
    }

    getSelectedIcon(): string {
        const value = this.formGroup.get('dataType').value;
        if (!value) return 'arrow_drop_down';
        const option = this.FIELD_TYPE_OPTIONS.find(opt => opt.value === value);
        return option?.icon || 'arrow_drop_down';
    }
}
