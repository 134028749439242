import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormsDataServiceInterface } from '@discoverer/dynamic-forms/dyn-forms/services';
import { FileDetailsHandlerService } from '../../shared-pages/file-details-handler.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FileFormsDataService implements FormsDataServiceInterface {

    public formServicePath: string = '/api/es/form/docpipeline';

    // Static cache shared across all instances
    private static cache: Map<string, { value: Observable<any>; timeoutId: any }> = new Map();

    constructor(
        private http: HttpClient,
        public _fileDetailsHandlerService: FileDetailsHandlerService,
    ) { }

    public getFormByKey(tabId: string): Observable<any> {
        const cached = FileFormsDataService.cache.get(tabId);

        if (cached) {
            // Reset the timeout to start again
            clearTimeout(cached.timeoutId);

            const newTimeoutId = setTimeout(() => {
                FileFormsDataService.cache.delete(tabId);
            }, 1000);
            FileFormsDataService.cache.set(tabId, { value: cached.value, timeoutId: newTimeoutId });
            return cached.value;
        }

        // Make a new HTTP request if not cached
        const request$ = this.http.get(`${this.formServicePath}/${tabId}/Details`).pipe(
            shareReplay(1)
        );

        const timeoutId = setTimeout(() => {
            FileFormsDataService.cache.delete(tabId);
        }, 1000);

        FileFormsDataService.cache.set(tabId, { value: request$, timeoutId });

        return request$;
    }

    postFormData(object: any) {
        Object.keys(object).forEach(key => {
            if (object[key] instanceof Date) {
                object[key] = new Date(object[key]).toUTCString();
            }
        });
        return this.http.post(`${this.formServicePath}`, object);
    }
}
