
import { mapAggregateName } from '../functions';
import { process } from '@progress/kendo-data-query';

export abstract class PivotChartCalcs{
    public static groupData(result, group, functionType, compareType = 'Percentage') {
        var aggdata;
        const fct = mapAggregateName(functionType);
        if (group) {
            group.filter(g => !g.field.includes('.val1')).forEach(g => g.field = g.field + '.val1');
            aggdata = process(result, { group });
            if (compareType) {
                aggdata.data.forEach(d => {
                    if (d.aggregates) { this.addCompare(d, compareType, fct); }
                });
            }
        }
        return aggdata?.data;
    }
    public static addCompare(item, compareType, fct) {
        var keys = Object.keys(item.aggregates).filter(key => key.endsWith('.val1')).map(key => key.replace('.val1', ''));
        keys.forEach(key => {
            const val1 = item.aggregates[`${key}.val1`][fct] || 0;
            const val2 = item.aggregates[`${key}.val2`] ? item.aggregates[`${key}.val2`][fct] : 0;
            item.aggregates[`${key}.valDiff`] = this.calcCompareTotal(val1, val2, compareType);
        })
        if (item.items) {
            item.items.forEach(ite => {
                if (ite.aggregates) { this.addCompare(ite, compareType, fct); }
            });
        }
    }
    public static calcCompareTotal(val1 = 0, val2 = 0, displayMode) {
        if (val1 !== null && val2 !== null) {
            return displayMode === 'Values' ? this._getDiffrence(val1, val2) : this._getPercentage(val1, val2);
        }
        return null;
    }
    public static _getDiffrence(current, previous) {
        return (current - previous);
    }

    public static _getPercentage(current, previous) {
        const val = (current - previous) / (previous === 0 ? 1 : previous);
        return (val * 100);
    }
}
