<div class="wrapper">
    <div *ngIf="true && data.value" class="table-wrapper">
        <p class="field-name">{{data.display}}
            <mat-icon class="copy-icon" *ngIf="!isCsvCopied" (click)="copyAsCsv()"
                matTooltip="Copy As CSV ">content_copy</mat-icon>
            <mat-icon class="copy-icon" *ngIf="isCsvCopied">check</mat-icon>
        </p>

        <kendo-grid [data]="mapGridData()" [resizable]="true">
            <kendo-grid-column [width]="200" *ngFor="let col of displayedColumns" [field]="col.fieldName"
            [title]="col.display">
            <ng-template kendoGridCellTemplate let-dataItem let-fieldName>
                <ng-container *ngIf="isLink(col.dataType); else regularCell">
                <a [href]="dataItem[col.fieldName]" target="_blank" rel="noopener"
                    class="link-value">{{dataItem[col.fieldName]}}</a>
                </ng-container>
                <ng-template #regularCell>
                <span>{{dataItem[col.fieldName]}}</span>
                </ng-template>
            </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</div>