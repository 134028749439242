import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DialogsManagerService } from '../../../dialogs';
import { BaseController, IKanbanViewState, IList, ReportPersistService, StateService, TabSettingsService } from '@discoverer/core/services';

@Component({
    selector: 'kanban-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})


export class ListComponent extends BaseController implements OnInit {

    @Input() list: IList;
    @Input() kanbanViewState: StateService<IKanbanViewState>;
    @Output() listScroll: EventEmitter<IList> = new EventEmitter<IList>();
    constructor(protected dialogsManagerService: DialogsManagerService,
        protected tabSettings: TabSettingsService,
        protected reportPersist: ReportPersistService,
    ) {
        super();
    }

    ngOnInit() {
    }
    public ListScroll(event: any, list: IList) {
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 2) {
            this.listScroll.emit(list);
        }
    }
}
