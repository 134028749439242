<div class="date-picker-contaier" *ngIf="!to.disabled; else temp">
    <input [tabIndex]="to?.tabindex" matInput [ngxMatDatetimePicker]="picker" [required]="to.required"
    [formControl]="$any(formControl)" [min]="field?.templateOptions?.minValue" [max]="field?.templateOptions?.maxValue">
    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
    <ngx-mat-datetime-picker #picker [enableMeridian]="true" [showSeconds]="field?.templateOptions?.showSeconds">
    </ngx-mat-datetime-picker>
</div>
<ng-template #temp>
    <div class="flex flex-col">
        <input type="text" [value]="returnDate()" disabled>
    </div>
</ng-template>