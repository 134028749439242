import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { convertToString } from "@app/ignite/components/reports/helpers/util";
import { ColumnSetting } from '@app/ignite/components/reports/models/column-settings';
import { getLinkFieldTypes } from '@discoverer/constants';
import { IColumnSetting, DataFormattingService } from '@discoverer/core';
import { FieldDataType } from '@discoverer/enums';

export interface ISchema {
    type: string
    description: string
    display: string
    fields?: ISchema
}

@Component({
    selector: 'app-table-object',
    templateUrl: './table-object.component.html',
    styleUrls: ['./table-object.component.scss']
})
export class TableObjectComponent implements OnInit {

    @Input() data: { key: string, value: any, display: string, schema: any }
    @Input() columnSettings: ColumnSetting[]

    isCsvCopied = false;
    displayedColumns: { display: string, fieldName: string, dataType: string }[] = [];

    constructor(
        private clipboard: Clipboard,
        private dataFormattingService: DataFormattingService
    ) { }

    ngOnInit() {
        
        this.displayedColumns = this.getFilteredColumns();
        if (!this.displayedColumns.length) {
            
            this.displayedColumns = this.data.schema?.map(schem => ({
                display: schem.display,
                fieldName: schem.key,
                dataType: schem.type
            })) || [];
        }
    }

    public isLink(dataType: string): boolean {
        return getLinkFieldTypes().includes(dataType);
    }
    private getFilteredColumns(): Array<{ display: string, fieldName: string, dataType: string }> {
        return this.columnSettings
            ?.filter(column => this.isValidColumn(column))
            ?.map(column => this.mapColumn(column));
    }

    private isValidColumn(column: IColumnSetting): boolean {
        return !!column.nestedPath && column.fieldName.includes(`${this.data.key}`);
    }

    private mapColumn(column: IColumnSetting): { display: string, fieldName: string, dataType: string } {
        
        return {
            display: column.display.split(' - ')[column.display.split(' - ').length - 1],
            fieldName: column.fieldName.split('.')[column.fieldName.split('.').length - 1],
            dataType: column.dataType,

        };
    }

    getDisplay(key: string): string {
        return this.data.schema[key]?.display || key;
    }

    formatCellValue(value: any, schemaType: string, dataType: string): string {
        if (!value) return '-';
        if (schemaType === 'single') {
            return this.dataFormattingService.getValue(value, dataType);
        }
        return this.dataFormattingService.getValue(value, schemaType);
    }

    mapGridData() {
        return this.data.value.map(row => {
            const formattedRow = {};
            this.displayedColumns.forEach(col => {
                const schema = this.data.schema.find(s => s.key === col.fieldName);
                formattedRow[col.fieldName] = this.formatCellValue(
                    row[col.fieldName],
                    schema?.type,
                    col.dataType
                );
            });
            return formattedRow;
        });
    }

    copyAsCsv() {
        const keys = Object.keys(this.data.schema)
        const headers = keys.map(header => convertToString(this.getDisplay(header)))
        const csvContent = [
            headers,
            ...this.data.value.map(row => keys.map(column => convertToString(row[column])))
        ].map(e => e.join(",")).join("\n");
        this.clipboard.copy(csvContent);
        this.isCsvCopied = true;

        setTimeout(() => this.isCsvCopied = false, 4000);
    }
}
