<div class="filter-groups" layoutDir="row">
    <div class="groups-wrapper gap-1" style="flex-grow: 1;overflow: auto"
        *ngIf="(queryService.oQuery | async) as currentQuery">
        <div *ngIf="($displayedFilters | async).length > 0" class="vl"></div>
        <router-outlet name="topfilters"></router-outlet>
        <ng-container *ngIf="$displayedFilters | async as appliedFilters">
            <ng-container *ngFor="let filter of appliedFilters; trackBy: trackByFields">
                <div class="filter-group" *ngIf="!!filter.facetValues">
                    <div class="top-label" *ngIf="!!columnDictionary ">
                        <div class="filter-title">
                            <list-form [closeMenuInput]="closeMenu" [showFooter]="false" [title]="filter.facetTitle + (filter.isExclusion ? '(Excluded)' : '')" [info]="filter.facetValues"
                                [initializeOpened]="filter.openFacet" (menuOpened)="filter.openFacet = $event; removeEmptyfacetFilters($event);"
                                class="list">
                                <div class="flex row pt-1 px-2.5" *ngIf="filter.openFacet">
                                    <div class="facet-container">
                                        <div class="facet-item">
                                            <facet-container [isCollapsible]="false" [facet]="filter.facet"
                                                [clearButtonKey]="false" [currentTab]="currentTab"
                                                [filterMode]="'All'" [queryService]="queryService" [isOpen]="true"
                                                [isFilterExcluded]="filter?.isExclusion" [showPin]="true" [showDoneButton]="true"
                                                (doneButtonClicked)="filter.openFacet = false">
                                            </facet-container>
                                        </div>
                                    </div>
                                </div>
                            </list-form>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let filter of $displayedAdvFilters | async;">
            <div class="filter-groups">
                <div class="top-label advanced">
                    <div class="title">Advanced Filter </div>
                    <div class="items">
                        <search-header-advanced-filter-container [filter]="filter" [advFilterService]="advFilterService"
                            [columnDictionary]="columnDictionary">
                        </search-header-advanced-filter-container>
                        <div class="filter-item">
                            <mat-icon class="close-icon edit-position" (click)="showAdvancedModal(0)">edit
                            </mat-icon>
                            <mat-icon class="close-icon" (click)="removeAdvancedFilter(0)">close</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
