import { Injectable } from "@angular/core";

export abstract class RoleChecker {
    public abstract hasRole(string: string): Promise<boolean>;
}


@Injectable()
export class AlwaysHasRoleChecker implements RoleChecker {
  public async hasRole(string: string): Promise<boolean> {
    return true;
  }
}
