import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DRQuery, DRResult, fixTimeZone } from '../../../core/services';
import { map } from 'rxjs/operators';

export class ChartGroupBy {
    start: string;
    end: string;
    gap: string
};
@Injectable({
    providedIn: 'root'
})
export class HistogramService {

    constructor(private _http: HttpClient) {

    }

    public async getNumericHistogramData(req: DRQuery, groupBy: ChartGroupBy, fieldName: string, serviceUrl: string): Promise<any> {
        let histogramData = [];
        var drQuery = this._buildQuery(req, groupBy, fieldName);
        var res = await this._httpReq(drQuery, serviceUrl);
        histogramData = res.facets[fieldName].buckets.map((bucket) => {
            return { value: bucket.value, tag: bucket.key };
        });
        return histogramData;
    }

    public async getDateHistogramData(req: DRQuery, groupBy: ChartGroupBy, fieldName: string, serviceUrl: string): Promise<any> {
        let histogramData = [];
        var drQuery = this._buildQuery(req, groupBy, fieldName);
        if (!groupBy.gap.startsWith('+0.')) {
            var res = await this._httpReq(drQuery, serviceUrl);
            histogramData = res.facets[fieldName].buckets.map((bucket) => {
                const minDate = fixTimeZone(bucket.key.split('T')[0]);
                const minDateFormated = minDate.getMonth() + 1 + '-' + minDate.getDate() + '-' + minDate.getFullYear();
                return { value: bucket.value, tag: minDateFormated };
            });
        }
        return histogramData;
    }

    private _buildQuery(req: DRQuery, groupBy: ChartGroupBy, fieldName: string): DRQuery {
        var sendQuery = new DRQuery();
        sendQuery.countSubRecords = false;
        sendQuery.size = 0;
        sendQuery.start = 0;
        sendQuery.stats = [{
            name: 'stat' + 1,
            type: 'stat',
            functionType: 'count',
            field: fieldName ? fieldName : ''
        }];
        sendQuery.filters = req.filters;
        sendQuery.facets = [this._getSingleFacetDef(groupBy, fieldName)];
        return sendQuery;
    }

    private _getSingleFacetDef(groupBy, fieldName) {
        const facetDef = {
            type: '',
            name: fieldName,
            field: fieldName,
            limit: 1500,
            statSortDir: 0,
            mincount: 1,
            sort: 'stat1 asc',
            includeAllFilters: false,
            subFacet: null
        };
        facetDef.type = 'range';
        facetDef['start'] = (groupBy) ? Math.floor(groupBy.start) : 0;
        facetDef['end'] = (groupBy) ? groupBy.end : 1;
        facetDef['gap'] = (groupBy) ? groupBy.gap : 1;
        return facetDef;
    }

    private async _httpReq(sendQuery: DRQuery, serviceUrl: string) {
        return this._http
            .post(serviceUrl, sendQuery)
            .pipe(map((res: any) => {
                const obj: DRResult<any> = new DRResult<any>();
                Object.assign(obj.responseHeader, res.responseHeader);
                Object.assign(obj.facets, res.facets);
                obj.response.numFound = res.response.numFound;
                obj.response.start = res.response.start;
                obj.esQuery = res.query;
                return obj;
            })).toPromise();
    }
}
