<div class="wrapper">
  <div class="flex items-center gap-4">
    <header>
      Connections
    </header>
    <button (click)="openNewConnectionDialog()" mat-mini-fab color="primary">
      <mat-icon>add</mat-icon>
    </button>
  </div>


  <div *ngIf="connections?.length == 0">
    <div class="text-base font-medium">
      You have no connections!
    </div>
  </div>

  <mat-table *ngIf="connections?.length > 0" [dataSource]="connections">
    <ng-container matColumnDef="connectionName">
      <mat-header-cell *matHeaderCellDef>Connection Name</mat-header-cell>
      <mat-cell *matCellDef="let connection">
        <ng-container *ngIf="!connection.isEditing">
          {{connection.connection_name}}
        </ng-container>
        <ng-container *ngIf="connection.isEditing">
          <input class="editable-connection-name" [(ngModel)]="connection.newConnectionName" />
        </ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="application">
      <mat-header-cell *matHeaderCellDef>Application</mat-header-cell>
      <mat-cell *matCellDef="let connection">{{connection.display_name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="created">
      <mat-header-cell *matHeaderCellDef>Created</mat-header-cell>
      <mat-cell *matCellDef="let connection">
        {{connection.created_at | date:'medium'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let connection;">
        <ng-container *ngIf="!connection.isEditing">
          <button mat-icon-button [matMenuTriggerFor]="summaryMenu">
            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:dots-vertical'"></mat-icon>
          </button>
          <mat-menu #summaryMenu="matMenu">
            <button (click)="testConnection(connection)" mat-menu-item>
              <mat-icon class="icon-size-5 mr-3 duplicate-mat-icon" [svgIcon]="'heroicons_solid:refresh'"></mat-icon>
              Test
            </button>
            <button (click)="editConnection(connection)" mat-menu-item> 
              <mat-icon class="icon-size-5 mr-3 duplicate-mat-icon" [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
              Edit
            </button>
            <button mat-menu-item (click)="deleteConnection(connection.connection_name)"> 
              <mat-icon class="icon-size-5 mr-3 " [svgIcon]="'heroicons_solid:trash'"></mat-icon>
              Delete
            </button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="connection.isEditing">
          <button mat-icon-button color="warn" [matTooltip]="'Cancel'"
            (click)="cancelEdit(connection)">
            <mat-icon>cancel</mat-icon>
          </button>
          <button mat-icon-button color="primary" [matTooltip]="'Save'"
            (click)="saveConnection(connection)">
            <mat-icon>save</mat-icon>
          </button>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>