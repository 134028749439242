import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { DatasetService } from './api/dataset.service';

export interface IToolbarSettings {
    title: string,
    subTitle?: string,
    showBackButton: boolean
}

@Injectable({
    providedIn: 'root'
})
export class ToolbarService {
    private _toolbarSettings$: ReplaySubject<IToolbarSettings>;
    public oToolbarSettings: Observable<IToolbarSettings>;

    constructor(private _datasetService: DatasetService) {
        this._toolbarSettings$ = new ReplaySubject<IToolbarSettings>(1);
        this.oToolbarSettings = this._toolbarSettings$.asObservable();
    }


    public async setSettings(datasetId: string, data) {
        if (!!datasetId) {
            const dataset = await this._datasetService.getCurrentBaseDatasetDetails();
            this._toolbarSettings$.next({
                title: dataset.Title,
                subTitle: dataset.EmailAlias,
                showBackButton: data.showBackButton
            })
        } else {
            this._toolbarSettings$.next({
                title: data?.title,
                showBackButton: data.showBackButton
            })
        }
    }



}