import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDashboardReportConfig, IDynamicReport, IRequest, ReportSubType } from '@discoverer/core/services';

export class FlattenedDirRequests implements IRequest {
    id: string;
    createTime: string;
    requestName: string;
    requestOrder: number;
    updateTime: string;
    description: string;
    type: string;
    subType: ReportSubType;
    request: IDynamicReport;
    selected: boolean;
    icon?: string;
    directoryId?: number;
    tabName?: string;
    directoryName?: string;
    isDirCommon?: boolean;
    tabKey: string
}

@Component({
    selector: 'filtered-reports-view',
    templateUrl: './filtered-reports-view.component.html',
    styleUrls: ['./filtered-reports-view.component.scss']
})

export class FilteredReportsViewComponent  {
    @Input() requests: FlattenedDirRequests[];
    @Input() highlitedRequests: IDashboardReportConfig[] = [];
    @Input() isOpenReport: boolean = true;
    @Input() searchText: string = '';

    @Output() scrollingFinished: EventEmitter<void> = new EventEmitter<void>()
    @Output() clickedRequest: EventEmitter<FlattenedDirRequests> = new EventEmitter<FlattenedDirRequests>();
    constructor() { }

    public requestClicked(request: FlattenedDirRequests) {
        this.clickedRequest.emit(request);
    }

    public reportIsSelected(request: IRequest) {
        return this.highlitedRequests?.find(req => request.id === req.id);
    }
}
