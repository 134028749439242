import { Injectable } from '@angular/core';
import { SchemaService } from './schema.service';
import { IgniteTabService } from './ignite-tab.service';
import { take } from 'rxjs/operators';
import { ParsedTabs } from '../models/enums';
import { DatasetService } from './api/dataset.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    constructor(
        private _schemaService: SchemaService,
        private _igniteTabService: IgniteTabService,
        private _datasetService: DatasetService
    ) { }

    public async updateColumnAndAssets(tabName: ParsedTabs,  datasetId: string ): Promise<void> {
        await this._schemaService.updateDatasetSchema(datasetId);
        const fields = await this._schemaService.oCurrentSchema.pipe(take(1)).toPromise();
        const files = await this._igniteTabService.initTab(tabName, fields, datasetId);
        await this._datasetService.createOrUpdateAssets(datasetId, files).toPromise();
    }


}