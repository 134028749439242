import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FadeInOutOptimizedAnimation, heightExpand } from "@app/app.animation";
import { BaseController, DiscovererQueryService, RequestFilingService } from '@discoverer/core/services';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [FadeInOutOptimizedAnimation, heightExpand],
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class DashboardComponent extends BaseController implements OnInit {
  reports
  tabId: any;
  appId: any;
  constructor(
    private requestFilingService: RequestFilingService,
    private activatedRoute: ActivatedRoute,
    public queryService: DiscovererQueryService,
    private _cdRef: ChangeDetectorRef,
    private router: Router

  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    const {
      directoryId,
      tabId
    } = this.activatedRoute.snapshot.params

    const {
      appId
    } = this.activatedRoute.snapshot.data
    this.tabId = tabId
    this.appId = appId

    const dirs = await this.requestFilingService.getDirectories(appId, tabId, false);
    const currentDirectory = dirs.find(d => d.id === +(directoryId))
    if (currentDirectory && currentDirectory.requests.length) {
      this.reports = currentDirectory.requests.map(r => { return {
        ...r,
        expand: true
       } })

    } else {
      console.warn('No avilable requests', currentDirectory)
    }
    this._cdRef.markForCheck();


    console.log('Hello world!')
  }

  navigateToReport(tabId, reportId) {
    this.router.navigate([this.appId, 'reports', tabId, reportId])
  }
}


