import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay/index';
import { Observable } from 'rxjs';
import { SaveReportDialog } from './save-report/save-report.dialog';
import { ColumnSettingsCustomizationDialog } from './column-settings-customization/column-settings-customization.dialog';
import { CustomConfirmDialog } from './confirm/confirm.dialog';

import { NewDirectoryDialog } from './add-directory/new-directory.dialog';
import { DiscovererQueryService, TabSettingsService, IMainViewState, IColumnSetting, StateService, IKanbanViewState, ITab2, IDashboardFilter } from '@discoverer/core/services';
import { KanbanCustomizationDialog } from './kanban-customization/kanban-customization.dialog';
import { EditTitleDialog } from './edit-title/edit-title.dialog';
import { RequestDirectoryDialog } from './request-directory/request-directory.dialog';
import { CopyTextDialog } from './copy-text-dialog/copy-text.dialog';
import { GroupDescriptor } from '@progress/kendo-data-query/dist/npm/grouping/group-descriptor.interface';
import { CategoryPickerDialog } from './category-picker-dialog/category-picker.dialog';
import {TabSelectorComponent} from './tab-selector/tab-selector.dialog'
import { NewReportComponent } from '../new-report/new-report.component';
import { DashboardFiltersComponent } from '../common/dashboard-filters/dashboard-filters.component';

@Injectable()
export class DialogsManagerService {

    constructor(private dialog: MatDialog) {

    }

    public openConfirmDialog(title: string, message: string): Observable<boolean> {
        const config: DialogConfig = new DialogConfig('confirm-dialog-container', { title, message }, '400px');
        return this.fireDialog(CustomConfirmDialog, config);
    }
    public openFilterSelectorDialog(filter?: IDashboardFilter): Observable<IDashboardFilter> {
        const config: DialogConfig = new DialogConfig('new-report-dialog', { filter }, 'unset');
        return this.fireDialog(DashboardFiltersComponent, config);
    }
    public openNewReportDialog(tabId: string, reportId?: string): Observable<{tab: string, newReqId: string, reportMainTitle: string}> {
        const config: DialogConfig = new DialogConfig('new-report-dialog', { tabId, reportId }, '80vw');
        return this.fireDialog(NewReportComponent, config);
    }
    public openShareDialog(app: string, tab: string, newRequestId: string) {
        const config: DialogConfig = new DialogConfig('new-directory-dialog-container', { app, tab, newRequestId }, '960px');
        return this.fireDialog(CopyTextDialog, config);
    }
    public openTabSelectorDialog(tabs: ITab2[]): Promise<ITab2> {
        const config: DialogConfig = new DialogConfig('new-directory-dialog-container', { tabs }, '400px');
        return this.fireDialog(TabSelectorComponent, config).toPromise();
    }

    public openCategoryPickerDialog(columns: IColumnSetting[], serviceUrl: string, mainQueryService: DiscovererQueryService) {
        const config: DialogConfig = new DialogConfig('new-directory-dialog-container', { columns, serviceUrl, mainQueryService }, '450px', 'auto', true);
        return this.fireDialog(CategoryPickerDialog, config);
    }

    public opeAddNewDirectoryDialog() {
        const config: DialogConfig = new DialogConfig('new-directory-dialog-container');
        return this.fireDialog(NewDirectoryDialog, config);
    }

    public openSaveReportDialog(tab) {
        const config: DialogConfig = new DialogConfig('save-report-dialog-container', { tab }, '960px');
        return this.fireDialog(SaveReportDialog, config);
    }

    public openRequestDirectoryDialog(tab) {
        const config: DialogConfig = new DialogConfig('save-report-dialog-container', { tab }, '960px');
        return this.fireDialog(RequestDirectoryDialog, config);
    }

    public openEditTitleDialog(title) {
        const config: DialogConfig = new DialogConfig('title-dialog-container', { title }, '600px');
        return this.fireDialog(EditTitleDialog, config);
    }

    public openColumnsSettingsDialog(
        reportColumns: IColumnSetting[],
        isColumns = false,
        tabSettings: TabSettingsService,
        queryService: DiscovererQueryService,
        mainViewState: StateService<IMainViewState>,
        selectedView: string,
        groupByColumns: GroupDescriptor[],
        selectedSetting?: string,
        showGroupFooter?: boolean
)
        : Observable<any> {
        const config: DialogConfig = new DialogConfig('column-settings-customization-dialog-container',
            {
                reportColumns,
                groupByColumns,
                isColumns,
                tabSettings,
                queryService,
                mainViewState,
                selectedView,
                selectedSetting,
                showGroupFooter
            });
        return this.fireDialog(ColumnSettingsCustomizationDialog, config);
    }


    public openKanbanDialog(tabSettings: TabSettingsService, queryService: DiscovererQueryService,
        kanbanViewState: StateService<IKanbanViewState>, mainViewState: StateService<IMainViewState>)
        : Observable<any> {
        const config: DialogConfig = new DialogConfig('kanban-customization-dialog-container', {
            kanbanListsInfo: null,
            tabSettings,
            queryService,
            kanbanViewState,
            mainViewState
        });
        return this.fireDialog(KanbanCustomizationDialog, config)
    }

    private fireDialog<T>(dialog: ComponentType<T>, config: DialogConfig) {
        const dialogRef = this.dialog.open(dialog, config);
        return dialogRef.afterClosed();
    }
}

class DialogConfig {
    constructor(
        public panelClass: string,
        public data?: any,
        public width: string = '960px',
        public height?: string,
        public autoFocus: boolean = false
    ) {

    }
}
