import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'dyn-link',
    template: ` <div class ="link" (click)= onClick($event)> {{this.field.templateOptions.text}} </div> `,
    styleUrls: ['./dyn-link.component.scss']
})
export class DynLinkComponent extends FieldType {
    constructor(private http: HttpClient) {
        super();
    }
    onClick(event) {
        this.http.post(this.field.templateOptions.action, this.field.templateOptions.includemodel ?
            this.field.model : {}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).toPromise().then(response => {
            if (response) {
                // const blob = new Blob([response], { type: response.type });
                const fileURL = URL.createObjectURL(response);
                window.open(fileURL, '_blank');
            }
        });
    }

}
