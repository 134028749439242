export const fixTimeZone = (dateStr: string = null): Date => {
  const isDateNum = +dateStr ? +dateStr : dateStr; // this to fix Octal literals issue
  const date: Date = (!!dateStr) ? new Date(isDateNum) : new Date();
  date.setUTCHours(date.getTimezoneOffset() / 60);
  return date;
};

export const generateHashFromString = (str: string): number => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    // tslint:disable-next-line:no-bitwise
    hash = ((hash << 5) - hash) + char;
    // tslint:disable-next-line:no-bitwise
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

export const generateHash = (obj: any): number => {
  const str = JSON.stringify(obj);
  return generateHashFromString(str);
};

export const saveFile = (data: Blob, filename: string) => {
  const fileURL = window.URL.createObjectURL(data);
  // IE11 why? because its a stupid kid browser, you need to handle it as such :)
   if (!! (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(data, filename);

  }

  const a = document.createElement('a');
  a.href = fileURL;
  a.download = filename;
  window.document.body.appendChild(a);
  a.click();
  window.document.body.removeChild(a);
  URL.revokeObjectURL(fileURL);
};

export const pendoInit = (user) => {
  const pendo = (<any>window).pendo;
  if (!!pendo) {
    pendo.initialize({
      visitor: {
        id: user.username   // Required if user is logged in
      },
      account: {
        id: !!user.groups ? user.groups[0] : null // Highly recommended
      }
    });
  }
};

export const groupBy = (array, key) => {
  const keyFn = key instanceof Function ? key : (obj) => obj[key];
  return array.reduce((objectsByKeyValue, obj) => {
    const value = keyFn(obj);
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
};

export const cleanBlankValues = (value: string) => {
  return (value === '(Blank)') ? '' : value;
};

export const disLogger = (msg: string, ...obj: any[]) => {
  try {
    const fullLogStack = new Error().stack;
    const logStack = fullLogStack.split('at')[2].split('.')[0];
    const now = new Date();
    const timeOnly = now.toLocaleTimeString() + `: ${now.getMilliseconds()} - ${logStack.trim()} - `;
    if (!!obj && obj.length !== 0) {
      if (obj.length === 1) {
        console.log(timeOnly, msg, obj[0]);
      } else {
        console.log(timeOnly, msg, obj);
      }
    } else {
      console.log(timeOnly, msg);
    }
  } catch (e) {
    const fullLogStack = new Error().stack;
    const now = new Date();
    const timeOnly = now.toLocaleTimeString() + `: ${now.getMilliseconds()} / ${fullLogStack} - `;
    console.error(timeOnly, ' Error happened when trying to log ', obj);
  }
};

export const stopImageDownload = () => {
  // Cancels image downloads, Warning dont use this function very carefully, it would stop scripts as well
  // currently it's being used in the routing handler
  // may not work on Safari? need to confirm
  console.warn('Stop Script Called');
  if (window.stop !== undefined) {
    window.stop();
  } else if (document.execCommand !== undefined) {
    document.execCommand('Stop', false);
  }
};

export const cloneDeep = (input: any) => {
  return JSON.parse(JSON.stringify(input));
};

export const mapFunctionName = (functionType: string) => {
  switch (functionType) {
    case 'sum':
      return 'Sum';
    case 'min':
      return 'Min';
    case 'max':
      return 'Max';
    case 'avg':
      return 'Avg';
    case 'count':
      return 'Count';
    case 'stddev':
      return 'Standard Deviation';
    case 'sumsq':
      return 'SumSQ';
  }
};


export const mapAggregateName = (functionType: string) => {
  switch (functionType) {
    case 'sum':
    case 'count':
      return 'sum';
    case 'min':
      return 'min';
    case 'max':
      return 'max';
    case 'avg':
      return 'average';
  }
};


export const isEmpty = (str: string): boolean => {
  return (!str || str.trim().length === 0);
}

export const searchForText = (findStr: string, inText: string): boolean => {
  var words = findStr?.toLowerCase()?.split(' ') ?? [];
  var text = inText?.toLowerCase() ?? '';
  return words.every(x => x.trim() == '' ? true : text.includes(x.trim()));
}
